import React, { FC, ReactNode } from "react";

import { Menubar } from "components/menubar/menubar";
import { Header } from "components/header/header";
import { ScrollLayout } from "components/scroll-layout/scroll-layout";

import styles from "./layout.module.scss";
import { LayoutType } from "types/utils";

type Props = {
  top: ReactNode;
  content: ReactNode;
  contentClassName?: string;
  topHeader?: string;
  contentHeader?: string;
  isCyclist?: boolean;
  dataTestId?: string;
  topMenuItems?: LayoutType;
  customHeader?: ReactNode;
  showAdditonalComps?: boolean;
  showCalendarComp?: boolean;
  contentCustomWrapper?:string;
  pinTab?:boolean
};

export const Layout: FC<Props> = ({
  topHeader,
  top,
  contentHeader,
  content,
  isCyclist,
  dataTestId,
  topMenuItems,
  customHeader,
  contentClassName,
  showAdditonalComps,
  showCalendarComp,
  contentCustomWrapper,
  pinTab=false
}) => {
  return (
    <div className={styles.wrapper} data-testid={dataTestId}>
      <Menubar />
      <div className={styles.page}>
        <Header customHeader={customHeader} isCyclist={isCyclist} hideBottom={false} />
        <ScrollLayout
          showAdditonalComps={showAdditonalComps}
          showCalendarComp={showCalendarComp}
          topMenuItems={topMenuItems}
          topHeader={topHeader}
          top={top}
          contentHeader={contentHeader}
          content={content}
          contentClassName={contentClassName}
          contentCustomWrapper={contentCustomWrapper}
          isCyclist={isCyclist}
          pinTab={pinTab}
        />
      </div>
    </div>
  );
};

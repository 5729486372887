import { FC, ChangeEvent, FormEvent, useCallback, useState } from "react";
import { Menubar } from "components/menubar/menubar";
import styles from "./settings.module.scss";
import { useForm } from "hooks/use-form";
import { NewPassword } from "types";
import { updatePassword } from "api/profile";
import { SettingsHeader } from "components/settings-header/settings-header";
import { ChangePasswordCode, ChangePasswordMessage, RoleType } from "enums";
import { setAuthState, setAccount } from "store/slices/auth";
import { useDispatch } from "hooks/app-hooks";
import { useHistory } from "react-router-dom";
import { AttentionPopup } from "components/session-expired-popup/attention-popup";
import { trackUserInteractionEvent } from "appInsights/logInsights";
import { PasswordInput } from "./password-component";

export const Settings: FC = () => {
  // const athlete = useSelector(getCurrentAthlete);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState("");
  const [changePasswordSuccessMsg, setChangePasswordSuccessMsg] = useState("");
  const passwordMessage = "Password must be at least 6 characters, including upper and lowercase letters, a number, and a special character.";

  const [form, changeHandler] = useForm({
    oldPassword: "",
    password: "",
    reTypePassword: "",
  });

  const changePassword = useCallback(async (data: NewPassword) => {
    return updatePassword(data);
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError(false);
    }
    changeHandler(e);
  };

  const handleLogout = async () => {
    dispatch(setAuthState(false));
    trackUserInteractionEvent("Logout", { role: RoleType.cyclist });
    await dispatch(setAccount(RoleType.cyclist));
    history.push("/sign-in");
  };

  const handleOnSave = async (e: FormEvent) => {
    e.preventDefault();
    if (form.password !== form.reTypePassword) {
      setError(true);
      setChangePasswordErrorMsg(
        "The repeated password does not match the new password. Please ensure they match."
      );
      return;
    }
    setIsLoading(true);
    const response = await changePassword({
      oldPassword: form.oldPassword,
      newPassword: form.reTypePassword,
    });

    setIsLoading(false);
    if (response?.code === 200 && response?.data === ChangePasswordCode.SUCCESS) {
      setError(false);
      setSuccess(true);
      setChangePasswordSuccessMsg(ChangePasswordMessage[ChangePasswordCode.SUCCESS]);
      return;
    }
    switch (response?.message) {
      case ChangePasswordCode.OLD_AND_NEW_PASS_SAME:
      case ChangePasswordCode.OLD_PASSWORD_IS_WRONG:
      case ChangePasswordCode.PASSWORD_CRITERIA_NOT_MATCHED:
        setError(true);
        setChangePasswordErrorMsg(ChangePasswordMessage[response?.message]);
        break;
      default:
        setError(true);
        setChangePasswordErrorMsg(ChangePasswordMessage.UNKNOWN_ERROR);
    }
  };

  return (
    <div className={styles.wrapper}>
      {success && (
        <AttentionPopup
          subtitle="Your password has been changed, please login again with new password."
          description="The page will be reloaded."
          onClose={() => handleLogout()}
        />
      )}
      <Menubar />
      <div className={styles.page}>
        <SettingsHeader hideBottom={false} />
        <div className={styles.heading}>Change Password</div>
        <div className={styles.athleteCardWrapper}>
          <form className={styles.form} onSubmit={handleOnSave} data-testid="change-password-form">
            <div className={styles.inputsBlock}>
              <PasswordInput
                label="Old password"
                name="oldPassword"
                value={form.oldPassword}
                placeholder="Enter your old password"
                error={error}
                onChange={handleInputChange}
              />
              <PasswordInput
                label="New password"
                name="password"
                value={form.password}
                placeholder="Enter your new password"
                error={error}
                onChange={handleInputChange}
              />
              <PasswordInput
                label="Repeat new password"
                name="reTypePassword"
                value={form.reTypePassword}
                placeholder="Repeat new password"
                error={error}
                onChange={handleInputChange}
              />
            </div>
            {error ? (
            <div className={styles.errorMessage}>{changePasswordErrorMsg}</div>
          ): <div className={styles.passwordMessage}>{passwordMessage}</div>}
            {success && <div className={styles.successMessage}>{changePasswordSuccessMsg}</div>}
          </form>
        </div>
        <div className={styles.buttonBlock}>
          <button
            type="submit"
            className={styles.button}
            data-testid="save-changes-button"
            tabIndex={0}
            onClick={handleOnSave}
            disabled={form.reTypePassword.length === 0 || form.password.length === 0 || isLoading}
          >
            {isLoading ? <div className={styles.loader}></div> : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

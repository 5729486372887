import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getAthleteList, getCurrentDateRange, getSelectedGroup } from "store/slices/shared";
import { fetchTeamPerformance } from "store/slices/performance";

import { ScatterChartWithHeader } from "components/charts/scatter-chart/scatter-chart";
import { ChartSize } from "components/charts/constants";
import {
  GRADIENT_AREA_RED,
  TEAM_PERFORMANCE_BAR_COLOR,
  TEAM_PERFORMANCE_BAR_COLOR_ONE,
  TEAM_PERFORMANCE_BAR_COLOR_TWO,
} from "components/charts/colors";
import { Loading } from "components/loading/loading";

import styles from "./team-performance-chart.module.scss";
import { DateSelector } from "components/date-selector/date-selector";
import { TimeRange, UnitsType } from "../../../enums";
import { TimeframeSelector } from "components/timeframe-selector/timeframe-selector";
import { PowerTypeSelector } from "components/power-type-selector/power-type-selector";

export const TeamPerformanceBarChart: FC = () => {
  const [loading, setLoading] = useState(true);
  const athleteList = useSelector(getAthleteList);
  const currentGroup = useSelector(getSelectedGroup);
  const currentDateRange = useSelector(getCurrentDateRange);
  const teamPerformance = useSelector((state) => state.performance.teamPerformance);
  const [selectedMetric, setSelectedMetric] = useState<string>("Absolute");
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeRange>(TimeRange.TenSeconds);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);

  const labels = useMemo(
    () =>
      teamPerformance?.map((item) => {
        const athleteName = athleteList.find((athlete) => athlete.id === item.athleteId);
        return `${athleteName?.firstName ?? ""} ${athleteName?.lastName ?? ""}`.trim();
      }) || [],
    [teamPerformance, athleteList]
  );

  const teamPerformanceSeries = useMemo(() => {
    if (!teamPerformance) return [];

    const metricPropertyName = `maximumOfMmp${selectedTimeFrame}${selectedMetric}`;
    const timeFrameMultiValue = `maximumOfMmp${selectedTimeFrame}`;

    return [
      {
        name: `${selectedMetric} (${selectedTimeFrame})`,
        color: TEAM_PERFORMANCE_BAR_COLOR,
        areaColor: GRADIENT_AREA_RED,
        chartType: "bar",
        hideInLegend: true,
        values: teamPerformance.map((item) => ({
          value: item[metricPropertyName as keyof typeof item],
        })),
        multipleValues: {
          values: teamPerformance.map((item) => [
            {
              value: item[`${timeFrameMultiValue}Absolute` as keyof typeof item],
              label: "Absolute",
              fixedNumber: 0,
              units: {
                type: UnitsType.STRING,
                value: "W",
              },
            },
            {
              value: item[`${timeFrameMultiValue}Relative` as keyof typeof item],
              label: "Relative",
              units: {
                type: UnitsType.STRING,
                value: "W/kg",
              },
            },
            {
              value: item[`${timeFrameMultiValue}Composite` as keyof typeof item],
              label: "Compound",
              fixedNumber: 0,
              units: {
                type: UnitsType.STRING,
                value: "W²/Kg",
              },
            },
          ]),
        },
      },
    ];
  }, [teamPerformance, selectedMetric, selectedTimeFrame]);

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(fetchTeamPerformance());
      setLoading(false);
    })();
  }, [dispatch, athleteList, currentGroup, currentDateRange]);

  if (loading) {
    return (
      <div>
        <div className={styles.content}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.content}>
        <ScatterChartWithHeader
          name="Team Power Profile"
          showCrosshair={true}
          type={ChartSize.Big}
          barMaxWidth="20px"
          rotateXAxisLabels={true}
          headerComponent={
            <div className={styles.headerContainer}>
              <div className={styles.bigTitle}>
                <span className={styles.bold}>Team Power Profile</span>
              </div>
              <div className={styles.selectionVariables}>
                <TimeframeSelector
                  selectedTimeFrame={selectedTimeFrame}
                  onTimeFrameChange={setSelectedTimeFrame}
                />
                <PowerTypeSelector
                  className={styles.powerTypeSelector}
                  selectedMetric={selectedMetric}
                  onMetricChange={setSelectedMetric}
                />
                <DateSelector />
              </div>
            </div>
          }
          source={selectedMetric == "Absolute" ? "TP" : "AI"}
          yLabelMargins={20}
          bottomMargin={10}
          labels={labels}
          series={teamPerformanceSeries}
          containLabels={true}
          isTablet={isTablet}
          intervals={0}
          colorsList={[TEAM_PERFORMANCE_BAR_COLOR_ONE, TEAM_PERFORMANCE_BAR_COLOR_TWO]}
        />
      </div>
    </div>
  );
};

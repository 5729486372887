import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import clsx from "clsx";

import { PerformanceRadar, TwoDateRangesComparison } from "types";
import { ComparisonTimeOptions } from "enums";

import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentAthlete, getCurrentAthleteName } from "store/slices/shared";
import { getIsCyclist } from "store/slices/auth";
import {
  fetchOverallComparisonPerformanceRadar,
  getPeriodType,
  getSelectedComparisonPeriod,
  setSelectedComparisonPeriod,
} from "store/slices/performance";

import {
  AthleteRadarChart,
  RadarChartIndicator,
  RadarChartSeries,
} from "components/charts/radar-chart/athlete-radar-chart";
import { Loading } from "components/loading/loading";

import styles from "./athlete-performance.module.scss";
import { Source } from "components/source/source";
import { comparisonTimePeriods } from "components/time-selection-popup/time-periods";
import { RadarLabelColors, RadarLegendColors } from "components/charts/colors";
import { GenericTypeSelector } from "components/generic-type-selector/generic-type-selector";
import { PowerTypeSelector } from "components/power-type-selector/power-type-selector";

export enum ComparisonType {
  Athlete = 1,
  Group = 2,
}

type MMP10SecValue = "mmp10SecondsAbsolute" | "mmp10SecondsRelative" | "mmp10SecondsComposite";

type MMP30SecValue = "mmp30SecondsAbsolute" | "mmp30SecondsRelative" | "mmp30SecondsComposite";

type MMP1MinValue = "mmp60SecondsAbsolute" | "mmp60SecondsRelative" | "mmp60SecondsComposite";

type MMP5MinValue = "mmp5MinutesAbsolute" | "mmp5MinutesRelative" | "mmp5MinutesComposite";

type MMP10MinValue = "mmp10MinutesAbsolute" | "mmp10MinutesRelative" | "mmp10MinutesComposite";

type MMP20MinValue = "mmp20MinutesAbsolute" | "mmp20MinutesRelative" | "mmp20MinutesComposite";

type MMP30MinValue = "mmp30MinutesAbsolute" | "mmp30MinutesRelative" | "mmp30MinutesComposite";

type MMP60MinValue = "mmp60MinutesAbsolute" | "mmp60MinutesRelative" | "mmp60MinutesComposite";

type MMPValue = "Absolute" | "Relative" | "Composite";

const MMPOptions = {
  mmpAbsolute: "Absolute",
  mmpRelative: "Relative",
  mmpComposite: "Compound Score",
};

const UnitOptions: {
  [key: string]: string;
} = {
  Absolute: "W",
  Relative: "W/Kg",
  Composite: "W²/Kg",
};

export const AthletePerformance: FC = () => {
  const dispatch = useDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentAthleteName = useSelector(getCurrentAthleteName);
  const periodType = useSelector(getPeriodType);
  const selectedComparisonPeriod = useSelector(
    getSelectedComparisonPeriod
  ) as TwoDateRangesComparison;
  const isCyclist: boolean = useSelector(getIsCyclist);

  const scoresCurrent = useSelector((state) => state.performance.overallRadarCurrent);
  const scoresComparison = useSelector((state) => state.performance.overallRadarComparison);

  const [selectedOption1, setSelectedOption1] = useState<ComparisonTimeOptions>(
    selectedComparisonPeriod.firstDateRange
  );
  const [selectedOption2, setSelectedOption2] = useState<ComparisonTimeOptions>(
    selectedComparisonPeriod.secondDateRange
  );

  const handleOptionChange1 = (option: ComparisonTimeOptions) => {
    setSelectedOption1(option);
    dispatch(
      setSelectedComparisonPeriod({
        firstDateRange: option,
        secondDateRange: selectedOption2,
      })
    );
  };

  const handleOptionChange2 = (option: ComparisonTimeOptions) => {
    setSelectedOption2(option);
    dispatch(
      setSelectedComparisonPeriod({
        firstDateRange: selectedOption1,
        secondDateRange: option,
      })
    );
  };

  const generateOptions = (
    selectedOption: ComparisonTimeOptions
  ): { value: ComparisonTimeOptions; label: string }[] => {
    return Object.entries(comparisonTimePeriods).map(([key, value]) => ({
      value: parseInt(key),
      label: value,
    }));
  };

  const optionsDropdown1 = generateOptions(selectedOption2);
  const optionsDropdown2 = generateOptions(selectedOption1);

  const [source, setSource] = useState("TP");

  const [selectedMetric, setSelectedMetric] = useState<string>("Absolute");

  const [currentMMP10Sec, setCurrentMMP10Sec] = useState<MMP10SecValue>("mmp10SecondsAbsolute");

  const [currentMMP30Sec, setCurrentMMP30Sec] = useState<MMP30SecValue>("mmp30SecondsAbsolute");

  const [currentMMP1Min, setCurrentMMP1Min] = useState<MMP1MinValue>("mmp60SecondsAbsolute");

  const [currentMMP5Min, setCurrentMMP5Min] = useState<MMP5MinValue>("mmp5MinutesAbsolute");

  const [currentMMP10Min, setCurrentMMP10Min] = useState<MMP10MinValue>("mmp10MinutesAbsolute");

  const [currentMMP20Min, setCurrentMMP20Min] = useState<MMP20MinValue>("mmp20MinutesAbsolute");

  const [currentMMP30Min, setCurrentMMP30Min] = useState<MMP30MinValue>("mmp30MinutesAbsolute");

  const [currentMMP60Min, setCurrentMMP60Min] = useState<MMP60MinValue>("mmp60MinutesAbsolute");

  useEffect(() => {
    dispatch(fetchOverallComparisonPerformanceRadar(selectedComparisonPeriod));
  }, [dispatch, currentAthlete?.id, periodType, selectedComparisonPeriod]);

  useEffect(() => {
    switch (selectedMetric) {
      case "Absolute":
        setCurrentMMP10Sec("mmp10SecondsAbsolute");
        setCurrentMMP30Sec("mmp30SecondsAbsolute");
        setCurrentMMP1Min("mmp60SecondsAbsolute");
        setCurrentMMP5Min("mmp5MinutesAbsolute");
        setCurrentMMP10Min("mmp10MinutesAbsolute");
        setCurrentMMP20Min("mmp20MinutesAbsolute");
        setCurrentMMP30Min("mmp30MinutesAbsolute");
        setCurrentMMP60Min("mmp60MinutesAbsolute");
        setSource("TP");
        break;
      case "Relative":
        setCurrentMMP10Sec("mmp10SecondsRelative");
        setCurrentMMP30Sec("mmp30SecondsRelative");
        setCurrentMMP1Min("mmp60SecondsRelative");
        setCurrentMMP5Min("mmp5MinutesRelative");
        setCurrentMMP10Min("mmp10MinutesRelative");
        setCurrentMMP20Min("mmp20MinutesRelative");
        setCurrentMMP30Min("mmp30MinutesRelative");
        setCurrentMMP60Min("mmp60MinutesRelative");
        setSource("AI");
        break;
      case "Composite":
        setCurrentMMP10Sec("mmp10SecondsComposite");
        setCurrentMMP30Sec("mmp30SecondsComposite");
        setCurrentMMP1Min("mmp60SecondsComposite");
        setCurrentMMP5Min("mmp5MinutesComposite");
        setCurrentMMP10Min("mmp10MinutesComposite");
        setCurrentMMP20Min("mmp20MinutesComposite");
        setCurrentMMP30Min("mmp30MinutesComposite");
        setCurrentMMP60Min("mmp60MinutesComposite");
        setSource("AI");
        break;
      default:
        break;
    }
  }, [selectedMetric]);

  /**
   * ToDo: If it is not used please remove chartRef
   * and all dependencies for this ref from component
   */
  const chartRef = useRef<ReactEcharts>(null);

  const indicators: RadarChartIndicator[] = useMemo(() => {
    const getMaxValue = (key: keyof PerformanceRadar<number>): number => {
      let max = 0;
      if (scoresCurrent && scoresComparison) {
        max = Math.max(scoresCurrent[key] || 0, scoresComparison[key] || 0);
      }
      return selectedMetric === MMPOptions.mmpRelative ? Number(max.toFixed(2)) : Math.round(max);
    };

    if (scoresCurrent) {
      return [
        {
          name: `60m`,
          max: getMaxValue(currentMMP60Min),
          unit: UnitOptions[selectedMetric],
          selectedOption: currentMMP60Min,
          indicatorColor: RadarLabelColors.mmp60Minutes,
        },
        {
          name: `30m`,
          max: getMaxValue(currentMMP30Min),
          selectedOption: currentMMP30Min,
          unit: UnitOptions[selectedMetric],
          indicatorColor: RadarLabelColors.mmp30Minutes,
        },
        {
          name: `20m`,
          max: getMaxValue(currentMMP20Min),
          unit: UnitOptions[selectedMetric],
          selectedOption: currentMMP20Min,
          indicatorColor: RadarLabelColors.mmp20Minutes,
        },
        {
          name: `10m`,
          max: getMaxValue(currentMMP10Min),
          unit: UnitOptions[selectedMetric],
          selectedOption: currentMMP10Min,
          indicatorColor: RadarLabelColors.mmp10Minutes,
        },
        {
          name: `5m`,
          max: getMaxValue(currentMMP5Min),
          unit: UnitOptions[selectedMetric],
          selectedOption: currentMMP5Min,
          indicatorColor: RadarLabelColors.mmp5Minutes,
        },
        {
          name: `1m`,
          max: getMaxValue(currentMMP1Min),
          unit: UnitOptions[selectedMetric],
          selectedOption: currentMMP1Min,
          indicatorColor: RadarLabelColors.mmp1Minute,
        },
        {
          name: `30s`,
          max: getMaxValue(currentMMP30Sec),
          unit: UnitOptions[selectedMetric],
          selectedOption: currentMMP30Sec,
          indicatorColor: RadarLabelColors.mmp30Seconds,
        },
        {
          name: `10s`,
          max: getMaxValue(currentMMP10Sec),
          unit: UnitOptions[selectedMetric],
          selectedOption: currentMMP10Sec,
          indicatorColor: RadarLabelColors.mmp10Seconds,
        },
      ];
    }
    return [];
  }, [
    scoresCurrent,
    scoresComparison,
    currentMMP60Min,
    selectedMetric,
    currentMMP30Min,
    currentMMP20Min,
    currentMMP10Min,
    currentMMP5Min,
    currentMMP1Min,
    currentMMP30Sec,
    currentMMP10Sec,
  ]);

  const series: (RadarChartSeries | undefined)[] = useMemo(() => {
    const getValue = (value: number) => {
      return selectedMetric === MMPOptions.mmpRelative
        ? Number(value.toFixed(2))
        : Math.round(value);
    };

    const sort = (obj: PerformanceRadar<number>) => {
      return [
        getValue(obj[currentMMP60Min] || 0),
        getValue(obj[currentMMP30Min] || 0),
        getValue(obj[currentMMP20Min] || 0),
        getValue(obj[currentMMP10Min] || 0),
        getValue(obj[currentMMP5Min] || 0),
        getValue(obj[currentMMP1Min] || 0),
        getValue(obj[currentMMP30Sec] || 0),
        getValue(obj[currentMMP10Sec] || 0),
      ];
    };

    const getPrimaryName: () => string = () => {
      if (selectedComparisonPeriod) {
        return comparisonTimePeriods[selectedOption1];
      }
      return currentAthleteName;
    };

    const getSecondaryName: () => string = () => {
      if (selectedComparisonPeriod) {
        return comparisonTimePeriods[selectedOption2];
      }
      return currentAthleteName;
    };

    return [
      {
        name: getPrimaryName(),
        color: RadarLegendColors.primaryColor,
        values: scoresCurrent ? sort(scoresCurrent) : [],
      },
      {
        name: getSecondaryName(),
        color: RadarLegendColors.secondaryColor,
        values: scoresComparison ? sort(scoresComparison) : [],
      },
    ];
  }, [
    scoresCurrent,
    selectedMetric,
    scoresComparison,
    currentMMP60Min,
    currentMMP30Min,
    currentMMP20Min,
    currentMMP10Min,
    currentMMP5Min,
    currentMMP1Min,
    currentMMP30Sec,
    currentMMP10Sec,
    selectedComparisonPeriod,
    currentAthleteName,
    selectedOption1,
    selectedOption2,
  ]);

  if (!scoresCurrent || !scoresComparison) {
    return (
      <div className={styles.panel}>
        <Loading />
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.container, isCyclist && styles.containerCyclist)}
      data-testid="athlete-performance"
    >
      <div className={styles.header}>
        <span className={styles.title}>Power Profile</span>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.selectionVariables}>
          <PowerTypeSelector
            selectedMetric={selectedMetric}
            onMetricChange={setSelectedMetric}
            className={styles.powerTypeSelector}
          />
          <GenericTypeSelector
            options={optionsDropdown1.map((option) => ({
              label: option.label,
              value: option.value,
            }))}
            selectedMetric={selectedOption1}
            handleOptionChange={handleOptionChange1}
            className={styles.genericOneSelector}
          />
          <GenericTypeSelector
            options={optionsDropdown2.map((option) => ({
              label: option.label,
              value: option.value,
            }))}
            selectedMetric={selectedOption2}
            handleOptionChange={handleOptionChange2}
            className={styles.genericTwoSelector}
          />
        </div>
        <div className={clsx(styles.chartWrapper)}>
          {indicators && series && (
            <AthleteRadarChart
              name="Power Profile"
              indicators={indicators}
              decimalPoint={selectedMetric === MMPOptions.mmpRelative ? 2 : 0}
              series={series}
              ref={chartRef}
            />
          )}
        </div>
      </div>
      <div className={styles.legend}>
        <Source source={source} />
        <div className={styles.legendItems}>
          {series.map(
            (item) =>
              item && (
                <div
                  className={clsx(styles.legendItem, isCyclist && styles.cyclistMargin)}
                  key={item.name}
                >
                  <div
                    className={styles.legendLine}
                    style={{
                      borderTopColor: item.color,
                      borderTopStyle: item.dashed ? "dashed" : "solid",
                    }}
                  />
                  {item.name}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

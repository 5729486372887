import React, { useEffect, useState } from "react";
import styles from "./success-countdown.module.scss";

interface CountdownProps {
  duration: number;
  onComplete: () => void;
}

export const CountdownTimer: React.FC<CountdownProps> = ({ duration, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [percentage, setPercentage] = useState(100);

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 0.05);
        setPercentage((timeLeft / duration) * 100);
      }, 50);

      return () => clearInterval(intervalId);
    } else {
      onComplete();
    }
  }, [timeLeft, onComplete, duration]);

  return (
    <div className={styles.countdown}>
      <svg className={styles.countdownCircle} viewBox="0 0 36 36">
        <path
          className={styles.circleBg}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={styles.circle}
          strokeDasharray={`${percentage}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <div className={styles.text}>Success! Closing popup in {timeLeft.toFixed(0)}</div>
    </div>
  );
};

export default CountdownTimer;

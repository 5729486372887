import { Layout } from "components/layout/layout";
import styles from "./table-view.module.scss";
import { AthleteSearch } from "pages/athlete-selection/athlete-search/athlete-search";
import { FC, useCallback, useEffect, useState } from "react";
import { PrimaryButton } from "components/primary-button/primary-button";
import { ReactComponent as FilterIcon } from "images/filter_icon.svg";
import { ReactComponent as SortIcon } from "images/sort_icon.svg";
import { ReportWeekSelector } from "components/report-week-selector/report-week-selector";
import DataTable, { SortOrder, TableColumn } from "react-data-table-component";
import { Athlete, WeeklyReport } from "types";
import {
  getAllCommentEnabledReports,
  getCurrentWeekRange,
  getFilteredWeeklyReportData,
  getFullWeeklyReportData,
  getSeletedIds,
  getToggleCommentPopup,
  setFilteredWeeklyReportData,
  setSelectedIds,
  setToggleCommentPopup,
  setWizardReportData,
} from "store/slices/shared";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { columns, downloadCSV, getWeekNumber, topMenuItems } from "../utils";
import { Loading } from "components/loading/loading";
import { AthletePopup } from "../../../components/athlete-popup/athlete-popup";
import { REPORT_TABLE_COLOR, SORT_STYLE } from "components/charts/colors";
import { CoachFeedback } from "../components/coach-feedback/coach-feedback";
import NotifyReporting from "../components/notify-reporting/notify-reporting";
import { FeedbackWizard } from "../components/feedback-wizard/feedback-wizard";

type Props = {
  loading: boolean;
};

export const TableView: FC<Props> = ({ loading }) => {
  const dispatch = useDispatch();
  const [isTablet, setIsTablet] = useState(false);
  const data = useSelector(getFullWeeklyReportData);
  const commentPopup = useSelector(getToggleCommentPopup);
  const filteredDataState = useSelector(getFilteredWeeklyReportData);
  const [filteredData, setFilteredData] = useState<WeeklyReport[]>([]);
  const selectedWeekRange = useSelector(getCurrentWeekRange);
  const selectedIds = useSelector(getSeletedIds);
  const [input, setInput] = useState<string>("");
  const [togglePopup, setTogglePopup] = useState(false);
  const allCommentEnabledReports = useSelector(getAllCommentEnabledReports);

  useEffect(() => {
    setFilteredData(filteredDataState);
  }, [filteredDataState]);

  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    const filtered = data.filter((report) => {
      const athlete = report.athlete as Athlete;
      const athleteName =
        `${athlete.firstName} ${athlete.lastName}`.toLowerCase();
      return athleteName.includes(event.target.value.toLowerCase());
    });
    setFilteredData(filtered);
    if (selectedIds) {
      dispatch(setSelectedIds(null));
      dispatch(setFilteredWeeklyReportData(data));
    }
  };

  const handleFilterPopup = useCallback(() => {
    if (selectedIds && selectedIds.length > 0) {
      const filtered = data.filter((report) =>
        selectedIds.includes(report.athleteId)
      );
      dispatch(setFilteredWeeklyReportData(filtered));
      setFilteredData(filtered);
    } else {
      dispatch(setFilteredWeeklyReportData(data));
      setFilteredData(data);
    }
    setTogglePopup(false);
  }, [data, selectedIds, dispatch]);

  const handleClearAll = () => {
    dispatch(setFilteredWeeklyReportData(data));
    setFilteredData(data);
    dispatch(setSelectedIds(null));
    setTogglePopup(false);
  };

  const handleExportReport = () => {
    const year = new Date(selectedWeekRange.startDate).getFullYear();
    const weekNumber = getWeekNumber(new Date(selectedWeekRange.startDate));
    downloadCSV(
      columns.map((col) => {
        if (col.id === "mCPRelative") {
          return { label: "mCP Relative", field: col.id as string };
        }
        return { label: col.name as string, field: col.id as string };
      }),
      filteredData.map((e) => {
        return {
          ...e,
          athlete: `${(e.athlete as Athlete).firstName} ${(e.athlete as Athlete).lastName
            }`,
        };
      }),
      `Weekly Report W${weekNumber} ${year}`
    );
  };

  const handleStartReporting = () => {
    dispatch(setWizardReportData(allCommentEnabledReports));
    dispatch(
      setToggleCommentPopup({
        toggle: false,
        wizardToggle: true,
        athleteReport: undefined,
      })
    );
  };

  const handleSort = (
    column: TableColumn<WeeklyReport>,
    sortDirection: SortOrder
  ) => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (column.selector) {
        const aValue = column.selector(a);
        const bValue = column.selector(b);
        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      } else if (column.sortFunction) {
        return column.sortFunction(a, b);
      }
      return 0;
    });
    dispatch(setFilteredWeeklyReportData(sortedData));
    setFilteredData(sortedData);
  };

  const topComponent = (
    <>
      <div className={styles.topWrapper}>
        <div className={styles.searchFilter}>
          <AthleteSearch
            input={input}
            handleChange={handleChange}
            className={styles.search}
          />
        </div>
        <div className={styles.exportDropdown}>
          <PrimaryButton
            className={styles.button}
            onClick={() => setTogglePopup(true)}
            title={isTablet ? undefined : "Filter"}
            icon={<FilterIcon />}
          />
          <div className={styles.rightOptions}>
            <ReportWeekSelector />
            <PrimaryButton
              className={styles.exportButton}
              onClick={handleExportReport}
              title={isTablet ? "Export" : "Export Report"}
            />
          </div>
        </div>
      </div>
      {allCommentEnabledReports?.length > 0 && (
        <NotifyReporting handleStartReporting={handleStartReporting} />
      )}
    </>
  );

  return (
    <>
      <Layout
        topMenuItems={topMenuItems}
        top={topComponent}
        contentClassName={styles.contentWrapper}
        showAdditonalComps={false}
        customHeader={<div className={styles.header}>Performance Report</div>}
        content={
          <DataTable
            progressPending={loading}
            noDataComponent="No Athlete Found"
            progressComponent={<Loading />}
            onSort={handleSort}
            sortIcon={
              <SortIcon className={styles.filterIcon} style={SORT_STYLE} />
            }
            columns={columns}
            data={filteredData}
            className={styles.tableData}
            customStyles={REPORT_TABLE_COLOR}
          />
        }
        showCalendarComp={false}
        dataTestId="table-view-layout"
      />
      {togglePopup && (
        <AthletePopup
          handleClearAll={handleClearAll}
          handleSubmit={handleFilterPopup}
          athleteList={data.map((report) => report.athlete as Athlete)}
          onClose={() => {
            setTogglePopup(false);
          }}
        />
      )}
      {commentPopup.toggle && (
        <CoachFeedback filteredData={filteredData} />
      )}
      {commentPopup.wizardToggle && (
        <FeedbackWizard
          filteredData={filteredData}
        />
      )}
    </>
  );
};

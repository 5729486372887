import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { useDispatch } from "hooks/app-hooks";
import { setAuthState, setAccount } from "store/slices/auth";

import { ReactComponent as SignOutIcon } from "images/sign-out.svg";

import styles from "./sign-out.module.scss";
import { RoleType } from "enums";

type Props = {
  className?: string;
};

export const SignOut: FC<Props> = ({ className }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(setAuthState(false));
    await dispatch(setAccount(RoleType.cyclist));
    history.push("/sign-in");
  };

  return (
    <button type="button" className={clsx(styles.button, className)} onClick={handleClick}>
      <SignOutIcon />
      <span className={styles.text}>Sign out</span>
    </button>
  );
};

import React, { useEffect, useState } from "react";
import { Layout } from "components/layout/layout";
import { HeartMeasurements } from "pages/wellness/heart-measurements/heart-measurements";
import { OverallWellness } from "pages/wellness/overall-wellness/overall-wellness";
import { BodyComposition } from "pages/wellness/body-composition/body-composition";
import { useSelector } from "hooks/app-hooks";
import { getIsCyclist } from "store/slices/auth";
import styles from "../health/health-details.module.scss";
import { ReactComponent as BackTick } from "clients/uaeMen/images/BackTick.svg";
import { Tabs } from "components/tabs-layout/tabs-layout";
import { PrimaryButton } from "components/primary-button/primary-button";
import { Illnesspopup } from "components/illness-popup/illness-popup";
import { Injurypopup } from "components/injury-popup/injury-popup";
import { PhysiotherapyPopup } from "components/physiotherapy-popup/physiotherapy-popup";
import { HealthTab } from "components/health-tab/health-tab";
import { RecoveryPopup } from "components/recovery-popup/recovery-popup";
import { ReactComponent as Edit } from "images/editPencil.svg";
import { fetchSelectedMedicalReportDetails } from "store/slices/health";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { convertUnixTimestampToDate } from "utils";
import { format } from "date-fns";
import Tippy from "@tippyjs/react";
import { checkTabletView } from "utils";

const tabs = [
  {
    id: Tabs.OverallWellnessTab,
    name: "Self reported wellness",
  },
  {
    id: Tabs.BodyCompositionTab,
    name: "Body Composition",
  },
  {
    id: Tabs.HeartMeasurementsTab,
    name: "Heart Rate Measurements",
  },
  {
    id: Tabs.MenstrualCycleTab,
    name: "Menstrual Chart",
  },
];

const componentMap = {
  [Tabs.OverallWellnessTab]: <OverallWellness />,
  [Tabs.BodyCompositionTab]: <BodyComposition />,
  [Tabs.HeartMeasurementsTab]: <HeartMeasurements />,
};

interface MedicalReportDetails {
  id: number;
  athleteId: number;
  recordType: string;
  bodyLocation: string;
  side: string;
  rehabilitationStage: string;
  type: string;
  illnessType: string;
  assessmentTools: string;
  modalityUsed: string;
  onsetDate: number;
  estimatedRecovery: number;
  symptom: string;
  ossicsCode: string;
  severity: string;
  area: string;
  createdBy: string;
  recovered: boolean;
  editable?: boolean;
  recoveredOn?: number;
  onsetMode?:string
}

export const HealthDetails = () => {
  const [isTablet, setIsTablet] = useState(false);
  const [recoveryPopup, setRecoveryPopup] = useState(false);
  const [physiotherapyPopup, setPhysiotherapyPopup] = useState(false);
  const [illnessPopup, setIllnessPopup] = useState(false);
  const [injuryPopup, setInjuryPopup] = useState(false);
  const isCyclist: boolean = useSelector(getIsCyclist);
  const [openPhysioPopup, setOpenPhysioPopup] = useState(false);
  const [openIllnessPopup, setOpenIllnessPopup] = useState(false);
  const [openInjuryPopup, setOpenInjuryPopup] = useState(false);

  const enums = useSelector((state: any) => state.health.healthEnums);
  const selectedMedicalReportId = useSelector(
    (state) => state?.health?.selectedMedicalReportId
  );
  const selectedMedicalReport: MedicalReportDetails = useSelector(
    (state) => state?.health?.selectedMedicalReport
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setRecoveryPopup(true);
  };

  const handleEditClick = () => {
    if (selectedMedicalReport?.recordType === "PHYSIO")
      setOpenPhysioPopup(true);
    else if (selectedMedicalReport?.recordType === "ILLNESS")
      setOpenIllnessPopup(true);
    else if (selectedMedicalReport?.recordType === "INJURY")
      setOpenInjuryPopup(true);
  };

  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);


  useEffect(() => {
    dispatch(fetchSelectedMedicalReportDetails(selectedMedicalReportId));
  }, [selectedMedicalReportId, dispatch]);

  const handleBackClick = () => {
    history.push("/health");
  };

  const getEnumValue = (parentKey: string, childkey: string) => {
    return enums[parentKey]?.[childkey];
  };


  const getMultiSelectEnumValues = (parentKey: string, reportKey: keyof typeof selectedMedicalReport) => {
    const reportValue = selectedMedicalReport[reportKey];
    if (reportValue === null || reportValue === undefined || reportValue === "" || reportValue === "{\"key\": \"value\"}") {
      return "No data";
    } else {
      const parsedArray = JSON?.parse(reportValue as string);
      const convertedArray = parsedArray?.map((value: string) => {
        return getEnumValue(parentKey, value);
      });

      const joinedValues = convertedArray?.join(" , ");
      return joinedValues;
    }
  };




  const physioTable = (
    <div className={styles.table}>
      <div className={styles.tableHeading}>
        Body location
        <p className={styles.tableData}>
          {selectedMedicalReport?.bodyLocation
            ? getEnumValue("bodyLocation", selectedMedicalReport?.bodyLocation)
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Side
        <p className={styles.tableData}>
          {selectedMedicalReport?.bodyLocation
            ? getEnumValue("side", selectedMedicalReport?.side)
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Stage of Rehab
        <p className={styles.tableData}>
          {selectedMedicalReport?.rehabilitationStage
            ? getEnumValue(
              "rehabilitationStage",
              selectedMedicalReport?.rehabilitationStage
            )
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Type
        <p className={styles.tableData}>
          {selectedMedicalReport?.type
            ? <Tippy content={getEnumValue("type", selectedMedicalReport?.type)} className={styles.customTooltip} placement="top-start"
              offset={[0, 0]}>
              <span>{getEnumValue("type", selectedMedicalReport?.type)}</span>
            </Tippy>
            : "No data"}
        </p>
      </div>



      <div className={styles.tableHeading}>
        Modality Used
        <p className={styles.tableData} style={{ cursor: 'pointer' }} >
          {selectedMedicalReport?.modalityUsed ? getMultiSelectEnumValues("modalityUsed", "modalityUsed")?.includes(",") ? (
            <Tippy
              content={getMultiSelectEnumValues("modalityUsed", "modalityUsed")}
              className={styles.customTooltip}
              placement="top-start"
              offset={[0, 0]}
            >
              <span>{getMultiSelectEnumValues("modalityUsed", "modalityUsed")}</span>
            </Tippy>
          ) : (
            <span>{getMultiSelectEnumValues("modalityUsed", "modalityUsed")}</span>
          ) : "No Data"}


        </p>
      </div>

      <div className={styles.tableHeading} style={{ cursor: 'pointer' }}>
        Assessment Tools
        <p className={styles.tableData}>

          {selectedMedicalReport?.assessmentTools ? getMultiSelectEnumValues("assessmentTools", "assessmentTools")?.includes(",") ? (
            <Tippy
              content={getMultiSelectEnumValues("assessmentTools", "assessmentTools")}
              className={styles.customTooltip}
              placement="top-start"
              offset={[0, 0]}
            >
              <span>{getMultiSelectEnumValues("assessmentTools", "assessmentTools")}</span>
            </Tippy>
          ) : (
            <span>{getMultiSelectEnumValues("assessmentTools", "assessmentTools")}</span>
          ) : "No Data"}


        </p>
      </div>

      <div className={styles.tableHeading}>
        Onset Date
        <p className={styles.tableData}>
          {selectedMedicalReport?.onsetDate
            ? convertUnixTimestampToDate(selectedMedicalReport?.onsetDate)
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Estimated Recovery
        <p className={styles.tableData}>
          {selectedMedicalReport?.estimatedRecovery
            ? convertUnixTimestampToDate(
              selectedMedicalReport?.estimatedRecovery
            )
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Added by
        <p className={styles.tableData}>
          {selectedMedicalReport?.createdBy
            ? selectedMedicalReport?.createdBy
            : "No data"}
        </p>
      </div>
    </div>
  );


  const illnessTable = (
    <div className={styles.table}>
      <div className={styles.tableHeading}>
        Type
        <p className={styles.tableData}>
          {selectedMedicalReport?.illnessType
            ? <Tippy content={getEnumValue("illnessType", selectedMedicalReport?.illnessType)} className={styles.customTooltip} placement="top-start"
              offset={[0, 0]}>
              <span>{getEnumValue("illnessType", selectedMedicalReport?.illnessType)}</span>
            </Tippy>
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Symptom
        <p className={styles.tableData}>
          {selectedMedicalReport?.symptom
            ? getEnumValue("symptom", selectedMedicalReport?.symptom)
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Severity
        <p className={styles.tableData}>
          {selectedMedicalReport?.severity
            ? getEnumValue("severity", selectedMedicalReport?.severity)
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        OSIICS Code
        <p className={styles.tableData} style={{ cursor: 'pointer' }} >
          {selectedMedicalReport?.ossicsCode
            ? <Tippy content={getEnumValue("ossicsCode", selectedMedicalReport?.ossicsCode)} className={styles.customTooltip} placement="top-start"
              offset={[0, 0]}>
              <span>{getEnumValue("ossicsCode", selectedMedicalReport?.ossicsCode)}</span>
            </Tippy>
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Onset Date
        <p className={styles.tableData}>
          {selectedMedicalReport?.onsetDate
            ? convertUnixTimestampToDate(selectedMedicalReport?.onsetDate)
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Estimated Recovery
        <p className={styles.tableData}>
          {selectedMedicalReport?.estimatedRecovery
            ? convertUnixTimestampToDate(
              selectedMedicalReport?.estimatedRecovery
            )
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Added by
        <p className={styles.tableData}>
          {selectedMedicalReport?.createdBy
            ? selectedMedicalReport?.createdBy
            : "No data"}
        </p>
      </div>
    </div>
  );

  const injuryTable = (
    <div className={styles.table}>

      <div className={styles.tableHeading}>
        Area
        <p className={styles.tableData}>
          {selectedMedicalReport?.area
            ? getEnumValue("bodyArea", selectedMedicalReport?.area)
            : "No data"}
        </p>
      </div>
      <div className={styles.tableHeading}>
        Severity
        <p className={styles.tableData}>
          {selectedMedicalReport?.severity
            ? getEnumValue("severity", selectedMedicalReport?.severity)
            : "No data"}
        </p>
      </div>
      <div className={styles.tableHeading}>
        Side
        <p className={styles.tableData}>
          {selectedMedicalReport?.side
            ? getEnumValue("side", selectedMedicalReport?.side)
            : "No data"}
        </p>
      </div>
      <div className={styles.tableHeading}>
        OSIICS Code
        <p className={styles.tableData} style={{ cursor: 'pointer' }} >
          {selectedMedicalReport?.ossicsCode
            ? <Tippy content={getEnumValue("ossicsCode", selectedMedicalReport?.ossicsCode)} className={styles.customTooltip} placement="top-start"
              offset={[0, 0]}>
              <span>{getEnumValue("ossicsCode", selectedMedicalReport?.ossicsCode)}</span>
            </Tippy>
            : "No data"}
        </p>
      </div>


      <div className={styles.tableHeading}>
       Mode of Onset
        <p className={styles.tableData} style={{ cursor: 'pointer' }} >
          {selectedMedicalReport?.onsetMode ? getMultiSelectEnumValues("onsetMode", "onsetMode")?.includes(",") ? (
            <Tippy
              content={getMultiSelectEnumValues("onsetMode", "onsetMode")}
              className={styles.customTooltip}
              placement="top-start"
              offset={[0, 0]}
            >
              <span>{getMultiSelectEnumValues("onsetMode", "onsetMode")}</span>
            </Tippy>
          ) : (
            <span>{getMultiSelectEnumValues("onsetMode", "onsetMode")}</span>
          ) : "No Data"}


        </p>
      </div>

      <div className={styles.tableHeading}>
        Onset Date
        <p className={styles.tableData}>
          {selectedMedicalReport?.onsetDate
            ? convertUnixTimestampToDate(selectedMedicalReport?.onsetDate)
            : "No data"}
        </p>
      </div>
      <div className={styles.tableHeading}>
        Estimated Recovery
        <p className={styles.tableData}>
          {selectedMedicalReport?.estimatedRecovery
            ? convertUnixTimestampToDate(
              selectedMedicalReport?.estimatedRecovery
            )
            : "No data"}
        </p>
      </div>

      <div className={styles.tableHeading}>
        Added by
        <p className={styles.tableData}>
          {selectedMedicalReport?.createdBy
            ? selectedMedicalReport?.createdBy
            : "No data"}
        </p>
      </div>

      {/* <div className={styles.tableHeading}>
        Recovered
        {selectedMedicalReport?.recovered ? (
          <p className={styles.date}>
            {selectedMedicalReport?.recoveredOn &&
              format(
                new Date(
                  convertUnixTimestampToDate(selectedMedicalReport.recoveredOn)
                ),
                "MMM dd, yyyy"
              )}
          </p>
        ) : (
          <p className={styles.tableData}>
            <PrimaryButton
              className={styles.markRecovered}
              onClick={handleSubmit}
              title={"Mark as Recovered"}
            />
          </p>
        )}
      </div> */}
    </div>
  );

  const topComponent = (
    <>
      <div className={styles.topWrapper}>
        <div className={styles.exportDropdown} style={{ marginLeft: checkTabletView() ? "0.5rem" : "1rem" }}>
          <div className={styles.tableBack} onClick={handleBackClick}>
            <BackTick className={styles.backTick} />
            <span style={{ marginLeft: checkTabletView() ? "2px" : "5px", fontSize: checkTabletView() ? "12px" : "14px" }}>Back</span>
          </div>

          <span className={styles.separator}></span>

          <div className={styles.table}>
            {selectedMedicalReport?.recordType === "PHYSIO"
              ? physioTable
              : selectedMedicalReport?.recordType === "ILLNESS"
                ? illnessTable
                : selectedMedicalReport?.recordType === "INJURY"
                  ? injuryTable
                  : "No data found"}
          </div>

          <span className={styles.separator}></span>
          <div className={styles.recoveredEditSection}>
            <div className={styles.tableHeading}>
              Recovered
              {selectedMedicalReport?.recovered ? (
                <p className={styles.date}>
                  {selectedMedicalReport?.recoveredOn &&
                    format(
                      new Date(
                        convertUnixTimestampToDate(
                          selectedMedicalReport.recoveredOn
                        )
                      ),
                      "MMM dd, yyyy"
                    )}
                </p>
              ) : (
                <p className={styles.tableData}>
                  <PrimaryButton
                    className={selectedMedicalReport?.editable ? styles.markRecovered : styles.markRecoveredDisabled}
                    onClick={handleSubmit}
                    title={"Mark as Recovered"}
                    disabled={!selectedMedicalReport?.editable}
                  />
                </p>
              )}
            </div>
            {selectedMedicalReport?.editable && (
              <div className={styles.editName} onClick={handleEditClick}>
                Edit
                <Edit className={styles.editIcon} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Layout
        top={topComponent}
        content={
          <HealthTab
            tabs={tabs}
            componentMap={componentMap}
            selectedMedicalReport={selectedMedicalReport}
          />
        }
        contentCustomWrapper={styles.healthTabsWrapper}
        showCalendarComp={false}
      />
      {recoveryPopup && (
        <RecoveryPopup onClose={() => setRecoveryPopup(false)} />
      )}
      {openPhysioPopup && (
        <PhysiotherapyPopup
          onClose={() => setOpenPhysioPopup(false)}
          openPyshioPopup={openPhysioPopup}
        />
      )}
      {openIllnessPopup && (
        <Illnesspopup
          onClose={() => setOpenIllnessPopup(false)}
          openIllnessPopup={openIllnessPopup}
        />
      )}
      {openInjuryPopup && (
        <Injurypopup
          onClose={() => setOpenInjuryPopup(false)}
          openInjuryPopup={openInjuryPopup}
        />
      )}
    </>
  );
};
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { ChatMessage } from "types";
import styles from "./chat-bot.module.scss";
import { getCurrentAthlete } from "store/slices/shared";
import { ChatHeader } from "./component/chat-header/chat-header";
import { ChatBody } from "./component/chat-body/chat-body";
import { ChatFooter } from "./component/chat-footer/chat-footer";
import { getChatQuery, getChatHistory} from "../../api/messages";
import clsx from "clsx";

export interface ChatBotMessage {
  message: string;
  isUser: boolean;
  isNewMessage: boolean;
  id:string|undefined;
  isDefaultMsg:boolean;
  isLiked?: boolean; 
  isDisliked?: boolean;
}

interface ChatHistoryMessage {
  id: string;
  created_at: number;
  assistant_id: string | null;
  thread_id: string | null;
  role: string;
  feedback_type:string
  content: {
    type: string;
    text: {
      value: string;
      annotations: any[];
    };
    
  }[];
}

interface ChatHistoryResponse {
  user_id: number;
  athlete_id: number;
  assistant_id: string | null;
  thread_id: string | null;
  messages: ChatHistoryMessage[];
  feedback_type:string
}

const CHAT_WINDOW_STATE_KEY = "chatBotWindowState";

export const ChatBot: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentAthlete = useSelector(getCurrentAthlete);
  const [isLoading, setIsLoading] = useState(false);
  const [isMinimized, setIsMinimized] = useState(() => {
    const savedState = localStorage.getItem(CHAT_WINDOW_STATE_KEY);
    return savedState ? JSON.parse(savedState).isMinimized : false;
  });
  const [isMaximized, setIsMaximized] = useState(() => {
    const savedState = localStorage.getItem(CHAT_WINDOW_STATE_KEY);
    return savedState ? JSON.parse(savedState).isMaximized : false;
  });
  const [userMessage, setUserMessage] = useState<string>("");
  const [maximizeWindow, setMaximizeWindow] = useState<boolean>(false);
  const [threadID, setThreadID] = useState<string | null>();
  const [assistantID, setAssistantID] = useState<string | null>();
  const [messageID, setMessageID] = useState<string>();
  const [isLatestMsg,setIsLatestMsg]=useState<string>()
  const [showChatBot, setShowChatBot] = useState(
    location.pathname === "/wellness" || location.pathname === "/performance"
  );
  const [chatBotMessages, setChatBotMessages] = useState<ChatBotMessage[]>([]);
  const [clearHistory,setClearHistory]=useState(false)

  const handleNewMessage = (response: string,id:string|undefined) => {
    setIsLoading(false);
    setChatBotMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];

      // Check if the last message is an empty loading message
      if (
        updatedMessages.length > 0 &&
        updatedMessages[updatedMessages.length - 1].message === ""
      ) {
        updatedMessages.pop(); // Remove the loading message
      }
      setIsLatestMsg(id)

      updatedMessages.push({
        message: response,
        isUser: false,
        isNewMessage: true,
        id:id,
        isDefaultMsg:false
      });

      return updatedMessages;
    });
  };

  const processChatHistory = (historyResponse: ChatHistoryResponse) => {
     setThreadID(historyResponse.thread_id);
     setAssistantID(historyResponse.assistant_id);

    const historyMessages = historyResponse.messages.map((msg) => ({
      message: msg.content[0].text.value,
      isUser: msg.role === "user",
      isNewMessage: false,
      id:msg.id,
      isDefaultMsg:false,
      isLiked: msg.feedback_type === 'thumbs_up',
      isDisliked: msg.feedback_type === 'thumbs_down',
    }));
    setIsLatestMsg(historyResponse?.messages?.[historyResponse?.messages?.length-1]?.id)
    setChatBotMessages(historyMessages);
  };

  useEffect(() => {
    const fetchChatHistory = async () => {
      if (currentAthlete?.id) {
        const historyResponse = await getChatHistory(currentAthlete.id)
        if (historyResponse && historyResponse.messages.length > 0) {
          processChatHistory(historyResponse);
        } else {
          setChatBotMessages([
            {
              message: "Hello! How can I help you today?",
              isUser: false,
              isNewMessage: false,
              id:"",
              isDefaultMsg:true
            },
          ]);
        }
      }
    };

    if (location) {
      const isWellnessOrPerformance =
        location.pathname === "/wellness" ||
        location.pathname === "/performance";
      setShowChatBot(isWellnessOrPerformance);
      if (isWellnessOrPerformance) {
        fetchChatHistory();
      }
    }
  }, [location, currentAthlete,clearHistory]);

  const processChatData = (response: ChatMessage) => {
    if (response?.id) {
      setThreadID(response.thread_id);
      setAssistantID(response.assistant_id);
      setMessageID(response.id);
      const queryResponseValue =
        response.content[0]?.text?.value ?? "Unable to get response.";
      handleNewMessage(queryResponseValue,response?.id);
    }
  };

  const getNewMessage = async (athleteId: number, query: string) => {
    const response = await getChatQuery(
      athleteId,
      query,
      threadID,
      assistantID
    );
    if (!response) {
      setIsLoading(false);
      handleNewMessage("Sorry, I am unable to get a response at the moment.","");
      return;
    }
    processChatData(response);
   
  };

  const handleSendClick = async (): Promise<void> => {
    if (!currentAthlete?.id || userMessage.trim() === "") return;
    setIsLoading(true);
    setChatBotMessages((prevMessages) => [
      ...prevMessages,
      { message: userMessage, isUser: true, isNewMessage: false ,id:messageID,isDefaultMsg:false},
      { message: "", isUser: false, isNewMessage: false,id:messageID ,isDefaultMsg:false}, // Temporary loading message
    ]);
    getNewMessage(currentAthlete?.id, userMessage);
    setUserMessage(""); // Clear the input field after sending
  };

  const handleInputChangeMultiline = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setUserMessage(e.target.value);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      await handleSendClick();
    }
  };

  const onExpandClick = async (): Promise<void> => {
    setMaximizeWindow(!maximizeWindow);
  };

  const handleMinimize = () => {
    const newState = !isMinimized;
    setIsMinimized(newState);
    localStorage.setItem(
      CHAT_WINDOW_STATE_KEY,
      JSON.stringify({ isMinimized: newState, isMaximized })
    );
  };

  const handleClose = async (): Promise<void> => {
    handleMinimize();
  };

  const handleSuggestionClick = async (suggestion: string): Promise<void> => {
    if (!currentAthlete?.id) return;
    setIsLoading(true);
    setChatBotMessages((prevMessages) => [
      ...prevMessages,
      { message: suggestion, isUser: true, isNewMessage: false ,id:messageID,isDefaultMsg:false},
      { message: "", isUser: false, isNewMessage: false,id:messageID,isDefaultMsg:false }, // Temporary loading message
    ]);
    getNewMessage(currentAthlete?.id, suggestion);
  };

  if (!showChatBot) {
    return null;
  }

  const handleLikeClick = (id: string | undefined) => {
    setChatBotMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === id
          ? { ...msg, isLiked: true, isDisliked: false } 
          : msg
      )
    );
  
  };

  const handleDislikeClick = (id: string | undefined) => {
    setChatBotMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === id
          ? { ...msg, isLiked: false, isDisliked: true } 
          : msg
      )
    );
  
  };

  return (
    <div
      className={clsx(styles.chatBotWrapper, isMaximized && styles.maximized)}
    >
      <div style={{ width: maximizeWindow ? "430px" : "355px" }}>
        <ChatHeader
          onClose={handleClose}
          onMinimize={handleMinimize}
          isMinimized={isMinimized}
          onExpand={onExpandClick}
          threadId={threadID}
          setClearHistory={setClearHistory}
        />
        {!isMinimized && (
          <div className={styles.chatBodyWrapper}>
            <ChatBody
              messages={chatBotMessages}
              isLoading={isLoading}
              isExpanded={maximizeWindow}
              threadId={ threadID}
              messageId={messageID}
              isLatestMsg={isLatestMsg}
              onLikeClick={handleLikeClick} 
              onDislikeClick={handleDislikeClick} 
             
            />
            <ChatFooter
              isLoading={isLoading}
              onSuggestionClick={handleSuggestionClick}
              onSendClick={handleSendClick}
              onChangeMultiline={handleInputChangeMultiline}
              onKeyDown={handleKeyDown}
            />
          </div>
        )}
      </div>
    </div>
  );
};

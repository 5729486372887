import React, { FC, useEffect } from "react";

import { DateGroupBy, DateRange } from "enums";
import { ChartMeta } from "types";
import {
  calculateDateRange,
  formatDate,
  formatDateAPI,
  parseDate,
  stringifyDateRange,
} from "utils";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentAthlete, getCurrentDateRange, getCurrentGroupBy } from "store/slices/shared";
import { fetchChartData } from "store/slices/chart";
import { APIWorkoutDetailsChartResponse } from "api/chart";

import { TrainingLoad } from "components/training-load/training-load";
import { Measurements } from "components/measurements/measurements";

const getChartMeta = (
  name: string,
  dateRange: DateRange,
  athleteIds: number[],
  currentDateUnit?: DateGroupBy
): ChartMeta<APIWorkoutDetailsChartResponse[]> => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    api: {
      url: "/performance/workoutDetails/:fromDate/:toDate?unit=:unit&athleteIds=:athleteIds&aggregatePerAthlete=false",
      params: {
        ":unit": currentDateUnit ?? stringifyDateRange(dateRange),
        ":fromDate": formatDateAPI(startDate),
        ":toDate": formatDateAPI(endDate),
        ":athleteIds": athleteIds.join("&athleteIds="),
      },
    },
    charts: [
      {
        name: `${name}TrainingVariables`,
        getLabels: (data: APIWorkoutDetailsChartResponse[]) =>
          data?.map((point) => formatDate(parseDate(point?.startDate))),
        getValues: (data: APIWorkoutDetailsChartResponse[]) => ({
          climbInMeter: data?.map((item) => ({
            value: item?.climbInMeter,
          })),
          distanceInKm: data?.map((item) => ({
            value: item?.distanceInKm,
          })),
          durationInHour: data?.map((item) => ({
            value: item?.durationInHour,
          })),
          intensityFactor: data?.map((item) => ({
            value: item?.intensityFactor,
          })),
          tss: data?.map((item) => ({ value: item?.tss })),
          workInKj: data?.map((item) => ({ value: item?.workInKj })),
        }),
      },
    ],
  };
};

export const Training: FC = () => {
  const dispatch = useDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentDateRange = useSelector(getCurrentDateRange);
  const currentDateUnit = useSelector(getCurrentGroupBy);

  useEffect(() => {
    console.info(currentDateUnit);
  }, [currentDateUnit]);

  useEffect(() => {
    if (currentAthlete) {
      dispatch(
        fetchChartData<APIWorkoutDetailsChartResponse[]>(
          getChartMeta("athlete", currentDateRange, [currentAthlete.id], currentDateUnit)
        )
      );
    }
  }, [dispatch, currentDateRange, currentDateUnit, currentAthlete]);

  const trainingVariables = useSelector((state) => state.chart.athleteTrainingVariables);

  return (
    <>
      <TrainingLoad />
      <Measurements data={trainingVariables} addBorder={false} />
    </>
  );
};

/* eslint-disable no-unsafe-optional-chaining */
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getAthleteList } from "store/slices/shared";
import { fetchTeamPerformance } from "store/slices/performance";

import { ScatterChartWithHeader } from "components/charts/scatter-chart/scatter-chart";
import { ChartSize } from "components/charts/constants";
import { GRADIENT_AREA_RED, TEAM_PERFORMANCE_BAR_COLOR } from "components/charts/colors";
import { Loading } from "components/loading/loading";

import styles from "./reusable-chart.module.scss";
import { WeeklyReport } from "types";
import { ToolTipDataType } from "enums";
import clsx from "clsx";

type Props = {
  weeklyReport: WeeklyReport[];
  chartTitle: string;
  chartValues: {
    value: number;
  }[];
  reportDataType?: ToolTipDataType;
  tooltipTitle: string;
  barColors: string[];
  capitalizeFirstLetter?: boolean;
  additionalComponent?: ReactNode;
  yAxisMargin?: number;
  markLineSpace?: number;
  customMarkLines?: Record<string, any>;
  customMarkArea?: Record<string, any>;
  loading?: boolean;
  isTablet?: boolean;
  isTimestamp?: boolean;
  chartType?: string;
  isCrossHair?: boolean;
  hideDecimalPoints?: boolean;
};

export const ReusableChartComp: FC<Props> = ({
  weeklyReport,
  chartTitle,
  chartValues,
  reportDataType,
  tooltipTitle,
  hideDecimalPoints,
  barColors,
  capitalizeFirstLetter,
  additionalComponent,
  yAxisMargin = 20,
  markLineSpace,
  customMarkLines,
  customMarkArea,
  loading,
  isTablet,
  isTimestamp,
  chartType,
  isCrossHair,
}) => {
  const athleteList = useSelector(getAthleteList);

  const roundDownToNearest50 = (num: number) => {
    return Math.floor(num / 10) * 10;
  };

  const minYAxis = useMemo(() => {
    const values = chartValues.map((item) => item.value);

    if (values?.length > 0) {
      const minValue = Math.min(...values);
      const roundedValue = roundDownToNearest50(minValue);
      return roundedValue > 0 ? 0 : roundedValue;
    } else {
      return 0;
    }
  }, [chartValues]);

  const labels = useMemo(() => {
    const emptyLabels = markLineSpace ? Array(markLineSpace).fill("") : [];
    const athleteLabels =
      weeklyReport?.map((item) => {
        const athleteName = athleteList.find((athlete) => athlete.id === item.athleteId);
        return `${athleteName?.firstName ?? ""} ${athleteName?.lastName ?? ""}`.trim();
      }) || [];
    return [...emptyLabels, ...athleteLabels];
  }, [weeklyReport, athleteList, markLineSpace]);

  const teamPerformanceSeries = useMemo(() => {
    if (!weeklyReport) return [];

    const emptyValues = markLineSpace ? Array(markLineSpace).fill("") : [];
    return [
      {
        name: tooltipTitle,
        color: TEAM_PERFORMANCE_BAR_COLOR,
        areaColor: GRADIENT_AREA_RED,
        dataType: reportDataType,
        chartType: chartType ?? "bar",
        hideInLegend: true,
        values: [...emptyValues, ...chartValues],
      },
    ];
  }, [chartValues, weeklyReport, reportDataType, tooltipTitle, markLineSpace, chartType]);

  if (loading) {
    return (
      <div>
        <div className={styles.content}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.content}>
        <ScatterChartWithHeader
          showCrosshair={isCrossHair}
          hideDecimalPoints={hideDecimalPoints}
          showGrid={false}
          capitalizeFirstLetter={capitalizeFirstLetter}
          name={chartTitle}
          type={ChartSize.Big}
          customMarkLines={customMarkLines}
          customMarkArea={customMarkArea}
          barMaxWidth="20px"
          rotateXAxisLabels={true}
          headerComponent={
            <div className={styles.headerContainer}>
              <div className={styles.bigTitle}>
                <span
                  className={clsx(
                    styles.bold,
                    !capitalizeFirstLetter && styles.removeCapitalizeFirstLetter
                  )}
                >
                  {chartTitle}
                </span>
                {additionalComponent}
              </div>
            </div>
          }
          source={"AI"}
          yLabelMargins={yAxisMargin}
          boundaryGap={true}
          bottomMargin={10}
          labels={labels}
          series={teamPerformanceSeries}
          containLabels={true}
          isTablet={isTablet}
          yAxis={[
            {
              min: minYAxis,
              timestamp: isTimestamp,
            },
          ]}
          intervals={0}
          colorsList={barColors}
        />
      </div>
    </div>
  );
};

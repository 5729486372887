import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as InfoIcon } from "images/info_icon.svg";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";

import styles from "./coach-feedback.module.scss";

import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getAthleteList,
  getCurrentWeekRange,
  getToggleCommentPopup,
  setFilteredWeeklyReportData,
  setFullWeeklyReportData,
  setToggleCommentPopup,
} from "store/slices/shared";
import { Athlete, WeeklyReport } from "types";
import { ReusableInputField } from "components/add-data/anthropometry-popup/resusable-input-field/resusable-input-field";
import {
  ErrorsState,
  FormState,
} from "components/add-data/anthropometry-popup/anthropometry-popup";
import { getAge, parseDate } from "utils";
import { countryCodesMapping } from "country-codes-mapping";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import Tippy from "@tippyjs/react";
import {
  coachCommentsContent,
  coachRatingContent,
  createTooltip,
  getWeekNumber,
  submitCoachFeedback,
} from "pages/weekly-report/utils";
import { trackError } from "appInsights/logInsights";
import { getWeeklyReport } from "api/weekly-reports";

type Props = {
  filteredData: WeeklyReport[];
};

export const CoachFeedback: FC<Props> = ({ filteredData }) => {
  const dispatch = useDispatch();
  const athleteList = useSelector(getAthleteList);
  const selectedWeekRange = useSelector(getCurrentWeekRange);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const athleteReportRedux = useSelector(getToggleCommentPopup).athleteReport;
  const athleteReport: WeeklyReport = useMemo(() => {
    return (athleteReportRedux || {}) as WeeklyReport;
  }, [athleteReportRedux]);
  const athlete: Athlete = athleteReport.athlete as Athlete;
  const memoizedSelectedWeekRange = useMemo(() => selectedWeekRange, [selectedWeekRange]);
  const memoizedAthleteList = useMemo(() => athleteList, [athleteList]);
  const [isEdit, setIsEdit] = useState(false);

  const fetchDataCallback = useCallback(async () => {
    if (!memoizedSelectedWeekRange || !memoizedAthleteList.length) {
      return;
    }

    const year = new Date(memoizedSelectedWeekRange.startDate).getFullYear();
    const weekNumber = getWeekNumber(new Date(memoizedSelectedWeekRange.startDate));
    const response = await getWeeklyReport(year, weekNumber);

    const resp = response.map((report) => {
      return {
        ...report,
        athlete: memoizedAthleteList.find((memAthlete) => memAthlete.id === report.athleteId),
      };
    });

    resp.sort((a, b) => {
      if (a.athlete && b.athlete) {
        return a.athlete.lastName.localeCompare(b.athlete.lastName);
      }
      return 0;
    });

    const result = resp.filter((item1) =>
      filteredData.some((item2) => item1.athleteId === item2.athleteId)
    );

    dispatch(setFullWeeklyReportData(resp));
    dispatch(setFilteredWeeklyReportData(result));
  }, [memoizedSelectedWeekRange, memoizedAthleteList, dispatch, filteredData]);

  const closePopup = () => {
    dispatch(
      setToggleCommentPopup({
        toggle: false,
        athleteReport: undefined,
      })
    );
  };

  const details: {
    label: string;
    value: string | number;
    color?: string;
  }[] = [
    { label: "Total Duration", value: athleteReport.durationStr },
    { label: "Elevation Gain", value: `${athleteReport.climbInMeter} meters` },
    { label: "Distance", value: `${athleteReport.distanceInKm.toFixed(2)} km` },
    { label: "TSS", value: athleteReport.tss.toFixed(0) },
    { label: "CTL", value: athleteReport.ctl.toFixed(0) },
    {
      label: "TSB",
      value: athleteReport.tsb.toFixed(0),
      color: athleteReport.tsb > 25 ? "#DEA754" : athleteReport.tsb <= -30 ? "#F93939" : undefined,
    },
    { label: "mCP (W/kg)", value: athleteReport.mCPRelative.toFixed(2) },
  ];

  const [form, setForm] = useState<FormState>({
    coachRating: "",
    comments: "",
    nextRace: "",
  });

  const [errors, setErrors] = useState<ErrorsState>({
    coachRating: false,
    comments: false,
    nextRace: false,
  });

  const fieldProperties = [
    {
      name: "coachRating",
      label: "Rate the athlete's performance (1-10)",
      type: "number",
      min: 1,
      max: 10,
      showMinMax: false,
      placeholderText: "Rate out of 10",
      toolTipComponent: createTooltip(coachRatingContent),
    },
    {
      name: "comments",
      label: "Coach's Evaluation Comments",
      type: "text",
      showCharCount: true,
      min: 0,
      max: 250,
      showMinMax: false,
      multiline: true,
      placeholderText: "Coach Comments",
      toolTipComponent: createTooltip(coachCommentsContent),
    },
    {
      name: "nextRace",
      label: "Next Race",
      type: "text",
      placeholderText: "Next Race",
    },
  ];

  useEffect(() => {
    if (athleteReport.comments && athleteReport.comments.length > 0) {
      setForm({
        coachRating: athleteReport.coachRating ? athleteReport.coachRating.toString() : "",
        comments: athleteReport.comments,
        nextRace: athleteReport.nextRace || "",
      });
      setIsEdit(true);
    }
  }, [athleteReport]);

  const handleSubmitClick = async (): Promise<void> => {
    const year = new Date(selectedWeekRange.startDate).getFullYear();
    const weekNumber = getWeekNumber(new Date(selectedWeekRange.startDate));
    const newErrors: ErrorsState = Object.keys(form).reduce<ErrorsState>((acc, key) => {
      acc[key] = form[key as keyof FormState].trim() === "";
      return acc;
    }, {} as ErrorsState);

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error === true);

    if (!hasErrors) {
      try {
        const response = await submitCoachFeedback(athlete.id, form, year, weekNumber);
        if (response) {
          fetchDataCallback().then(() => {
            closePopup();
          });
        }
      } catch (error) {
        trackError(error as Error, { location: "submitCoachFeedback" });
        setErrorMessage((error as any).message);
      }
    } else {
      setErrorMessage("Form submission prevented due to validation errors.");
    }
  };

  const handleIsEditSubmitClick = async (): Promise<void> => {
    const year = new Date(selectedWeekRange.startDate).getFullYear();
    const weekNumber = getWeekNumber(new Date(selectedWeekRange.startDate));
    const newErrors: ErrorsState = Object.keys(form).reduce<ErrorsState>((acc, key) => {
      acc[key] = form[key as keyof FormState].trim() === "";
      return acc;
    }, {} as ErrorsState);

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error === true);

    if (!hasErrors) {
      try {
        const response = await submitCoachFeedback(athlete.id, form, year, weekNumber);
        if (response) {
          fetchDataCallback().then(() => {
            closePopup();
          });
        }
      } catch (error) {
        trackError(error as Error, { location: "submitCoachFeedback" });
        setErrorMessage((error as any).message);
      }
    } else {
      setErrorMessage("Form submission prevented due to validation errors.");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const fieldProp = fieldProperties.find((prop) => prop.name === name);

    if (!fieldProp) return;

    let isValid = true;
    if (fieldProp.type === "number") {
      const numericValue = parseFloat(value);
      isValid = numericValue >= fieldProp.min! && numericValue <= fieldProp.max!;
    } else if (fieldProp.showCharCount) {
      isValid = value.length <= 250;
    }

    setForm((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: !isValid }));
  };

  const handleInputChangeMultiline = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const fieldProp = fieldProperties.find((prop) => prop.name === name);

    if (!fieldProp) return;

    let isValid = true;
    if (fieldProp.type === "number") {
      const numericValue = parseFloat(value);
      isValid = numericValue >= fieldProp.min! && numericValue <= fieldProp.max!;
    } else if (fieldProp.showCharCount) {
      isValid = value.length <= 250;
    }

    setForm((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: !isValid }));
  };

  const age = athlete && getAge(parseDate(athlete.dateOfBirth));
  const groupName = athlete?.group?.map((item) => item?.name).join(", ");
  const countryAlpha2Code =
    athlete?.countryCode && countryCodesMapping[athlete.countryCode]
      ? countryCodesMapping[athlete?.countryCode]
      : "AE";
  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div className={clsx(styles.inputBox, styles.box)} data-testid="add-comparison-list">
        <div className={styles.header}>
          <h3 className={styles.title}>Weekly Report {selectedWeekRange.label}</h3>
          <Close
            className={styles.closeButton}
            onClick={closePopup}
            aria-label="Close"
            title="Close"
          />
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.athleteDetailSection}>
            <div className={styles.athleteCardWrapper}>
              <div className={styles.athleteCard}>
                <img className={styles.avatarPictureIcon} alt="" src={athlete?.picture} />
                <div className={styles.frameParent}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.xAvatarComponentsParent}>
                        <div className={styles.xAvatarComponents}>
                          <div className={styles.firstName}>{athlete?.firstName}</div>
                          <div className={styles.lastName}>{athlete?.lastName}</div>
                        </div>
                      </div>
                      <div className={styles.flags}>
                        <img
                          className={styles.flagIcon}
                          src={flagUrl}
                          alt={athlete?.country}
                          title={athlete?.country}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.xAvatarComponentsGroup}>
                    <div className={styles.xAvatarComponents3}>
                      <div className={styles.yrs}>{age} Yrs</div>
                    </div>
                    <div className={styles.frameChild} />
                    <div className={styles.xAvatarComponents3}>
                      <div className={styles.yrs}>H: {athlete?.height || "--"}</div>
                    </div>
                    <div className={styles.frameChild} />
                    <div className={styles.xAvatarComponents3}>
                      <div className={styles.yrs}>W: {athlete?.weight || "--"}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.details}>
              {details.map((detail, index) => (
                <div key={index} className={styles.detail}>
                  <div className={styles.label}>{detail.label}:</div>
                  <div
                    className={styles.value}
                    style={{
                      color: detail.color,
                    }}
                  >
                    {detail.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.coachInputSection}>
            {fieldProperties.map(
              ({
                name,
                label,
                min,
                max,
                type,
                showCharCount,
                showMinMax,
                multiline,
                placeholderText,
                toolTipComponent,
              }) => (
                <ReusableInputField
                  key={name}
                  name={name}
                  label={label}
                  toolTipComponent={toolTipComponent}
                  type={type}
                  min={min}
                  max={max}
                  placeholderText={placeholderText}
                  multiline={multiline}
                  showCharCount={showCharCount}
                  showMinMax={showMinMax}
                  value={form[name as keyof FormState]}
                  onChange={handleInputChange}
                  onChangeMultiline={handleInputChangeMultiline}
                  error={errors[name as keyof ErrorsState]}
                />
              )
            )}
          </div>
        </div>

        <div className={styles.footer}>
          <div>{errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}</div>
          <PrimaryButton
            className={styles.saveButton}
            onClick={!isEdit ? handleSubmitClick : handleIsEditSubmitClick}
            title={"Save"}
            disabled={
              Object.values(errors).some((error) => error) ||
              !form.coachRating ||
              !form.comments ||
              !form.nextRace
            }
          />
        </div>
      </div>
    </div>
  );
};

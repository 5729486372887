import { appInsights } from "./AppInsights";

function generateTelemetryProperties(additionalProperties?: {
  [key: string]: string;
}) {
  const flavorType = process.env.REACT_APP_FLAVOR;
  const environment = process.env.REACT_APP_ENV_NAME;
  const userName = localStorage.getItem("userName");

  return {
    flavorType,
    environment,
    userName,
    ...additionalProperties,
  };
}

function trackUserInteractionEvent(
  eventName: string,
  additionalProperties?: { [key: string]: string }
) {
  const properties = generateTelemetryProperties(additionalProperties);
  appInsights.trackEvent({ name: eventName, properties });
}

function trackChartInteraction(
  interactionType: string,
  additionalProperties?: { [key: string]: string | undefined }
) {
  const properties = generateTelemetryProperties({
    interactionType,
    ...additionalProperties,
  });
  appInsights.trackEvent({ name: "ChartInteraction", properties });
}

function trackChartViewEvent(
  interactionType: string,
  additionalProperties?: { [key: string]: string | undefined }
) {
  const properties = generateTelemetryProperties({
    interactionType,
    ...additionalProperties,
  });
  appInsights.trackEvent({ name: "ChartInteraction", properties });
}

function trackError(
  error: Error,
  additionalProperties?: { [key: string]: string }
) {
  const properties = generateTelemetryProperties(additionalProperties);
  appInsights.trackException({ exception: error, properties });
}

export {
  trackChartInteraction,
  trackChartViewEvent,
  trackError,
  trackUserInteractionEvent,
};

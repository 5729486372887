import React, { useEffect, useState } from "react";
import { Layout } from "components/layout/layout";
import { HeartMeasurements } from "pages/wellness/heart-measurements/heart-measurements";
import { OverallWellness } from "pages/wellness/overall-wellness/overall-wellness";
import { BodyComposition } from "pages/wellness/body-composition/body-composition";
import { useSelector } from "hooks/app-hooks";
import { getIsCyclist } from "store/slices/auth";
import styles from "../health/health.module.scss";
import { ReactComponent as FilterIcon } from "images/filter_icon.svg";
import { Tabs, TabsLayout } from "components/tabs-layout/tabs-layout";
import { PrimaryButton } from "components/primary-button/primary-button";
import { PhysiotherapyPopup } from "components/physiotherapy-popup/physiotherapy-popup";
import { Illnesspopup } from "components/illness-popup/illness-popup";
import { Injurypopup } from "components/injury-popup/injury-popup";
import { HealthTable } from "components/health/health-table";
import { useDispatch } from "react-redux";
import { fetchHealthEnums, fetchMedicalReports } from "store/slices/health";

export const Health = () => {
  const [isTablet, setIsTablet] = useState(false);
  const [togglePopup, setTogglePopup] = useState(false);
  const [physiotherapyPopup, setPhysiotherapyPopup] = useState(false);
  const [illnessPopup, setIllnessPopup] = useState(false);
  const [injuryPopup, setInjuryPopup] = useState(false);
  const dispatch = useDispatch();



  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);

  // fetch all health enums
  useEffect(() => {
    dispatch(fetchHealthEnums);
    dispatch(fetchMedicalReports(0));
  }, [dispatch, isTablet]);

  const topComponent = (
    <>
      <div className={styles.topWrapper}>
        <div className={styles.exportDropdown}>
          {/* <PrimaryButton
            className={styles.button}
            onClick={() => setTogglePopup(true)}
            title={isTablet ? undefined : "Filter"}
            icon={<FilterIcon />}
          /> */}
          <div className={styles.rightOptions}>
            <PrimaryButton
              className={styles.exportButton}
              onClick={() => setPhysiotherapyPopup(true)}
              title={isTablet ? "Report Rehabilitation" : "Report Rehabilitation"}
            />
            <PrimaryButton
              className={styles.exportButton}
              onClick={() => setIllnessPopup(true)}
              title={isTablet ? "Report Illness" : "Report Illness"}
            />
            <PrimaryButton
              className={styles.exportButton}
              onClick={() => setInjuryPopup(true)}
              title={isTablet ? "Report Injury" : "Report Injury"}
            />
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <Layout
        top={topComponent}
        content={<HealthTable />}
        showCalendarComp={false}
        contentClassName={styles.table}
        contentCustomWrapper={styles.table_Content}
        pinTab={true}
      />

      {physiotherapyPopup && (
        <PhysiotherapyPopup
          onClose={() => {
            setPhysiotherapyPopup(false);
          }}
        />
      )}

      {illnessPopup && (
        <Illnesspopup
          onClose={() => {
            setIllnessPopup(false);
          }}
        />
      )}

      {injuryPopup && (
        <Injurypopup
          onClose={() => {
            setInjuryPopup(false);
          }}
        />
      )}
    </>
  );
};


const IllnessVomiting = ({ opacity }: { opacity: number }) => {
  return (
    <svg width="160" height="410" viewBox="0 0 160 410" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={opacity}>
      <path d="M82.3805 70.2364C80.8324 70.733 79.1676 70.733 77.6195 70.2364L74.5863 69.2636C73.1679 68.8086 72.2058 67.4896 72.2058 66V66C72.2058 64.5104 73.1679 63.1914 74.5863 62.7364L77.6195 61.7636C79.1676 61.267 80.8324 61.267 82.3805 61.7636L85.4137 62.7364C86.8321 63.1914 87.7942 64.5104 87.7942 66V66C87.7942 67.4896 86.8321 68.8086 85.4137 69.2636L82.3805 70.2364Z" fill="#32CD32" fill-opacity="0.4" />
      <path d="M81.8993 69.3734C80.6658 69.7803 79.3342 69.7803 78.1007 69.3734L75.8371 68.6266C74.7036 68.2526 73.9378 67.1936 73.9378 66V66C73.9378 64.8064 74.7036 63.7474 75.8371 63.3734L78.1007 62.6266C79.3342 62.2197 80.6658 62.2197 81.8993 62.6266L84.1629 63.3734C85.2964 63.7474 86.0622 64.8064 86.0622 66V66C86.0622 67.1936 85.2964 68.2526 84.1629 68.6266L81.8993 69.3734Z" fill="#32CD32" fill-opacity="0.7" />
    </svg>
  )
}

export default IllnessVomiting
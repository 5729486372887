import React, { FC } from "react";

import { getCoachProfile } from "store/slices/shared";
import { useDispatch, useSelector } from "hooks/app-hooks";
import clsx from "clsx";

import styles from "./coach-info.module.scss";

import { ReactComponent as SignOutIcon } from "images/sign-out.svg";
import { ReactComponent as SettingsIcon } from "images/settings.svg";

import { DropdownMenuItemComponent } from "components/dropdown-profile-btns/dropdown-profile";
import { useHistory } from "react-router-dom";
import { setAccount, setAuthState } from "store/slices/auth";
import { useState } from "react";
import { RoleType } from "enums";
import { trackUserInteractionEvent } from "appInsights/logInsights";

type Props = {
  className?: string;
};

export const CoachInfo: FC<Props> = ({ className }) => {
  const coachProfile = useSelector(getCoachProfile);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleLogout = async () => {
    dispatch(setAuthState(false));
    trackUserInteractionEvent("Logout", { role: RoleType.coach });
    await dispatch(setAccount(RoleType.cyclist));
    history.push("/sign-in");
  };

  const handleSettings = async () => {
    history.push("/settings");
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className={clsx(styles.wrapper, className)} onClick={toggleDropdown}>
      <div className={styles.info}>
        <div className={styles.name}>
          {coachProfile?.firstName} <span className={styles.strong}>{coachProfile?.lastName}</span>
        </div>
        <div className={styles.role}>
          <span>{coachProfile?.role} </span>
        </div>
      </div>
      <div className={styles.avatar}>
        <img className={styles.image} src={coachProfile?.picture} alt="User avatar" />
      </div>
      {isDropdownVisible && (
        <div className={styles.dropdown}>
          <ul className={styles.dropdownMenu}>
            <li>
              <DropdownMenuItemComponent
                icon={<SettingsIcon />}
                text="Settings"
                className={styles.icon}
                handleClick={handleSettings}
              />
              <DropdownMenuItemComponent
                icon={<SignOutIcon />}
                text="Logout"
                className={styles.icon}
                handleClick={handleLogout}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

import { TableColumn } from "react-data-table-component";
import { toExcel } from "to-excel";
import { IHeader } from "to-excel/lib/cjs/interfaces";
import { APIResponse, Athlete, WeeklyReport } from "types";
import AthleteDetails from "../../components/athlete-details/athlete-details";
import { WeekReportRange } from "enums";
import { ReactComponent as InfoIcon } from "images/info_icon.svg";
import styles from "./utils.module.scss";
import Tippy from "@tippyjs/react";
import {
  DETRAINING_LINE_COLOR,
  DETRAINING_LABEL_COLOR,
  COMPETITION_READY_LINE_COLOR,
  COMPETITION_READY_LABEL_COLOR,
  TRANSITIONAL_LINE_COLOR,
  TRANSITIONAL_LABEL_COLOR,
  PRODUCTIVE_TRAINING_LINE_COLOR,
  PRODUCTIVE_TRAINING_LABEL_COLOR,
  OVERREACHING_LINE_COLOR,
  OVERREACHING_LABEL_COLOR,
  DETRAINING_AREA_COLOR,
  COMPETITION_READY_AREA_COLOR,
  TRANSITIONAL_AREA_COLOR,
  PRODUCTIVE_TRAINING_AREA_COLOR,
  OVERREACHING_AREA_COLOR,
} from "components/charts/colors";
import { AddComments } from "./components/add-comments/add-comments";
import { FormState } from "components/add-data/anthropometry-popup/anthropometry-popup";
import { axiosInstance } from "axios-instance/axios-instance";

function convertArrayOfObjectsToCSV(headers: IHeader[], array: WeeklyReport[]) {
  let result: string;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = headers.map((header) => header.field);

  result = "";
  result += headers.map((header) => header.label).join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: { [x: string]: any }) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      let value = item[key];

      if (typeof value === "object" && value !== null) {
        value = JSON.stringify(value);
      }
      if (typeof value === "string") {
        value = value.replace(/[\r\n]+/g, " "); // Replacing newline characters with a space
      }
      result += value;

      ctr++;
    });
    result += lineDelimiter;
  });
  

  return result;
}

export function downloadCSV(headers: IHeader[], array: WeeklyReport[], name: string) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(headers, array);
  if (csv == null) return;

  const filename = name + ".csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export const getWeekNumber = (date: Date): number => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - startOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
};

export function generateWeeklyOptions(): WeekReportRange[] {
  const options: WeekReportRange[] = [];
  const now = new Date();
  const latestLabel = "Latest";

  for (let i = 0; i < 6; i++) {
    const currentDate = new Date(now);
    const dayOfWeek = currentDate.getDay();
    const diffToMonday = (dayOfWeek + 6) % 7;
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() - diffToMonday - i * 7 + 6);
    endDate.setHours(23, 59, 59, 999);
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 6);
    startDate.setHours(0, 0, 0, 0);

    const label =
      i === 0
        ? latestLabel
        : `W${getWeekNumber(startDate)} ${startDate.toLocaleDateString("en-US", {
            month: "short",
          })}`;

    options.push({
      label: label,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  }

  return options;
}

export const topMenuItems = [
  {
    to: "/weekly-report-table",
    text: "Summary Table",
  },
  {
    to: "/weekly-report-chart",
    text: "Performance Charts",
  },
];

export function exportToExcel(headers: IHeader[], data: WeeklyReport[], filename: string) {
  toExcel.exportXLS(headers, data, filename);
}

const athleteSort = (rowA: WeeklyReport, rowB: WeeklyReport) => {
  if (rowA.athlete && rowB.athlete) {
    const a = (rowA.athlete as Athlete).lastName.toLowerCase();
    const b = (rowB.athlete as Athlete).lastName.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }
  }
  return 0;
};

export const groupReportColumns: TableColumn<WeeklyReport>[] = [
  {
    name: "Athlete",
    id: "athlete",
    cell: (row) => <AthleteDetails athlete={row.athlete as Athlete} />,
    sortable: true,
    sortFunction: athleteSort,
    width: "300px",
    style: {
      position: "sticky",
      left: "0px",
      backgroundColor: "inherit",
      zIndex: 5,
      boxShadow: "5px 0 5px -3px rgba(0,0,0,0.5)",
    },
  },
  {
    name: "Total Duration",
    id: "durationStr",
    center: true,
    width: "160px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selector: (row) => row.durationStr,
    sortable: true,
    sortFunction: (a, b) => a.durationInHour - b.durationInHour,
  },
  {
    name: "Distance (km)",
    center: true,
    width: "160px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "distanceInKm",
    selector: (row) => parseFloat(row.distanceInKm.toFixed(0)),
    sortable: true,

    sortFunction: (a, b) => a.distanceInKm - b.distanceInKm,
  },
  {
    name: "Elevation Gain (m)",
    id: "climbInMeter",
    width: "160px",
    center: true,
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selector: (row) => row.climbInMeter,
    sortable: true,
    sortFunction: (a, b) => a.climbInMeter - b.climbInMeter,
  },
  {
    name: "Work (kJ)",
    center: true,
    width: "120px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "workInKj",
    selector: (row) => parseFloat(row.workInKj.toFixed(0)),
    sortable: true,

    sortFunction: (a, b) => a.workInKj - b.workInKj,
  },
  {
    name: "TSS",
    center: true,
    width: "120px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "tss",
    selector: (row) => parseFloat(row.tss.toFixed(0)),
    sortable: true,

    sortFunction: (a, b) => a.tss - b.tss,
  },
  {
    name: "CTL",
    center: true,
    width: "120px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "ctl",
    selector: (row) => parseFloat(row.ctl.toFixed(0)),
    sortable: true,
    sortFunction: (a, b) => a.ctl - b.ctl,
  },
  {
    name: "TSB",
    center: true,
    width: "120px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "tsb",
    selector: (row) => parseFloat(row.tsb.toFixed(0)),
    sortable: true,
    conditionalCellStyles: [
      {
        when: (row) => row.tsb > 25,
        style: {
          color: "#DEA754",
        },
      },
      {
        when: (row) => row.tsb <= -30,
        style: {
          color: "#F93939",
        },
      },
    ],
    sortFunction: (a, b) => a.tsb - b.tsb,
  },
  {
    name: (
      <div className={styles.columnHeader}>
        mCP (W/kg)
        <Tippy
          placement="bottom"
          content={
            <div className={styles.tooltipContent}>
              <span>Last</span> <span>90 Days</span>
            </div>
          }
        >
          <InfoIcon className={styles.infoIcon} />
        </Tippy>
      </div>
    ),
    id: "mCPRelative",
    center: true,
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selector: (row) => row.mCPRelative.toFixed(2),
    sortable: true,
  },
];

export const columns: TableColumn<WeeklyReport>[] = [
  {
    name: "Athlete",
    id: "athlete",
    cell: (row) => <AthleteDetails athlete={row.athlete as Athlete} />,
    sortable: true,
    sortFunction: athleteSort,
    width: "300px",
    style: {
      position: "sticky",
      left: "0px",
      backgroundColor: "inherit",
      zIndex: 5,
      boxShadow: "5px 0 5px -3px rgba(0,0,0,0.5)",
    },
  },
  {
    name: "Total Duration",
    id: "durationStr",
    center: true,
    width: "140px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selector: (row) => row.durationStr,
    sortable: true,
    sortFunction: (a, b) => a.durationInHour - b.durationInHour,
  },
  {
    name: "Elevation Gain (m)",
    id: "climbInMeter",
    width: "160px",
    center: true,
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selector: (row) => parseFloat(row.climbInMeter.toFixed(0)),
    sortable: true,
    sortFunction: (a, b) => a.climbInMeter - b.climbInMeter,
  },
  {
    name: "Work (kJ)",
    center: true,
    width: "120px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "workInKj",
    selector: (row) => parseFloat(row.workInKj.toFixed(0)),
    sortable: true,

    sortFunction: (a, b) => a.workInKj - b.workInKj,
  },
  {
    name: "Distance (km)",
    center: true,
    width: "140px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "distanceInKm",
    selector: (row) => parseFloat(row.distanceInKm.toFixed(0)),
    sortable: true,

    sortFunction: (a, b) => a.distanceInKm - b.distanceInKm,
  },
  {
    name: "TSS",
    center: true,
    width: "100px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "tss",
    selector: (row) => parseFloat(row.tss.toFixed(0)),
    sortable: true,

    sortFunction: (a, b) => a.tss - b.tss,
  },
  {
    name: "CTL",
    center: true,
    width: "100px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "ctl",
    selector: (row) => parseFloat(row.ctl.toFixed(0)),
    sortable: true,
    sortFunction: (a, b) => a.ctl - b.ctl,
  },
  {
    name: "TSB",
    center: true,
    width: "100px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "tsb",
    selector: (row) => parseFloat(row.tsb.toFixed(0)),
    sortable: true,
    conditionalCellStyles: [
      {
        when: (row) => row.tsb > 25,
        style: {
          color: "#DEA754",
        },
      },
      {
        when: (row) => row.tsb <= -30,
        style: {
          color: "#F93939",
        },
      },
    ],
    sortFunction: (a, b) => a.tsb - b.tsb,
  },
  {
    name: "Coach Rating",
    center: true,
    width: "140px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "coachRating",
    selector: (row) => (row.coachRating ? row.coachRating.toFixed(0) : "-"),
    sortable: true,
  },
  {
    name: "Coach Comments",
    width: "300px",
    center: true,
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "comments",
    cell: (row) => {
      if (row.comments && row.comments.length > 0) {
        return (
          <div className={styles.commentsWrapper}>
            <span>
              {row.comments.length >= 75 ? (
                !row.commentEnabled ? (
                  <Tippy
                    content={
                      <div className={styles.commentTooltipContent}>
                        <span>{row.comments}</span>
                      </div>
                    }
                  >
                    <span>{`${row.comments.substring(0, 75)}...`}</span>
                  </Tippy>
                ) : (
                  <span>{`${row.comments.substring(0, 75)}...`}</span>
                )
              ) : (
                row.comments
              )}{" "}
              <AddComments athleteReport={row} isDisabled={!row.commentEnabled} isEdit />
            </span>
          </div>
        );
      } else {
        return <AddComments athleteReport={row} isDisabled={!row.commentEnabled} />;
      }
    },
  },
  {
    name: "Next Race",
    width: "200px",
    center: true,
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "nextRace",
    cell: (row) => (row.nextRace && row.nextRace.length > 0 ? row.nextRace : "-"),
  },
  {
    name: (
      <div className={styles.columnHeader}>
        mCP (W/kg)
        <Tippy
          placement="bottom"
          content={
            <div className={styles.tooltipContent}>
              <span>Last</span> <span>90 Days</span>
            </div>
          }
        >
          <InfoIcon className={styles.infoIcon} />
        </Tippy>
      </div>
    ),
    id: "mCPRelative",
    width: "110px",
    center: true,
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selector: (row) => row.mCPRelative.toFixed(2),
    sortable: true,
  },
  {
    name: "Coach",
    center: true,
    width: "150px",
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    id: "coachName",
    selector: (row) => (row.coachName && row.coachName.length > 0 ? row.coachName : "-"),
    sortable: true,
  },
];

export function hhmmssToHours(time: string): number {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return hours + minutes / 60 + seconds / 3600;
}

export function secondsToHhmmss(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = (totalSeconds % 3600) % 60;

  const hoursStr = hours.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

const createMarkLineData = (
  yAxis: number,
  lineColor: string,
  formatter: string,
  labelColor: string,
  position = "insideStartTop"
) => ({
  yAxis,
  lineStyle: {
    type: "dashed",
    color: lineColor,
  },
  label: {
    formatter,
    position,
    color: labelColor,
  },
});

export const customMarkLines = {
  symbol: ["none", "none"],
  silent: true,
  animation: false,
  label: {
    show: true,
    backgroundColor: "transparent",
    borderRadius: 5,
    padding: 5,
    color: "black",
    borderWidth: 1,
  },
  data: [
    createMarkLineData(25, DETRAINING_LINE_COLOR, "Detraining", DETRAINING_LABEL_COLOR),
    createMarkLineData(
      10,
      COMPETITION_READY_LINE_COLOR,
      "Competition\nReady",
      COMPETITION_READY_LABEL_COLOR
    ),
    createMarkLineData(-10, TRANSITIONAL_LINE_COLOR, "Transitional", TRANSITIONAL_LABEL_COLOR),
    createMarkLineData(
      -30,
      PRODUCTIVE_TRAINING_LINE_COLOR,
      "Productive\nTraining",
      PRODUCTIVE_TRAINING_LABEL_COLOR
    ),
    createMarkLineData(
      -30,
      OVERREACHING_LINE_COLOR,
      "Overreaching",
      OVERREACHING_LABEL_COLOR,
      "insideStartBottom"
    ),
  ],
};

const createColor = (colorStops: any) => ({
  type: "linear",
  x: 0,
  y: 0,
  x2: 1,
  y2: 0,
  colorStops,
});

const createMarkAreaData = (
  startY: number,
  endY: number,
  colorStops: { offset: number; color: string }[]
) => [
  {
    yAxis: startY,
    itemStyle: {
      color: createColor(colorStops),
    },
  },
  {
    yAxis: endY,
  },
];

export const customMarkArea = {
  silent: true,
  data: [
    createMarkAreaData(25, 100000, [
      { offset: 0, color: `rgba(${DETRAINING_AREA_COLOR}, 0.1)` },
      { offset: 0.025, color: `rgba(${DETRAINING_AREA_COLOR}, 0.025)` },
      { offset: 1, color: `rgba(${DETRAINING_AREA_COLOR}, 0)` },
    ]),
    createMarkAreaData(10, 25, [
      { offset: 0, color: `rgba(${COMPETITION_READY_AREA_COLOR}, 0.1)` },
      { offset: 0.025, color: `rgba(${COMPETITION_READY_AREA_COLOR}, 0.025)` },
      { offset: 1, color: `rgba(${COMPETITION_READY_AREA_COLOR}, 0)` },
    ]),
    createMarkAreaData(-10, 10, [
      { offset: 0, color: `rgba(${TRANSITIONAL_AREA_COLOR}, 0.1)` },
      { offset: 0.025, color: `rgba(${TRANSITIONAL_AREA_COLOR}, 0.025)` },
      { offset: 1, color: `rgba(${TRANSITIONAL_AREA_COLOR}, 0)` },
    ]),
    createMarkAreaData(-30, 10, [
      { offset: 0, color: `rgba(${PRODUCTIVE_TRAINING_AREA_COLOR}, 0.1)` },
      { offset: 0.025, color: `rgba(${PRODUCTIVE_TRAINING_AREA_COLOR}, 0.025)` },
      { offset: 1, color: `rgba(${PRODUCTIVE_TRAINING_AREA_COLOR}, 0)` },
    ]),
    createMarkAreaData(-30, -50000, [
      { offset: 0, color: `rgba(${OVERREACHING_AREA_COLOR}, 0.1)` },
      { offset: 0.025, color: `rgba(${OVERREACHING_AREA_COLOR}, 0.025)` },
      { offset: 1, color: `rgba(${OVERREACHING_AREA_COLOR}, 0)` },
    ]),
  ],
};

export const createTooltip = (content: JSX.Element) => (
  <Tippy content={content}>
    <InfoIcon className={styles.infoIcon} />
  </Tippy>
);

export const coachRatingContent = (
  <div className={styles.popupTooltipContent}>
    <ul>
      <li>
        <strong>1-2:</strong> Needs significant improvement
      </li>
      <li>
        <strong>3-4:</strong> Below expectations
      </li>
      <li>
        <strong>5-6:</strong> Meets some expectations
      </li>
      <li>
        <strong>7-8:</strong> Exceeds expectations
      </li>
      <li>
        <strong>9-10:</strong> Exceptional performance
      </li>
    </ul>
  </div>
);

export const coachCommentsContent = (
  <div className={styles.popupTooltipContent}>
    <h3>Please provide detailed feedback on the athlete's performance this week.</h3>
    <ul>
      <li>
        <strong>Strengths:</strong> Highlight the athlete's strong points and successes.
      </li>
      <li>
        <strong>Areas for Improvement:</strong> Identify specific areas where the athlete can
        improve.
      </li>
      <li>
        <strong>Progress:</strong> Mention any noticeable progress or achievements.
      </li>
      <li>
        <strong>Additional Notes:</strong> Include any other relevant observations or
        recommendations.
      </li>
    </ul>
  </div>
);

export const submitCoachFeedback = async (
  athleteId: number,
  form: FormState,
  year: number,
  week: number
): Promise<APIResponse<any> | null> => {
  const requestBody = {
    athleteId,
    performanceRating: parseInt(form.coachRating),
    nextRace: form.nextRace,
    comments: form.comments,
    year: year,
    week: week,
  };
  try {
    const response: APIResponse<any> = await axiosInstance.post("/coach/saveFeedback", requestBody);
    return response.data || null;
  } catch (err: any) {
    throw new Error(err.data ? err.data.message : "Failed to submit feedback");
  }
};


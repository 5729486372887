export const COLOR_LIGHTER_BLUE = "#BBC0CF";
export const COLOR_LIGHT_BLUE = "#B6C2E3";
export const COLOR_BLUE = "#55A1FA";
export const COLOR_BRIGHT_BLUE = "#4696ec";
export const COLOR_SALMON = "#FF616A";
export const COLOR_LIGHT_RED = "#EC4646";
export const COLOR_ORCHID = "#DA70D6";
export const COLOR_LIGHT_GREEN = "#8ECFA5";
export const COLOR_GREEN = "#12BE50";
export const COLOR_TRANSPARENT = "#191E27";
export const COLOR_DARK_GREEN = "#008B32";
export const COLOR_WHITE = "#f9fafd";
export const COLOR_LIGHT_GRAY = "#C5CDDE";
export const COLOR_GRAY = "#8C93A6";
export const COLOR_DARK_GRAY = "#585b63";
export const COLOR_TOOLTIP = "#FFFFFF";
export const COLOR_LIGHT_YELLOW = "#FFE37F";

export const COLOR_CHART_LINE_ONE = "#0759F8";
export const COLOR_CHART_LINE_TWO = "#8AD629";
export const COLOR_CHART_LINE_THREE = "#29C1D6";
export const COLOR_CHART_LINE_FOUR = "#B429D6";
export const COLOR_CHART_LINE_FIVE = "#F80707";
export const COLOR_CHART_LINE_SIX = "#D69129";
export const COLOR_CHART_LINE_SEVEN = "#CF5A4A";
export const COLOR_CHART_LINE_EIGHT = "#104C89";
export const COLOR_CHART_LINE_NINE = "#3773C0";

export const GRADIENT_AREA_BLUE: [string, string] = [
  "rgba(70, 150, 236, 0.2)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_RED: [string, string] = [
  "rgba(236, 70, 70, 0.2)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_WHITE: [string, string] = [
  "rgba(212, 211, 211, 0.2)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_GREEN: [string, string] = [
  "rgba(18, 190, 80, 0.2)",
  "rgba(18, 190, 80, 0)",
];

export const COLOR_AXIS = "#8C93A6";

export const COLOR_BAR_DEFAULT = "#404451";

export const COLOR_RADAR_SECONDARY = "#008B32";

export const TEAM_PERFORMANCE_BAR_COLOR = "#7a5af8";

export const TEAM_PERFORMANCE_BAR_COLOR_ONE = "#8AD629";
export const TEAM_PERFORMANCE_BAR_COLOR_TWO = "#29C1D6";
export const DEFAULT_FONT_COLOR = "#104C89";

export enum RadarLabelColors {
  mmp10Seconds = "#29c1d6",
  mmp30Seconds = "#29c1d6",
  mmp1Minute = "#b429d6",
  mmp5Minutes = "#b429d6",
  mmp10Minutes = "#b429d6",
  mmp20Minutes = "#b429d6",
  mmp30Minutes = "#f48371",
  mmp60Minutes = "#f48371",
}

export enum RadarLegendColors {
  primaryColor = "#0759F8",
  secondaryColor = "#D69129",
}

export const BORDER_SCATTER_DOT = "#303A47";

export const PERFORMANCE_MGMT_COLORS: {
  [key: string]: string | [string, string];
} = {
  TSB: "#FFE37F",
  TSB_GRADIENT: ["rgba(255, 227, 127, 0.2)", "rgba(255, 227, 127, 0)"],
  ATL: "#C354DE",
  ATL_GRADIENT: ["rgba(195, 84, 222, 0.2)", "rgba(195, 84, 222, 0)"],
  CTL: "#397AF9",
  CTL_GRADIENT: ["rgba(57, 122, 249, 0.2)", "rgba(57, 122, 249, 0)"],
};

export const PERFORMANCE_SEPERATOR = "#3c91e7";

export const MARKLINE_COLOR = "#104c89";

export const REPORT_TABLE_COLOR = {
  headCells: {
    style: {
      "&:first-child": {
        boxShadow: "5px 0 5px -3px rgba(0,0,0,0.5)",
        position: "sticky" as const,
        left: "0px",
        backgroundColor: "inherit",
        zIndex: 5,
      },
    },
  },
  rows: {
    style: {
      backgroundColor: "#EBEFFF",
      border: "none",
      color: "#1B212C",
      fontWeight: 500,
      fontSize: "12px",
      "&:not(:last-of-type)": {
        border: "none",
      },
      "&:nth-child(2n)": {
        backgroundColor: "#F5F7FF",
      },
    },
  },
  headRow: {
    style: {
      border: "none",
      backgroundColor: "#F5F7FF",
      color: "#1B212C",
      fontWeight: 500,
      fontSize: "10px",
    },
  },
  noData: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#1B212C",
      backgroundColor: "#FFFFFF",
    },
  },
  progress: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#1B212C",
      backgroundColor: "#FFFFFF",
    },
  },
};

export const REPORT_HEALTH_WOMEN_TABLE_COLOR = {
  headCells: {
    style: {
      "&:first-child": {
        // boxShadow: "5px 0 5px -3px rgba(0,0,0,0.5)",
        // position: "sticky" as const,
        // left: "0px",
        // backgroundColor: "inherit",
        // zIndex: 5,
      },
    },
  },
  rows: {
    style: {
      backgroundColor: "#EBEFFF",
      border: "none",
      color: "#1B212C",
      fontWeight: 500,
      fontSize: "12px",
      "&:not(:last-of-type)": {
        border: "none",
      },
      "&:nth-child(2n)": {
        backgroundColor: "#F5F7FF",
      },
    },
  },
  headRow: {
    style: {
      border: "none",
      backgroundColor: "#F5F7FF",
      color: "#1B212C",
      fontWeight: 500,
      fontSize: "10px",
    },
  },
  noData: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#1B212C",
      backgroundColor: "#FFFFFF",
    },
  },
  progress: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#1B212C",
      backgroundColor: "#FFFFFF",
    },
  },
};

export const SORT_STYLE = {
  marginLeft: "10px",
  fill: "#1B212C",
  width: "12px",
};

export const DETRAINING_LINE_COLOR = "#8AD629";
export const DETRAINING_LABEL_COLOR = "#008FA3";
export const DETRAINING_AREA_COLOR = "0, 143, 163";

export const COMPETITION_READY_LINE_COLOR = "#D69129";
export const COMPETITION_READY_LABEL_COLOR = "#5EA700";
export const COMPETITION_READY_AREA_COLOR = "90, 161, 0";

export const TRANSITIONAL_LINE_COLOR = "#FFCE22";
export const TRANSITIONAL_LABEL_COLOR = "#AE8700";
export const TRANSITIONAL_AREA_COLOR = "174, 135, 0";

export const PRODUCTIVE_TRAINING_LINE_COLOR = "#F80707";
export const PRODUCTIVE_TRAINING_LABEL_COLOR = "#C97C08";
export const PRODUCTIVE_TRAINING_AREA_COLOR = "222, 167, 84";

export const OVERREACHING_LINE_COLOR = "#F80707";
export const OVERREACHING_LABEL_COLOR = "#A90000";
export const OVERREACHING_AREA_COLOR = "167, 2, 0";

export const COMPLETED_DURATION_BAR_ONE = "#F48371";
export const COMPLETED_DURATION_BAR_TWO = "#8CB3ED";
export const DISTANCE_BAR_ONE = "#29C1D6";
export const DISTANCE_BAR_TWO = "#8CB3ED";
export const ELEVATION_GAIN_BAR_ONE = "#FF6981";
export const ELEVATION_GAIN_BAR_TWO = "#D26657";
export const TSS_BAR_ONE = "#71A2F4";
export const TSS_BAR_TWO = "#EBB700";
export const CTL_BAR_ONE = "#8AD629";
export const CTL_BAR_TWO = "#8CB3ED";
export const TSB_BAR_ONE = "#54CDDE";
export const TSB_BAR_TWO = "#397AF9";
export const MCP_BAR_ONE = "#1F5FA6";
export const MCP_BAR_TWO = "#FF6981";

export const ATHLETE_VARIANCE_RED = "#f80707";
export const ATHLETE_VARIANCE_GREEN = "#8ad629";
export const ATHLETE_VARIANCE_ORANGE = "#d69129";

export const CHAT_LOADER_COLOR =  "#104c89";
export const HRV_RHR_LIGHT_GREEN = '#90EE90';
export const HRV_RHR_DARK_GREEN = '#008000';
export const HRV_RHR_LIGHT_YELLOW = '#FFFFE0';
export const HRV_RHR_DARK_ORANGE = '#FF8C00';
export const HRV_RHR_YELLOW = '#FFD700';
export const HRV_RHR_ORANGE = '#FFA500';
export const HRV_RHR_RED = '#FF0000';
export const BODY_CONTAINER_FILL_COLOR_WOMEN = "#95B2FF";
export const BODY_CONTAINER_STROKE_COLOR_WOMEN = "#3773C0";
export const MCP_LIGHT_GREEN = '#A4D7A7';
export const MCP_MEDIUM_GREEN = '#4CAF50';
export const MCP_DARK_GREEN = '#2E7D32';
export const MCP_LIGHT_ORANGE = '#FFCC80';
export const MCP_MEDIUM_ORANGE = '#FF9800';
export const MCP_DARK_RED = '#B71C1C';
import { axiosInstance } from "axios-instance/axios-instance";
import { Group, APIResponseV2 } from "types";

export const getGroups = async (): Promise<Group[]> => {
  const { data }: APIResponseV2<Group[]> = await axiosInstance.get(
    `/group/all`
  );
  return (data.data || []).map((group) => group);
};

export const getAllGroups = async (): Promise<Group[]> => {
  const { data }: APIResponseV2<Group[]> = await axiosInstance.get(
    `/group/all`
  );
  return (data.data || []).map((group) => group);
};

export const deleteGroups = async (ids: number[]): Promise<number> => {
  const { data }: APIResponseV2<number> = await axiosInstance.delete(
    `/group/delete?ids=${ids.join(",")}`
  );

  return data.data;
};

export const addGroup = async (group: Group): Promise<Group> => {
  const { data }: APIResponseV2<Group> = await axiosInstance.post(
    `/group/add`,
    group
  );

  return data.data;
};

export const updateGroup = async (group: Group): Promise<Group> => {
  const { data }: APIResponseV2<Group> = await axiosInstance.post(
    `/group/update`,
    group
  );

  return data.data;
};

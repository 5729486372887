import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";

import { api } from "api/api";
import {
  WellnessScore,
  HeartRateMeasurement,
  WellnessLastUpdates,
  Menstrual,
} from "types";
import { RootState } from "store/index";
import { calculateDateRange } from "utils";

interface WellnessState {
  overallScore?: WellnessScore;
  overallScoreComparison?: WellnessScore;
  heartRateMeasurement?: HeartRateMeasurement;
  menstrual?: Menstrual[];
  lastUpdates?: WellnessLastUpdates;
}

const initialState: WellnessState = {
  overallScore: undefined,
  overallScoreComparison: undefined,
  heartRateMeasurement: undefined,
  menstrual: undefined,
  lastUpdates: undefined,
};

export const wellnessSlice = createSlice({
  name: "wellness",
  initialState,
  reducers: {
    setOverallWellnessScore: (
      state,
      action: PayloadAction<WellnessScore | undefined>
    ) => {
      state.overallScore = action.payload;
    },
    setOverallWellnessScoreComparison: (
      state,
      action: PayloadAction<WellnessScore | undefined>
    ) => {
      state.overallScoreComparison = action.payload;
    },
    setHeartRateMeasurement: (
      state,
      action: PayloadAction<HeartRateMeasurement | undefined>
    ) => {
      state.heartRateMeasurement = action.payload;
    },
    setMenstrual: (state, action: PayloadAction<Menstrual[] | undefined>) => {
      state.menstrual = action.payload;
    },
    setOverallWellnessLastUpdates: (
      state,
      action: PayloadAction<WellnessLastUpdates | undefined>
    ) => {
      state.lastUpdates = action.payload;
    },
  },
});

export const {
  setOverallWellnessScore,
  setOverallWellnessScoreComparison,
  setHeartRateMeasurement,
  setMenstrual,
  setOverallWellnessLastUpdates,
} = wellnessSlice.actions;

const getTodayTimestamp = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
};

export const fetchWellnessLastUpdates =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    const comparison = getState().shared.selectedComparison;
    if (athleteId !== undefined) {
      const lastUpdates = await api.wellness.getLastUpdates(
        athleteId,
        comparison
      );
      if (lastUpdates) {
        dispatch(setOverallWellnessLastUpdates(lastUpdates));
      }
    }
  };

export const fetchHeartRateMeasurement =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    if (athleteId !== undefined) {
      const { startDate, endDate } = calculateDateRange(
        getState().shared.currentDateRange
      );

      const score = await api.wellness.getHeartRateMeasurement(
        startDate,
        endDate,
        getState().shared.currentDateRange,
        athleteId
      );
      dispatch(setHeartRateMeasurement(score));
    }
  };

export const fetchMenstrual =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    if (athleteId !== undefined) {
      const menstrual = await api.wellness.getMenstrual(athleteId);
      dispatch(setMenstrual(menstrual));
    }
  };

export const wellness = wellnessSlice.reducer;

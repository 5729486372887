import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";
import styles from "./injury-popup.module.scss";
import Select, { ActionMeta } from "react-windowed-select";
import "../physiotherapy-popup/physiotherapy-popup.overwrite.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  convertEnumToOptions,
  convertToUnixTimestamp,
  convertUnixTimestampToDate,
  formatDateToYYYYMMDD,
} from "utils";
import { refreshAthleteList, getCurrentAthlete } from "store/slices/shared";
import { createMedicalReport, updateMedicalReport } from "api/medical-report";
import { BodyMap } from "components/body-map/body-map";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { fetchMedicalReports, fetchSelectedMedicalReportDetails } from "store/slices/health";

type Props = {
  onClose: () => void;
  openInjuryPopup?: boolean;
};

interface Options {
  label: string;
  value: string;
}

interface FormState {
  recordType: string;
  athleteId: number;
  side: string;
  onsetDate: number;
  estimatedRecovery: number;
  severity: string;
  area?: string;
  ossicsCode?: string;
  onsetMode:any
  // type: string;
  // bodyLocation: string;
  // rehabilitationStage: string;
  // modalityUsed: string;
  // assessmentTools: string;
  note: string;
}

interface MedicalReportDetails {
  id: number;
  athleteId: number;
  recordType: string;
  bodyLocation: string;
  side: string;
  rehabilitationStage: string;
  type: string;
  illnessType: string;
  assessmentTools: string;
  modalityUsed: string;
  onsetDate: number;
  estimatedRecovery: number;
  symptom: string;
  ossicsCode: string;
  severity: string;
  area: string;
  createdBy: string;
  recovered: boolean;
  onsetMode:any,
  note:string
}

const bodyPartsWithNoSides = [
  "HEAD",
  "CHEST",
  "ABDOMEN",
  "LUMBAR_SPINE",
  "NECK",
  "THORACIC_SPINE",
  "UPPER_BACK",
  "LOWER_BACK"
];


export const Injurypopup: FC<Props> = ({
  onClose,
  openInjuryPopup = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const selectedMedicalReportId = useSelector(
    (state: any) => state.health.selectedMedicalReportId
  );

  const selectedMedicalReport: MedicalReportDetails = useSelector(
    (state: any) => state?.health?.selectedMedicalReport
  );
  const healthEnums = useSelector((state: any) => state.health.healthEnums);
  const currentAthlete = useSelector(getCurrentAthlete);

  const [selectedOption, setSelectedOption] = useState<any>();
  const [form, setForm] = useState<FormState>({
    recordType: "INJURY",
    athleteId: currentAthlete?.id || 0,
    onsetDate: Math.floor(Date.now() / 1000),
    estimatedRecovery: Math.floor(
      (Date.now() + 7 * 24 * 60 * 60 * 1000) / 1000
    ),
    side: openInjuryPopup ? selectedMedicalReport?.side : "BOTH",
    severity: openInjuryPopup ? selectedMedicalReport?.severity : "MILD",
    area: "",
    ossicsCode: "",
    onsetMode:[],
    note: "",
    // optional params
    // bodyLocation: "",
    // rehabilitationStage: "",
    // type: "",
    // assessmentTools: "",
    // modalityUsed: "",
    
  });

  const getEnumValue = useCallback((parentKey: string, childkey: string) => {
    return healthEnums[parentKey]?.[childkey];
  }, [healthEnums]);

  const areaOptions: Options[] = useMemo(
    () => convertEnumToOptions(healthEnums?.bodyArea || {}),
    [healthEnums?.bodyArea]
  );
  const sideOptions: Options[] = useMemo(
    () => convertEnumToOptions(healthEnums?.side || {}),
    [healthEnums?.side]
  );
  const severityOptions: Options[] = useMemo(
    () => convertEnumToOptions(healthEnums?.severity || {}),
    [healthEnums?.severity]
  );
  const ossicsCodeOptions: Options[] = useMemo(
    () => convertEnumToOptions(healthEnums?.ossicsCode || {}),
    [healthEnums?.ossicsCode]
  );
  const onsetModeOptions: Options[] = useMemo(
    () => convertEnumToOptions(healthEnums?.onsetMode || {}),
    [healthEnums?.onsetMode]
  );

  // to set previously selected value in setSelectedOption
  useEffect(() => {
      // MAP onsetMode used multi-select options
      let onsetModeOptionsMulti = [];
      if (selectedMedicalReport?.onsetMode && selectedMedicalReport?.onsetMode !== "") {
        const parsedArray = JSON?.parse(selectedMedicalReport?.onsetMode);
        onsetModeOptionsMulti = parsedArray?.map((item: string) => ({ label: getEnumValue("onsetMode", item), value: item }));
      }
    if (openInjuryPopup) {
      setSelectedOption({
        area: areaOptions.find(
          (option) => option.value === selectedMedicalReport?.area
        ),
        side: selectedMedicalReport?.side,
        severity: selectedMedicalReport?.severity,

        ossicsCode: ossicsCodeOptions.find(
          (option) => option.value === selectedMedicalReport?.ossicsCode
        ),
        onsetDate: convertUnixTimestampToDate(selectedMedicalReport?.onsetDate),
        estimatedRecovery: convertUnixTimestampToDate(
          selectedMedicalReport?.estimatedRecovery
        ),
        onsetMode:onsetModeOptionsMulti,
        note:selectedMedicalReport?.note
      });
    }
  }, [selectedMedicalReport, areaOptions, openInjuryPopup, ossicsCodeOptions,getEnumValue]);

  const handleDateChange = (name: string) => (dates: Date[]) => {
    const selectedDate = dates[0];
    const timestamp = convertToUnixTimestamp(
      formatDateToYYYYMMDD(selectedDate)
    );
    setForm((prevForm) => ({
      ...prevForm,
      [name]: timestamp,
    }));
    setSelectedOption({
      ...selectedOption,
      [name]: convertUnixTimestampToDate(timestamp),
    });
  };
  // to set initiall value of form if edit popup is open
  useEffect(() => {
    if (openInjuryPopup && selectedOption) {
      setForm({
        recordType: "INJURY",
        athleteId: currentAthlete?.id || 0,
        side: selectedOption?.side,
        severity: selectedOption?.severity,
        area: selectedOption.area?.value,
        ossicsCode: selectedOption?.ossicsCode?.value,
        onsetDate:
          selectedOption?.onsetDate &&
          convertToUnixTimestamp(selectedOption?.onsetDate),
        estimatedRecovery:
          selectedOption?.estimatedRecovery &&
          convertToUnixTimestamp(selectedOption?.estimatedRecovery),
          onsetMode:selectedOption?.onsetMode?.map((item: Options) => item?.value),
          note:selectedOption?.note
      });
    }
  }, [openInjuryPopup, selectedOption, currentAthlete]);



  // On change input of Select type
  const handleInputChange = (e: any, actionMeta: ActionMeta<Options>) => {
    if (actionMeta.name === "onsetMode") {
      const { name } = actionMeta
      const optionFinders: Record<string, Options[]> = {
        onsetMode: onsetModeOptions
      };
      setForm({
        ...form,
        [actionMeta.name as keyof Options]: e.map((item: Options) => item.value),
      });

      if (name && optionFinders[name]) {
        setSelectedOption({
          ...selectedOption,
          [name]: e.map((item: Options) => optionFinders[name].find((option: Options) => option.value === item.value)),
        });
      }
      return;
    }
    const { name } = actionMeta
    const optionFinders: Record<string, Options[]> = {
      area: areaOptions,
      ossicsCode: ossicsCodeOptions
    };
    setForm({
      ...form,
      [actionMeta.name as keyof Options]: e.value,
    });

    if (name && optionFinders[name]) {
      setSelectedOption({
        ...selectedOption,
        [name]: optionFinders[name].find((option) => option.value === e.value),
      });
    }


  };

  // On change input of radio and textArea type
  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setSelectedOption({
      ...selectedOption,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmitClick = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);

    let isMounted = true;


    try {
      // Remove empty fields from the form
      if (form.area === "") delete form.area;
      if (form.ossicsCode === "") delete form.ossicsCode;
      if (form.onsetMode && form.onsetMode?.length <= 0) delete form.onsetMode;

       // encode onsetMode to string before sending to API if they are not empty
       if (form.onsetMode && form.onsetMode.length > 0) {
        form.onsetMode = JSON.stringify(form.onsetMode);
      }

      let response;
      if (!openInjuryPopup) {
        response = await createMedicalReport(form);
        if (isMounted) {
          dispatch(fetchMedicalReports(0));
          dispatch(refreshAthleteList());
        }
      } else {
        response = await updateMedicalReport(selectedMedicalReportId, form);
        if (isMounted) {
          dispatch(fetchSelectedMedicalReportDetails(selectedMedicalReportId));
          dispatch(refreshAthleteList());
        }
      }

      if (response && isMounted) {
        onClose();
      }
    } finally {
      if (isMounted) {
        setIsSubmitting(false);
      }
    }

    return () => {
      isMounted = false; // Cleanup function to prevent updates after unmount
    };
  };


  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Injury</h3>
          <Close
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
            title="Close"
          />
        </div>


        <div className={styles.popupDataContainer}>
          <div className={styles.formContainer}>
            <form>
              <div className={styles.formGroup}>
                <label>Area</label>
                <Select
                  isClearable={false}
                  isSearchable={false}
                  options={areaOptions}
                  name="area"
                  placeholder={"Select Area"}
                  value={selectedOption?.area}
                  onChange={handleInputChange}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              {(form?.area && !bodyPartsWithNoSides?.includes(form?.area)) && <div className={styles.formGroup}>
                <label>Side</label>
                <div className={clsx(styles["tw-toggle"], styles.radiobox)}>
                  {sideOptions?.map((option) => {
                    return (
                      <>
                        <input
                          type="radio"
                          name="side"
                          value={option?.value}
                          id={option.label}
                          onChange={handleChange}
                          checked={
                            selectedOption?.side
                              ? selectedOption.side === option.value
                              : form.side === option.value
                          }
                        />
                        <label
                          className={`${styles.toggle} ${styles["toggle-yes"]}`}
                          htmlFor={option.label}
                        >
                          {option.label}
                        </label>
                      </>
                    );
                  })}
                  <span></span>
                </div>
              </div>}

              <div className={styles.formGroup}>
                <label>Severity</label>
                <div className={clsx(styles["tw-toggle"], styles.radiobox)}>
                  {severityOptions?.map((option) => {
                    return (
                      <>
                        <input
                          type="radio"
                          name="severity"
                          value={option?.value}
                          id={option.label}
                          onChange={handleChange}
                          checked={
                            selectedOption?.severity
                              ? selectedOption.severity === option.value
                              : form.severity === option.value
                          }
                        />
                        <label
                          className={`${styles.toggle} ${styles["toggle-yes"]}`}
                          htmlFor={option.label}
                        >
                          {option.label}
                        </label>
                      </>
                    );
                  })}

                  <span></span>
                </div>
              </div>

              <div className={styles.separator}></div>
              <p className={styles.codePara}>
                Select an injury by choosing an OSSICS code or by searching
                for it using the input below
              </p>

              <div className={styles.formGroup}>
                <label>OSSICS Code</label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  options={ossicsCodeOptions}
                  placeholder={"Select for a code"}
                  onChange={handleInputChange}
                  value={selectedOption?.ossicsCode}
                  name="ossicsCode"
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox
                  )}
                  classNamePrefix="react-select"
                />
              </div>
              <div className={styles.separator}></div>

              <div className={styles.formGroup}>
                <label>Mode of Onset</label>
                <Select
                  name="onsetMode"
                  isMulti={true}
                  isClearable={false}
                  isSearchable={false}
                  options={onsetModeOptions}
                  placeholder="Select Onset Mode"
                  value={selectedOption?.onsetMode}
                  onChange={handleInputChange}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              <div className={styles.formGroup}>
                <label>Onset Date</label>

                <Flatpickr
                  id="onsetDate"
                  name="onsetDate"
                  value={selectedOption?.onsetDate || new Date()}
                  onChange={handleDateChange("onsetDate")}
                  options={{
                    dateFormat: "Y-m-d", locale: {
                      weekdays: {
                        shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                        longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                      },
                      months: {
                        shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        longhand: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                      },
                      firstDayOfWeek: 1,
                    },
                    shorthandCurrentMonth: true
                  }} className={clsx(styles.injuryDate, styles.datepick)}
                />
              </div>

              <div className={styles.formGroup}>
                <label>Estimated Recovery Date</label>
                <Flatpickr
                  id="estimatedRecovery"
                  name="estimatedRecovery"
                  value={selectedOption?.estimatedRecovery || new Date()}
                  onChange={handleDateChange("estimatedRecovery")}
                  options={{
                    dateFormat: "Y-m-d", locale: {
                      weekdays: {
                        shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                        longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                      },
                      months: {
                        shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        longhand: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                      },
                      firstDayOfWeek: 1,
                    },
                    shorthandCurrentMonth: true
                  }} className={clsx(styles.injuryDate, styles.datepick)}
                />
              </div>

              <div className={styles.formGroup}>
                <label>Note</label>
                <textarea
                  placeholder="Note"
                  name="note"
                  onChange={handleChange}
                  value={selectedOption?.note && selectedOption?.note}
                  className={styles.textArea}
                ></textarea>
                
              </div>
            </form>
          </div>

          {/* Body Map TODO: Pass color props based on field selection */}
          <BodyMap
            recordType="INJURY"
            selectedSide={form?.side ? form?.side : selectedOption?.side}
            selectedBodyLocation={""}
            selectedArea={
              form?.area ? form?.area : selectedOption?.area?.value
            }
            selectedSymptom=""
            selectedSeverity={form?.severity}
            isAthleteCard={false}
          />
        </div>


        <div className={styles.footer}>
          <PrimaryButton
            className={styles.exportButton}
            disabled={isSubmitting}
            onClick={handleSubmitClick}
            title={"Save"}
          />
        </div>
      </div>
    </div>
  );
};

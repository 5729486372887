import { Layout } from "components/layout/layout";
import styles from "./group-overview.module.scss";
import { FC, useCallback, useEffect, useState } from "react";
import { PrimaryButton } from "components/primary-button/primary-button";
import { ReactComponent as SortIcon } from "images/sort_icon.svg";
import DataTable, { SortOrder, TableColumn } from "react-data-table-component";
import { Athlete, WeeklyReport } from "types";
import {
  fetchGroupReportData,
  getCurrentDateRange,
  getCurrentWeekRange,
  getFilteredGroupReportData,
  getSelectedGroup,
  setFilteredWeeklyReportData,
} from "store/slices/shared";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { Loading } from "components/loading/loading";
import { REPORT_TABLE_COLOR, SORT_STYLE } from "components/charts/colors";
import {
  getWeekNumber,
  downloadCSV,
  groupReportColumns,
} from "pages/weekly-report/utils";
import { GroupMenuItems } from "../const";
import { DateSelector } from "components/date-selector/date-selector";
import HeaderWithBackButton from "../header-with-back-button/header-with-back-button";

type Props = {
  loading: boolean;
};

export const GroupOverView: FC<Props> = ({ loading }) => {
  const dispatch = useDispatch();
  const [isTablet, setIsTablet] = useState(false);
  const selectedGroup = useSelector(getSelectedGroup);
  const filteredDataState = useSelector(getFilteredGroupReportData);
  const currentDateRange = useSelector(getCurrentDateRange);
  const [filteredData, setFilteredData] = useState<WeeklyReport[]>([]);
  const selectedWeekRange = useSelector(getCurrentWeekRange);

  useEffect(() => {
    setFilteredData(filteredDataState);
  }, [filteredDataState]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchGroupReportData());
    })();
  }, [dispatch, selectedGroup, currentDateRange]);

  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);

  const handleExportReport = () => {
    const year = new Date(selectedWeekRange.startDate).getFullYear();
    const weekNumber = getWeekNumber(new Date(selectedWeekRange.startDate));
    downloadCSV(
      groupReportColumns.map((col) => {
        if (col.id === "mCPRelative") {
          return { label: "mCP Relative", field: col.id as string };
        }
        return { label: col.name as string, field: col.id as string };
      }),
      filteredData.map((e) => {
        return {
          ...e,
          athlete: `${(e.athlete as Athlete).firstName} ${
            (e.athlete as Athlete).lastName
          }`,
        };
      }),
      `Weekly Report W${weekNumber} ${year}`
    );
  };

  const handleSort = (
    column: TableColumn<WeeklyReport>,
    sortDirection: SortOrder
  ) => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (column.selector) {
        const aValue = column.selector(a);
        const bValue = column.selector(b);
        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      } else if (column.sortFunction) {
        return column.sortFunction(a, b);
      }
      return 0;
    });
    dispatch(setFilteredWeeklyReportData(sortedData));
    setFilteredData(sortedData);
  };

  const topComponent = (
    <div className={styles.topWrapper}>
      <div className={styles.exportDropdown}>
        <div className={styles.rightOptions}>
          <DateSelector />
          <PrimaryButton
            className={styles.exportButton}
            onClick={handleExportReport}
            title={isTablet ? "Export" : "Export Report"}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Layout
      topMenuItems={GroupMenuItems}
      contentClassName={styles.contentWrapper}
      top={topComponent}
      showAdditonalComps={false}
      customHeader={
        <HeaderWithBackButton
          backNavLocation={"/group-selection"}
          title={selectedGroup?.name || ""}
        />
      }
      content={
        <DataTable
          progressPending={loading}
          noDataComponent="No Athlete Found"
          progressComponent={<Loading />}
          onSort={handleSort}
          sortIcon={
            <SortIcon className={styles.filterIcon} style={SORT_STYLE} />
          }
          columns={groupReportColumns}
          data={filteredData}
          className={styles.tableData}
          customStyles={REPORT_TABLE_COLOR}
        />
      }
      showCalendarComp={false}
      dataTestId="table-view-layout"
    />
  );
};

import { FC, ChangeEvent, useState } from "react";
import clsx from "clsx";
import { ReactComponent as SignInLock } from "images/sign-in-lock.svg";
import { ReactComponent as PasswordInputEye } from "images/password-input-eye.svg";
import styles from "./settings.module.scss";

interface PasswordInputProps {
  label: string;
  name: string;
  value: string;
  placeholder: string;
  error: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordInput: FC<PasswordInputProps> = ({
  label,
  name,
  value,
  placeholder,
  error,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordEyeMouseDown = () => {
    if ("ontouchend" in document) {
      return;
    }
    setShowPassword(true);
  };

  const handlePasswordEyeMouseUp = () => {
    if ("ontouchend" in document) {
      return;
    }
    setShowPassword(false);
  };

  const handlePasswordEyeTouchStart = () => {
    setShowPassword(true);
  };

  const handlePasswordEyeTouchEnd = () => {
    setShowPassword(false);
  };

  const handlePasswordEyeKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === "Space" || e.code === "Enter") {
      setShowPassword(true);
    }
  };

  const handlePasswordEyeKeyUp = (e: React.KeyboardEvent) => {
    if (e.code === "Space" || e.code === "Enter") {
      setShowPassword(false);
    }
  };

  return (
    <div className={styles.inputBlock}>
      <label htmlFor={name} className={styles.label}>
        {label}
        <div className={styles.inputWrapper}>
          <div className={clsx(styles.iconWrapper, value.length > 0 && styles.activeIcon)}>
            <SignInLock />
          </div>
          <input
            type={showPassword ? "text" : "password"}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            className={clsx(
              styles.passwordInput,
              !error && value.length > 0 && styles.activeInput,
              error && styles.errorInput
            )}
          />
          <button
            type="button"
            className={clsx(
              styles.iconWrapper,
              styles.passwordEyeIcon,
              showPassword && styles.activeIcon
            )}
            onMouseDown={handlePasswordEyeMouseDown}
            onMouseUp={handlePasswordEyeMouseUp}
            onTouchStart={handlePasswordEyeTouchStart}
            onTouchEnd={handlePasswordEyeTouchEnd}
            onKeyDown={handlePasswordEyeKeyDown}
            onKeyUp={handlePasswordEyeKeyUp}
          >
            <PasswordInputEye />
          </button>
        </div>
      </label>
    </div>
  );
};

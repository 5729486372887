import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import {
  getMedicalReportById,
  getMedicalReportEnums,
  getMedicalReports,
  getMedicalReportsByTeam,
} from "api/medical-report";
import { set } from "lodash";
import { RootState } from "store/index";
import { Athlete } from "types";

interface MedicalReportDetails {
  id: number;
  athleteId: number;
  recordType: string;
  bodyLocation: string;
  side: string;
  rehabilitationStage: string;
  type: string;
  illnessType: string;
  assessmentTools: string;
  modalityUsed: string;
  symptom: string;
  ossicsCode: string;
  severity: string;
  area: string;
  onsetDate: number;
  estimatedRecovery: number;
  createdBy: string;
  recovered: boolean;
}

export interface HealthEnums {
  assessmentTools: {
    PSYCHOLOGICAL: string;
    PERFORMANCE: string;
    FLEXIBILITY: string;
    RANGE_OF_MOTION: string;
    STRENGTH: string;
    CARDIOVASCULAR_FITNESS: string;
    GAIT_ANALYSIS: string;
    NEUROLOGICAL: string;
    PAIN: string;
    EDEMA: string;
    FUNCTIONAL_MOVEMENT_SCREENING: string;
    BALANCE_PROPRIOCEPTION: string;
    SPORT_SPECIFIC_FUNCTIONAL_TESTS: string;
    POSTURAL: string;
  };
  reportType: {
    INJURY: string;
    ILLNESS: string;
    PHYSIO: string;
  };
  severity: {
    MILD: string;
    MODERATE: string;
    SEVERE: string;
  };
  side: {
    LEFT: string;
    RIGHT: string;
    BOTH: string;
  };
  illnessType: {
    RESPIRATORY: string;
    MUSCULOSKELETAL: string;
    CARDIOVASCULAR: string;
    GENITOURINARY: string;
    THERMOREGULATORY: string;
    NOT_SPECIFIC: string;
    DENTAL: string;
    NEUROLOGICAL: string;
    OPHTHALMOLOGICAL: string;
    MULTIPLE_NOT_SPECIFIED: string;
    OTOLOGICAL: string;
    GASTROINTESTINAL: string;
    PSYCHIATRIC_PSYCHOLOGICAL: string;
    DERMATOLOGICAL: string;
    ENDOCRINOLOGICAL: string;
    HEMATOLOGIC: string;
  };
  modalityUsed: {
    COMPRESSION_THERAPY: string;
    ULTRASOUND_THERAPY: string;
    CRYOTHERAPY: string;
    HYDROTHERAPY: string;
    NEW_MODALITY: string;
    KINESIOLOGY_TAPING: string;
    THERMOTHERAPY: string;
    BIOFEEDBACK: string;
    ELECTRICAL_STIMULATION: string;
    TRACTION_THERAPY: string;
    SHOCKWAVE_THERAPY: string;
    ACUPUNCTURE_DRY_NEEDLING: string;
    MANUAL_THERAPY: string;
    EXERCISE_THERAPY: string;
    LASER_THERAPY: string;
  };
  symptom: {
    NASAL_CONGESTION: string;
    OTHER: string;
    VOMITING: string;
    CHILLS: string;
    RASH: string;
    STOMACH_PAIN: string;
    ALLERGY: string;
    THROAT_PAIN: string;
    HEAVY_FATIGUE: string;
    CHEST_INFECTION: string;
  };

  bodyArea: {
    ABDOMEN: string;
    CHEST: string;
    ELBOW: string;
    FOOT: string;
    FOREARM: string;
    GROIN_HIP: string;
    HAND: string;
    HEAD: string;
    KNEE: string;
    LOWER_BACK: string;
    LOWER_LEG: string;
    LUMBAR_SPINE: string;
    NECK: string;
    SHOULDER: string;
    THIGH: string;
    THORACIC_SPINE: string;
    UPPER_ARM: string;
    UPPER_BACK: string;
    WRIST: string;
  };

  bodyLocation:{
   
      HEAD_NECK: string;
      SHOULDER: string;
      HIP_PELVIS: string;
      HAND_WRIST: string;
      LEG_KNEE: string;
      ARM_ELBOW: string;
      ANKLE_FOOT: string;
      BACK_SPINE: string;
  }

  rehabilitationStage: {
    RETURN_TO_PLAY: string;
    ACUTE: string;
    FUNCTIONAL: string;
    CHRONIC: string;
    SUB_ACUTE: string;
  };
  type: {
    CHRONIC_INJURY_MANAGEMENT: string;
    NEW_INJURY: string;
    REINJURY: string;
    RECURRENCE: string;
  };
  ossicsCode:any
}

interface MedicalReportState {
  healthEnums: HealthEnums;
  medicalReports: any[];
  selectedMedicalReportId: number;
  selectedMedicalReport: MedicalReportDetails;
  allTeamMedicalReports: any[];
  selectedMedicalReportAthlete: any;
}

const initialState: MedicalReportState = {
  healthEnums: {
    reportType: {
      INJURY: "",
      ILLNESS: "",
      PHYSIO: "",
    },
    severity: {
      MILD: "",
      MODERATE: "",
      SEVERE: "",
    },
    side: { LEFT: "", RIGHT: "", BOTH: "" },
    illnessType: {
      RESPIRATORY: "",
      MUSCULOSKELETAL: "",
      CARDIOVASCULAR: "",
      GENITOURINARY: "",
      THERMOREGULATORY: "",
      NOT_SPECIFIC: "",
      DENTAL: "",
      NEUROLOGICAL: "",
      OPHTHALMOLOGICAL: "",
      MULTIPLE_NOT_SPECIFIED: "",
      OTOLOGICAL: "",
      GASTROINTESTINAL: "",
      PSYCHIATRIC_PSYCHOLOGICAL: "",
      DERMATOLOGICAL: "",
      ENDOCRINOLOGICAL: "",
      HEMATOLOGIC: "",
    },
    modalityUsed: {
      COMPRESSION_THERAPY: "",
      ULTRASOUND_THERAPY: "",
      CRYOTHERAPY: "",
      HYDROTHERAPY: "",
      NEW_MODALITY: "",
      KINESIOLOGY_TAPING: "",
      THERMOTHERAPY: "",
      BIOFEEDBACK: "",
      ELECTRICAL_STIMULATION: "",
      TRACTION_THERAPY: "",
      SHOCKWAVE_THERAPY: "",
      ACUPUNCTURE_DRY_NEEDLING: "",
      MANUAL_THERAPY: "",
      EXERCISE_THERAPY: "",
      LASER_THERAPY: "",
    },
    symptom: {
      NASAL_CONGESTION: "",
      OTHER: "",
      VOMITING: "",
      CHILLS: "",
      RASH: "",
      STOMACH_PAIN: "",
      ALLERGY: "",
      THROAT_PAIN: "",
      HEAVY_FATIGUE: "",
      CHEST_INFECTION: "",
    },
    assessmentTools: {
      PSYCHOLOGICAL: "",
      PERFORMANCE: "",
      FLEXIBILITY: "",
      RANGE_OF_MOTION: "",
      STRENGTH: "",
      CARDIOVASCULAR_FITNESS: "",
      GAIT_ANALYSIS: "",
      NEUROLOGICAL: "",
      PAIN: "",
      EDEMA: "",
      FUNCTIONAL_MOVEMENT_SCREENING: "",
      BALANCE_PROPRIOCEPTION: "",
      SPORT_SPECIFIC_FUNCTIONAL_TESTS: "",
      POSTURAL: "",
    },
    bodyArea :{
      ABDOMEN: "",
      CHEST: "",
      ELBOW: "",
      FOOT: "",
      FOREARM: "",
      GROIN_HIP: "",
      HAND: "",
      HEAD: "",
      KNEE: "",
      LOWER_BACK: "",
      LOWER_LEG: "",
      LUMBAR_SPINE: "",
      NECK: "",
      SHOULDER: "",
      THIGH: "",
      THORACIC_SPINE: "",
      UPPER_ARM: "",
      UPPER_BACK: "",
      WRIST: "",
    },
    bodyLocation:{
        HEAD_NECK: "",
        SHOULDER: "",
        HIP_PELVIS: "",
        HAND_WRIST: "",
        LEG_KNEE: "",
        ARM_ELBOW: "",
        ANKLE_FOOT: "",
        BACK_SPINE: "",
    },
    rehabilitationStage: {
      RETURN_TO_PLAY: "",
      ACUTE: "",
      FUNCTIONAL: "",
      CHRONIC: "",
      SUB_ACUTE: "",
    },
    type: {
      CHRONIC_INJURY_MANAGEMENT: "",
      NEW_INJURY: "",
      REINJURY: "",
      RECURRENCE: "",
    }, 
    ossicsCode:{}
  },
  medicalReports: [],
  selectedMedicalReportId: 0,
  selectedMedicalReport: {
    id: 0,
    athleteId: 0,
    recordType: "",
    bodyLocation: "",
    side: "",
    rehabilitationStage: "",
    type: "",
    illnessType: "",
    assessmentTools: "",
    modalityUsed: "",
    symptom: "",
    ossicsCode: "",
    severity: "",
    area: "",
    onsetDate: 0,
    estimatedRecovery: 0,
    createdBy: "",
    recovered: false,
  },
  allTeamMedicalReports: [],
  selectedMedicalReportAthlete: {},
};

export const healthSlice = createSlice({
  name: "health",
  initialState,
  reducers: {
    setHealthEnums: (state, action: PayloadAction<any>) => {
      state.healthEnums = action.payload;
    },
    setMedicalReports: (state, action: PayloadAction<any>) => {
      state.medicalReports = action.payload;
    },
    setSelectedMedicalReportId: (state, action: PayloadAction<number>) => {
      state.selectedMedicalReportId = action.payload;
    },
    setSelectedMedicalReport: (state, action: PayloadAction<any>) => {
      state.selectedMedicalReport = action.payload;
    },
    setAllTeamMedicalReports: (state, action: PayloadAction<any>) => {
      state.allTeamMedicalReports = action.payload;
    },
    setSelectedMedicalReportAthlete: (state, action: PayloadAction<number>) => {
      state.selectedMedicalReportAthlete = action.payload;
    },
  },
});

export const {
  setHealthEnums,
  setMedicalReports,
  setSelectedMedicalReportId,
  setSelectedMedicalReport,
  setAllTeamMedicalReports,
  setSelectedMedicalReportAthlete,
} = healthSlice.actions;

export const fetchHealthEnums = async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const response = await getMedicalReportEnums();
  if (response) {
    dispatch(setHealthEnums(response));
  }
};

export const fetchMedicalReports = (page: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared?.selectedAthleteId;
    if (athleteId) {
      const response = await getMedicalReports(athleteId, page, 15);
      if (response) {
        dispatch(setMedicalReports(response));
      }
    } else return;
  };
};

export const setSelectedMedicalReportIdAction =
  (id: number) => (dispatch: Dispatch) => {
    dispatch(setSelectedMedicalReportId(id));
  };

export const fetchSelectedMedicalReportDetails =
  (id: number) => async (dispatch: Dispatch) => {
    const response = await getMedicalReportById(id);
    if (response) {
      dispatch(setSelectedMedicalReport(response));
    }
  };

export const fetchAllTeamMedicalReports = async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const response = await getMedicalReportsByTeam();
  if (response) {
    dispatch(setAllTeamMedicalReports(response));
  } else return;
};

export const setSelectedMedicalReportAthleteAction =
  (data: number) => (dispatch: Dispatch) => {
    dispatch(setSelectedMedicalReportAthlete(data));
  };

export const health = healthSlice.reducer;

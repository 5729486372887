import { trackError } from "appInsights/logInsights";
import { axiosInstance } from "axios-instance/axios-instance";
import { MedicalReportData, MedicalReportPhysio } from "types";
import { EnumType } from "typescript";

interface FileData {
  // id: number;
  medicalReportId: number;
  // fileName: string;
  // filePath: string;
  // fileSize: string;
  // fileDescription: string;
  multipartFile: any;
  // createdBy: string;
}

interface NoteData {
  id?: number;
  medicalReportId: number;
  note: string;
  createdBy: string;
  editable: boolean;
}

type GetNotesResponse = NoteData[];

// get all enums for medical report
export const getMedicalReportEnums = async () => {
  try {
    const resp = await axiosInstance.get("/medical/enums");
    return resp.data;
  } catch (error) {
    console.error("Enum API failed", error);
  }
};

// get all medical reports with pagination
export const getMedicalReports = async (
  athleteId: number,
  page: number,
  size: number
) => {
  try {
    const resp = await axiosInstance.get(
      `/medical?athleteId=${athleteId}&page=${page}&size=${size}`
    );
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getMedicalReport" });
    console.error(
      "Request failed:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// get medical report by id
export const getMedicalReportById = async (id: number) => {
  try {
    const resp = await axiosInstance.get(`/medical/${id}`);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getMedicalReportDetails" });
    console.error(
      "Request failed:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// create medical report
export const createMedicalReport = async (data: MedicalReportPhysio) => {
  try {
    const resp = await axiosInstance.post("/medical", data);
    return resp.data || null;
  } catch (error: any) {
    trackError(error as Error, { location: "createMedicalReport" });
    console.error(
      "Request failed:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// update medical report
export const updateMedicalReport = async (
  id: number,
  data: MedicalReportPhysio
) => {
  try {
    const resp = await axiosInstance.put(`/medical`, {
      ...data,
      id,
    });
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "patchMedicalReport" });
    console.error(
      "Request failed:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// CRUD operations on the notes in a medical report
export const createNote = async (data: NoteData) => {
  try {
    const resp = await axiosInstance.post(`/medical/note`, data);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "postNotesData" });
    throw error;
  }
};

// todo: noteid is passed in a data, should be passed as a param
export const updateNote = async (data: NoteData) => {
  try {
    const resp = await axiosInstance.put(`/medical/note`, data);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "updateNotesData" });
    throw error;
  }
};

export const getNotes = async (
  medicalReportId: number
): Promise<GetNotesResponse> => {
  try {
    const resp = await axiosInstance.get<GetNotesResponse>(
      `/medical/note?medicalReportId=${medicalReportId}`
    );
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getNotesData" });
    throw error;
  }
};

export const deleteNote = async (noteId: number) => {
  try{
    const resp = await axiosInstance.delete(
      `/medical/note?id=${noteId}`
    );
    return resp.data;
  }catch (error: any) {
    trackError(error as Error, { location: "deleteNote" });
    throw error;
  }
};

// CRUD operations on the files in a medical report
// note: medical report id should come in { data }
export const uploadFile = async (data: FormData) => {
  try {
    const resp = await axiosInstance.post(`/medical/file/upload`, data,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
  });
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "postUploadFile" });
    throw error;
  }
};

export const deleteFile = async (fileId: number) => {
  try {
    const resp = await axiosInstance.delete(`/medical/file?fileId=${fileId}`);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "deleteUploadFile" });
    throw error;
  }
};


export const downloadFile = async (fileId: number) => {
  try{
    const resp = await axiosInstance.get(`/medical/file/download?fileId=${fileId}`,{
      headers:{
        "Content-Type": "application/x-www-form-urlencoded",
      },
      responseType: 'blob', 
      
    });
    return resp.data;
  }catch (error: any) {
    trackError(error as Error, { location: "downloadUploadFile" });
    throw error;
  }
};

// get all the files against a medical report
export const getFiles = async (medicalReportId: number) => {
  try {
    const resp = await axiosInstance.get(
      `/medical/file?medicalReportId=${medicalReportId}`
    );
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getUploadFile" });
    throw error;
  }
};

// Mark as Recovered API
export const markAsRecovered = async (medicalReportId: number,data:number) => {
  try {
    const resp = await axiosInstance.put(`/medical/markRecovered/${medicalReportId}?recoveredOn=${data}`);
    return resp.data;
  } 
  catch (error: any) {
    trackError(error as Error, { location: "patchMarkAsRecovered" });
    throw error;
  }
};

// get all medical reports by team
export const getMedicalReportsByTeam = async () => {
  try {
    const resp = await axiosInstance.get(`/medical/team?page=0&size=100`);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getMedicalReportsByTeam" });
    throw error;
  }
};


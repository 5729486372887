import React, { useCallback, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import ReactEcharts from "echarts-for-react";

import { convertHoursToHMS, roundFloat } from "utils";
import { ReactComponent as CircleDashedIcon } from "images/circle-dashed.svg";
import { ReactComponent as TimeStampIcon } from "images/timestamp_icon.svg";
import { ChartColor, ChartProps, SeriesItem } from "components/charts/types";
import {
  BORDER_SCATTER_DOT,
  COLOR_AXIS,
  COLOR_TOOLTIP,
  COLOR_WHITE,
  TEAM_PERFORMANCE_BAR_COLOR,
} from "components/charts/colors";
import { ChartSize, FONT_SIZE_XSS, GradientDirection } from "components/charts/constants";
import { DEFAULT_CHART_X_AXIS_PHYS, DEFAULT_CHART_Y_AXIS_PHYS } from "components/charts/defaults";
import { getAxisLabelStyle, toEChartsColor, toEChartsTextStyle } from "components/charts/utils";
import { NoData } from "components/charts/no-data/no-data";

import { withHeader } from "hocs/with-header/with-header";

import styles from "./scatter-chart.module.scss";
import { useSelector } from "hooks/app-hooks";
import { getCurrentDateRange, getCurrentGroupBy } from "store/slices/shared";
import { DateGroupBy, DateRange, ToolTipDataType, UnitsType } from "enums";
import { ChartDataUnits } from "types";

export type ScatterChartSeries = SeriesItem<{
  areaColor?: ChartColor;
  opacity?: number;
  z?: number;
  emphasisDisabled?: boolean;
  shadowColor?: ChartColor;
  shadowBlur?: number;
  chartType?: string;
  shadowOffsetY?: number;
  width?: number;
  xAxisIndex?: number;
  yAxisIndex?: number;
  smooth?: boolean;
  dashed?: boolean;
  seriesName?: string;
  dataType?: ToolTipDataType;
  suffix?: string;
  colorRange?: {
    color: string;
    range: [number, number];
  }[];
  units?: ChartDataUnits;
}>;

export type ScatterChartProps = ChartProps<{
  type?: ChartSize;
  units?: string;
  markLineAt?: string;
  customMarkLines?: Record<string, any>;
  customMarkArea?: Record<string, any>;
  xAxis?: {
    name?: string;
    type?: string;
    min?: number;
    position?: string;
    gridIndex?: number;
  }[];
  yAxis?: {
    name?: string;
    type?: string;
    min?: number;
    max?: number;
    gridIndex?: number;
    yAxisIndex?: number;
    position?: string;
    timestamp?: boolean;
  }[];
  series: (ScatterChartSeries | undefined)[];
  titlePrefix?: string;
  isTooltipAxisLogarithmic?: boolean;
  title?: string;
  subtitle?: string;
  rotateXAxisLabels?: boolean;
  boundaryGap?: boolean;
  bottomMargin?: number;
  rightMargin?: number;
  containLabels?: boolean;
  intervals?: number;
  yLabelMargins?: number;
  colorsList?: string[];
  hideDecimalPoints?: boolean;
  isTablet?: boolean;
  barMaxWidth?: string;
  customToolTipTitle?: string;
  showGrid?: boolean;
  showCrosshair?: boolean;
  formatLabels?: boolean;
  showXAxisLine?: boolean;
  showYAxisLine?: boolean;
  showYAxisName?: boolean;
}>;

interface TooltipValue {
  $vars: string[];
  axisDim: string;
  axisId: string;
  axisIndex: number;
  axisType: string;
  axisValue: string;
  axisValueLabel: string;
  borderColor?: string;
  color: string;
  componentIndex: 0;
  componentSubType: string;
  componentType: string;
  data: {
    value: any[];
    suffix?: string;
    itemStyle: {
      [key: string]: any;
    };
    dataType: ToolTipDataType;
    units?: ChartDataUnits;
  };
  dataIndex: 1;
  dataType: undefined;
  dimensionNames: string[];
  encode: { x: number[]; y: number[] };
  marker: string;
  name: string;
  seriesId: string;
  seriesIndex: string;
  seriesName: string;
  seriesType: string;
  value: string[];
}

const getGridRightPadding = (type: ChartSize) => (type === ChartSize.Big ? 42 : 32);

const findDashedLegend = (name: string, filteredSeries: ScatterChartSeries[]) => {
  const data = filteredSeries.find((item) => item.name === name);
  if (data) {
    return !!data.dashed;
  }
  return false;
};

export const ScatterChart = React.forwardRef<ReactEcharts, ScatterChartProps>(
  (
    {
      type = ChartSize.Small,
      series,
      labels,
      xAxis,
      yAxis,
      markLineAt,
      customMarkLines,
      customMarkArea,
      titlePrefix,
      isTooltipAxisLogarithmic,
      title,
      rotateXAxisLabels,
      boundaryGap,
      bottomMargin = 42,
      rightMargin = 32,
      containLabels = false,
      hideDecimalPoints,
      colorsList,
      isTablet,
      barMaxWidth,
      intervals,
      yLabelMargins,
      subtitle,
      customToolTipTitle,
      showGrid = true,
      showCrosshair = false,
      formatLabels = false,
      showXAxisLine = false,
      showYAxisLine = false,
      showYAxisName = true,
      onChartReady,
      onEvents,
    },
    ref
  ) => {
    const currentDateUnits = useSelector(getCurrentGroupBy);
    const currentDateRange = useSelector(getCurrentDateRange);
    const filteredSeries: ScatterChartSeries[] = series
      .filter((el) => el !== undefined)
      .map((el) => el as ScatterChartSeries);

    const multipleValues = filteredSeries
      .flatMap((el) => el.multipleValues?.values)
      .filter((el) => el);

    const hasData = useMemo(
      () =>
        filteredSeries.some((seriesItem) => {
          return seriesItem?.values?.filter((item) => item?.value).length > 0;
        }),
      [filteredSeries]
    );

    const determineUnits = (units: ChartDataUnits | undefined) => {
      switch (units?.type) {
        case UnitsType.STRING:
          return units.value ? ` ${units.value}` : "";
        case UnitsType.TIMESTAMP:
          return ReactDOMServer.renderToStaticMarkup(
            <span className={styles.tooltipIcon}>
              {" "}
              <TimeStampIcon
                style={{
                  fill: BORDER_SCATTER_DOT,
                }}
              />
            </span>
          );

        default:
          return "";
      }
    };

    const determineValue = (element: TooltipValue) => {
      const value = parseFloat(element.data.value[1]);
      if (hideDecimalPoints || element.data.itemStyle?.decimalPoint === 0) {
        return value.toFixed(0);
      } else if (element.data.itemStyle?.decimalPoint === 1) {
        return roundFloat(value, 1);
      } else if (
        isTooltipAxisLogarithmic &&
        (element.seriesName === "Fitness" || element.seriesName === "Fatigue")
      ) {
        return Math.pow(2, value).toFixed(2);
      }
      return value.toFixed(2);
    };

    const renderIcon = (element: TooltipValue) => {
      if (findDashedLegend(element.seriesName, filteredSeries)) {
        return ReactDOMServer.renderToStaticMarkup(
          <span className={styles.tooltipIcon}>
            <CircleDashedIcon style={{ stroke: element.color || "white" }} />
          </span>
        );
      }
      return element.marker;
    };

    const customTooltipFormatter = (value: TooltipValue[]) => {
      const toolTipTitle = customToolTipTitle
        ? `${customToolTipTitle} - ${value[0].axisValue}`
        : `${titlePrefix || ""} ${value[0].axisValue}`;

      const tooltipContent = value
        .map((element: TooltipValue) => {
          const dataValue =
            element.data.dataType === ToolTipDataType.TIMESTAMP
              ? convertHoursToHMS(parseFloat(element.data.value[1]))
              : `${determineValue(element)}${element.data.suffix}`;
          const dataUnits = determineUnits(element?.data?.units);

          return `
      <div class="${styles.tooltip}">
        <div>${renderIcon(element)}${element.seriesName}:</div>
        <div class="${styles.tooltipValue}">${dataValue}${dataUnits}</div>
      </div>`;
        })
        .join("");

      return `<div class="${styles.singleTooltip}">${toolTipTitle}${tooltipContent}</div>`;
    };

    const customMutliValueTooltipFormatter = (value: TooltipValue[]) => {
      return `
      <div class="${styles.tooltipWrapper}">
        <div class="${styles.tooltipTitle}">${titlePrefix || ""} ${value[0].axisValueLabel}</div>
        ${value
          .map((element: TooltipValue) => {
            const multipleValuePairs = multipleValues[element.dataIndex];

            const multipleValuesHtml = multipleValuePairs
              ? multipleValuePairs
                  .map(
                    (pair) => `
              <div class="${styles.tooltipPair}">
              
                <span class="${styles.tooltipPairLine}">${pair.label}: ${pair.value.toFixed(
                      pair.fixedNumber ?? 2
                    )}${determineUnits(pair?.units)}</span>
              </div>
            `
                  )
                  .join("")
              : "";

            return `
              <div class="${styles.multiValueTooltip}">
                
                ${multipleValuesHtml} 
              </div>`;
          })
          .join("")}</div>`;
    };

    return (
      <div className={styles.wrapper}>
        {!hasData ? (
          <NoData chartSize={type} />
        ) : (
          <ReactEcharts
            ref={ref}
            onChartReady={onChartReady}
            onEvents={onEvents}
            opts={{ renderer: "canvas" }}
            style={{ width: "100%", height: "100%" }}
            notMerge
            option={{
              title: {
                text: title,
                subtext: subtitle,
                left: "center",
                top: "top",
                itemGap: 280,
                padding: [15, 10],
                textStyle: {
                  color: "#12BE50",
                  fontSize: 12,
                },
                subtextStyle: {
                  fontSize: 12,
                  color: "#12BE50",
                },
                show: true,
              },
              tooltip: {
                axisPointer: showCrosshair && {
                  type: "cross",
                  label: {
                    show: false,
                    formatter: function (params: any) {
                      if (params.axisDimension === "x") {
                        return `${params.value} `;
                      }
                      return "";
                    },
                    backgroundColor: "#E7040400",
                    textStyle: {
                      color: TEAM_PERFORMANCE_BAR_COLOR,
                      fontSize: 12,
                    },
                  },
                },
                trigger: hasData && "axis",
                backgroundColor: COLOR_TOOLTIP,
                borderColor: COLOR_TOOLTIP,
                textStyle: {
                  color: COLOR_WHITE,
                  fontSize: 12,
                  fontFamily: "Montserrat",
                },
                formatter:
                  multipleValues.length <= 0
                    ? customTooltipFormatter
                    : customMutliValueTooltipFormatter,
              },
              axisPointer: {
                link: [
                  {
                    xAxisIndex: "all",
                  },
                ],
              },
              legend: {
                show: false,
              },
              grid: xAxis
                ? [
                    ...xAxis.map((axis, index) => ({
                      left: type === ChartSize.Big ? 50 : 58,
                      right: getGridRightPadding(type),
                      top: index === 1 ? "55%" : 27,
                      bottom: type === ChartSize.Big ? 42 : 25,
                      height: "35%",
                    })),
                  ]
                : {
                    top: yAxis ? 27 : 8,
                    left: type === ChartSize.Big ? 50 : 58,
                    bottom: type === ChartSize.Big ? bottomMargin : 25,
                    right: yAxis ? getGridRightPadding(type) : rightMargin,
                    containLabel: containLabels,
                  },
              xAxis: (xAxis || [{}]).map((axis, idx) => {
                return {
                  ...DEFAULT_CHART_X_AXIS_PHYS,
                  boundaryGap: boundaryGap ?? false,
                  gridIndex: axis.gridIndex ?? 0,
                  axisLabel: {
                    ...toEChartsTextStyle(
                      {},
                      {
                        color: COLOR_AXIS,
                        size: type === ChartSize.Big ? (isTablet ? 8 : 13) : 8,
                        fontFamily: "Montserrat",
                      }
                    ),
                    rotate: rotateXAxisLabels ? 45 : null,
                    margin: 13,
                    interval: intervals,
                    formatter(value: string, index: number) {
                      if (formatLabels) {
                        if (
                          currentDateUnits === DateGroupBy.Day &&
                          currentDateRange === DateRange.Year
                        ) {
                          if (index % 30 === 0) {
                            return `${value} `;
                          }
                          return "";
                        } else if (
                          currentDateUnits === DateGroupBy.Day &&
                          currentDateRange === DateRange.TwoMonth
                        ) {
                          if (index % 15 === 0) {
                            return `${value} `;
                          }
                          return "";
                        } else if (
                          currentDateUnits === DateGroupBy.Day &&
                          currentDateRange === DateRange.ThreeMonth
                        ) {
                          if (index % 15 === 0) {
                            return `${value} `;
                          }
                          return "";
                        } else {
                          return `${value} `;
                        }
                      } else {
                        return `${value} `;
                      }
                    },
                  },
                  axisLine: showXAxisLine && {
                    show: true,
                    lineStyle: {
                      type: "dashed",
                      color: "rgba(84, 93, 118, 0.8)",
                    },
                  },
                  splitLine: {
                    lineStyle: {
                      type: "dashed",
                      color: "rgba(84, 93, 118, 0.8)",
                    },
                    show: hasData && showGrid,
                  },
                  data: labels,
                };
              }),
              yAxis: (yAxis || [{}]).map((axis, index) => {
                return {
                  ...DEFAULT_CHART_Y_AXIS_PHYS,
                  type: "value",
                  min: function (value: { min: number }) {
                    return axis.min !== undefined
                      ? axis.min
                      : Math.ceil(value.min) - Math.floor(value.min);
                  },
                  max: axis?.max,
                  name: showYAxisName && axis.name,
                  gridIndex: axis.gridIndex ?? 0,
                  // offset: 40 * (axis.yAxisIndex || 0),
                  nameTextStyle: {
                    ...toEChartsTextStyle(
                      {},
                      {
                        color: COLOR_AXIS,
                        fontWeight: "normal",
                        size: FONT_SIZE_XSS,
                        fontFamily: "Montserrat",
                      }
                    ),
                    align: axis.yAxisIndex && axis.yAxisIndex > 0 ? "right" : "left",
                    margin: -30,
                  },
                  axisLabel: {
                    fontWeight: 500,
                    size: 10,
                    fontFamily: "Montserrat",
                    formatter(value: string) {
                      if (axis.name === "Percentage" && parseFloat(value) <= 1) {
                        return `${parseFloat(value) * 100} `;
                      }
                      return `${value} `;
                    },
                  },
                  ...getAxisLabelStyle(type, yLabelMargins, axis.timestamp),
                  axisLine: showYAxisLine && {
                    show: true,
                    lineStyle: {
                      type: "dashed",
                      color: "rgba(84, 93, 118, 0.8)",
                    },
                  },
                  splitLine: {
                    ...DEFAULT_CHART_Y_AXIS_PHYS.splitLine,
                    lineStyle: {
                      type: "dashed",
                      color: "rgba(84, 93, 118, 0.3)",
                    },
                    show: hasData && axis.yAxisIndex === 0 && showGrid,
                  },
                };
              }),
              series: [
                ...filteredSeries.map((seriesItem) => ({
                  markLine: customMarkLines
                    ? customMarkLines
                    : markLineAt
                    ? {
                        silent: true,
                        animation: true,
                        label: {
                          show: false,
                        },
                        lineStyle: {
                          color: COLOR_WHITE,
                          type: "solid",
                        },
                        data: [
                          [
                            {
                              y: "85%",
                              xAxis: markLineAt,
                              symbol:
                                "path://M5.0913 1.5145L3 5L0.908698 1.5145C0.508783 0.847971 0.988896 0 1.76619 0H4.23381C5.0111 0 5.49122 0.847972 5.0913 1.5145Z",
                              symbolKeepAspect: true,
                            },
                            {
                              y: "5%",
                              xAxis: markLineAt,
                              symbol:
                                "path://M5.0913 1.5145L3 5L0.908698 1.5145C0.508783 0.847971 0.988896 0 1.76619 0H4.23381C5.0111 0 5.49122 0.847972 5.0913 1.5145Z",
                              symbolKeepAspect: true,
                            },
                          ],
                        ],
                      }
                    : undefined,
                  xAxisIndex: seriesItem.xAxisIndex,
                  yAxisIndex: seriesItem.yAxisIndex,
                  type: seriesItem.chartType || "scatter",
                  symbol: "circle",
                  smooth: seriesItem.smooth ?? true,
                  showAllSymbol: true,
                  z: seriesItem.z,
                  itemStyle: {
                    borderColor: seriesItem.borderColor,
                    color: seriesItem.color,
                  },
                  tooltip: {
                    show: true,
                  },
                  name: seriesItem.name,
                  areaStyle: seriesItem.areaColor
                    ? {
                        color: toEChartsColor(seriesItem.areaColor, GradientDirection.TopToBottom),
                        opacity: seriesItem.opacity,
                      }
                    : null,
                  emphasis: {
                    disabled: seriesItem.emphasisDisabled,
                  },
                  lineStyle: {
                    width: seriesItem.width ? seriesItem.width : 1,
                    type: seriesItem.dashed ? "dashed" : "solid",
                    shadowColor: seriesItem.shadowColor || null,
                    shadowBlur: seriesItem.shadowBlur || null,
                    shadowOffsetY: seriesItem.shadowOffsetY || null,
                  },
                  barMaxWidth: barMaxWidth ? barMaxWidth : "10px",
                  markArea: customMarkArea,
                  data: hasData
                    ? seriesItem?.values
                        ?.map((item, index) => {
                          const value = roundFloat(item?.value);
                          if (!value) return null;

                          const label = labels ? labels[index] : index;

                          const colorFromRange = seriesItem.colorRange?.find(
                            (range) => value >= range.range[0] && value <= range.range[1]
                          )?.color;
                          const alternateColor =
                            index % 2 === 0 ? colorsList?.[0] : colorsList?.[1];
                          const effectiveColor = colorFromRange || alternateColor;
                          const dataType = seriesItem.dataType;
                          const units = seriesItem.units;
                          const suffix = seriesItem.suffix || "";

                          const itemStyle = {
                            ...item?.itemStyle,
                            color: effectiveColor,
                          };

                          return {
                            value: [label, value],
                            itemStyle,
                            dataType,
                            units,
                            suffix,
                          };
                        })
                        .filter((item) => item)
                    : [[0, 0]],
                })),
              ],
            }}
          />
        )}
      </div>
    );
  }
);

export const ScatterChartWithHeader = withHeader(ScatterChart);

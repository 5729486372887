import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { api } from "api/api";
import { TimeOptions, ComparisonTimeOptions } from "enums";

import {
  PerformanceLastUpdates,
  PerformanceRadar,
  PerformanceScore,
  PeriodType,
  PowerDerivatives,
  TssItrimp,
  TeamPerformanceT,
  FitnessFatigue,
  SelectedPeriod,
  TwoDateRangesComparison,
  CriticalPowerPoint,
  CompositeValue,
} from "types";
import { RootState } from "store/index";
import {
  getStartAndEndDate,
  calculateDateRange,
  sortDataBasedOnAthleteOrder,
} from "utils";

interface PerformanceState {
  periodType: PeriodType;
  selectedPeriod?: SelectedPeriod;
  selectedComparisonPeriod?: SelectedPeriod;
  overallScore?: PerformanceScore;
  overallScoreComparison?: PerformanceScore;
  powerDerivatives?: PowerDerivatives;
  powerDerivativesComparison?: PowerDerivatives;
  powerDurations?: CriticalPowerPoint;
  powerDurationsRelative?: CriticalPowerPoint;
  powerDurationsComparison?: CriticalPowerPoint;
  tssITrimp?: TssItrimp;
  teamPerformance?: TeamPerformanceT[];
  compoundScore?: CompositeValue[];
  tssITrimpComparison?: TssItrimp;
  fitnessFatigue?: FitnessFatigue;
  fitnessFatigueComparison?: FitnessFatigue;
  overallRadarCurrent?: PerformanceRadar<number>;
  overallRadarComparison?: PerformanceRadar<number>;
  lastUpdates?: PerformanceLastUpdates;
}

const initialState: PerformanceState = {
  periodType: TimeOptions.LastWeek,
  selectedPeriod: undefined,
  selectedComparisonPeriod: {
    firstDateRange: ComparisonTimeOptions.LastWeek,
    secondDateRange: ComparisonTimeOptions.LastThirtyDays,
  },
  overallScore: undefined,
  overallScoreComparison: undefined,
  powerDerivatives: undefined,
  powerDerivativesComparison: undefined,
  tssITrimp: undefined,
  teamPerformance: undefined,
  compoundScore: undefined,
  tssITrimpComparison: undefined,
  fitnessFatigue: undefined,
  fitnessFatigueComparison: undefined,
  overallRadarCurrent: undefined,
  overallRadarComparison: undefined,
  lastUpdates: undefined,
};

export const performanceSlice = createSlice({
  name: "performance",
  initialState,
  reducers: {
    setOverallPerformancePeriodType: (
      state,
      action: PayloadAction<PeriodType>
    ) => {
      state.periodType = action.payload;
    },
    setOverallPerformanceSelectedPeriod: (
      state,
      action: PayloadAction<SelectedPeriod | undefined>
    ) => {
      state.selectedPeriod = action.payload;
    },
    setSelectedComparisonPeriod: (
      state,
      action: PayloadAction<SelectedPeriod | undefined>
    ) => {
      state.selectedComparisonPeriod = action.payload;
    },
    setOverallPerformanceScore: (
      state,
      action: PayloadAction<PerformanceScore | undefined>
    ) => {
      state.overallScore = action.payload;
    },
    setOverallPerformanceRadarCurrent: (
      state,
      action: PayloadAction<PerformanceRadar<number> | undefined>
    ) => {
      state.overallRadarCurrent = action.payload;
    },
    setOverallPerformanceRadarComparison: (
      state,
      action: PayloadAction<PerformanceRadar<number> | undefined>
    ) => {
      state.overallRadarComparison = action.payload;
    },
    setOverallPerformanceScoreComparison: (
      state,
      action: PayloadAction<PerformanceScore | undefined>
    ) => {
      state.overallScoreComparison = action.payload;
    },
    setPowerDerivatives: (
      state,
      action: PayloadAction<PowerDerivatives | undefined>
    ) => {
      state.powerDerivatives = action.payload;
    },
    setPowerDerivativesComparison: (
      state,
      action: PayloadAction<PowerDerivatives | undefined>
    ) => {
      state.powerDerivativesComparison = action.payload;
    },
    setPowerDurations: (
      state,
      action: PayloadAction<CriticalPowerPoint | undefined>
    ) => {
      state.powerDurations = action.payload;
    },
    setPowerDurationsRelative: (
        state,
        action: PayloadAction<CriticalPowerPoint | undefined>
    ) => {
      state.powerDurationsRelative = action.payload;
    },
    setTssItrimp: (state, action: PayloadAction<TssItrimp | undefined>) => {
      state.tssITrimp = action.payload;
    },
    setTeamPerformance: (
      state,
      action: PayloadAction<TeamPerformanceT[] | undefined>
    ) => {
      state.teamPerformance = action.payload;
    },
    setCompoundScore: (
      state,
      action: PayloadAction<CompositeValue[] | undefined>
    ) => {
      state.compoundScore = action.payload;
    },
    setTssItrimpComparison: (
      state,
      action: PayloadAction<TssItrimp | undefined>
    ) => {
      state.tssITrimpComparison = action.payload;
    },
    setFitnessFatigue: (
      state,
      action: PayloadAction<FitnessFatigue | undefined>
    ) => {
      state.fitnessFatigue = action.payload;
    },
    setFitnessFatigueComparison: (
      state,
      action: PayloadAction<FitnessFatigue | undefined>
    ) => {
      state.fitnessFatigueComparison = action.payload;
    },
    setOverallPerformanceLastUpdates: (
      state,
      action: PayloadAction<PerformanceLastUpdates | undefined>
    ) => {
      state.lastUpdates = action.payload;
    },
  },
});

export const {
  setOverallPerformancePeriodType,
  setOverallPerformanceSelectedPeriod,
  setSelectedComparisonPeriod,
  setOverallPerformanceScore,
  setOverallPerformanceScoreComparison,
  setPowerDerivatives,
  setCompoundScore,
  setPowerDerivativesComparison,
  setPowerDurations,
  setPowerDurationsRelative,
  setTssItrimp,
  setTeamPerformance,
  setTssItrimpComparison,
  setFitnessFatigue,
  setFitnessFatigueComparison,
  setOverallPerformanceRadarCurrent,
  setOverallPerformanceRadarComparison,
} = performanceSlice.actions;

export const fetchPowerDerivatives =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    if (athleteId !== undefined) {
      const currentDateUnits = getState().shared.groupBy;
      const { startDate, endDate } = calculateDateRange(
        getState().shared.currentDateRange
      );

      const derivatives = await api.performance.getPowerDerivatives(
        startDate,
        endDate,
        getState().shared.currentDateRange,
        [athleteId],
        currentDateUnits
      );
      dispatch(setPowerDerivatives(derivatives));

      if (getState().shared.selectedComparison) {
        const comparison = await api.performance.getPowerDerivatives(
          startDate,
          endDate,
          getState().shared.currentDateRange,
          getState().shared.selectedComparison as number[]
        );
        dispatch(setPowerDerivativesComparison(comparison));
      } else {
        dispatch(setPowerDerivativesComparison(undefined));
      }
    }
  };

export const fetchPowerDurations =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    if (athleteId !== undefined) {
      const { startDate, endDate } = calculateDateRange(
        getState().shared.currentDateRange
      );

      const derivatives = await api.performance.getPowerDurations(
        startDate,
        endDate,
        athleteId
      );
      dispatch(setPowerDurations(derivatives));
    }
  };

  export const fetchPowerDurationsRelative =
    () => async (dispatch: Dispatch, getState: () => RootState) => {
      const athleteId = getState().shared.selectedAthleteId;
      if (athleteId !== undefined) {
        const { startDate, endDate } = calculateDateRange(
            getState().shared.currentDateRange
        );

        const derivatives = await api.performance.getPowerDurationsRelative(
            startDate,
            endDate,
            athleteId
        );
        dispatch(setPowerDurationsRelative(derivatives));
      }
    };

export const fetchTssItrimp =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    if (athleteId !== undefined) {
      const { startDate, endDate } = calculateDateRange(
        getState().shared.currentDateRange
      );

      const tssITrimp = await api.performance.getTssItrimp(startDate, endDate, [
        athleteId,
      ]);
      dispatch(setTssItrimp(tssITrimp));

      if (getState().shared.selectedComparison) {
        const comparison = await api.performance.getTssItrimp(
          startDate,
          endDate,
          getState().shared.selectedComparison as number[]
        );
        dispatch(setTssItrimpComparison(comparison));
      } else {
        dispatch(setTssItrimpComparison(undefined));
      }
    }
  };

export const fetchTeamPerformance =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const group = getState().shared.selectedGroup;
    const athletes = getState().shared.athleteList;
    const { startDate, endDate } = calculateDateRange(
      getState().shared.currentDateRange
    );

    if (group?.athleteIds) {
      const teamPerformance = await api.performance.getAthletesPerformance(
        startDate,
        endDate,
        group?.athleteIds
      );
      const sortedTeamPerformance = sortDataBasedOnAthleteOrder(
        teamPerformance,
        athletes
      );
      dispatch(setTeamPerformance(sortedTeamPerformance));
    }
  };

const getAllCompositeValues = (data: TeamPerformanceT[]): CompositeValue[] => {
  const compositeValues: CompositeValue[] = [];
  data.forEach((item) => {
    compositeValues.push(
      { value: parseInt(item.maximumOfMmp10SecondsComposite.toFixed(0)) },
      { value: parseInt(item.maximumOfMmp30SecondsComposite.toFixed(0)) },
      { value: parseInt(item.maximumOfMmp1MinutesComposite.toFixed(0)) },
      { value: parseInt(item.maximumOfMmp5MinutesComposite.toFixed(0)) },
      { value: parseInt(item.maximumOfMmp10MinutesComposite.toFixed(0)) },
      { value: parseInt(item.maximumOfMmp20MinutesComposite.toFixed(0)) },
      { value: parseInt(item.maximumOfMmp30MinutesComposite.toFixed(0)) },
      { value: parseInt(item.maximumOfMmp60MinutesComposite.toFixed(0)) }
    );
  });
  return compositeValues;
};

export const fetchCompoundScore =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    if (athleteId !== undefined) {
      const { startDate, endDate } = calculateDateRange(
        getState().shared.currentDateRange
      );
      const teamPerformance = await api.performance.getAthletesPerformance(
        startDate,
        endDate,
        [athleteId]
      );
      const compoundScore =
        getAllCompositeValues(teamPerformance || []) || undefined;

      dispatch(setCompoundScore(compoundScore));
    }
  };

export const fetchFitnessFatigue =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    if (athleteId !== undefined) {
      const { startDate, endDate } = calculateDateRange(
        getState().shared.currentDateRange
      );

      const fitnessFatigue = await api.performance.getFitnessFatigue(
        startDate,
        endDate,
        getState().shared.currentDateRange,
        [athleteId]
      );
      dispatch(setFitnessFatigue(fitnessFatigue));

      if (getState().shared.selectedComparison) {
        const comparison = await api.performance.getFitnessFatigue(
          startDate,
          endDate,
          getState().shared.currentDateRange,
          getState().shared.selectedComparison as number[]
        );
        dispatch(setFitnessFatigueComparison(comparison));
      } else {
        dispatch(setFitnessFatigueComparison(undefined));
      }
    }
  };

export const fetchOverallComparisonPerformanceRadar =
  (selectedPeriod?: SelectedPeriod) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const athleteId = getState().shared.selectedAthleteId;
    if (athleteId !== undefined) {
      const firstDateType = (selectedPeriod as TwoDateRangesComparison)
        .firstDateRange;
      const secondDateType = (selectedPeriod as TwoDateRangesComparison)
        .secondDateRange;

      const { startDate, endDate } = getStartAndEndDate(firstDateType);

      const athleteScore = await api.performance.getRadarForRange(
        startDate,
        endDate,
        [athleteId]
      );

      const comparisonScore = await api.performance.getRadarForRange(
        getStartAndEndDate(secondDateType).startDate,
        getStartAndEndDate(secondDateType).endDate,
        [athleteId]
      );

      dispatch(setOverallPerformanceRadarCurrent(athleteScore));
      dispatch(setOverallPerformanceRadarComparison(comparisonScore));
    }
  };

export const getPeriodType = (state: RootState) => state.performance.periodType;
export const getSelectedPeriod = (state: RootState) =>
  state.performance.selectedPeriod;
export const getSelectedComparisonPeriod = (state: RootState) =>
  state.performance.selectedComparisonPeriod;

export const performance = performanceSlice.reducer;

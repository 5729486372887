import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientConfig } from "clients/types";

interface ClientConfigState {
	config: ClientConfig | null;
}

const initialState: ClientConfigState = {
	config: null,
};

const clientConfigSlice = createSlice({
	name: 'clientConfig',
	initialState,
	reducers: {
		setClientConfig: (state, action: PayloadAction<ClientConfig>) => {
			state.config = action.payload;
		},
	},
});

// Export the action creators
export const { setClientConfig } = clientConfigSlice.actions;

// Export the reducer
export const clientConfig = clientConfigSlice.reducer;

import DataTable from "react-data-table-component";
import { ReactComponent as SortIcon } from "images/sort_icon.svg";
import { REPORT_HEALTH_MEN_TABLE_COLOR, SORT_STYLE } from "clients/uaeMen/colors";
import { REPORT_HEALTH_WOMEN_TABLE_COLOR } from "clients/adqWomen/colors";
import { useHistory } from "react-router-dom";
import { ReactComponent as Comment } from "images/comment-box.svg";
import { ReactComponent as Attachment } from "images/attachment.svg";
import { useDispatch, useSelector } from "react-redux";
import styles from "./health-table.module.scss";
import "./health-table.overwrite.scss";
import {
  fetchMedicalReports,
  setSelectedMedicalReportIdAction,
} from "store/slices/health";
import { getEnumValueForIssue } from "utils";
import { FlavorType } from "enums";

interface Report {
  id: number;
  recordType: string;
  issue: string;
  moderate: string;
  date: string;
  recovered: string;
  reported: string;
  details: any;
  severity?: string
  createdBy?: string
  estimatedRecovery?: number
  notesCount?: number
  attachmentsCount?: number
  onsetDate?: number
}

const paginationComponentOptions = {
  noRowsPerPage: true,
};

export const HealthTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const medicalReportsData = useSelector(
    (state: any) => state.health.medicalReports
  );
  const healthEnums = useSelector((state: any) => state.health.healthEnums);
  const flavorType = process.env.REACT_APP_FLAVOR;

  const getEnumValue = (parentKey: string, childkey: string) => {
    return healthEnums[parentKey]?.[childkey];
  };

  const getSeverityRowColor = (row: { moderate: string }) => {

    if (row?.moderate === "MODERATE") return "orange";
    else if (row?.moderate === "SEVERE") return "red";
    else if (row?.moderate === "REHABILITATION") return "#397AF9";
    else if (row?.moderate === "MILD") return "green";
    else return flavorType === FlavorType.adqWomen ? "black" : "white";
  };

  const handleRowClicked = (row: Report) => {
    console.log("Row Data:", row);
    dispatch(setSelectedMedicalReportIdAction(row.id));
    history.push("/health-details");
  };

  const formattedData = medicalReportsData.map((item: Report) => ({
    id: item.id,
    recordType: item.recordType,
    issue: getEnumValueForIssue(healthEnums, item?.recordType, item?.issue) || "Not Specified",
    moderate: item.severity || "Not Specified",
    date: item?.onsetDate && new Date(item?.onsetDate * 1000).toLocaleDateString(),
    recovered: item?.estimatedRecovery && new Date(item?.estimatedRecovery * 1000).toLocaleDateString(),
    reported: item.createdBy,
    details: (
      <div onClick={() => handleRowClicked(item)}>
        <Comment className={styles.commentIcon} aria-label="Comments"
          title="Comments" />
        <span className={styles.count}>{item.notesCount}</span>
        <Attachment className={styles.attachmentIcon} aria-label="Attachments"
          title="Attachments" />
        <span className={styles.count}>{item.attachmentsCount}</span>
      </div>
    ),
  }));

  const columns = [
    {
      name: "Issue",
      selector: (row: Report) => row.issue,
      sortable: true,
      cell: (row: Report) => (
        <div onClick={() => handleRowClicked(row)}>
          <div style={{ fontWeight: "bold" }}>{row?.issue}</div>
          <div style={{ color: "#6c757d", fontSize: "12px", marginTop: "3px" }}>
            {/* {getEnumValue(row?.recordType === "PHYSIO" ? "type" : "severity", row?.moderate) || "Not specific"} •{" "} */}
            {/* todo: row.recordType to be added */}
            {getEnumValue("reportType", row?.recordType) || "Not specific"}
          </div >
        </div >
      ),
      grow: 1,
    },
    {
      name: "Severity / Stage",
      selector: (row: Report) => row.moderate,
      sortable: true,
      cell: (row: Report) => (
        <span
          onClick={() => handleRowClicked(row)}
          style={{
            color: getSeverityRowColor(row),
          }}
        >
          {healthEnums?.severity[row?.moderate] || healthEnums?.rehabilitationStage[row?.moderate] || "Not Specified"}
        </span>
      ),
      // center: true,
    },
    {
      name: "Onset date",
      selector: (row: Report) => row.date,
      sortable: true,
    },
    {
      name: "Recovered By",
      selector: (row: Report) => row.recovered,
      sortable: true,
    },
    {
      name: "Reported By",
      selector: (row: Report) => row.reported,
      sortable: true,
    },
    {
      name: "Details",
      selector: (row: Report) => row.details,
      sortable: true,
    },
  ];

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    console.log("New Per Page:", newPerPage, "Page:", page);
  };

  const handlePageChange = (page: number) => {
    dispatch(fetchMedicalReports(page - 1));
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={formattedData}
        className={styles.tableData}
        sortIcon={<SortIcon className={styles.filterIcon} style={SORT_STYLE} />}
        customStyles={flavorType === FlavorType.adqWomen ? REPORT_HEALTH_WOMEN_TABLE_COLOR : REPORT_HEALTH_MEN_TABLE_COLOR}
        onRowClicked={handleRowClicked}
        onRowDoubleClicked={handleRowClicked}
        pagination={formattedData?.length > 14 ? true : false}
        paginationComponentOptions={paginationComponentOptions}
        paginationServer
        // paginationTotalRows={30} 
        // paginationPerPage={15}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </>
  );
};

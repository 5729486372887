import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import ReactECharts from "echarts-for-react";
import clsx from "clsx";
import styles from "./pinned-graph.module.scss";
import _ from "lodash";
import { getAiGraphAllPinTabs, getAiGraphComputeData, getSimplifiedComputeData } from "api/ai-graph";
import { COLOR_TOOLTIP, COLOR_WHITE } from "components/charts/colors";
import { AIComputeResponse, AiGraphInScope, Axis, Plot, PlotData, YAxisData } from "types";
import { ReactComponent as PresentationLine } from "images/presentation-chart-line.svg";
import { DateSelector } from "components/date-selector/date-selector";
import { useSelector } from "react-redux";

import { getAthleteList } from "store/slices/shared";
import { countryCodesMapping } from "country-codes-mapping";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import { calculateDateRange } from "utils";
import { computeGraphOption, validateGraphConfig } from "../utils/utils";


export const PinnedGraph: FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabId = searchParams.get("tab_id");
  
  const athleteList = useSelector(getAthleteList);
  const athleteId = useSelector((state:any)=>state?.shared?.selectedAthleteId)
  const getDate= useSelector((state:any)=> state?.shared.currentDateRange)
  const [graphData, setGraphData] = useState<any[]>([]);
  const [loading,setLoading]=useState(false);

  const handleComputeData = useCallback((computeData: AIComputeResponse) => {
    if (computeData?.ai_graph_in_scope) {
      (async () => {
       const configOption= computeGraphOption(computeData)
        setGraphData((prevGraph) => [...prevGraph, configOption]);
      })();
    }
  }, []);

  const selectedAthlete = useMemo(() => 
  athleteList?.find((athlete) => athlete?.id === athleteId), 
  [athleteId, athleteList]
);

const athleteCountryAlpha2Code = useMemo(() => {
  return selectedAthlete?.countryCode &&
    countryCodesMapping[selectedAthlete.countryCode]
      ? countryCodesMapping[selectedAthlete?.countryCode]
      : "AE";
}, [selectedAthlete]);

const athleteFlagUrl = useMemo(() => 
  `${DEFAULT_CDN_URL}${athleteCountryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`,
  [athleteCountryAlpha2Code]
);

// Memoize the dataSource array
const dataSource = useMemo(() => {
  if (!selectedAthlete) return []; 
  if(getDate){
    const {startDate,endDate}=calculateDateRange(getDate)
    return [
      {
        "type": "AthleteDataSourceMetadata",
        "athlete_id": selectedAthlete?.id?.toString(),
        "athlete_full_name": selectedAthlete?.fullName,
        "athlete_image_identifier": selectedAthlete?.picture,
        "athlete_flag_identifier": athleteFlagUrl,
        "athlete_profile_pic": null
      },
      {
        "type": "GraphDateSourceMetadata",
        "date_name": "graph_start_date",
        "selected_date": startDate?.toISOString()?.slice(0,10)// You can replace this with dynamic values
      },
      {
        "type": "GraphDateSourceMetadata",
        "date_name": "graph_end_date",
        "selected_date":  endDate?.toISOString()?.slice(0,10)
      }
    ];

  }
  return []
}, [selectedAthlete, athleteFlagUrl,getDate]);

// useEffect that triggers only when tabId changes to call simplified compute graph api
useEffect(() => {
  if (!tabId) return; 
  (async () => {
    setGraphData([]); 
    try {
      setLoading(true)
      const tabsResponse = await getAiGraphAllPinTabs("single_athlete");
      const pinnedGraph = tabsResponse?.find((tab: any) => tab?.tab_id === tabId);
      if (pinnedGraph?.tab_graphs?.length<=0) {
        setLoading(false)
        return
      }; 
      await Promise.all(pinnedGraph?.tab_graphs?.map(async (graph: any) => {
        const computeDataResponse = await getSimplifiedComputeData(
          graph?.graph_id, dataSource
        );
        const computedData = computeDataResponse?.ai_graph_in_scope?.ai_generated_graphs[0]?.computed_data;
        if (
          computedData?.plots_data?.length > 0 &&
          computedData?.x_axes_data?.length > 0 &&
          computedData?.y_axes_data?.length > 0
        ) {
          
          handleComputeData(computeDataResponse); 
        }
        setLoading(false)
      }));
    } catch (error) {
      console.error("Error fetching graph data:", error);
      setLoading(false)
    }
  })();
  
}, [tabId, dataSource, handleComputeData]); 




  return (
    <>
      <div className={styles.dateSelector}>
        <DateSelector />
      </div>
      {graphData?.length > 0 && !loading ?
        graphData?.map((graph) => {
          return (
            <div
              className={clsx(styles.container)}
              data-testid="athlete-performance"
            >
              {/* <div className={styles.header}>
                <DateSelector />
              </div> */}
              {validateGraphConfig(graph) && (
                <>
                  <ReactECharts
                    key={JSON.stringify(graph)}
                    opts={{ renderer: "svg" }}
                    option={graph}
                  // style={{ height: "100%", width: "100%", padding: "1rem" }}
                  // showLoading={isLoading}
                  // loadingOption={loadingOptions}
                  />
                </>
              )}
            </div>
          );
        }) : <div className={styles.noData}> 
              <PresentationLine className={`${styles.graphImage} ${loading ? styles.loading : ""}`} /> 
              {loading ? 'Loading graphs...' : 'No Pinned Charts!'}
        </div>}
    </>
  );
};

import { FC } from "react";

import styles from "./uae-cycling-header.module.scss";

type Props = {
  showMarketingBanner?: boolean;
};

export const UaeCyclingHeader: FC<Props> = ({
  showMarketingBanner = false,
}) => {
  return (
    <div className={styles.headerBlock}>
      {showMarketingBanner && <div className={styles.marketingBanner}></div>}
    </div>
  );
};

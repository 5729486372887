// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { LinearGradient } from "echarts/lib/util/graphic";
import {
  ChartSize,
  directionsMap,
  FONT_SIZE_XS,
  GradientDirection,
} from "./constants";
import { ChartColor, TextStyle } from "./types";
import { COLOR_GRAY } from "./colors";

export const toEChartsTextStyle = (style: TextStyle, defaults: TextStyle) => {
  return {
    color: style.color || defaults.color,
    fontSize: style.size || defaults.size,
    fontFamily: style.fontFamily || defaults.fontFamily,
    fontWeight: style.fontWeight || defaults.fontWeight,
  };
};

export const toEChartsColor = (
  color: ChartColor,
  gradientDirection: GradientDirection
) => {
  if (color instanceof Array) {
    return new LinearGradient(...directionsMap[gradientDirection], [
      { offset: 0, color: color[0] },
      { offset: 1, color: color[1] },
    ]);
  }
  return color;
};

export const getAxisLabelStyle = (type: ChartSize, margin?: number, timeStamp?: boolean) => {
  if (type === ChartSize.Small) {
    return {};
  }
  if (margin) {
    return {
      axisLabel: {
        ...toEChartsTextStyle(
          {},
          {
            color: COLOR_GRAY,
            fontWeight: "bold",
            size: FONT_SIZE_XS,
            fontFamily: "Montserrat",
          }
        ),
        formatter: (value: string) => {
          if (timeStamp) {
            return `${value}:00:00`;
          }
          return value;
        },
        margin: margin,
      },
    };
  }

  return {
    axisLabel: {
      ...toEChartsTextStyle(
        {},
        {
          color: COLOR_GRAY,
          fontWeight: "bold",
          size: FONT_SIZE_XS,
          fontFamily: "Montserrat",
        }

      ),
    },
  };
};

export function filterChartValue(value?: number) {
  if (typeof value === "number" && value !== 0) {
    return value;
  }

  return null;
}

export const wrap = (str: string, width: number) =>
  str.replace(
    new RegExp(`(?![^\\n]{1,${width}}$)([^\\n]{1,${width}})\\s`, "g"),
    "$1\n"
  );

import React, { FC, useEffect, useState, useMemo } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import { DateGroupBy } from "enums";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentDateRange, getCurrentGroupBy, setCurrentGroupBy } from "store/slices/shared";

import styles from "./date-group-by.module.scss";
import "./date-group-by.overwrite.scss";
import { DateRange } from "enums";

interface SelectorOption {
  label: string;
  value: DateGroupBy;
}

const options: SelectorOption[] = [
  {
    label: "Group By: Days",
    value: DateGroupBy.Day,
  },
  {
    label: "Group By: Weeks",
    value: DateGroupBy.Week,
  },
  {
    label: "Group By: Months",
    value: DateGroupBy.Month,
  },
];

interface Props {
  small?: boolean;
  ignoreOptions?: DateGroupBy[];
}

export const DateGroupBySelector: FC<Props> = ({ small, ignoreOptions = [] }) => {
  const dispatch = useDispatch();
  const currentDateGroupBy = useSelector(getCurrentGroupBy);
  const currentDateRange = useSelector(getCurrentDateRange);

  const filteredOptions = useMemo(() => {
    let newOptions: SelectorOption[];
    switch (currentDateRange) {
      case DateRange.Week:
        newOptions = options.filter((option) => option.value === DateGroupBy.Day);
        break;
      case DateRange.Month:
        newOptions = options.filter(
          (option) => option.value === DateGroupBy.Day || option.value === DateGroupBy.Week
        );
        break;
      default:
        newOptions = options;
        break;
    }

    if (ignoreOptions.length) {
      newOptions = newOptions.filter((option) => !ignoreOptions.includes(option.value));
    }

    return newOptions;
  }, [currentDateRange, ignoreOptions]);

  const value: SelectorOption | undefined = filteredOptions.find(
    (option) => option.value === currentDateGroupBy
  );

  const handleChange = (newOption: SelectorOption) => {
    dispatch(setCurrentGroupBy(newOption.value));
  };

  return (
    <div className={clsx(styles.wrapper, "date-group-by-selector")}>
      <Select
        isClearable={false}
        isSearchable={false}
        options={filteredOptions}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx("group-by-selector__select", small && "group-by-selector__select--small")}
        classNamePrefix="react-select"
      />
    </div>
  );
};

import { FC } from "react";
import clsx from "clsx";

import styles from "./settings-header.module.scss";

import { CoachInfo } from "components/coach-info/coach-info";
import { useSelector } from "hooks/app-hooks";
import { getIsCyclist } from "store/slices/auth";

type Props = {
  hideBottom?: boolean;
};

export const SettingsHeader: FC<Props> = ({ hideBottom }) => {
  const isCyclist: boolean = useSelector(getIsCyclist);

  return (
    <div className={clsx(styles.athleteSelectionHeader, hideBottom && styles.hideBottom)}>
      <div className={styles.athleteLeft}>
        <span className={styles.athleteText}>Settings</span>
      </div>
      {!isCyclist && <CoachInfo className={styles.coachButton} />}
    </div>
  );
};

// GroupInfo.tsx
import React from "react";
import styles from "./group-info.module.scss";

interface GroupCardProps {
  groupName: string;
  memberImages: string[];
  onClick?: () => void;
}

export const GroupInfo: React.FC<GroupCardProps> = ({ groupName, memberImages, onClick }) => {
  const hiddenCount = memberImages.length > 5 ? memberImages.length - 5 : 0;
  return (
    <div className={styles.groupInfo}>
      <div className={styles.groupMembers}>
        {memberImages.slice(0, 5).map((image, index) => (
          <img key={index} src={image} alt={`Member ${index + 1}`} className={styles.memberImage} />
        ))}
        {hiddenCount > 0 && (
          <div className={styles.memberRemainder}>
            <span>+{hiddenCount}</span>
          </div>
        )}
      </div>
      <div className={styles.groupCard} onClick={onClick}>
        <div className={styles.groupContent}>
          <h2 className={styles.groupName}>{groupName}</h2>
        </div>
      </div>
    </div>
  );
};

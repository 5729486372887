import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { getAiGraphId } from "api/ai-graph";
import { RootState } from "store/index";
import { AthleteType } from "types";

interface AiGraphState {
  aiGraphId: string;
}
const initialState: AiGraphState = {
  aiGraphId: "",
};

export const aiGraphSlice = createSlice({
  name: "aiGraph",
  initialState,
  reducers: {
    setAiGraphId: (state, action: PayloadAction<any>) => {
      state.aiGraphId = action.payload;
    },
  },
});

export const { setAiGraphId } = aiGraphSlice.actions;

export const fetchAiGraphId = (dataSources: AthleteType[]) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const response = await getAiGraphId(dataSources);
    if (response) {
      dispatch(setAiGraphId(response?.id));
    }
  };
};

export const aiGraph = aiGraphSlice.reducer;

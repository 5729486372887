import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { api } from "api/api";
import { ChartData, ChartMeta } from "types";

interface ChartsState {
  [key: string]: ChartData;
}

const initialState: ChartsState = {};

export const chartSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {
    setChart: (state, action: PayloadAction<ChartData>) => {
      state[action.payload.name] = action.payload;
    },
  },
});

export const { setChart } = chartSlice.actions;

const isNotEmpty = (data: any): boolean => {
  // Check if data is an array and not empty
  if (Array.isArray(data) && data.length > 0) {
    return true;
  }

  // Check if data is an object and not empty
  if (
    typeof data === "object" &&
    data !== null &&
    Object.keys(data).length > 0
  ) {
    return true;
  }

  return false;
};

export const fetchChartData =
  <T>(meta: ChartMeta<T>) =>
  async (dispatch: Dispatch) => {
    meta?.charts?.forEach((chart) => {
      dispatch(
        setChart({
          name: chart?.name,
          loaded: false,
          labels: [],
          values: {},
          source: {},
          colors: {},
        })
      );
    });

    let { url } = meta.api;
    Object.keys(meta.api.params).forEach((key) => {
      url = url.replace(key, meta.api.params[key]);
    });

    const data = await api.chart.getChart<T>(url);

    if (!data || !isNotEmpty(data)) {
      console.error("No data found for chart", meta);
      return;
    }

    meta?.charts?.forEach((chart) => {
      dispatch(
        setChart({
          name: chart?.name,
          loaded: true,
          labels: chart.getLabels(data),
          values: chart.getValues(data),
          source: chart.getSource ? chart.getSource(data) : {},
          colors: chart.getColors ? chart.getColors(data) : {},
        })
      );
    });
  };

export const chart = chartSlice.reducer;

import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { useForm } from "hooks/use-form";

import { ReactComponent as Check } from "images/check.svg";

import { UaeCyclingHeader } from "pages/home/uae-cycling-header/uae-cycling-header";

import styles from "./reset-password.module.scss";
import { verifyOTP, setNewPassword } from "api/profile";
import { getTeamName, getURIQueryParams } from "utils";
import { PasswordInput } from "pages/settings/password-component";
import { QueryParams, SetNewPassword } from "types";
import { ChangePasswordCode, ChangePasswordMessage } from "enums";

export const ResetPassword = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const [passwordReset, setPasswordReset] = useState(false);
  const [resetPasswordErrorMsg, setResetPasswordErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const passwordMessage =
    "Password must be at least 6 characters, including upper and lowercase letters, a number, and a special character.";
  const [form, changeHandler] = useForm({
    password: "",
    reTypePassword: "",
  });

  const resetPassword = useCallback(async (data: SetNewPassword) => {
    return setNewPassword(data);
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError(false);
    }
    changeHandler(e);
  };
  const handleGoBack = () => {
    history.push("/sign-in");
  };
  useEffect(() => {
    const { otp, email, team } = getURIQueryParams();
    setQueryParams({ otp, email, team });
    const validateOTP = async () => {
      if (otp && email && team) {
        const response = await verifyOTP(email, otp, team);
        if (response?.code === 200) {
          setIsLoading(false);
          setIsValid(true);
        } else {
          setIsValid(false);
          history.push("/sign-in");
        }
      } else {
        setIsValid(false);
        history.push("/sign-in");
      }
    };
    validateOTP();
  }, [history]);

  const handleResetPassword = async (e: FormEvent) => {
    e.preventDefault();

    // get team based on the falvor
    if (form.password !== form.reTypePassword) {
      setError(true);
      setResetPasswordErrorMsg(
        "The repeated password does not match the new password. Please ensure they match."
      );
      return;
    }
    setIsLoading(true);
    const response = await resetPassword({
      otp: queryParams?.otp ?? "",
      email: queryParams?.email ?? "",
      newPassword: form.reTypePassword,
      team: getTeamName(),
    });
    setIsLoading(false);
    if (response?.code === 200) {
      setError(false);
      setIsLoading(false);
      setPasswordReset(true);
    } else {
      setError(true);
      switch (response?.message) {
        case ChangePasswordCode.OLD_AND_NEW_PASS_SAME:
        case ChangePasswordCode.OLD_PASSWORD_IS_WRONG:
        case ChangePasswordCode.PASSWORD_CRITERIA_NOT_MATCHED:
          setError(true);
          setResetPasswordErrorMsg(ChangePasswordMessage[response?.message]);
          break;
        default:
          setError(true);
          setResetPasswordErrorMsg(ChangePasswordMessage.UNKNOWN_ERROR);
      }
    }
  };

  const resetPasswordContent = () => {
    return (
      <div className={styles.sidebarContent}>
        <h1 className={styles.signInText}>Reset Password</h1>
        <form
          className={styles.form}
          onSubmit={handleResetPassword}
          data-testid="reset-password-form"
        >
          <div className={styles.inputsBlock}>
            <PasswordInput
              label="New password"
              name="password"
              value={form.password}
              placeholder="Enter your new password"
              error={error}
              onChange={handleInputChange}
            />
            <PasswordInput
              label="Confirm password"
              name="reTypePassword"
              value={form.reTypePassword}
              placeholder="Repeat new password"
              error={error}
              onChange={handleInputChange}
            />
          </div>
          {error ? (
            <div className={styles.errorMessage}>{resetPasswordErrorMsg}</div>
          ) : (
            <div className={styles.passwordMessage}>{passwordMessage}</div>
          )}
          <div className={styles.buttonBlock}>
            <button
              type="submit"
              className={styles.button}
              tabIndex={0}
              disabled={
                form.reTypePassword.length === 0 ||
                form.password.length === 0 ||
                isLoading
              }
            >
              {isLoading ? (
                <div className={styles.loader}></div>
              ) : (
                "Set Password"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const emailSuccessContent = () => {
    return (
      <div className={styles.emailSuccessContent}>
        <div className={styles.emailIcon}>
          <Check />
        </div>
        <div className={styles.signInText}>Password Changed!</div>
        <div className={styles.weveSentAContainer}>
          <p className={styles.weveSentA}>
            You can now use your new password to log in to your account.
          </p>
        </div>
        <div className={styles.buttonBlock}>
          <button
            type="submit"
            className={styles.button}
            tabIndex={0}
            onClick={handleGoBack}
          >
            {isLoading ? <div className={styles.loader}></div> : "Ok"}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.home}>
      <div className={styles.loginOverlay} />;
      <div className={styles.background}>
        <div className={styles.sidebarWrapper}>
          <UaeCyclingHeader />
          {passwordReset ? emailSuccessContent() : resetPasswordContent()}
        </div>
      </div>
    </div>
  );
};

import { COLOR_TOOLTIP, COLOR_WHITE } from "components/charts/colors";
import { AIComputeResponse, AiGraphInScope, Axis, NameValueObject, Plot, PlotData, YAxisData } from "types";

  // Function to convert object to sparse series data
  export const convertToSparseSeriesData=(
    obj: NameValueObject
  ): { name: string; value: string }[] =>{
    return Object.entries(obj).map(([key, value]) => ({ name: key, value }));
  }
  
  export const customDateFormatter =(value: string): string =>{
    const date = new Date(value);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
  
     // funtion to create EChart Options
  export const createGraphOptions = (
    aiGraphInScope: AiGraphInScope,
    plots: any,
    series: any,
    yAxisLabel: any,
    xAxisLabel: any
  ) => {
    const dateUnits = ["date", "day"];
    /* 
    * Format of Mapping:
    <y_axies_length>: {
          left: <left_offset>,
          right: <right_offset>
        } 
    */
    const yAxisGridOffsetMapping: { [key: number]: { left: number; right: number } } = {
      1: {
        left: 15,
        right: 15,
      },
      2: {
        left: 15,
        right: 15,
      },
      3: {
        left: 50,
        right: 15,
      },
      4: {
        left: 50,
        right: 50,
      },
      5: {
        left: 80,
        right: 50,
      },
      6: {
        left: 100,
        right: 150,
      },
    };
  
    return {
      title: {
        text: aiGraphInScope?.title?.value,
        left: "left",
        textStyle: {
          color: COLOR_WHITE,
          fontSize: 12,
          fontWeight: 550,
          fontFamily: "Montserrat",
          // width: 220,
          // overflow: "truncate"
        },
      },
      textStyle: {
        fontFamily: "Montserrat, sans=serif",
        fontSize: 12,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        backgroundColor: COLOR_TOOLTIP,
        borderColor: COLOR_TOOLTIP,
        formatter: function (params: any) {
          let tooltipTopTitle = "";
          if (dateUnits?.includes(aiGraphInScope?.x_axes[0]?.unit)) {
            const date = new Date(params[0].name);
            tooltipTopTitle = date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            });
          } else tooltipTopTitle = params[0].name;
  
          let tooltipContent = tooltipTopTitle + "<br/>";
          params.forEach((param: any) => {
            const marker = param.marker;
            const seriesName = param.seriesName;
            const value = param.value;
  
            if (value && value !== null) {
              tooltipContent +=
                marker + " " + seriesName + ":  " + `<b>${value}</b>` + "<br/>";
            }
          });
          return `<div style="font-size: 11px;">${tooltipContent}</div>`;
        },
        textStyle: {
          color: COLOR_WHITE,
          fontFamily: "Montserrat, sans=serif",
          fontSize: 12,
          itemGap: 20,
        },
      },
      legend: {
        orient: "horizontal",
        itemGap: 12,
        textStyle: {
          color: COLOR_WHITE,
          fontFamily: "Montserrat, sans=serif",
          fontSize: 10,
        },
        top: "10%",
      },
      barMaxWidth: "12px",
      grid: {
        top: 80,
        left: yAxisGridOffsetMapping[
          aiGraphInScope?.computed_data?.y_axes_data?.length
        ]?.left,
        bottom: 20,
        right:
          yAxisGridOffsetMapping[
            aiGraphInScope?.computed_data?.y_axes_data?.length
          ]?.right,
        containLabel: true,
      },
      xAxis: xAxisLabel,
      yAxis: yAxisLabel,
      series: series,
      darkMode: true,
    };
  };
  
  
  export const getYAxisPosition = (id: string, yAxisMetaData: any) => {
    const yAxisData = yAxisMetaData?.find((axis: any) => {
      return axis?.linked_axis_ids?.includes(id);
    });
    return yAxisData?.position;
  };
  
  export const validateGraphConfig = (graphData:any) => {
    if (!graphData) {
      return false;
    }
    if (!graphData?.xAxis) {
      return false;
    }
    if (!graphData?.yAxis || graphData?.yAxis?.length == 0) {
      return false;
    }
    if (graphData?.series?.length === 0) {
      return false;
    }
    return true;
  }
  
  export const computeGraphOption = (computeData: AIComputeResponse)=>{
    const aiGeneratedGraph =
    computeData?.ai_graph_in_scope?.ai_generated_graphs[0];
  const plots = aiGeneratedGraph?.plots || [];
  const computedData = aiGeneratedGraph?.computed_data;
  
  const dateUnits = ["date", "day"];
  const isDateUnit = dateUnits?.includes(
    aiGeneratedGraph?.x_axes[0]?.unit
  );
  // 1. Map the X Axis data
  const xAxisLabel = {
    type: aiGeneratedGraph?.x_axes[0]?.range_log_scaled ? "log" : (aiGeneratedGraph?.x_axes[0]?.series_data_type || "category"),
    name: aiGeneratedGraph?.x_axes[0]?.unit_readable_short_name,
    nameLocation: "middle",
    nameGap: 25,
    data: aiGeneratedGraph?.computed_data?.x_axes_data[0]
      ?.series_only_values_data,
    axisLabel: {
      formatter: isDateUnit ? customDateFormatter : `{value}`,
      rotate: 0,
      textStyle: {
        fontSize: 10,
        color: COLOR_WHITE,
      },
    },
    axisTick: {
      alignWithLabel: true,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#666",
      },
    },
    axisPointer: {
      label: {
        fontSize: 11,
        formatter: isDateUnit
          ? (axisObject: any) => {
              const value = axisObject?.value;
              return new Date(value).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              });
            }
          : `{value}`,
      },
    },
  };
  
  // 2. Map the Y Axis data
  const yAxisLabel = aiGeneratedGraph?.y_axes?.map(
    (yAxis, index: number) => {
      const matchingPlot = plots?.find((plot: Plot) =>
        yAxis?.linked_plot_ids?.includes(plot.id)
      );
  
      // offset values for multiple y axes on the same position (max 6 y axes)
      const offsets = {
        1: 30,
        2: 60,
        3: 90,
        4: 120,
        5: 150,
        6: 180,
      };
  
      return {
        type: yAxis?.range_log_scaled ? "log" : "value",
        name: yAxis?.unit_readable_short_name,
        position: getYAxisPosition(yAxis.id, computedData?.y_axes_data),
        axisLabel: {
          formatter: `{value}`,
          color: matchingPlot?.plot_view_metadata?.color_name,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: matchingPlot?.plot_view_metadata?.color_name,
          },
        },
        axisPointer: {
          show: false,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: false,
        },
        textStyle: {
          color: COLOR_WHITE,
          fontFamily: "Montserrat, sans-serif",
          fontSize: 12,
        },
        ...(index > 1 && { offset: 30 * index }),
        min:  yAxis?.range_automatic_min_value ? 'dataMin' : yAxis?.range_min_value,
        max: yAxis?.range_automatic_max_value ? 'dataMax' : yAxis?.range_max_value,
      };
    }
  );
  
  const checkIfLinkedYAxisExists = (currentPlotId: string) => {
    const matchingYAxes = aiGeneratedGraph?.y_axes?.find((axis) =>
      axis.linked_plot_ids.includes(currentPlotId)
    );
    if (matchingYAxes) {
      const yAxisDataElement = computedData?.y_axes_data?.find(
        (axis) => axis.linked_axis_ids[0] === matchingYAxes.id
      );
      if (yAxisDataElement) return true;
      else return false;
    } else return false;
  };
  
  const getYAxisIndex = (currentPlotId: string) => {
    let yAxisIndex = null;
    const matchingYAxes = aiGeneratedGraph?.y_axes?.find((axis: Axis) =>
      axis.linked_plot_ids.includes(currentPlotId)
    );
    if (matchingYAxes) {
      computedData?.y_axes_data?.forEach(
        (axis, index: number) => {
          if (axis?.linked_axis_ids?.[0] === matchingYAxes?.id) {
            yAxisIndex = index;
          }
        }
      );
    }
    return yAxisIndex;
  };
  
  // 3. Map the Series data
  const series = aiGeneratedGraph?.computed_data?.plots_data?.map(
    (plotData: PlotData, index: number) => {
      const matchingPlot = plots.find(
        (plot: Plot) => plot.id === plotData.linked_plot_id
      );
  
      return {
        name: plotData?.readable_name_with_datasource,
        type: matchingPlot?.plot_view_metadata.type,
        data: convertToSparseSeriesData(plotData?.series_key_value_data),
        symbol:
          matchingPlot?.plot_view_metadata.line_mark_points_shape ||
          "emptyCircle",
        color: matchingPlot?.plot_view_metadata.color_name,
        showSymbol: matchingPlot?.plot_view_metadata.is_show_on_legend,
        connectNulls: true,
        lineStyle: {
          type:
            matchingPlot?.plot_view_metadata.line_style_type || "solid",
        },
        backgroundStyle: {
          color: matchingPlot
            ? "rgba(35, 41, 50, 0.6)"
            : "rgba(0, 0, 0, 0)",
        },
        ...(aiGeneratedGraph?.y_axes?.length > 1 &&
          index !== 0 &&
          checkIfLinkedYAxisExists(plotData?.linked_plot_id) && {
            yAxisIndex: getYAxisIndex(plotData?.linked_plot_id),
          }),
        ...(matchingPlot?.plot_view_metadata?.is_area_shaded_plot && {
          areaStyle: {
            color:
              matchingPlot?.plot_view_metadata?.area_shaded_color_name,
          },
        }),
      };
    }
  );
  
  return createGraphOptions(
    aiGeneratedGraph,
    plots,
    series,
    yAxisLabel,
    xAxisLabel
  );
  }
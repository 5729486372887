import React from "react";
import clsx from "clsx";

import { Layout } from "components/layout/layout";
import { HeartMeasurements } from "pages/wellness/heart-measurements/heart-measurements";
import { OverallWellness } from "pages/wellness/overall-wellness/overall-wellness";
import { BodyComposition } from "pages/wellness/body-composition/body-composition";
import { WellnessScores } from "pages/wellness/wellness-scores/wellness-scores";
import { useSelector } from "hooks/app-hooks";
import { getIsCyclist } from "store/slices/auth";
import { AddData } from "components/add-data/add-data";
import styles from "./wellness.module.scss";

import { Tabs, TabsLayout } from "components/tabs-layout/tabs-layout";
import { MenstrualCycle } from "./menstrual-cycle/menstrual-cycle";
import { ChatBot } from "components/chat-bot/chat-bot";

const tabs = [
  {
    id: Tabs.OverallWellnessTab,
    name: "Self reported wellness",
  },
  {
    id: Tabs.BodyCompositionTab,
    name: "Body Composition",
  },
  {
    id: Tabs.HeartMeasurementsTab,
    name: "Heart Rate Measurements",
  },
  {
    id: Tabs.MenstrualCycleTab,
    name: "Menstrual Chart",
  },
];

const componentMap = {
  [Tabs.OverallWellnessTab]: <OverallWellness />,
  [Tabs.BodyCompositionTab]: <BodyComposition />,
  [Tabs.HeartMeasurementsTab]: <HeartMeasurements />,
  [Tabs.MenstrualCycleTab]: <MenstrualCycle />,
};

export const Wellness = () => {
  const isCyclist: boolean = useSelector(getIsCyclist);
  const topComponent = (
    <>
      <div className={clsx(styles.topHeaderCls, !isCyclist && styles.hidden)}>
        {isCyclist && <AddData />}
      </div>
      <div
        className={clsx(
          styles.topContainer,
          isCyclist && styles.topContainerCyclist
        )}
      >
        <WellnessScores topHeader="Daily Wellness Scores" />
      </div>
    </>
  );
  return (
    <>
      <Layout
        top={topComponent}
        contentHeader="Historical Wellness Metrics "
        content={<TabsLayout tabs={tabs} componentMap={componentMap} />}
        isCyclist={isCyclist}
        dataTestId="historical-wellness-metrics"
        pinTab={true}
      />
     
    </>
  );
};

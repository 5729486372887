const WheezeFront = ({ opacity }: { opacity: number }) => {
    return (
        <svg width="160" height="410" viewBox="0 0 160 410" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={opacity}>
            <path d="M81.2463 96.8093L80.7942 96.6269L81.2463 96.8093C80.8998 97.6679 79.6636 97.6651 79.3272 96.7977L79.1257 96.2782L79.5918 96.0974L79.1257 96.2782C78.1305 93.7124 75.6823 91.9481 72.8619 91.7824L72.2938 91.7491C71.3712 91.6949 70.9585 90.5299 71.7152 89.9361L72.1592 89.5878C74.3576 87.8628 75.3128 85.0464 74.6105 82.3922L74.4681 81.8543C74.2214 80.9217 75.2655 80.256 76.0251 80.7416L76.501 81.0458L76.2316 81.4671L76.501 81.0458C78.8601 82.5542 81.91 82.5709 84.2908 81.0876L84.7709 80.7885C85.5357 80.312 86.5735 80.9879 86.3132 81.9195L86.1634 82.4559C85.424 85.1028 86.3402 87.9285 88.5134 89.6762L88.9525 90.0293C89.7044 90.6341 89.2697 91.7915 88.351 91.8355L87.7825 91.8628C84.9597 91.9981 82.4862 93.7362 81.455 96.292L81.2463 96.8093Z" fill="url(#paint0_linear_1768_107976)" fill-opacity="0.2" stroke="#FF0000" />
            <path d="M80.5001 84.2197L78.8619 87.4961C78.6956 87.8286 78.9374 88.2197 79.3091 88.2197H81.6503C82.0315 88.2197 82.2725 88.6293 82.0874 88.9625L80.0001 92.7197" stroke="#FF0013" stroke-linecap="round" />
            <path d="M80 68.65C78.4116 68.65 76.987 68.1667 75.9674 67.402C74.9479 66.6374 74.35 65.6078 74.35 64.5C74.35 63.3922 74.9479 62.3626 75.9674 61.598C76.987 60.8333 78.4116 60.35 80 60.35C81.5884 60.35 83.013 60.8333 84.0326 61.598C85.0521 62.3626 85.65 63.3922 85.65 64.5C85.65 65.6078 85.0521 66.6374 84.0326 67.402C83.013 68.1667 81.5884 68.65 80 68.65Z" stroke="white" stroke-width="0.7" />
            <path d="M80 70.55C77.5988 70.55 75.4434 69.8464 73.8995 68.7314C72.355 67.6159 71.45 66.1141 71.45 64.5C71.45 62.8859 72.355 61.3841 73.8995 60.2686C75.4434 59.1536 77.5988 58.45 80 58.45C82.4012 58.45 84.5566 59.1536 86.1005 60.2686C87.645 61.3841 88.55 62.8859 88.55 64.5C88.55 66.1141 87.645 67.6159 86.1005 68.7314C84.5566 69.8464 82.4012 70.55 80 70.55Z" stroke="white" stroke-width="0.9" />
            <path d="M80 72.55C76.7684 72.55 73.8618 71.6215 71.7748 70.1432C69.6867 68.6641 68.45 66.6625 68.45 64.5C68.45 62.3375 69.6867 60.3359 71.7748 58.8568C73.8618 57.3785 76.7684 56.45 80 56.45C83.2316 56.45 86.1382 57.3785 88.2252 58.8568C90.3133 60.3359 91.55 62.3375 91.55 64.5C91.55 66.6625 90.3133 68.6641 88.2252 70.1432C86.1382 71.6215 83.2316 72.55 80 72.55Z" stroke="white" stroke-width="0.9" />
            <path d="M80 66.7C78.4553 66.7 77.3 65.6661 77.3 64.5C77.3 63.3339 78.4553 62.3 80 62.3C81.5447 62.3 82.7 63.3339 82.7 64.5C82.7 65.6661 81.5447 66.7 80 66.7Z" stroke="white" stroke-width="0.6" />
            <mask id="path-7-inside-1_1768_107976" fill="white">
                <ellipse cx="80" cy="64.5" rx="0.5" ry="1" transform="rotate(90 80 64.5)" />
            </mask>
            <path d="M80 64.4C79.7968 64.4 79.6425 64.3575 79.5612 64.3169C79.5207 64.2967 79.5205 64.2881 79.5367 64.3071C79.5561 64.3301 79.6 64.3969 79.6 64.5L78.4 64.5C78.4 64.9878 78.7798 65.2678 79.0246 65.3902C79.3052 65.5305 79.6509 65.6 80 65.6L80 64.4ZM79.6 64.5C79.6 64.6031 79.5561 64.6699 79.5367 64.6929C79.5205 64.7119 79.5207 64.7033 79.5612 64.6831C79.6425 64.6425 79.7968 64.6 80 64.6L80 63.4C79.6509 63.4 79.3052 63.4695 79.0246 63.6098C78.7798 63.7322 78.4 64.0122 78.4 64.5L79.6 64.5ZM80 64.6C80.2032 64.6 80.3575 64.6425 80.4388 64.6831C80.4793 64.7033 80.4795 64.7119 80.4633 64.6929C80.4439 64.6699 80.4 64.6031 80.4 64.5L81.6 64.5C81.6 64.0122 81.2202 63.7322 80.9754 63.6098C80.6948 63.4695 80.3491 63.4 80 63.4L80 64.6ZM80.4 64.5C80.4 64.3969 80.4439 64.3301 80.4633 64.3071C80.4795 64.2881 80.4793 64.2967 80.4388 64.3169C80.3575 64.3575 80.2032 64.4 80 64.4L80 65.6C80.3491 65.6 80.6948 65.5305 80.9754 65.3902C81.2202 65.2678 81.6 64.9878 81.6 64.5L80.4 64.5Z" fill="white" mask="url(#path-7-inside-1_1768_107976)" />
            <defs>
                <linearGradient id="paint0_linear_1768_107976" x1="81.0001" y1="78.7197" x2="80.4969" y2="96.9453" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.883491" stop-color="#212835" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default WheezeFront
import { FC } from "react";
import clsx from "clsx";

import styles from "./athlete-selection.module.scss";
import { TopMenu } from "components/top-menu/top-menu";
import { CoachInfo } from "components/coach-info/coach-info";
import { AthleteSearch } from "pages/athlete-selection/athlete-search/athlete-search";
import { AtheleteGroupItems } from "pages/group/const";

type Props = {
  hideBottom?: boolean;
  athleteCount: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  input: string;
  hideMenuItems?: boolean;
};

export const AthleteSelectionHeader: FC<Props> = ({
  hideBottom,
  athleteCount,
  handleChange,
  input,
  hideMenuItems,
}) => {
  return (
    <>
      <div className={clsx(styles.athleteSelectionHeader, hideBottom && styles.hideBottom)}>
        <div className={styles.athleteLeft}>
          <span className={styles.athleteText}>All Athletes</span>
          <span className={styles.athleteCount}>{athleteCount}</span>
        </div>
        <CoachInfo className={styles.coachButton} />
        <div className={styles.search}>
          <AthleteSearch input={input} handleChange={handleChange} />
        </div>
      </div>
      {!input && <TopMenu menuItems={!hideMenuItems ? AtheleteGroupItems : []} />}
    </>
  );
};

import React, { FC } from "react";
import clsx from "clsx";

import styles from "./primary-button.module.scss";

type Props = {
  className?: string;
  onClick: () => void;
  title?: string;
  disabled?: boolean;
  icon?: React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  };
  children?: React.ReactNode;
};

export const PrimaryButton: FC<Props> = ({ className, onClick, title, disabled = false, icon, children }) => {
  return (
    <button
      type="button"
      className={clsx(styles.button, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {icon}
      {title}
    </button>
  );
};

import { set } from 'lodash';
import React, { useState, useEffect } from 'react';

interface StreamingTextProps {
    text: string;
    speed?: number;
    setStreamingCompleted: (value: boolean) => void;
}

const StreamingText: React.FC<StreamingTextProps> = ({ text, speed = 40, setStreamingCompleted }) => {
    // State to hold the current visible portion of the text
    const [displayedText, setDisplayedText] = useState<string>('');

    useEffect(() => {
        setDisplayedText(''); // Clear displayed text when text changes
        let index = 0;

        // Function to update the displayed text incrementally
        const typeText = () => {
            if (index < text.length) {
                setDisplayedText((prev) => prev + text[index]);
                index++;
            } else {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                clearInterval(interval); // Clear interval when done
            }
        };

        // Set interval to call typeText function at a specified speed
        const interval = setInterval(typeText, speed);

        return () => clearInterval(interval);
    }, [text, speed]);

    if (displayedText === text) {
        // pass streamingCompleted as prop to parent component
        setStreamingCompleted(true);
    }

    return <p>{displayedText}</p>;
};

export default StreamingText;

import { axiosInstance } from "axios-instance/axios-instance";
import { ChartColor } from "components/charts/types";
import { SeriesOption } from "echarts";
import { ToolTipDataType, UnitsType } from "enums";
import { APIResponse, SeriesValue } from "types";

export interface MMPPoints {
  fresh: number;
  "2000Kj": number;
  "3000Kj": number;
}

export interface MMPWorkPoints {
  freshPower: number;
  "freshKj/KG": number;
  "2000Kj/KG": number;
  "2000KjPower": number;
  "3000Kj/KG": number;
  "3000KjPower": number;
}

export interface MinutesMMP {
  value: number;
}

export interface TssValue {
  value: number;
}

export interface ChartValue {
  value: number;
}
export interface IntensityFactorValue {
  value: number;
}

export interface BarScatterModel {
  units?: {
    type: UnitsType;
    value: string | React.ReactNode;
  };
  dataType?: ToolTipDataType;
  colorRange?: {
    color: string;
    range: [number, number];
    title: string;
  }[];
  showMainCircle?: boolean;
  borderColor?: ChartColor;
  values: SeriesValue[];
  name: string;
  color: string;
  areaColor?: [string, string];
  chartType: string;
  hideInLegend: boolean;
  yAxisIndex?: number;
  min?: number;
}

export interface MaxPoint {
  oneMinutesMMPs: MMPPoints;
  twoMinutesMMPs: MMPPoints;
  fiveMinutesMMPs: MMPPoints;
  tenMinutesMMPs: MMPPoints;
  fifteenMinutesMMPs: MMPPoints;
  twentyMinutesMMPs: MMPPoints;
}

export interface KjKGToPowerPoints {
  oneMinutesMMPs: MMPWorkPoints;
  twoMinutesMMPs: MMPWorkPoints;
  fiveMinutesMMPs: MMPWorkPoints;
  tenMinutesMMPs: MMPWorkPoints;
  fifteenMinutesMMPs: MMPWorkPoints;
  twentyMinutesMMPs: MMPWorkPoints;
}

export interface APIChartResponse {
  athleteIds?: number[];
  startDate: string;
  endDate: string;
  points: {
    startDate: string;
    endDate: string;
    data: {
      [key: string]: number;
    };
  }[];
  average?: {
    [key: string]: number;
  };
  source?: {
    hrv: string;
    rhr: string;
  };
  maxPoint: MaxPoint;
  kjKGToPowerPoints: KjKGToPowerPoints;
  kjKGToPowerRelativeToFreshPoints: KjKGToPowerPoints;
  relativeToFreshPoints: MaxPoint;
}

export interface FatigureResistanceType extends APIChartResponse {
  dataPoints?: number[];
  relativeDataPoints?: number[];
  athleteWeight?: number;
  dataPointsAfter2kKJ?: number[];
  dataPointsAfter3kKJ?: number[];
  percentageDecline2kKJ?: number[];
  percentageDecline3kKJ?: number[];
  relativeDataPointsAfter2kKJ?: number[];
  relativeDataPointsAfter3kKJ?: number[];

  dataPointsAfter1900KJ?: number[];
  dataPointsAfter2800KJ?: number[];
  percentageDecline1900KJ?: number[];
  percentageDecline2800KJ?: number[];
  relativeDataPointsAfter1900KJ?: number[];
  relativeDataPointsAfter2800KJ?: number[];
}

export interface APIWorkoutDetailsChartResponse {
  athleteId: number;
  startDate: string;
  endDate: string;
  durationStr: string;
  durationInHour: number;
  distanceInKm: number;
  tss: number;
  climbInMeter: number;
  workInKj: number;
  intensityFactor: number;
}

export interface APICriticalPowerWChartResponse {
  athleteId: number;
  cp: number;
  w: number;
  athleteWeight: number;
}

export interface APIMenstrualChartResponse {
  data: {
    athleteId: number;
    startDate: string;
    cycleLast: number;
    somatic: {
      [key: string]: number;
    };
    gastronintestinal: {
      [key: string]: number;
    };
    mood: {
      [key: string]: number;
    };
  }[];
}

export interface APIBoundedChartResponse extends APIChartResponse {
  variances: {
    startDate: string;
    endDate: string;
    data: {
      [key: string]: SeriesValue;
    };
  }[];
}

const getChart = async <T>(url: string): Promise<T | undefined> => {
  const { data }: APIResponse<T> = await axiosInstance.get(url);
  return data;
};

export type SeriesItem = {
  color: string;
  fillColor?: string;
  custom?: {
    legendName?: string;
  };
  data: (number | null)[];
  id?: number | string;
  lineType?: "line" | "spline";
  lineStyle?: "Dash" | "Solid";
  linkedTo?: string;
  name: string;
  selected?: boolean;
  stack?: number | string;
  yAxis?: number;
  visible?: boolean;
  marker?: SeriesOption;
};

export type ChartData = {
  categories: string[];
  series: SeriesItem[];
  minValue?: number;
  maxValue?: number;
};

export const chart = { getChart };

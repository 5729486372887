import React, { FC } from "react";

import { ChartData } from "types";
import { useSelector } from "hooks/app-hooks";
import { getCurrentAthleteName } from "store/slices/shared";

import { LineChartSeries, LineChartWithHeader } from "components/charts/line-chart/line-chart";
import { Loading } from "components/loading/loading";
import { COLOR_GREEN, COLOR_LIGHT_GREEN } from "components/charts/colors";

import styles from "./menstrual-form.module.scss";
import { ChartSize } from "components/charts/constants";

type Props = {
  data?: ChartData;
  name: string
};

export const MenstrualForm: FC<Props> = ({ data, name }) => {
  const currentAthleteName = useSelector(getCurrentAthleteName);

  const labels = data?.labels || [];

  const formatVariableName = (variableName: string) => {
    // Split camel case
    const words = variableName.replace(/([a-z])([A-Z])/g, '$1 $2');
    // Capitalize each word
    return words.charAt(0).toUpperCase() + words.slice(1);
  };

  const getSeries = ({ values, colors }: ChartData): LineChartSeries[] => {
    if (!values) {
      return [];
    }
    const dataKeys = Object.keys(values);

    return dataKeys.map((dataKey) => ({
      name: formatVariableName(dataKey),
      color: colors[dataKey] || COLOR_GREEN,
      shadowColor: colors[dataKey] || COLOR_LIGHT_GREEN,
      shadowBlur: 14,
      shadowOffsetY: 8,
      values: values[dataKey] || 0,
    }));
  };

  if (!data?.loaded) {
    return (
      <div className={styles.content}>
          <Loading />
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <LineChartWithHeader
        name={name}
        source="Coach, Athlete"
        type={ChartSize.Big}
        series={getSeries(data)}
        labels={labels}
      />
    </div>
  );
};

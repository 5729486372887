import React, { FC, useEffect, useMemo } from "react";

import { ChartMeta } from "types";
import { DateRange } from "enums";
import { calculateDateRange, formatDate, formatDateAPI, parseDate } from "utils";
import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getCurrentAthlete,
  getCurrentComparison,
  getCurrentDateRange,
} from "store/slices/shared";
import { fetchChartData } from "store/slices/chart";
import { APIChartResponse } from "api/chart";

import { Loading } from "components/loading/loading";
import { LineChartWithHeader } from "components/charts/line-chart/line-chart";
import { ChartSize } from "components/charts/constants";
import { PERFORMANCE_MGMT_COLORS } from "components/charts/colors";

import styles from "./training-load.module.scss";
import clsx from "clsx";

const getChartMeta = (
  name: string,
  dateRange: DateRange,
  athleteIds: number[]
): ChartMeta<APIChartResponse> => {
  const { startDate, endDate } = calculateDateRange(
    dateRange
  );
  return {
    api: {
      url: "/performance/trainingLoad/:fromDate/:toDate?&athleteId=:athleteId",
      params: {
        ":fromDate": formatDateAPI(startDate),
        ":toDate": formatDateAPI(endDate),
        ":athleteId": athleteIds.join("&athleteIds="),
      },
    },
    charts: [
      {
        name: `${name}TrainingLoad`,
        getLabels: (data: APIChartResponse) =>
          data?.points?.map((point) => formatDate(parseDate(point.startDate))),
        getValues: (data: APIChartResponse) => ({
          atl: data?.points?.map((item) => ({ value: item?.data?.atl })),
          ctl: data?.points?.map((item) => ({ value: item?.data?.ctl })),
          tsb: data?.points?.map((item) => ({ value: item?.data?.tsb })),
        }),
      },
    ],
  };
};

export const TrainingLoad: FC = () => {
  const dispatch = useDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentDateRange = useSelector(getCurrentDateRange);
  const currentComparison = useSelector(getCurrentComparison);
  const dateRange = useSelector(getCurrentDateRange);

  useEffect(() => {
    if (currentAthlete) {
      dispatch(
        fetchChartData<APIChartResponse>(
          getChartMeta("athlete", currentDateRange, [currentAthlete.id])
        )
      );
    }
  }, [dispatch, currentDateRange, currentAthlete]);

  useEffect(() => {
    if (currentComparison) {
      dispatch(
        fetchChartData<APIChartResponse>(
          getChartMeta("comparison", currentDateRange, currentComparison)
        )
      );
    }
  }, [dispatch, currentDateRange, currentComparison]);

  useEffect(() => {
    if (currentDateRange === DateRange.Year) {
      if (currentAthlete) {
        dispatch(
          fetchChartData<APIChartResponse>(
            getChartMeta("athleteYear", DateRange.FourDays, [currentAthlete.id])
          )
        );
      }
    }
  }, [currentDateRange, dispatch, currentAthlete]);

  const trainingLoad = useSelector((state) => state.chart.athleteTrainingLoad);
  const yearTrainingLoad = useSelector((state) => state.chart.athleteYearTrainingLoad);
  const trainingLoadComparison = useSelector((state) => state.chart.comparisonTrainingLoad);

  const roundDownToNearest50 = (num: number) => {
    return Math.floor(num / 50) * 50;
  };

  const todaysPerformance: {
    tsbLow?: boolean;
    tsbHigh?: boolean;
    atl: string;
    ctl: string;
    tsb: string;
  } = useMemo(() => {
    const getLastValue = (values: { value: number }[] | undefined): string => {
      if (values && values.length > 0) {
        return values[values.length - 1].value.toFixed(0);
      }
      return "0";
    };

    const getPerformance = (load: {
      values?: {
        atl?: { value: number }[];
        ctl?: { value: number }[];
        tsb?: { value: number }[];
      };
    }) => {
      const atl = getLastValue(load?.values?.atl);
      const ctl = getLastValue(load?.values?.ctl);
      const tsb = getLastValue(load?.values?.tsb);
      const tsbValue = parseFloat(tsb);

      const tsbLow = tsbValue <= -30;
      const tsbHigh = tsbValue >= 25;

      return {
        atl,
        ctl,
        tsb,
        tsbLow,
        tsbHigh,
      };
    };

    if (currentDateRange === DateRange.Year) {
      return getPerformance(yearTrainingLoad);
    }

    return getPerformance(trainingLoad);
  }, [trainingLoad, yearTrainingLoad, currentDateRange]);

  const minYAxis = useMemo(() => {
    const tsbValues = trainingLoad?.values?.tsb?.map((item) => item?.value) || [];
    const tsbComparisonValues = currentComparison
      ? trainingLoadComparison?.values?.tsb?.map((item) => item?.value) || []
      : [];
    const allValues = [...tsbValues, ...tsbComparisonValues];

    if (allValues?.length > 0) {
      const minValue = Math.min(...allValues);
      return roundDownToNearest50(minValue);
    } else {
      return 0;
    }
  }, [trainingLoad, trainingLoadComparison, currentComparison]);

  const series = [
    {
      name: `ATL`,
      width: 2,
      color: PERFORMANCE_MGMT_COLORS.ATL,
      values: trainingLoad?.values?.atl || [],
    },
    currentComparison
      ? {
          name: `ATL`,
          width: 2,
          color: PERFORMANCE_MGMT_COLORS.ATL,
          dashed: true,
          values: trainingLoadComparison?.values?.atl || [],
        }
      : undefined,
    {
      name: `CTL`,
      width: 2,
      color: PERFORMANCE_MGMT_COLORS.CTL,
      values: trainingLoad?.values?.ctl || [],
    },
    currentComparison
      ? {
          name: `CTL`,
          width: 2,
          color: PERFORMANCE_MGMT_COLORS.CTL,
          dashed: true,
          values: trainingLoadComparison?.values?.ctl || [],
        }
      : undefined,
    {
      name: `TSB`,
      width: 2,
      color: PERFORMANCE_MGMT_COLORS.TSB,
      origin: "start",
      areaColor: PERFORMANCE_MGMT_COLORS.TSB_GRADIENT,
      values: trainingLoad?.values?.tsb || [],
      showSubCircles: false,
      colorRange: [
        { color: "#F93939", range: [-Infinity, -30] as [number, number], title: "Overreaching" },
        { color: "#DEA754", range: [25, Infinity] as [number, number], title: "Detraining" },
      ],
    },
    currentComparison
      ? {
          name: `TSB`,
          width: 2,
          color: PERFORMANCE_MGMT_COLORS.TSB,
          dashed: true,
          values: trainingLoadComparison?.values?.tsb || [],
        }
      : undefined,
  ];

  if (!trainingLoad?.loaded || (currentComparison && !trainingLoadComparison?.loaded)) {
    return (
      <div className={styles.content}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <LineChartWithHeader
        type={ChartSize.Big}
        name="Performance Management Chart"
        source="AI"
        labels={trainingLoad?.labels || []}
        series={series}
        showZeroValues={true}
        hideDecimalPoints={true}
        showLegendInSourceBar={true}
        headerComponent={
          <div className={styles.headerContainer}>
            <div className={styles.bigTitle}>
              <span className={styles.bold}>Performance Management Chart</span>
            </div>
            <div className={styles.globalTooltip}>
              <span className={clsx(styles.tooltipText, styles.bold)}>Today: </span>
              <div className={styles.tooltipItem}>
                <span className={styles.tooltipText}>CTL</span>
                <span className={clsx(styles.tooltipValue, styles.bold)}>
                  {todaysPerformance.ctl}
                </span>
              </div>
              <div className={styles.tooltipItem}>
                <span className={styles.tooltipText}>ATL</span>
                <span className={clsx(styles.tooltipValue, styles.bold)}>
                  {todaysPerformance.atl}
                </span>
              </div>
              <div className={styles.tooltipItem}>
                <span className={styles.tooltipText}>TSB</span>
                <span
                  className={clsx(
                    styles.tooltipValue,
                    styles.bold,
                    todaysPerformance.tsbLow && styles.tsbLow,
                    todaysPerformance.tsbHigh && styles.tsbHigh
                  )}
                >
                  {todaysPerformance.tsb}
                </span>
              </div>
            </div>
          </div>
        }
        yAxis={[
          {
            min: minYAxis,
          },
        ]}
        titlePrefix={dateRange >= DateRange.Year ? "Week of" : undefined}
      />
    </div>
  );
};

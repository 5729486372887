import { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import Calendar, { CalendarTileProperties } from "react-calendar";
import styles from "./recovery-popup.module.scss";
import { format } from "date-fns";
import { PrimaryButton } from "components/primary-button/primary-button";
import "./recovery-popup.overwrite.scss";
import { convertToUnixTimestamp, formatDatePower } from "utils";
import { markAsRecovered } from "api/medical-report";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedMedicalReportDetails } from "store/slices/health";
import { refreshAthleteList } from "store/slices/shared";

type Props = {
  onClose: () => void;
};

export interface FormState {
  [key: string]: string;
}

export interface ErrorsState {
  [key: string]: boolean;
}

export const RecoveryPopup: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedMedicalReportId = useSelector(
    (state: any) => state.health.selectedMedicalReportId
  );
  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const calendarRef = useRef(null);

  const handleDateChange = (value: Date): void => {
    setDateSelected(value);
  };

  const handleSubmitClick = async (): Promise<void> => {
    if (dateSelected) {
      const formattedDate = format(dateSelected, 'yyyy-MM-dd');
      const response = await markAsRecovered(selectedMedicalReportId, convertToUnixTimestamp(formattedDate))
      dispatch(fetchSelectedMedicalReportDetails(selectedMedicalReportId));
      dispatch(refreshAthleteList());
      onClose()
    }
  };

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>Recovery Date</h3>
            <Close
              className={styles.closeButton}
              onClick={onClose}
              aria-label="Close"
              title="Close"
            />
          </div>
          <div className={styles.athleteCardWrapper}>
            <div className={styles.calendar}>
              <div
                ref={calendarRef}
                className={clsx(
                  "current-date-calendar",
                  styles.calendarWrapper
                )}
              >
                <Calendar
                  onChange={handleDateChange}
                  value={dateSelected}
                  // maxDate={new Date()}
                  minDetail="month"
                  prev2Label={null}
                  next2Label={null}
                  formatShortWeekday={(locale, date) => format(date, "E")[0]}
                  formatMonthYear={(locale, date) => format(date, "MMM yyyy")}
                  minDate={
                    new Date(
                      new Date().getFullYear() - 3,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  }
                  locale="en"
                />
              </div>
            </div>
          </div>
          <div className={styles.date}>{dateSelected && format(dateSelected, 'MMM dd, yyyy')}</div>
          <div className={styles.footer}>
            <PrimaryButton
              className={styles.addButton}
              onClick={handleSubmitClick}
              title={"Confirm"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const IllnessChills = ({ opacity }: { opacity: number }) => {
  return (
    <svg width="160" height="410" viewBox="0 0 160 410" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={opacity}>
      <path d="M154.074 189.406L149.14 181.075C148.352 179.744 148.108 178.16 148.46 176.653L149.696 171.361C150.055 169.825 149.794 168.209 148.971 166.864L145.095 160.531C144.141 158.973 143.949 157.066 144.573 155.35L146.485 150.084C147.08 148.446 146.934 146.631 146.084 145.109L141.458 136.829C140.667 135.414 140.483 133.739 140.947 132.185L142.275 127.738C142.781 126.046 142.515 124.217 141.549 122.738L136.926 115.664" stroke="#397AF9" />
      <path d="M158.427 179.838L155.643 174.763C154.949 173.498 154.734 172.026 155.037 170.616L155.544 168.255C155.853 166.817 155.623 165.315 154.898 164.035L153.166 160.981C152.326 159.5 152.156 157.731 152.697 156.116L153.429 153.929C153.945 152.39 153.815 150.707 153.068 149.265L150.805 144.892C150.111 143.551 149.948 141.998 150.348 140.542L150.814 138.849C151.252 137.26 151.016 135.56 150.163 134.15L147.651 129.999" stroke="#397AF9" />
      <path d="M23.2787 115.693L18.2985 123.997C17.5028 125.323 17.2503 126.906 17.5938 128.415L18.8005 133.714C19.1507 135.252 18.8812 136.866 18.0503 138.207L14.1388 144.519C13.1769 146.071 12.9742 147.977 13.5881 149.697L15.4716 154.973C16.0575 156.614 15.9009 158.429 15.0425 159.945L10.3708 168.199C9.57221 169.61 9.37862 171.284 9.83414 172.84L11.1381 177.295C11.6343 178.99 11.3584 180.817 10.384 182.291L5.72156 189.339" stroke="#397AF9" />
      <path d="M12.7761 129.999L9.99187 135.074C9.29811 136.339 9.08308 137.811 9.38616 139.221L9.89358 141.582C10.2027 143.02 9.97262 144.522 9.24701 145.802L7.51572 148.855C6.67575 150.337 6.50496 152.106 7.04594 153.721L7.77853 155.907C8.29425 157.447 8.16407 159.13 7.41772 160.572L5.15411 164.945C4.45988 166.286 4.29692 167.839 4.69777 169.295L5.16375 170.988C5.60134 172.577 5.36563 174.277 4.51198 175.687L1.9999 179.838" stroke="#397AF9" />
      <path d="M113.127 376.397L111.06 366.937C110.729 365.426 110.996 363.845 111.803 362.526L114.639 357.889C115.462 356.543 115.722 354.928 115.363 353.392L113.672 346.162C113.256 344.383 113.673 342.513 114.804 341.079L118.274 336.681C119.354 335.313 119.785 333.543 119.456 331.832L117.665 322.518C117.359 320.926 117.71 319.277 118.639 317.948L121.297 314.144C122.309 312.696 122.631 310.876 122.178 309.169L120.011 301" stroke="#397AF9" />
      <path d="M119.317 367.846L118.261 362.155C117.997 360.737 118.254 359.271 118.983 358.027L120.204 355.943C120.948 354.674 121.199 353.176 120.911 351.733L120.222 348.291C119.888 346.621 120.28 344.888 121.299 343.523L122.679 341.675C123.651 340.375 124.054 338.736 123.796 337.133L123.015 332.271C122.775 330.78 123.107 329.253 123.943 327.996L124.916 326.534C125.829 325.162 126.137 323.474 125.767 321.867L124.681 317.139" stroke="#397AF9" />
      <path d="M47.1506 376.397L49.2178 366.937C49.5481 365.426 49.2818 363.845 48.4745 362.526L45.6384 357.889C44.8153 356.543 44.5552 354.928 44.9143 353.392L46.6051 346.162C47.0209 344.383 46.6043 342.513 45.4732 341.079L42.0032 336.681C40.9238 335.313 40.4926 333.543 40.8216 331.832L42.6124 322.518C42.9185 320.926 42.5672 319.277 41.6386 317.948L38.9803 314.144C37.9687 312.696 37.6463 310.876 38.0993 309.169L40.2663 301" stroke="#397AF9" />
      <path d="M40.9608 367.846L42.0168 362.155C42.2799 360.737 42.0233 359.271 41.2941 358.027L40.0733 355.943C39.3295 354.674 39.078 353.176 39.3666 351.733L40.0551 348.291C40.3892 346.621 39.9978 344.888 38.9786 343.523L37.5983 341.675C36.6267 340.375 36.2236 338.736 36.4812 337.133L37.2624 332.271C37.502 330.78 37.1705 329.253 36.334 327.996L35.3617 326.534C34.4486 325.162 34.1406 323.474 34.5099 321.867L35.5967 317.139" stroke="#397AF9" />
      <path d="M49.8 34L48.3001 35.2757C47.3752 36.0623 47.2973 37.463 48.1291 38.3473V38.3473C49.0121 39.2861 48.8618 40.7887 47.8104 41.5339L47.1934 41.9712C46.1458 42.7137 46.184 44.281 47.2666 44.9716L47.4106 45.0635C48.4945 45.7549 48.453 47.3514 47.3346 47.9855V47.9855C46.2478 48.6018 46.1703 50.1387 47.1896 50.8612L47.972 51.4157C48.92 52.0876 49.0207 53.457 48.1811 54.2603V54.2603C47.3542 55.0515 47.4374 56.3964 48.3555 57.0797L48.4655 57.1615C49.2357 57.7347 49.4789 58.7805 49.0406 59.6347L49.0214 59.6723C48.4346 60.8159 49.0543 62.209 50.2964 62.5392L50.6266 62.6269C51.9098 62.968 52.6349 64.3251 52.2052 65.5813L51.72 67" stroke="#397AF9" />
      <path d="M42.6 38L41.4764 38.9653C40.7799 39.5637 40.721 40.6213 41.3468 41.2934V41.2934C42.0113 42.007 41.8976 43.142 41.1047 43.7097L40.6443 44.0393C39.8555 44.6041 39.8844 45.7863 40.6999 46.3118L40.8088 46.382C41.6253 46.9081 41.5939 48.1123 40.751 48.5951V48.5951C39.9321 49.0641 39.8734 50.2232 40.6407 50.7726L41.227 51.1923C41.9416 51.7039 42.0178 52.738 41.3858 53.3487V53.3487C40.7635 53.9503 40.8264 54.9657 41.5182 55.4858L41.5986 55.5462C42.1778 55.9816 42.3608 56.7699 42.0326 57.416L42.0168 57.4471C41.5753 58.3165 42.0456 59.3718 42.9873 59.6246L43.2189 59.6868C44.1835 59.9457 44.7289 60.9657 44.4085 61.9117L44.04 63" stroke="#397AF9" />
      <path d="M109.2 34L110.7 35.2757C111.625 36.0623 111.703 37.463 110.871 38.3473V38.3473C109.988 39.2861 110.138 40.7887 111.19 41.5339L111.807 41.9712C112.854 42.7137 112.816 44.281 111.733 44.9716L111.589 45.0635C110.505 45.7549 110.547 47.3514 111.665 47.9855V47.9855C112.752 48.6018 112.83 50.1387 111.81 50.8612L111.028 51.4157C110.08 52.0876 109.979 53.457 110.819 54.2603V54.2603C111.646 55.0515 111.563 56.3964 110.644 57.0797L110.535 57.1615C109.764 57.7347 109.521 58.7805 109.959 59.6347L109.979 59.6723C110.565 60.8159 109.946 62.209 108.704 62.5392L108.373 62.6269C107.09 62.968 106.365 64.3251 106.795 65.5813L107.28 67" stroke="#397AF9" />
      <path d="M116.4 38L117.524 38.9653C118.22 39.5637 118.279 40.6213 117.653 41.2934V41.2934C116.989 42.007 117.102 43.142 117.895 43.7097L118.356 44.0393C119.145 44.6041 119.116 45.7863 118.3 46.3118L118.191 46.382C117.375 46.9081 117.406 48.1123 118.249 48.5951V48.5951C119.068 49.0641 119.127 50.2232 118.359 50.7726L117.773 51.1923C117.058 51.7039 116.982 52.738 117.614 53.3487V53.3487C118.237 53.9503 118.174 54.9657 117.482 55.4858L117.401 55.5462C116.822 55.9816 116.639 56.7699 116.967 57.416L116.983 57.4471C117.425 58.3165 116.954 59.3718 116.013 59.6246L115.781 59.6868C114.816 59.9457 114.271 60.9657 114.591 61.9117L114.96 63" stroke="#397AF9" />
    </svg>

  )
}

export default IllnessChills
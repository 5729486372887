import { FC } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import { WorkOptions } from "enums";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentWorkOption, setCurrentWorkOption } from "store/slices/shared";

import styles from "./work-selector.module.scss";
import "./work-selector.overwrite.scss";

interface SelectorOption {
  label: string;
  value: WorkOptions;
}

interface Props {
  small?: boolean;
}

export const WorkSelector: FC<Props> = ({ small }) => {
  const dispatch = useDispatch();
  const currentWorkOption = useSelector(getCurrentWorkOption) || WorkOptions.kJ2000;
  const athleteFatigueResistance = useSelector((state) => state.chart.athleteFatigueResistance);

  const generateOptions = () => {
    const options: SelectorOption[] = [];
    if (athleteFatigueResistance?.values?.dataPointsAfter2kKJ?.length > 0) {
      options.push({
        label: "kJ: 2000",
        value: WorkOptions.kJ2000,
      });
    }
    if (athleteFatigueResistance?.values?.dataPointsAfter3kKJ?.length > 0) {
      options.push({
        label: "kJ: 3000",
        value: WorkOptions.kJ3000,
      });
    }
    if (athleteFatigueResistance?.values?.dataPointsAfter1900KJ?.length > 0) {
      options.push({
        label: "kJ: 1900",
        value: WorkOptions.kJ1900,
      });
    }
    if (athleteFatigueResistance?.values?.dataPointsAfter2800KJ?.length > 0) {
      options.push({
        label: "kJ: 2800",
        value: WorkOptions.kJ2800,
      });
    }
    return options;
  };

  const options = generateOptions();

  const value: SelectorOption | undefined = options.find(
    (option) => option.value === currentWorkOption
  );

  const handleChange = (newOption: SelectorOption | null) => {
    if (newOption) {
      dispatch(setCurrentWorkOption(newOption.value));
    }
  };

  return (
    <div className={clsx(styles.wrapper, "work-by-selector")}>
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx("work__select", small && "work__select--small")}
        classNamePrefix="react-select"
      />
    </div>
  );
};

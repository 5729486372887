import { FC } from "react";
import clsx from "clsx";
import styles from "./source-bottom-bar.module.scss";
import { ChartSize } from "components/charts/constants";
import { Source } from "components/source/source";

type Props = {
  source?: string;
  className?: string;
  chartType?: ChartSize;
};

export const SourceBar: FC<Props> = ({ source, className, chartType, children }) => {
  return (
    <div
      className={clsx(
        chartType === ChartSize.Big ? styles.bigFooter : styles.smallFooter,
        styles.scoreBarLegend,
        className
      )}
    >
      <Source source={source} />
      {children}
    </div>
  );
};

import { Menubar } from "components/menubar/menubar";
import React, { useEffect } from "react";
import styles from "./team-dashboard.module.scss";
import { TeamDashboardHeader } from "components/team-dashboard-header/team-dashboard-header";
import { TeamPerformanceBarChart } from "./team-performance-chart/team-performance-chart";
import { TeamWorkoutDetails } from "./team-workout-details/team-workout-details";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { fetchAllGroups, getAllGroups } from "store/slices/shared";
import { GroupSelector } from "components/group-selector/group-selector";
import { TeamCriticalPowerW } from "./team-critical-power-w/team-critical-power-w";

const TeamDashboard = () => {

  const dispatch = useDispatch();
  const groups = useSelector(getAllGroups);
  useEffect(() => {
    dispatch(fetchAllGroups());
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <Menubar />
      <div className={styles.page}>
        <TeamDashboardHeader />
        {groups && groups?.length > 0 && <GroupSelector groups={groups}/> }
        <div className={styles.pageContent}>
          <TeamPerformanceBarChart />
          <TeamWorkoutDetails />
          <TeamCriticalPowerW />
        </div>
      </div>
    </div>
  );
};

export default TeamDashboard;

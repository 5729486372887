import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";
import styles from "./illness-popup.module.scss";
import Select, { ActionMeta } from "react-windowed-select";
import "../physiotherapy-popup/physiotherapy-popup.overwrite.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  convertEnumToOptions,
  convertToUnixTimestamp,
  convertUnixTimestampToDate,
  formatDateToYYYYMMDD,
} from "utils";
import { refreshAthleteList, getCurrentAthlete } from "store/slices/shared";
import { createMedicalReport, updateMedicalReport } from "api/medical-report";
import { BodyMap } from "components/body-map/body-map";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { fetchMedicalReports, fetchSelectedMedicalReportDetails } from "store/slices/health";

type Props = {
  onClose: () => void;
  openIllnessPopup?: boolean;
};

interface Option {
  label: string;
  value: string;
}

interface FormState {
  recordType: string;
  athleteId: number;
  severity: string;
  onsetDate: number;
  estimatedRecovery: number;
  side?: string;
  symptom?: string;
  illnessType?: string;
  ossicsCode?: string
  note:string
}

interface MedicalReportDetails {
  id: number;
  athleteId: number;
  recordType: string;
  bodyLocation: string;
  side: string;
  rehabilitationStage: string;
  type: string;
  illnessType: string;
  assessmentTools: string;
  modalityUsed: string;
  onsetDate: number;
  estimatedRecovery: number;
  symptom: string;
  ossicsCode: string;
  severity: string;
  area: string;
  createdBy: string;
  recovered: boolean;
  note:string
}

export const Illnesspopup: FC<Props> = ({
  onClose,
  openIllnessPopup = false,
}) => {
  const dispatch = useDispatch();
  const selectedMedicalReportId = useSelector(
    (state: any) => state.health.selectedMedicalReportId
  );

  const selectedMedicalReport: MedicalReportDetails = useSelector(
    (state: any) => state?.health?.selectedMedicalReport
  );
  const currentAthlete = useSelector(getCurrentAthlete);
  const healthEnums = useSelector((state: any) => state.health.healthEnums);
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [form, setForm] = useState<FormState>({
    recordType: "ILLNESS",
    athleteId: currentAthlete?.id || 0,
    onsetDate: Math.floor(Date.now() / 1000),
    estimatedRecovery: Math.floor(
      (Date.now() + 7 * 24 * 60 * 60 * 1000) / 1000
    ),
    severity: "MILD",
    side: "",
    symptom: "",
    illnessType: "",
    ossicsCode: "",
    note:""
  });


  const illnessTypeOptions: Option[] = useMemo(
    () => convertEnumToOptions(healthEnums?.illnessType || {}),
    [healthEnums?.illnessType]
  );


  const symptomOptions: Option[] = useMemo(
    () => convertEnumToOptions(healthEnums?.symptom || {}),
    [healthEnums?.symptom]
  );

  const severityOptions: Option[] = useMemo(
    () => convertEnumToOptions(healthEnums?.severity || {}),
    [healthEnums?.severity]
  );

  const ossicsCodeOptions: Option[] = useMemo(
    () => convertEnumToOptions(healthEnums?.ossicsCode || {}),
    [healthEnums?.ossicsCode]
  );

  // On change input of Select type
  const handleInputChange = (e: any, actionMeta: ActionMeta<Option>) => {
    const { name } = actionMeta
    const optionFinders: Record<string, Option[]> = {
      symptom: symptomOptions,
      ossicsCode: ossicsCodeOptions,
      illnessType: illnessTypeOptions
    };
    setForm({
      ...form,
      [actionMeta.name as keyof Option]: e.value,
    });

    if (name && optionFinders[name]) {
      setSelectedOption({
        ...selectedOption,
        [name]: optionFinders[name].find((option) => option.value === e.value),
      });
    }

  };

  // On change of date
  const handleDateChange = (name: string) => (dates: Date[]) => {
    const selectedDate = dates[0];
    const timestamp = convertToUnixTimestamp(
      formatDateToYYYYMMDD(selectedDate)
    );
    setForm((prevForm) => ({
      ...prevForm,
      [name]: timestamp,
    }));
    setSelectedOption({
      ...selectedOption,
      [name]: convertUnixTimestampToDate(timestamp),
    });
  };

  // On change input of radio and textArea type
  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setSelectedOption({
      ...selectedOption,
      [e.target.name]: e.target.value,
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitClick = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    let isMounted = true;
    // remove the side field if it is not required
    if (form.side === "") {
      delete form.side;
    }
    if (form.symptom === "") {
      delete form.symptom;
    }
    if (form.illnessType === "") {
      delete form.illnessType;
    }
    if (form.ossicsCode === "") {
      delete form.ossicsCode;
    }

    let response;
    try {
      if (!openIllnessPopup) {
        response = await createMedicalReport(form);
        if (isMounted) {
          dispatch(fetchMedicalReports(0));
          dispatch(refreshAthleteList());
        }
      } else {
        response = await updateMedicalReport(selectedMedicalReportId, form);
        if (isMounted) {
          dispatch(fetchSelectedMedicalReportDetails(selectedMedicalReportId));
          dispatch(refreshAthleteList());
        }
      }

      if (response && isMounted) {
        onClose();
      }
    } finally {
      if (isMounted) {
        setIsSubmitting(false);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  // to set previously selected value in setSelectedOption
  useEffect(() => {
    if (openIllnessPopup) {
      setSelectedOption({
        symptom: symptomOptions?.find(
          (option) => option?.value === selectedMedicalReport?.symptom
        ),

        severity: selectedMedicalReport?.severity,
        illnessType: illnessTypeOptions?.find(
          (option) => option?.value === selectedMedicalReport?.illnessType
        ),
        ossicsCode: ossicsCodeOptions?.find(
          (option) => option?.value === selectedMedicalReport?.ossicsCode
        ),
        onsetDate: convertUnixTimestampToDate(selectedMedicalReport?.onsetDate),
        estimatedRecovery: convertUnixTimestampToDate(
          selectedMedicalReport?.estimatedRecovery
        ),
        note:selectedMedicalReport?.note
      });
    }
  }, [
    selectedMedicalReport,
    symptomOptions,
    openIllnessPopup,
    ossicsCodeOptions,
    illnessTypeOptions,
  ]);

  // to set initiall value of form if edit popup is open
  useEffect(() => {
    if (openIllnessPopup && selectedOption) {
      setForm({
        recordType: "ILLNESS",
        athleteId: currentAthlete?.id || 0,
        symptom: selectedOption?.symptom?.value,
        severity: selectedOption?.severity,
        illnessType: selectedOption?.illnessType?.value,
        ossicsCode: selectedOption?.ossicsCode?.value,
        onsetDate: selectedOption?.onsetDate && convertToUnixTimestamp(selectedOption?.onsetDate),
        estimatedRecovery: selectedOption?.estimatedRecovery && convertToUnixTimestamp(selectedOption?.estimatedRecovery),
        note:selectedOption?.note
      })
    }
  }, [openIllnessPopup, selectedOption, currentAthlete])

  useEffect(() => {
  }, [selectedOption]);

  useEffect(() => {
  }, [form]);


  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Illness</h3>
          <Close
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
            title="Close"
          />
        </div>


        <div className={styles.popupDataContainer}>
          <div className={styles.formContainer}>
            <form>
              <div className={clsx(styles.formGroup, styles.selectBox)}>
                <label>Type</label>
                <Select
                  isClearable={false}
                  isSearchable={false}
                  options={illnessTypeOptions}
                  name="illnessType"
                  placeholder="Select Type"
                  onChange={handleInputChange}
                  value={selectedOption?.illnessType}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              <div className={styles.formGroup}>
                <label>Symptom</label>

                <Select
                  isClearable={false}
                  isSearchable={false}
                  options={symptomOptions}
                  placeholder="Select Symptom"
                  name="symptom"
                  value={selectedOption?.symptom}
                  onChange={handleInputChange}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              <div className={styles.formGroup}>
                <label>Severity</label>

                <div className={clsx(styles["tw-toggle"], styles.radiobox)}>
                  {severityOptions?.map((option) => {
                    return (
                      <>
                        <input
                          type="radio"
                          name="severity"
                          value={option?.value}
                          id={option.label}
                          onChange={handleChange}
                          checked={
                            selectedOption?.severity
                              ? selectedOption.severity === option.value
                              : form.severity === option.value
                          }
                        />
                        <label
                          className={`${styles.toggle} ${styles["toggle-yes"]}`}
                          htmlFor={option.label}
                        >
                          {option.label}
                        </label>
                      </>
                    );
                  })}
                </div>
              </div>

              <div className={styles.separator}></div>
              <p className={styles.codePara}>
                Select an illness by choosing an OSSICS code or by searching
                for it using the input below
              </p>

              <div className={styles.formGroup}>
                <label>OSSICS Code</label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  options={ossicsCodeOptions}
                  placeholder="Search for a code"
                  name="ossicsCode"
                  onChange={handleInputChange}
                  value={selectedOption?.ossicsCode || null}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox
                  )}
                  classNamePrefix="react-select"
                />
              </div>
              <div className={styles.separator}></div>

              <div className={clsx(styles.formGroup, styles.selectBox)}>
                <label>Onset Date</label>

                <Flatpickr
                  // id="onsetDate"
                  name="onsetDate"
                  value={selectedOption?.onsetDate || new Date()}
                  onChange={handleDateChange("onsetDate")}
                  options={{
                    dateFormat: "Y-m-d", locale: {
                      weekdays: {
                        shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                        longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                      },
                      months: {
                        shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        longhand: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                      },
                      firstDayOfWeek: 1,
                    },
                    shorthandCurrentMonth: true
                  }} className={clsx(styles.illnessDate, styles.selectBox)}
                />
              </div >

              <div className={styles.formGroup}>
                <label>Estimated Recovery Date</label>

                <Flatpickr
                  id="estimatedRecovery"
                  name="estimatedRecovery"
                  value={selectedOption?.estimatedRecovery || new Date()}
                  onChange={handleDateChange("estimatedRecovery")}
                  options={{
                    dateFormat: "Y-m-d", locale: {
                      weekdays: {
                        shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                        longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                      },
                      months: {
                        shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        longhand: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                      },
                      firstDayOfWeek: 1,
                    },
                    shorthandCurrentMonth: true
                  }} className={clsx(styles.illnessDate, styles.selectBox)}
                />
              </div >

              <div className={styles.formGroup}>
                <label>Note</label>
                <textarea
                  placeholder="Note"
                  name="note"
                  onChange={handleChange}
                  value={selectedOption?.note && selectedOption?.note}
                  className={styles.textArea}
                ></textarea>
                
              </div>
            </form >
          </div >

          < BodyMap
            recordType="ILLNESS"
            selectedSide={"BOTH"}
            selectedBodyLocation={form?.symptom ? form?.symptom : selectedOption?.symptom?.value}
            selectedArea={""}
            selectedSymptom={form?.symptom ? form?.symptom : selectedOption?.symptom?.value}
            selectedSeverity={form?.severity ? form?.severity : selectedOption?.severity}
          />
        </div >


        <div className={styles.footer}>
          <PrimaryButton
            className={styles.exportButton}
            disabled={isSubmitting}
            onClick={handleSubmitClick}
            title={"Save"}
          />
        </div>
      </div >
    </div >
  );
};

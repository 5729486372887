import { trackError } from "appInsights/logInsights";
import { axiosInstance } from "axios-instance/axios-instance";
import { formatDatePower } from "utils";

interface PerformanceResponse {
	status?: string;
	timestamp?: string;
	message?: string;
	debugMessage?: string;
	code?: number;
	data?: any;
}

export const postTargetPowerSFTData = async (
	athleteId: number,
	targetPower: number,
): Promise<PerformanceResponse | null> => {

	const requestBody = {
		athleteId,
		targetPower,
		updatedAt: formatDatePower(new Date()),
	};

	try {
		const response = await axiosInstance.post<PerformanceResponse>(
			`/coach/input/targetPowerOfSFT`,
			requestBody,
			{}
		);
		return response.data || null;
	} catch (error: any) {
		trackError(error as Error, { location: "postTargetPowerSFTData" });
		console.error("Request failed:", error.response ? error.response.data : error);
		throw error;
	}
};

export const postDateOfNextSFTData = async (
	athleteId: number,
	dateOfNextSft: string,
): Promise<PerformanceResponse | null> => {

	const requestBody = {
		athleteId,
		dateOfNextSft,
		updatedAt: formatDatePower(new Date()),
	};

	try {
		const response = await axiosInstance.post<PerformanceResponse>(
			`/coach/input/dateOfNextSft`,
			requestBody,
			{}
		);
		return response.data || null;
	} catch (error: any) {
		trackError(error as Error, { location: "postDateOfNextSFTData" });
		console.error("Request failed:", error.response ? error.response.data : error);

		throw error;
	}
};

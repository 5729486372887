import React, { FC } from "react";
import clsx from "clsx";

import styles from "./dropdown-profile.module.scss";

type Props = {
  className?: string;
  icon?: React.ReactNode;
  handleClick: (event?: any) => Promise<void>;
  text: string;
};

export const DropdownMenuItemComponent: FC<Props> = ({ className, icon, handleClick, text }) => {
  return (
    <div className={styles.wrapper}>
      <button type="button" className={clsx(styles.button, className)} onClick={handleClick}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <span className={styles.text}>{text}</span>
      </button>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as HeartRate } from "images/heart-rate.svg";
import { ReactComponent as BarChart } from "images/bar-chart.svg";
import styles from "./top-menu.module.scss";
import { LayoutType } from "types/utils";
import { getAiGraphAllPinTabs } from "api/ai-graph";

const defaultMenuItems: LayoutType = [
  {
    to: "/performance",
    icon: BarChart,
    text: "Performance",
  },
  {
    to: "/wellness",
    icon: HeartRate,
    text: "Wellness",
  },
  {
    to: "/health",
    icon: BarChart,
    text: "Health",
  },
];

export const TopMenu = ({ menuItems = defaultMenuItems, pinTabs = false }) => {
  const [tabOption, setTabOptions] = useState<any>([]);

  // api call to get Tab options when pinTab is true
  useEffect(() => {
    if (pinTabs) {
      (async () => {
        const tabsResponse = await getAiGraphAllPinTabs("single_athlete");
        if (tabsResponse?.length > 0) {
          const newTabsOptions = tabsResponse.map((tabs: any) => ({
            to: `/pinned-ai-charts?tab_id=${tabs?.tab_id}`,
            icon: BarChart,
            text: tabs?.tab_name,
          }));
          setTabOptions([...defaultMenuItems, ...newTabsOptions]);
        }
      })();
    }
  }, [pinTabs]);

  return (
    <div className={styles.menubar}>
      {tabOption?.length > 0
        ? tabOption.map((item: any, index: any) => (
          <NavLink
            key={index}
            to={item.to}
            className={styles.link}
            activeClassName={styles.activeLink}
            isActive={(match, location) => {
              if (!match) return false;
              if (!item.to.includes('?')) {
                return match.isExact;
              }
              const searchParams = new URLSearchParams(location.search);
              const tabId = searchParams.get('tab_id');
              return item.to.includes(`tab_id=${tabId}`);
            }}
          >
            {/* {item.icon && <item.icon className={styles.icon} />} */}
            <span className={styles.linkText}>{item.text}</span>
          </NavLink>
        ))
        : menuItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.to}
            className={styles.link}
            activeClassName={styles.activeLink}
          >
            {/* {item.icon && <item.icon className={styles.icon} />} */}
            <span className={styles.linkText}>{item.text}</span>
          </NavLink>
        ))}
    </div>
  );
};

import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

import { useDispatch, useSelector } from "hooks/app-hooks";
import { AttentionPopup } from "components/session-expired-popup/attention-popup";
import { getAccount, getAuthState, setAuthState } from "store/slices/auth";
import { Profile, CoachProfile } from "types";
import { getProfile, getCoachProfile } from "api/profile";
import { fetchAllAthletes, setCoachProfile } from "store/slices/shared";
import { RoleType } from "enums";

type PrivateRouteProps = {
  children: ReactElement;
  path: string;
  exact?: boolean;
};

export const PrivateRoute = ({ children, path, exact = false }: PrivateRouteProps) => {
  const dispatch = useDispatch();
  const authorised = useSelector(getAuthState);
  const role = useSelector(getAccount);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [coachProfileInfo, setCoachProfileInfo] = useState<CoachProfile | null>(null);

  const getUserProfile = useCallback(async () => {
    const data = await getProfile();
    setProfile(data);
  }, []);

  const getCoachProfileData = useCallback(async () => {
    const data = await getCoachProfile();
    setCoachProfileInfo(data);
  }, []);

  useEffect(() => {
    if (!profile) {
      getUserProfile();
    }

    if (profile) {
      dispatch(fetchAllAthletes(profile));
    }
  }, [dispatch, profile, getUserProfile]);

  useEffect(() => {
    if (!coachProfileInfo && role === RoleType.coach) {
      getCoachProfileData();
    }

    if (coachProfileInfo) {
      dispatch(setCoachProfile(coachProfileInfo));
    }
  }, [dispatch, getCoachProfileData, coachProfileInfo, role]);

  return (
    <>
      {authorised === null ? (
        <AttentionPopup
          subtitle="Your session has expired, please login again."
          description="The page will be reloaded."
          onClose={() => dispatch(setAuthState(false))}
        />
      ) : (
        <Route
          path={path}
          render={({ location }) =>
            authorised ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/sign-in",
                  state: { from: location },
                }}
                exact={exact}
              />
            )
          }
        />
      )}
    </>
  );
};

import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";

import { getGroups } from "api/groups";
import { api } from "api/api";
import { RootState } from "store";
import {
  AnthropometryMeasurements,
  CoachFeedbackPopup,
  Athlete,
  CoachProfile,
  ComparisonInfo,
  Group,
  Profile,
  WeeklyReport,
} from "types";
import {
  DataTypeSelector,
  DateGroupBy,
  DateRange,
  MMPType,
  WeekReportRange,
  WorkOptions,
} from "enums";

import { LAST_UPDATE_DATE } from "components/current-date-calendar/current-date-calendar";
import { getAllAnthros } from "api/anthropometry";
import { getGroupReport } from "api/weekly-reports";
import { calculateDateRange } from "utils";
import { generateWeeklyOptions } from "pages/weekly-report/utils";

interface SharedState {
  toggleCommentPopup: CoachFeedbackPopup;
  fullWeeklyReportData: WeeklyReport[];
  wizardReportData: WeeklyReport[];
  filteredWeeklyReportData: WeeklyReport[];
  fullGroupReportData: WeeklyReport[];
  filteredGroupReportData: WeeklyReport[];
  selectedIds: number[] | null;
  currentDate: number;
  currentDateRange: DateRange;
  currentWeekRange: WeekReportRange;
  currentWorkOption: WorkOptions | null;
  currentMMPType: MMPType;
  currentDataType: DataTypeSelector;
  groupBy: DateGroupBy;
  selectedGroup: Group | null;
  selectedAthleteId?: Athlete["id"];
  selectedComparison: Athlete["id"][] | undefined;
  selectedPerformanceComparison: Athlete["id"][] | undefined;
  athleteList: Athlete[];
  anthroList: AnthropometryMeasurements[] | null;
  coachProfile: CoachProfile | null;
  groups: Group[] | undefined;
  comparisonData: ComparisonInfo | null;
  performanceComparisonData: ComparisonInfo | null;
}

const initialState: SharedState = {
  toggleCommentPopup: {
    toggle: false,
    wizardToggle: false,
  },
  wizardReportData: [],
  selectedIds: null,
  fullWeeklyReportData: [],
  filteredWeeklyReportData: [],
  fullGroupReportData: [],
  filteredGroupReportData: [],
  currentMMPType: MMPType.Absolute,
  currentWeekRange: generateWeeklyOptions()[1],
  currentWorkOption: null,
  groupBy: DateGroupBy.Day,
  currentDate: LAST_UPDATE_DATE,
  currentDateRange: DateRange.ThreeMonth,
  currentDataType: DataTypeSelector.ABSOLUTE,
  selectedAthleteId: undefined,
  selectedComparison: undefined,
  selectedPerformanceComparison: undefined,
  athleteList: [],
  anthroList: null,
  coachProfile: null,
  comparisonData: null,
  selectedGroup: null,
  groups: undefined,
  performanceComparisonData: null,
};

export const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setToggleCommentPopup: (
      state,
      action: PayloadAction<CoachFeedbackPopup>
    ) => {
      state.toggleCommentPopup = action.payload;
    },
    setSelectedIds: (state, action: PayloadAction<number[] | null>) => {
      state.selectedIds = action.payload;
    },
    setFullWeeklyReportData: (state, action: PayloadAction<WeeklyReport[]>) => {
      state.fullWeeklyReportData = action.payload;
    },
    setWizardReportData: (state, action: PayloadAction<WeeklyReport[]>) => {
      state.wizardReportData = action.payload;
    },
    setFilteredWeeklyReportData: (
      state,
      action: PayloadAction<WeeklyReport[]>
    ) => {
      state.filteredWeeklyReportData = action.payload;
    },
    setFullGroupReportData: (state, action: PayloadAction<WeeklyReport[]>) => {
      state.fullGroupReportData = action.payload;
    },
    setFilteredGroupReportData: (
      state,
      action: PayloadAction<WeeklyReport[]>
    ) => {
      state.filteredGroupReportData = action.payload;
    },
    setCurrentDate: (state, action: PayloadAction<number>) => {
      state.currentDate = action.payload;
    },
    setCurrentDateRange: (state, action: PayloadAction<DateRange>) => {
      state.currentDateRange = action.payload;
    },
    setCurrentWeekRange: (state, action: PayloadAction<WeekReportRange>) => {
      state.currentWeekRange = action.payload;
    },
    setCurrentMMPType: (state, action: PayloadAction<MMPType>) => {
      state.currentMMPType = action.payload;
    },
    setCurrentWorkOption: (state, action: PayloadAction<WorkOptions>) => {
      state.currentWorkOption = action.payload;
    },
    setCurrentDataType: (state, action: PayloadAction<DataTypeSelector>) => {
      state.currentDataType = action.payload;
    },
    setCurrentGroupBy: (state, action: PayloadAction<DateGroupBy>) => {
      state.groupBy = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<Group>) => {
      state.selectedGroup = action.payload;
    },
    setAthleteList: (state, action: PayloadAction<Athlete[]>) => {
      state.athleteList = action.payload;
    },
    setAnthroList: (
      state,
      action: PayloadAction<AnthropometryMeasurements[] | null>
    ) => {
      state.anthroList = action.payload;
    },
    setCoachProfile: (state, action: PayloadAction<CoachProfile | null>) => {
      state.coachProfile = action.payload;
    },
    setSelectedAthlete: (state, action: PayloadAction<number | undefined>) => {
      state.selectedAthleteId = action.payload;
    },
    setComparison: (state, action: PayloadAction<number[] | undefined>) => {
      state.selectedComparison = action.payload;
    },
    setAllGroups: (state, action: PayloadAction<Group[] | undefined>) => {
      state.groups = action.payload;
    },
    setPerformanceComparison: (
      state,
      action: PayloadAction<number[] | undefined>
    ) => {
      state.selectedPerformanceComparison = action.payload;
    },
    setComparisonData: (
      state,
      action: PayloadAction<ComparisonInfo | null>
    ) => {
      state.comparisonData = action.payload;
    },
    setPerformanceComparisonData: (
      state,
      action: PayloadAction<ComparisonInfo | null>
    ) => {
      state.performanceComparisonData = action.payload;
    },
  },
});

export const {
  setToggleCommentPopup,
  setSelectedIds,
  setFullWeeklyReportData,
  setWizardReportData,
  setFilteredWeeklyReportData,
  setFullGroupReportData,
  setFilteredGroupReportData,
  setCurrentDate,
  setCurrentDateRange,
  setCurrentWeekRange,
  setCurrentMMPType,
  setCurrentWorkOption,
  setSelectedGroup,
  setCurrentDataType,
  setCurrentGroupBy,
  setAthleteList,
  setAnthroList,
  setAllGroups,
  setSelectedAthlete,
  setCoachProfile,
  setComparison,
  setPerformanceComparison,
  setComparisonData,
  setPerformanceComparisonData,
} = sharedSlice.actions;

export const fetchAllAthletes =
  (profile?: Profile) => async (dispatch: Dispatch) => {
    const athletes = await api.athletes.getAthletes();
    const currentAthlete =
      (profile && profile.searchAthleteId) || athletes[0].id;
    dispatch(setAthleteList(athletes));
    if (athletes.length > 0) {
      dispatch(setSelectedAthlete(currentAthlete));
      const allAthleteGroup = {
        id: -1,
        name: "All Athletes",
        athleteIds: athletes?.map((athlete) => athlete.id) || [],
        description: "",
      };
      dispatch(setSelectedGroup(allAthleteGroup));
      const globalComparison = profile?.globalComparison;
      if (globalComparison && globalComparison.type === "athlete") {
        const data = athletes.find((item) => item.id === globalComparison.id);
        if (data) {
          dispatch(
            setComparisonData({
              type: "individual",
              id: globalComparison.id || 0,
              option: data || undefined,
            })
          );
          dispatch(setComparison([globalComparison.id]));
        }
      }
      if (globalComparison && globalComparison.type === "group") {
        const groups = await getGroups();
        const data = groups.find((item) => item.id === globalComparison.id);
        if (data) {
          dispatch(
            setComparisonData({
              name: data.name,
              type: "group",
              id: globalComparison.id || 0,
              group: data,
            })
          );
          dispatch(setComparison(data?.athleteIds || []));
        }
      }
    } else {
      dispatch(setSelectedAthlete(undefined));
    }
  };

  // fetchOnlySelectedAtheles
  export const refreshAthleteList = () => async (dispatch: Dispatch) => {
    const athletes = await api.athletes.getAthletes();
    dispatch(setAthleteList(athletes));
  };


export const fetchAllAnthro = (id: number) => async (dispatch: Dispatch) => {
  const anthroList = await getAllAnthros(id);
  dispatch(setAnthroList(anthroList));
};

export const fetchGroupReportData =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const { startDate, endDate } = calculateDateRange(
      getState().shared.currentDateRange
    );
    const group = getState().shared.selectedGroup;
    const response = await getGroupReport(startDate, endDate, group?.id || 0);
    const athleteList = getState().shared.athleteList;
    const result = response.map((report) => {
      const athleteData = athleteList.find(
        (athlete) => athlete.id === report.athleteId
      );
      return {
        ...report,
        athlete: athleteData,
      };
    });

    result.sort((a: WeeklyReport, b: WeeklyReport) => {
      if (a.athlete && b.athlete) {
        const athleteA = a.athlete as Athlete;
        const athleteB = b.athlete as Athlete;
        return athleteA.lastName.localeCompare(athleteB.lastName);
      }
      return 0;
    });
    dispatch(setFullGroupReportData(result));
    dispatch(setFilteredGroupReportData(result));
  };

export const setCurrentAthlete = (id: number) => async (dispatch: Dispatch) => {
  dispatch(setSelectedAthlete(id));
};

export const setCurrentComparison =
  (items: number[] | undefined) => async (dispatch: Dispatch) => {
    dispatch(setComparison(items));
  };

export const setCurrentPerformanceComparison =
  (items: number[] | undefined) => async (dispatch: Dispatch) => {
    dispatch(setPerformanceComparison(items));
  };

export const setCurrentPerformanceComparisonData =
  (items: ComparisonInfo | null) => async (dispatch: Dispatch) => {
    dispatch(setPerformanceComparisonData(items));
  };

export const setCurrentComparisonData =
  (items: ComparisonInfo | null) => async (dispatch: Dispatch) => {
    dispatch(setComparisonData(items));
  };

export const getCurrentAthlete = (state: RootState) => {
  return state.shared.athleteList.find(
    (athlete) => athlete.id === state.shared.selectedAthleteId
  );
};

export const getCoachProfile = (state: RootState) => {
  return state.shared.coachProfile;
};

export const getSelectedGroup = (state: RootState) => {
  return state.shared.selectedGroup;
};
export const fetchAllGroups = () => async (dispatch: Dispatch) => {
  const groups = await getGroups();
  dispatch(setAllGroups(groups));
};

export const getAllGroups = (state: RootState) => {
  return state.shared.groups;
};

export const getCurrentAthleteName = (state: RootState) => {
  const athlete = getCurrentAthlete(state);
  return athlete?.lastName || "";
};

export const getAthleteList = (state: RootState) => {
  return state.shared.athleteList;
};

export const getAnthroList = (state: RootState) => {
  return state.shared.anthroList;
};

export const getCurrentComparison = (state: RootState) => {
  return state.shared.selectedComparison;
};

export const getCurrentComparisonData =
  (type: string) => (state: RootState) => {
    if (type === "all") {
      return state.shared.comparisonData;
    }
    return state.shared.performanceComparisonData;
  };

export const getComparisonName = (state: RootState) => {
  const { selectedComparison, comparisonData } = state.shared;
  if (selectedComparison !== undefined && selectedComparison.length === 1) {
    const athlete = state.shared.athleteList.find(
      (el) => el.id === selectedComparison[0]
    );

    if (athlete) {
      return athlete.lastName;
    }
  }

  if (selectedComparison !== undefined && comparisonData) {
    return comparisonData.name || "";
  }

  return "Team Average";
};

export const getCurrentDate = (state: RootState) => state.shared.currentDate;
export const getCurrentDateRange = (state: RootState) =>
  state.shared.currentDateRange;
export const getCurrentWeekRange = (state: RootState) =>
  state.shared.currentWeekRange;
export const getCurrentWorkOption = (state: RootState) =>
  state.shared.currentWorkOption;
export const getCurrentMMPType = (state: RootState) =>
  state.shared.currentMMPType;
export const getCurrentDataType = (state: RootState) =>
  state.shared.currentDataType;

export const getCurrentGroupBy = (state: RootState) => state.shared.groupBy;
export const getFullWeeklyReportData = (state: RootState) =>
  state.shared.fullWeeklyReportData;
export const getAllCommentEnabledReports = (state: RootState) => {
  return state.shared.fullWeeklyReportData.filter(
    (report) => report.commentEnabled && report.comments === ""
  );
};
export const getWizardReportData = (state: RootState) =>
  state.shared.wizardReportData;
export const getFilteredWeeklyReportData = (state: RootState) =>
  state.shared.filteredWeeklyReportData;
export const getFullGroupReportData = (state: RootState) =>
  state.shared.fullGroupReportData;
export const getFilteredGroupReportData = (state: RootState) =>
  state.shared.filteredGroupReportData;
export const getSeletedIds = (state: RootState) => state.shared.selectedIds;
export const getToggleCommentPopup = (state: RootState) =>
  state.shared.toggleCommentPopup;

export const shared = sharedSlice.reducer;

import { Layout } from "components/layout/layout";
import styles from "./group-profiles.module.scss";
import { AthleteSearch } from "pages/athlete-selection/athlete-search/athlete-search";
import { FC, useCallback, useEffect, useState } from "react";
import { PrimaryButton } from "components/primary-button/primary-button";
import { ReactComponent as FilterIcon } from "images/filter_icon.svg";
import { Athlete } from "types";
import {
  getAthleteList,
  getSelectedGroup,
  getSeletedIds,
  setCurrentAthlete,
  setSelectedIds,
} from "store/slices/shared";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { AthletePopup } from "components/athlete-popup/athlete-popup";
import { GroupMenuItems } from "../const";
import { AthleteCard, AthletePopupState } from "components/athlete-card/athlete-card";
import { updateProfile } from "api/profile";
import { useHistory } from "react-router-dom";
import { AnthropometryPopup } from "components/add-data/anthropometry-popup/anthropometry-popup";
import HeaderWithBackButton from "../header-with-back-button/header-with-back-button";

type Props = {
  loading: boolean;
};

export const GroupProfile: FC<Props> = ({ loading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isTablet, setIsTablet] = useState(false);
  const athleteList = useSelector(getAthleteList);
  const selectedGroup = useSelector(getSelectedGroup);
  const [selectedGroupAthleteList, setSelectedGroupAthleteList] = useState(athleteList);
  const [filteredAthletes, setFilteredAthletes] = useState(athleteList);
  const selectedIds = useSelector(getSeletedIds);
  const [input, setInput] = useState<string>("");
  const [togglePopup, setTogglePopup] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState<Athlete | undefined>(undefined);
  const [popups, setPopups] = useState<AthletePopupState>({
    anthropometry: false,
    weight: false,
    illness:false,
    physiotherapy:false,
  injury:false
  });

  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);

  useEffect(() => {
    const filtered = athleteList.filter((athlete) =>
      selectedGroup?.athleteIds.includes(athlete.id)
    );
    setSelectedGroupAthleteList(filtered);
    setFilteredAthletes(filtered);
  }, [selectedGroup, athleteList]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    const filtered = selectedGroupAthleteList.filter((athlete) => {
      const athleteName = `${athlete.firstName} ${athlete.lastName}`.toLowerCase();
      return athleteName.includes(event.target.value.toLowerCase());
    });
    setFilteredAthletes(filtered);
    if (selectedIds) {
      dispatch(setSelectedIds(null));
    }
  };

  const anthroTogglePopup = (popup: keyof AthletePopupState) => {
    setPopups((prev) => ({ ...prev, [popup]: !prev[popup] }));
  };

  const renderPopup = (key: keyof AthletePopupState, athlete?: Athlete) => {
    switch (key) {
      case "anthropometry":
        return (
          <AnthropometryPopup
            onClose={() => anthroTogglePopup("anthropometry")}
            selectedAthlete={athlete}
          />
        );
      default:
        return null;
    }
  };

  const handleFilterPopup = useCallback(() => {
    if (selectedIds && selectedIds.length > 0) {
      const filtered = selectedGroupAthleteList.filter((athlete) =>
        selectedIds.includes(athlete.id)
      );
      setFilteredAthletes(filtered);
    } else {
      setFilteredAthletes(selectedGroupAthleteList);
    }
    setTogglePopup(false);
  }, [selectedGroupAthleteList, selectedIds]);

  const handleClearAll = () => {
    setFilteredAthletes(selectedGroupAthleteList);
    dispatch(setSelectedIds(null));
    setTogglePopup(false);
  };

  const handleOnClick = async (athlete: Athlete) => {
    setInput("");
    if (athlete && athlete.id) {
      dispatch(setCurrentAthlete(athlete.id));
      await updateProfile({
        globalComparison: {
          id: 0,
          type: "athlete",
        },
        searchAthleteId: athlete.id,
      });
      history.push("/performance");
    }
  };

  const topComponent = (
    <div className={styles.topWrapper}>
      <div className={styles.searchFilter}>
        <AthleteSearch input={input} handleChange={handleChange} className={styles.search} />
      </div>
      <div className={styles.exportDropdown}>
        <PrimaryButton
          className={styles.button}
          onClick={() => setTogglePopup(true)}
          title={isTablet ? undefined : "Filter"}
          icon={<FilterIcon />}
        />
      </div>
    </div>
  );

  return (
    <>
      <Layout
        topMenuItems={GroupMenuItems}
        top={topComponent}
        contentClassName={styles.contentWrapper}
        showAdditonalComps={false}
        customHeader={
          <HeaderWithBackButton
            backNavLocation={"/group-selection"}
            title={selectedGroup?.name || ""}
          />
        }
        content={
          <div className={styles.athleteCardWrapper}>
            {filteredAthletes.map((athlete) => (
              <AthleteCard
                key={athlete.id}
                setSelectedAthlete={setSelectedAthlete}
                togglePopup={anthroTogglePopup}
                athlete={athlete}
                onClick={() => handleOnClick(athlete)}
              />
            ))}
          </div>
        }
        showCalendarComp={false}
        dataTestId="table-view-layout"
      />
      {Object.keys(popups).map(
        (key) =>
          popups[key as keyof AthletePopupState] &&
          renderPopup(key as keyof AthletePopupState, selectedAthlete)
      )}
      {togglePopup && (
        <AthletePopup
          handleClearAll={handleClearAll}
          handleSubmit={handleFilterPopup}
          athleteList={selectedGroupAthleteList}
          onClose={() => {
            setTogglePopup(false);
          }}
        />
      )}
    </>
  );
};

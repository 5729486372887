import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "hooks/app-hooks";

import { ReactComponent as AnalogLogoExpanded } from "images/g42-logo.svg";
import { ReactComponent as AnalogLogoCollapsed } from "images/logo-collapse.svg";
import { ReactComponent as MenuItemArrow } from "images/menu-item-arrow.svg";
import { ReactComponent as ReportIcon } from "images/report_icon.svg";
import { ReactComponent as G42LogoHome } from "images/g42-logo-home.svg";
import { ReactComponent as ExpandDown } from "images/expand-down.svg";
import { ReactComponent as EmiratesLogo } from "images/menu-logo.svg";
import { ReactComponent as ADQLogo } from "images/adq.svg";
import { ReactComponent as ADQUAETeamLogo } from "images/adq-uae-team.svg";
import { ReactComponent as HeartRate } from "images/heart-rate.svg";
import { ReactComponent as BarChart } from "images/bar-chart.svg";
import { ReactComponent as Athlete } from "images/athletes.svg";
import { ReactComponent as SignOutIcon } from "images/sign-out.svg";
import { ReactComponent as TeamDashboardIcon } from "images/chart-pie.svg";
import { ReactComponent as StarIcon } from "images/star.svg";
import { getIsCyclist, setAccount, setAuthState } from "store/slices/auth";
import clsx from "clsx";

import styles from "./menubar.module.scss";
import { getAccount } from "store/slices/auth";
import { FlavorType, RoleType } from "enums";
import { SignOut } from "components/sign-out/sign-out";
import { SettingsBtn } from "components/settings-btn/settings";

enum DropdownType {
  ATHLETE = "ATHLETE",
  REPORT = "REPORT",
}

export const Menubar = () => {
  const clientType = process.env.REACT_APP_FLAVOR as FlavorType;
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useSelector(getAccount);
  const isCyclist = useSelector(getIsCyclist);
  const [dropdownStates, setDropdownStates] = useState<
    { [key in DropdownType]?: boolean }
  >({});
  const location = useLocation();

  const useActiveLinks = (activeLinks: string[]) => {
    const activeLocation = useLocation();
    return useMemo(() => {
      return (path: string) => {
        return (
          activeLocation.pathname === path ||
          activeLinks.includes(activeLocation.pathname)
        );
      };
    }, [activeLocation.pathname, activeLinks]);
  };

  const isActiveGroup = useActiveLinks([
    "/group-profiles",
    "/group-overview",
    "/group-chart-view",
  ]);
  const isActiveAthlete = useActiveLinks([
    "/athlete-selection",
    "/wellness",
    "/performance",
  ]);
  const isActiveWeeklyReport = useActiveLinks([
    "/weekly-report-table",
    "/weekly-report-chart",
  ]);

  const isActiveMedicalReport = useActiveLinks(["/medical-report-table", "/medical-report", "/medical-report-details",]);

  const toggleDropdown = (menu: DropdownType) => {
    setDropdownStates((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key as DropdownType] = false;
        return acc;
      }, {} as { [key in DropdownType]?: boolean });
      return {
        ...newState,
        [menu]: !prevState[menu],
      };
    });
  };

  useEffect(() => {
    const checkIfTabletAndSetDropdown = () => {
      const screenWidth = window.innerWidth;
      if (
        screenWidth > 1439 &&
        [
          "/weekly-report",
          "/weekly-report-table",
          "/weekly-report-chart",
          "/medical-report",
          "/medical-report-table",
          "/medical-report-details",
        ].includes(location.pathname)
      ) {
        setDropdownStates((prevState) => ({
          ...prevState,
          [DropdownType.REPORT]: true,
          [DropdownType.ATHLETE]: false,
        }));
      } else if (
        screenWidth > 1439 &&
        [
          "/athlete-selection",
          "/group-selection",
          "/group-profiles",
          "/group-overview",
          "/group-chart-view",
        ].includes(location.pathname)
      ) {
        setDropdownStates((prevState) => ({
          ...prevState,
          [DropdownType.ATHLETE]: true,
          [DropdownType.REPORT]: false,
        }));
      } else {
        setDropdownStates((prevState) => ({
          ...prevState,
          [DropdownType.ATHLETE]: false,
          [DropdownType.REPORT]: false,
        }));
      }
    };

    checkIfTabletAndSetDropdown();

    window.addEventListener("resize", checkIfTabletAndSetDropdown);
    return () =>
      window.removeEventListener("resize", checkIfTabletAndSetDropdown);
  }, [location.pathname]);

  const menuItems = useMemo(() => {
    if (role === RoleType.cyclist) {
      return (
        <div className={styles.miniMenuWrapper}>
          <NavLink
            to="/performance"
            className={styles.link}
            activeClassName={styles.activeLink}
          >
            <div className={styles.menuItemOption}>
              <BarChart className={styles.icon} />
              <span className={styles.linkText}>{`Rider Performance`}</span>
            </div>
          </NavLink>
          <NavLink
            to="/wellness"
            className={styles.link}
            activeClassName={styles.activeLink}
          >
            <div className={styles.menuItemOption}>
              <HeartRate className={styles.icon} />
              <span className={styles.linkText}>{`Rider Wellness`}</span>
            </div>
          </NavLink>
        </div>
      );
    } else {
      return (
        <div className={styles.miniMenuWrapper}>
          <NavLink
            to="/team-dashboard"
            className={styles.link}
            activeClassName={styles.activeLink}
          >
            <div className={styles.menuItemOption}>
              <TeamDashboardIcon className={styles.icon} />
              <span className={styles.linkText}>Dashboards</span>
            </div>
          </NavLink>
          <div
            className={`${styles.dropdownContainer} ${dropdownStates[DropdownType.ATHLETE] ? styles.activeLink : ""
              }`}
          >
            <div
              onClick={() => toggleDropdown(DropdownType.ATHLETE)}
              className={`${styles.link} ${dropdownStates[DropdownType.ATHLETE] ? styles.activeLink : ""
                }`}
            >
              <div className={styles.menuItemOption}>
                <Athlete className={styles.icon} />
                <span className={styles.linkText}>Athletes</span>
              </div>
              <ExpandDown
                className={clsx(
                  styles.expandDown,
                  dropdownStates[DropdownType.ATHLETE]
                    ? styles.rotateChevron
                    : ""
                )}
              />
            </div>
            {dropdownStates[DropdownType.ATHLETE] && (
              <div className={styles.dropdown}>
                <NavLink
                  to="/athlete-selection"
                  className={
                    isActiveAthlete("/athlete-selection")
                      ? `${styles.dropdownItem} ${styles.activeLink}`
                      : styles.dropdownItem
                  }
                  activeClassName={styles.activeLink}
                >
                  <MenuItemArrow className={styles.dropdownIcon} />
                  <span>All Athletes</span>
                </NavLink>
                <NavLink
                  to="/group-selection"
                  className={
                    isActiveGroup("/group-selection")
                      ? `${styles.dropdownItem} ${styles.activeLink}`
                      : styles.dropdownItem
                  }
                  activeClassName={styles.activeLink}
                >
                  <MenuItemArrow className={styles.dropdownIcon} />
                  <span>Groups</span>
                </NavLink>
              </div>
            )}
          </div>
          <div
            className={`${styles.dropdownContainer} ${dropdownStates[DropdownType.REPORT] ? styles.activeLink : ""
              }`}
          >
            <div
              onClick={() => toggleDropdown(DropdownType.REPORT)}
              className={`${styles.link} ${dropdownStates[DropdownType.REPORT] ? styles.activeLink : ""
                }`}
            >
              <div className={styles.menuItemOption}>
                <ReportIcon className={styles.icon} />
                <span className={styles.linkText}>Reports</span>
              </div>
              <ExpandDown
                className={clsx(
                  styles.expandDown,
                  dropdownStates[DropdownType.REPORT]
                    ? styles.rotateChevron
                    : ""
                )}
              />
            </div>
            {dropdownStates[DropdownType.REPORT] && (
              <div className={styles.dropdown}>
                <NavLink
                  to="/weekly-report-table"
                  activeClassName={styles.activeLink}
                  className={
                    isActiveWeeklyReport("/weekly-report")
                      ? `${styles.dropdownItem} ${styles.activeLink}`
                      : styles.dropdownItem
                  }
                >
                  <MenuItemArrow className={styles.dropdownIcon} />
                  <span>Performance Report</span>
                </NavLink>

                <NavLink
                  to="/medical-report-table"
                  activeClassName={styles.activeLink}
                  className={
                    isActiveMedicalReport("/medical-report")
                      ? `${styles.dropdownItem} ${styles.activeLink}`
                      : styles.dropdownItem
                  }
                >
                  <MenuItemArrow className={styles.dropdownIcon} />
                  <span>Medical Report</span>
                </NavLink>
              </div>
            )}

          </div>

          <NavLink
            to="/ai-graph"
            className={styles.link}
            activeClassName={styles.activeLink}
          >
            <div className={styles.menuItemOption}>
              <StarIcon className={styles.icon} />
              <span className={styles.linkText}>Smart Charts</span>
            </div>
          </NavLink>
          {/* TODO: Enable this once the reports funcationality is implemented */}
          {/* <div
            className={`${styles.dropdownContainer} ${
              dropdownStates[DropdownType.GROUP] ? styles.activeLink : ""
            }`}
          >
            <div
              onClick={() => toggleDropdown(DropdownType.GROUP)}
              className={`${styles.link} ${
                dropdownStates[DropdownType.GROUP] ? styles.activeLink : ""
              }`}
            >
              <div className={styles.menuItemOption}>
                <GroupIcon className={styles.icon} />
                <span className={styles.linkText}>Groups</span>
              </div>
              <ExpandDown
                className={clsx(
                  styles.expandDown,
                  dropdownStates[DropdownType.GROUP] ? styles.rotateChevron : ""
                )}
              />
            </div>
            {dropdownStates[DropdownType.GROUP] && (
              <div className={styles.dropdown}>
                <NavLink to="/group1" className={styles.dropdownItem}>
                  <MenuItemArrow className={styles.dropdownIcon} />
                  <span>Group 1</span>
                </NavLink>
                <NavLink to="/group2" className={styles.dropdownItem}>
                  <MenuItemArrow className={styles.dropdownIcon} />
                  <span>Group 2</span>
                </NavLink>
              </div>
            )}
          </div>
          */}

          <div className={styles.logOut}>
            <NavLink
              onClick={async () => {
                dispatch(setAuthState(false));
                await dispatch(setAccount(RoleType.cyclist));
                history.push("/sign-in");
              }}
              to="/"
              className={styles.link}
            >
              <SignOutIcon className={styles.icon} />
            </NavLink>
          </div>
        </div>
      );
    }
  }, [
    role,
    dispatch,
    history,
    dropdownStates,
    isActiveGroup,
    isActiveAthlete,
    isActiveWeeklyReport,
    isActiveMedicalReport
  ]);

  return (
    <div className={styles.menubar}>
      <div className={styles.menubarLogoHeader}>
        {clientType === FlavorType.uaeMen ? (
          <div className={styles.menubarLogoWatermark}>
            <div className={styles.logoItem}>
              <EmiratesLogo className={styles.logo} />
              <span className={styles.logoTitle}>Team Emirates</span>
            </div>
          </div>
        ) : (
          <div className={styles.menubarWomenLogoWatermark}>
            <div className={styles.womenLogoItem}>
              <ADQUAETeamLogo className={styles.logo} />
              <ADQLogo className={styles.logoTitle} />
            </div>
          </div>
        )}
      </div>
      <div className={styles.header}>
        <div className={styles.g42logo}>
          <G42LogoHome />
          <div className={styles.versionMini}>Ver - 1.4.0</div>
        </div>
      </div>

      {menuItems}
      <div className={styles.bottomFooterWrapper}>
        <div className={clsx(styles.signOut, !isCyclist && styles.coachFixed)}>
          <a href="https://analog.io" className={styles.footerLogoLink}>
            <AnalogLogoCollapsed
              className={`${styles.footerLogo} ${styles.collapsedLogo}`}
            />
            <AnalogLogoExpanded
              className={`${styles.footerLogo} ${styles.expandedLogo}`}
            />
          </a>
          {isCyclist && (
            <div className={styles.btnOptions}>
              <SettingsBtn className={styles.signOutButton} />
              <SignOut className={styles.signOutButton} />{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

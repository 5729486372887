import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { formatTime, generateSmoothCurve, formatDate, getWithExpiry, setWithExpiry } from "utils";

import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getCurrentAthlete,
  getCurrentDataType,
  getCurrentMMPType,
  getCurrentDateRange,
  getCurrentGroupBy,
} from "store/slices/shared";
import {
  fetchPowerDerivatives,
  fetchPowerDurations,
  fetchCompoundScore,
  fetchPowerDurationsRelative,
} from "store/slices/performance";
import { ScatterChartWithHeader } from "components/charts/scatter-chart/scatter-chart";
import { ChartSize, LineMarkType } from "components/charts/constants";
import { Loading } from "components/loading/loading";
import { debounce } from "lodash";
import {
  COLOR_CHART_LINE_ONE,
  COLOR_CHART_LINE_TWO,
  COLOR_CHART_LINE_THREE,
  COLOR_CHART_LINE_FOUR,
  COLOR_CHART_LINE_FIVE,
  COLOR_CHART_LINE_SIX,
  COLOR_GREEN,
  COLOR_LIGHT_RED,
} from "components/charts/colors";

import styles from "./power-derivatives.module.scss";
import {
  LineChartTimeSeries,
  LineChartTimeWithHeader,
} from "components/charts/scatter-chart/line-chart-time";
import { MMPTypeSelector } from "components/mmp-type-selector/mmp-type-selector";
import { DateSelector } from "components/date-selector/date-selector";
import { TypeSelector } from "components/type-selector/type-selector";
import { DataTypeSelector, DateGroupBy, MMPType } from "enums";
import { DateGroupBySelector } from "components/date-group-by/date-group-by";
import clsx from "clsx";

const torqueChartYAxis = [
  { name: "(N m)", yAxisIndex: 0 },
  { name: "(Nm/kg)", min: 0, yAxisIndex: 1 },
];

const defaultBodyMass = 70;
const key = "athletePowerDerivative-labels";

export const PowerDerivatives: FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hideSeries, setHideSeries] = useState({});
  const [markLineAt, setMarkLineAt] = useState<string | null>(null);
  const [markLineText, setMarkLineText] = useState<LineMarkType | null>(null);
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentDateRange = useSelector(getCurrentDateRange);
  const currentDateUnits = useSelector(getCurrentGroupBy);
  const compoundScore = useSelector((state) => state.performance.compoundScore);
  const lastUpdates = useSelector((state) => state.wellness.lastUpdates);
  const currentDataType = useSelector(getCurrentDataType);
  const currentMmpType = useSelector(getCurrentMMPType);
  const derivatives = useSelector((state) => state.performance.powerDerivatives);
  const powerDurations = useSelector((state) => state.performance.powerDurations);
  const powerDurationsRelative = useSelector((state) => state.performance.powerDurationsRelative);

  const criticalPowerLabels = useMemo(
    () => powerDurations?.dataPoints?.map((value: number, index: number) => Math.log2(index)) || [],
    [powerDurations]
  );
  const [labels, setLabels] = useState<string[]>(
    criticalPowerLabels.map((e) => e.toString()) ?? []
  );

  const smoothPowerDurationCurve = useMemo(() => {
    if (currentDataType === DataTypeSelector.ABSOLUTE || !powerDurations?.dataPoints) {
      return generateSmoothCurve(powerDurations?.dataPoints || []);
    } else if (currentDataType === DataTypeSelector.RELATIVE && powerDurations?.dataPoints) {
      return generateSmoothCurve(
        powerDurationsRelative?.dataPoints || []
      );
    }
    return [];
  }, [currentDataType, powerDurations?.dataPoints, powerDurationsRelative?.dataPoints]);

  const title = useMemo(
    () =>
      powerDurations
        ? `${
            currentDataType === DataTypeSelector.RELATIVE
              ? `CP = ${powerDurations?.cp.toFixed(0)} Watts `
              : ""
          }W' = ${(powerDurations?.w / 1000).toFixed(1)} kJ`
        : "",
    [powerDurations, currentDataType]
  );

  const criticalPowerSeries = useMemo(
    () => [
      {
        name: "PD Curve",
        color: COLOR_LIGHT_RED,
        hideInLegend: false,
        chartType: "line",
        width: 2,
        emphasisDisabled: false,
        areaColor: "rgba(255, 0, 0, 0.2)",
        dashed: false,
        values:
          smoothPowerDurationCurve.map((item) => ({
            value: item[1],
            units: currentDataType === DataTypeSelector.RELATIVE ? "W/kg" : "W",
            fixedNumber: currentDataType === DataTypeSelector.RELATIVE ? 2 : 0,
          })) || [],
      },
      {
        name: "MMP Curve",
        color: COLOR_GREEN,
        hideInLegend: false,
        emphasisDisabled: false,
        chartType: "line",
        dashed: true,
        width: 2,
        values:
          (currentDataType === DataTypeSelector.RELATIVE
            ? powerDurationsRelative?.dataPoints?.map((value) => ({
                value: value,
                units: "W/kg",
                fixedNumber: 2,
              }))
            : powerDurations?.dataPoints?.map((value) => ({
                value,
                units: "W",
              }))) || [],
      },
    ],
    [smoothPowerDurationCurve, powerDurations, powerDurationsRelative, currentDataType]
  );

  const mmpChartLabels = useMemo(
    () =>
      derivatives?.powerAbsolutePoints?.map((item) => formatDate(new Date(item.startDate))) || [],
    [derivatives]
  );

  const mmpChartSeries = useMemo(() => {
    const mmpSeries =
      currentMmpType === MMPType.Absolute
        ? derivatives?.powerAbsolutePoints
        : derivatives?.powerToWeightAbsolutePoints;
    const suffix = currentMmpType === MMPType.Absolute ? "w" : "w/kg";
    return [
      {
        name: `MMP 5s`,
        chartType: "line",
        color: COLOR_CHART_LINE_FIVE,
        suffix: suffix,
        yAxisIndex: 0,
        values:
          mmpSeries?.map((item) => ({
            value: item?.mmp5Sec,
          })) || [],
      },
      {
        name: `MMP 1m`,
        chartType: "line",
        color: COLOR_CHART_LINE_THREE,
        suffix: suffix,
        yAxisIndex: 0,
        values:
          mmpSeries?.map((item) => ({
            value: item?.mmp1Min,
          })) || [],
      },
      {
        name: `MMP 5m`,
        chartType: "line",
        color: COLOR_CHART_LINE_TWO,
        suffix: suffix,
        yAxisIndex: 0,
        values:
          mmpSeries?.map((item) => ({
            value: item?.mmp5Min,
          })) || [],
      },
      {
        name: `MMP 10m`,
        chartType: "line",
        color: COLOR_CHART_LINE_ONE,
        suffix: suffix,
        yAxisIndex: 0,
        values:
          mmpSeries?.map((item) => ({
            value: item?.mmp10Min,
          })) || [],
      },
      {
        name: `MMP 20m`,
        chartType: "line",
        color: COLOR_CHART_LINE_FOUR,
        suffix: suffix,
        yAxisIndex: 0,
        values:
          mmpSeries?.map((item) => ({
            value: item?.mmp20Min,
          })) || [],
      },
      {
        name: `MMP 60m`,
        chartType: "line",
        color: COLOR_CHART_LINE_SIX,
        suffix: suffix,
        yAxisIndex: 0,
        values:
          mmpSeries?.map((item) => ({
            value: item?.mmp60Min,
          })) || [],
      },
    ];
  }, [currentMmpType, derivatives?.powerAbsolutePoints, derivatives?.powerToWeightAbsolutePoints]);

  const compositeSeriesLabels = [
    "10sec",
    "30sec",
    "1min",
    "5min",
    "10min",
    "20min",
    "30min",
    "60min",
  ];

  const compositeSeries = useMemo(
    () => [
      {
        color: COLOR_CHART_LINE_TWO,
        chartType: "bar",
        hideInLegend: true,
        name: "Compound Score",
        values: compoundScore || [],
      },
    ],
    [compoundScore]
  );

  const torqueDurationLabels = useMemo(
    () =>
      derivatives?.torqueDurationPoints?.data?.map((item) => formatTime(Number(item.seconds))) ||
      [],
    [derivatives]
  );

  const torqueDurationSeries = useMemo(
    () => [
      {
        name: "(Nm)",
        color: COLOR_LIGHT_RED,
        chartType: "line",
        yAxisIndex: 0,
        values:
          derivatives?.torqueDurationPoints?.data?.map((item) => ({
            value: item.value,
          })) || [],
      },
      {
        name: "(Nm/kg)",
        color: COLOR_LIGHT_RED,
        chartType: "line",
        dashed: true,
        yAxisIndex: 1,
        values:
          derivatives?.torqueDurationPoints?.data?.map((item) => ({
            value: item.value / (lastUpdates?.athlete?.bodyMass?.data || defaultBodyMass),
          })) || [],
      },
    ],
    [derivatives, lastUpdates]
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchPowerDurations());
    dispatch(fetchPowerDurationsRelative());
    dispatch(fetchCompoundScore());
    if (currentAthlete?.id) {
      setLoading(false);
    }
  }, [dispatch, currentAthlete?.id, currentDateRange]);

  useEffect(() => {
    if (currentAthlete?.id) {
      const savedLabels = getWithExpiry(`${key}-${currentAthlete?.id}`);
      if (savedLabels === null || savedLabels === undefined || !savedLabels.length) {
        setWithExpiry(
          `${key}-${currentAthlete?.id}`,
          criticalPowerLabels.map((e) => e.toString()),
          15 * 60 * 1000
        );
        setLabels(criticalPowerLabels.map((e) => e.toString()));
      } else {
        setLabels(savedLabels);
      }
    }
  }, [criticalPowerLabels, currentDataType, currentAthlete]);

  const saveLabels = useCallback(async () => {
    if (currentAthlete?.id) {
      const savedLabels = getWithExpiry(`${key}-${currentAthlete?.id}`);
      if (savedLabels === null || savedLabels === undefined || !savedLabels.length) {
        setWithExpiry(
          `${key}-${currentAthlete?.id}`,
          criticalPowerLabels.map((e) => e.toString()),
          15 * 60 * 1000
        );
        setLabels(criticalPowerLabels.map((e) => e.toString()));
      } else {
        setLabels(savedLabels);
      }
    }
  }, [criticalPowerLabels, currentAthlete]);

  const clearMarkLine = useCallback(() => {
    setMarkLineAt(null);
    setMarkLineText(null);
    saveLabels();
  }, [setMarkLineAt, setMarkLineText, saveLabels]);

  const refetchPowerDerivative = useCallback(async () => {
    await dispatch(fetchPowerDerivatives());
    await saveLabels();
  }, [dispatch, saveLabels]);

  useEffect(() => {
    refetchPowerDerivative();
  }, [currentDateRange, refetchPowerDerivative, currentDateUnits]);

  useEffect(() => {
    const debouncedRefetchPowerDurations = debounce(saveLabels, 500);

    window.addEventListener("resize", debouncedRefetchPowerDurations);

    return () => {
      window.removeEventListener("resize", debouncedRefetchPowerDurations);
      debouncedRefetchPowerDurations.cancel();
    };
  }, [saveLabels]);

  useEffect(() => {
    if (markLineAt) {
      const textArr: string[] = [];
      const axisValue = Math.pow(2, Number(markLineAt));
      const parsedAxisValue = Number(axisValue.toFixed(0));

      const formattedTime = formatTime(parsedAxisValue);

      textArr.push(`{time|Time: ${formattedTime}}`);
      let arrayLength = 0;
      criticalPowerSeries
        .filter((el) => el !== undefined)
        .map((el) => el as LineChartTimeSeries)
        .filter((el) => {
          const seriesSettings = hideSeries[el.name as keyof typeof hideSeries] as any;
          return !(seriesSettings && typeof seriesSettings === "object" && seriesSettings.hidden);
        })
        .forEach((seriesItem, index) => {
          arrayLength = seriesItem.values.length;
          const values = seriesItem.values[parsedAxisValue];

          if (values) {
            textArr.push(
              `{item| ${seriesItem.name}: ${
                currentDataType === DataTypeSelector.ABSOLUTE
                  ? values.value.toFixed(0)
                  : values.value.toFixed(2)
              } ${values.units || ""}}`
            );
          }
        });

      let alignValue: "left" | "right" | null = null;
      if (parsedAxisValue < 3) {
        alignValue = "left";
      } else if (parsedAxisValue >= arrayLength - 6000) {
        alignValue = "right";
      } else if (parsedAxisValue >= 3 && parsedAxisValue < arrayLength - 6000) {
        alignValue = null;
      }

      const markLineTextValue: LineMarkType = {
        value: textArr.join("\n"),
        align: alignValue,
      };

      setMarkLineText(markLineTextValue);
      saveLabels();
    }
  }, [markLineAt, saveLabels, criticalPowerSeries, currentDataType, hideSeries]);

  if (loading) {
    return (
      <div>
        <div className={styles.content}>
          <Loading />
        </div>
        <div className={styles.content}>
          <Loading />
        </div>
        <div className={styles.content}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={clsx(styles.content)}>
        <LineChartTimeWithHeader
          setMarkLineAt={setMarkLineAt}
          markLineAt={markLineAt}
          savedLabels={saveLabels}
          setMarkLineText={setMarkLineText}
          markLineText={markLineText}
          hideSeries={hideSeries}
          setHideSeries={setHideSeries}
          showLegendInSourceBar={true}
          markLineYAt={Number(powerDurations?.cp?.toFixed(0))}
          markLineYLabel={`CP`}
          type={ChartSize.Big}
          source="AI"
          name="Power Duration Curve"
          labels={labels ?? []}
          series={criticalPowerSeries}
          titlePrefix={"MMP"}
          headerComponent={
            <div className={styles.headerContainer}>
              <div className={styles.titleWrapper}>
                <div className={styles.bigTitle}>
                  <span className={styles.bold}>Power Duration Curve</span>
                </div>
                {powerDurations && (
                  <>
                    <div className={styles.globalTooltip}>
                      <div className={styles.tooltipItem}>
                        <span className={styles.tooltipText}>CP: </span>
                        <span className={clsx(styles.tooltipValue, styles.bold)}>
                          {`${Number(powerDurations?.cp?.toFixed(0))} Watts`}
                        </span>
                      </div>
                      <div className={styles.tooltipItem}>
                        <span className={styles.tooltipText}>W': </span>
                        <span className={clsx(styles.tooltipValue, styles.bold)}>
                          {`${(powerDurations?.w / 1000).toFixed(1)} kJ`}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.selectors}>
                {markLineText && (
                  <button type="button" className={styles.clearMarkline} onClick={clearMarkLine}>
                    Clear Marklines
                  </button>
                )}
                <TypeSelector />
              </div>
            </div>
          }
        />
      </div>
      <div className={clsx(styles.content)}>
        <ScatterChartWithHeader
          type={ChartSize.Big}
          source="AI"
          name="Torque Duration Curve"
          labels={torqueDurationLabels}
          series={torqueDurationSeries}
          yAxis={torqueChartYAxis}
          intervals={0}
          titlePrefix={"MMP"}
        />
      </div>
      <div className={clsx(styles.content)}>
        <ScatterChartWithHeader
          type={ChartSize.Big}
          source="AI"
          name="MMP"
          showGrid={false}
          showXAxisLine={true}
          showYAxisLine={true}
          hideDecimalPoints={currentMmpType === MMPType.Absolute}
          headerComponent={
            <div className={styles.headerContainer}>
              <div className={styles.bigTitle}>
                <span className={styles.bold}>MMP</span>
                <div>
                  <span className={styles.subTitle}>Mean Max Power</span>
                </div>
              </div>
              <div className={styles.selectors}>
                <MMPTypeSelector />
                <DateSelector />
                <DateGroupBySelector ignoreOptions={[DateGroupBy.Month]} />
              </div>
            </div>
          }
          showLegendInSourceBar={true}
          labels={mmpChartLabels}
          series={mmpChartSeries}
        />
      </div>
      <div className={clsx(styles.content)}>
        <ScatterChartWithHeader
          hideDecimalPoints={true}
          name="Compound Score"
          source={"TP"}
          showGrid={false}
          showXAxisLine={true}
          showYAxisLine={true}
          barMaxWidth="20px"
          yLabelMargins={20}
          bottomMargin={10}
          containLabels={true}
          type={ChartSize.Big}
          labels={compositeSeriesLabels}
          series={compositeSeries}
        />
      </div>
    </div>
  );
};

const CoughFront = ({ opacity }: { opacity: number }) => {
    return (
        <svg width="160" height="410" viewBox="0 0 160 410" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={opacity}>
            <path d="M82.3805 64.2364C80.8324 64.733 79.1676 64.733 77.6195 64.2364L74.5863 63.2636C73.1679 62.8086 72.2058 61.4896 72.2058 60V60C72.2058 58.5104 73.1679 57.1914 74.5863 56.7364L77.6195 55.7636C79.1676 55.267 80.8324 55.267 82.3805 55.7636L85.4137 56.7364C86.8321 57.1914 87.7942 58.5104 87.7942 60V60C87.7942 61.4896 86.8321 62.8086 85.4137 63.2636L82.3805 64.2364Z" fill="#CD3232" fill-opacity="0.4" />
            <path d="M81.8993 63.3734C80.6658 63.7803 79.3342 63.7803 78.1007 63.3734L75.8371 62.6266C74.7036 62.2526 73.9378 61.1936 73.9378 60V60C73.9378 58.8064 74.7036 57.7474 75.8371 57.3734L78.1007 56.6266C79.3342 56.2197 80.6658 56.2197 81.8993 56.6266L84.1629 57.3734C85.2964 57.7474 86.0622 58.8064 86.0622 60V60C86.0622 61.1936 85.2964 62.2526 84.1629 62.6266L81.8993 63.3734Z" fill="#CD3232" fill-opacity="0.7" />
            <path d="M72.1362 71.595L66.2338 68.8488C62.7365 67.2217 60.5 63.7143 60.5 59.857C60.5 56.141 62.5774 52.7368 65.882 51.0374L71.4315 48.1837C77.0521 45.2934 83.7368 45.3626 89.2963 48.3687L94.0125 50.9189C97.293 52.6927 99.3372 56.1219 99.3372 59.8512C99.3372 63.7291 97.1285 67.2684 93.6451 68.9726L88.6135 71.4342C83.4267 73.9717 77.3714 74.0308 72.1362 71.595Z" fill="url(#paint0_radial_1766_77053)" fill-opacity="0.7" stroke="#B93033" />
            <path d="M74.1117 68.5369L69.1375 66.4628C66.5291 65.3752 64.8302 62.8261 64.8302 60C64.8302 57.1739 66.5291 54.6248 69.1375 53.5372L74.1117 51.4631C77.8279 49.9135 82.0093 49.9135 85.7255 51.4631L90.6998 53.5372C93.3082 54.6248 95.0071 57.1739 95.0071 60C95.0071 62.8261 93.3082 65.3752 90.6998 66.4628L85.7255 68.5369C82.0093 70.0865 77.8279 70.0865 74.1117 68.5369Z" fill="url(#paint1_radial_1766_77053)" fill-opacity="0.7" stroke="#B93033" />
            <path d="M75.9878 65.753L73.0071 64.4623C71.1975 63.6788 70.0263 61.8953 70.0263 59.9234C70.0263 58.0228 71.1153 56.2903 72.828 55.4663L75.6298 54.1183C78.3403 52.8142 81.4969 52.8142 84.2075 54.1183L87.0092 55.4663C88.7219 56.2903 89.8109 58.0228 89.8109 59.9234C89.8109 61.8953 88.6397 63.6788 86.8302 64.4623L83.8494 65.7531C81.3414 66.8391 78.4958 66.8391 75.9878 65.753Z" fill="url(#paint2_radial_1766_77053)" fill-opacity="0.7" stroke="#B93033" />
            <path d="M78.2573 62.8986L75.9938 62.1518C75.0651 61.8454 74.4378 60.9779 74.4378 60C74.4378 59.0221 75.0651 58.1546 75.9938 57.8482L78.2573 57.1014C79.3891 56.728 80.6109 56.728 81.7427 57.1014L84.0062 57.8482C84.9349 58.1546 85.5622 59.0221 85.5622 60C85.5622 60.9779 84.9349 61.8454 84.0062 62.1518L81.7427 62.8986C80.6109 63.272 79.3891 63.272 78.2573 62.8986Z" fill="url(#paint3_radial_1766_77053)" fill-opacity="0.7" stroke="#B93033" />
            <defs>
                <radialGradient id="paint0_radial_1766_77053" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(79.9186 59.5) rotate(90) scale(10.3125 12.4491)">
                    <stop offset="0.175" stop-color="#CD3232" />
                    <stop offset="1" stop-color="#FF3333" stop-opacity="0.5" />
                </radialGradient>
                <radialGradient id="paint1_radial_1766_77053" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(79.9186 60) rotate(90) scale(7.1875 9.74279)">
                    <stop offset="0.175" stop-color="#CD3232" />
                    <stop offset="1" stop-color="#FF3333" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint2_radial_1766_77053" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(79.9186 59.75) rotate(90) scale(5.15625 6.49519)">
                    <stop offset="0.175" stop-color="#CD3232" />
                    <stop offset="1" stop-color="#FF3333" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint3_radial_1766_77053" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80 60) rotate(90) scale(2.5 4.375)">
                    <stop offset="0.175" stop-color="#CD3232" />
                    <stop offset="1" stop-color="#FF3333" stop-opacity="0" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default CoughFront
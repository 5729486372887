import { axiosInstance } from "axios-instance/axios-instance";

import {
  APIResponse,
  PerformanceRadar,
  PowerDerivatives,
  FitnessFatigue,
  TssItrimp,
  PowerDerivativesItem,
  PerformanceLastUpdates,
  TeamPerformanceT,
} from "types";

import {
  formatDateAPI,
  getQueryParams,
  getITrimpTssParams,
  parseDate,
} from "utils";
import { DateGroupBy, DateRange } from "enums";
import { adaptLastUpdateScore, APILastUpdateScore } from "./utils";
import { trackError } from "appInsights/logInsights";

interface APIPerformanceLastUpdate {
  athlete: {
    athleteId: number;
    mmpFiveMin: APILastUpdateScore;
    mmpTwentyMin: APILastUpdateScore;
    powerMassRpe: APILastUpdateScore;
    tte: APILastUpdateScore;
  };
  comparison: {
    athleteId: number[];
    mmpFiveMin: APILastUpdateScore;
    mmpTwentyMin: APILastUpdateScore;
    powerMassRpe: APILastUpdateScore;
    tte: APILastUpdateScore;
  };
}

interface APIPowerDerivativesItem<T> {
  startDate: string;
  endDate: string;
  data: {
    startDate: string;
    mmp5Sec: T;
    mmp1Min: T;
    mmp5Min: T;
    mmp10Min: T;
    mmp20Min: T;
    mmp60Min: T;
  };
}

export interface PowerTorqueData {
  value: number;
  seconds: number;
}

export interface LinearPoint {
  value: number;
}

export interface TorqueDurationPoints {
  startDate: string;
  endDate: string;
  data: PowerTorqueData[];
}

export interface CriticalPowerPoint {
  model: string;
  startDate: string;
  cp: number;
  w: number;
  linearPoints?: LinearPoint[];
  dataPoints?: number[];
  endDate: string;
  data?: PowerTorqueData[];
}

export interface TssItrimpData {
  tss: number;
  ratio: number;
  itrimp: number;
}

export interface TssItrimpPoint {
  startDate: string;
  endDate: string;
  size: number;
  data: TssItrimpData;
}

interface APITssItrimp {
  athleteIds: number[];
  startDate: string;
  endDate: string;
  twoStdUpperBound: number;
  oneStdUpperBound: number;
  twoStdLowerBound: number;
  oneStdLowerBound: number;
  points: TssItrimpPoint[];
}

export interface FitnessFatigueData {
  fitness: number;
  fatigue: number;
  performance: number;
  prediction: number;
}
export interface FitnessFatiguePoint {
  startDate: string;
  endDate: string;
  data: FitnessFatigueData;
}
export interface AthleteModel {
  k1: number;
  k2: number;
  t1: number;
  t2: number;
  rmse: number;
  pstar: number;
  tn: number;
  tg: number;
}

export interface APIFitnessFatigue {
  athleteIds: number[];
  startDate: string;
  endDate: string;
  points: FitnessFatiguePoint[];
  athleteModel: AthleteModel;
}

interface APIPowerDerivatives {
  athleteIds: number[];
  startDate: string;
  endDate: string;
  torqueDurationPoints: TorqueDurationPoints;
  powerAbsolutePoints: APIPowerDerivativesItem<number>[];
  powerToWeightAbsolutePoints: APIPowerDerivativesItem<number>[];
}

interface APIPowerDurations {
  model: string;
  startDate: string;
  cp: number;
  w: number;
  linearPoints?: LinearPoint[];
  dataPoints?: number[];
  endDate: string;
  data?: PowerTorqueData[];
}

const adaptDataPoints: <T>(
  points: APIPowerDerivativesItem<T>[]
) => PowerDerivativesItem<T>[] = (points) => {
  return (
    points?.map((point) => ({
      ...point.data,
      startDate: parseDate(point.startDate).getTime(),
    })) || []
  );
};

const getPowerDerivatives = async (
  startDate: Date,
  endDate: Date,
  currentDateRange: DateRange,
  athleteIds: number[],
  currentDateUnit?: DateGroupBy
): Promise<PowerDerivatives | undefined> => {
  const { data }: APIResponse<APIPowerDerivatives> = await axiosInstance.get(
    `/performance/powerDerivatives/${formatDateAPI(startDate)}/${formatDateAPI(
      endDate
    )}?${getQueryParams(athleteIds, currentDateRange, currentDateUnit)}`
  );

  return data
    ? {
        torqueDurationPoints: data?.torqueDurationPoints,
        powerAbsolutePoints: adaptDataPoints<number>(data?.powerAbsolutePoints),
        powerToWeightAbsolutePoints: adaptDataPoints<number>(
          data?.powerToWeightAbsolutePoints
        ),
      }
    : undefined;
};

const getPowerDurations = async (
  startDate: Date,
  endDate: Date,
  athleteId: number
): Promise<CriticalPowerPoint | undefined> => {
  const { data }: APIResponse<APIPowerDurations> = await axiosInstance.get(
    `/performance/pdCurve/${formatDateAPI(startDate)}/${formatDateAPI(endDate)}?athleteId=${athleteId}`
  );

  return data;
};

const getPowerDurationsRelative = async (
    startDate: Date,
    endDate: Date,
    athleteId: number
): Promise<CriticalPowerPoint | undefined> => {
  const { data }: APIResponse<APIPowerDurations> = await axiosInstance.get(
      `/performance/pdCurveRelative/${formatDateAPI(startDate)}/${formatDateAPI(endDate)}?athleteId=${athleteId}`
  );

  return data;
};

const getTssItrimp = async (
  startDate: Date,
  endDate: Date,
  athleteIds: number[]
): Promise<TssItrimp | undefined> => {
  const { data }: APIResponse<APITssItrimp> = await axiosInstance.get(
    `/performance/iTRIMP-TSS/${formatDateAPI(startDate)}/${formatDateAPI(
      endDate
    )}?${getITrimpTssParams(athleteIds)}`
  );

  return data
    ? {
        twoStdUpperBound: data.twoStdUpperBound,
        oneStdUpperBound: data.oneStdUpperBound,
        twoStdLowerBound: data.twoStdLowerBound,
        oneStdLowerBound: data.oneStdLowerBound,
        points:
          data?.points.map((point) => ({
            data: point.data,
            startDate: parseDate(point.startDate).getTime(),
          })) || [],
      }
    : undefined;
};

async function getAthletesPerformance(
  startDate: Date,
  endDate: Date,
  athleteIds: number[]
): Promise<TeamPerformanceT[] | undefined> {
  const url = `/performance/peak/${formatDateAPI(startDate)}/${formatDateAPI(
    endDate
  )}?`;
  const query = `athleteIds=${athleteIds.join("&athleteIds=") || ""}`;
  try {
    const { data } = await axiosInstance.get<TeamPerformanceT[]>(url + query);
    return data;
  } catch (error) {
    trackError(error as Error);
    console.error(error);
    return undefined;
  }
}

const getFitnessFatigue = async (
  startDate: Date,
  endDate: Date,
  currentDateRange: DateRange,
  athleteIds: number[]
): Promise<FitnessFatigue | undefined> => {
  const { data }: APIResponse<APIFitnessFatigue> = await axiosInstance.get(
    `/performance/fitness-fatigue/${formatDateAPI(startDate)}/${formatDateAPI(
      endDate
    )}?${getQueryParams(athleteIds, currentDateRange)}`
  );

  return data
    ? {
        points:
          data?.points.map((point) => ({
            data: point.data,
            startDate: parseDate(point.startDate).getTime(),
          })) || [],
        athleteModel: data?.athleteModel,
      }
    : undefined;
};

const adaptRadarData = (data: TeamPerformanceT) => {
  return {
    mmp10SecondsAbsolute: data?.maximumOfMmp10SecondsAbsolute,
    mmp10SecondsRelative: data?.maximumOfMmp10SecondsRelative,
    mmp10SecondsComposite: data?.maximumOfMmp10SecondsComposite,
    mmp30SecondsAbsolute: data?.maximumOfMmp30SecondsAbsolute,
    mmp30SecondsRelative: data?.maximumOfMmp30SecondsRelative,
    mmp30SecondsComposite: data?.maximumOfMmp30SecondsComposite,
    mmp60SecondsAbsolute: data?.maximumOfMmp1MinutesAbsolute,
    mmp60SecondsRelative: data?.maximumOfMmp1MinutesRelative,
    mmp60SecondsComposite: data?.maximumOfMmp1MinutesComposite,
    mmp5MinutesAbsolute: data?.maximumOfMmp5MinutesAbsolute,
    mmp5MinutesRelative: data?.maximumOfMmp5MinutesRelative,
    mmp5MinutesComposite: data?.maximumOfMmp5MinutesComposite,
    mmp10MinutesAbsolute: data?.maximumOfMmp10MinutesAbsolute,
    mmp10MinutesRelative: data?.maximumOfMmp10MinutesRelative,
    mmp10MinutesComposite: data?.maximumOfMmp10MinutesComposite,
    mmp20MinutesAbsolute: data?.maximumOfMmp20MinutesAbsolute,
    mmp20MinutesRelative: data?.maximumOfMmp20MinutesRelative,
    mmp20MinutesComposite: data?.maximumOfMmp20MinutesComposite,
    mmp30MinutesAbsolute: data?.maximumOfMmp30MinutesAbsolute,
    mmp30MinutesRelative: data?.maximumOfMmp30MinutesRelative,
    mmp30MinutesComposite: data?.maximumOfMmp30MinutesComposite,
    mmp60MinutesAbsolute: data?.maximumOfMmp60MinutesAbsolute,
    mmp60MinutesRelative: data?.maximumOfMmp60MinutesRelative,
    mmp60MinutesComposite: data?.maximumOfMmp60MinutesComposite,
  };
};

const getRadarForRange = async (
  startDate: Date,
  endDate: Date,
  athleteIds: number[]
): Promise<PerformanceRadar<number> | undefined> => {
  const query = `athleteIds=${athleteIds.join("&athleteIds=") || ""}`;
  const { data }: APIResponse<TeamPerformanceT[]> = await axiosInstance.get(
    `/performance/peak/${formatDateAPI(startDate)}/${formatDateAPI(
      endDate
    )}?${query}`
  );

  if (!data) {
    return undefined;
  }

  return adaptRadarData(data[0]);
};

const getLastUpdates = async (
  athleteId: number,
  athleteIds?: number[]
): Promise<PerformanceLastUpdates | undefined> => {
  const comparisonString = athleteIds
    ? `&athleteIds=${athleteIds?.join("&athleteIds=") || ""}`
    : "";
  const { data }: APIResponse<APIPerformanceLastUpdate> =
    await axiosInstance.get(
      `/performance/overall/lastUpdated?athleteId=${athleteId}${comparisonString}`
    );

  if (data) {
    return {
      athlete: {
        mmpFiveMin: adaptLastUpdateScore(data.athlete.mmpFiveMin),
        mmpTwentyMin: adaptLastUpdateScore(data.athlete.mmpTwentyMin),
        powerMassRpe: adaptLastUpdateScore(data.athlete.powerMassRpe),
        tte: adaptLastUpdateScore(data.athlete.tte),
      },
      comparison: data.comparison
        ? {
            mmpFiveMin: adaptLastUpdateScore(data.comparison.mmpFiveMin),
            mmpTwentyMin: adaptLastUpdateScore(data.comparison.mmpTwentyMin),
            powerMassRpe: adaptLastUpdateScore(data.comparison.powerMassRpe),
            tte: adaptLastUpdateScore(data.comparison.tte),
          }
        : undefined,
    };
  }
  return undefined;
};

export const performance = {
  getPowerDerivatives,
  getPowerDurations,
  getPowerDurationsRelative,
  getTssItrimp,
  getAthletesPerformance,
  getFitnessFatigue,
  getRadarForRange,
  getLastUpdates,
};

const IllnessChillsBack = ({ opacity }: { opacity: number }) => {
  return (
    <svg width="160" height="410" viewBox="0 0 160 410" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={opacity}>
      <path d="M24.2787 114.693L19.2985 122.997C18.5028 124.323 18.2503 125.906 18.5938 127.415L19.8005 132.714C20.1507 134.252 19.8812 135.866 19.0503 137.207L15.1388 143.519C14.1769 145.071 13.9742 146.977 14.5881 148.697L16.4716 153.973C17.0575 155.614 16.9009 157.429 16.0425 158.945L11.3708 167.199C10.5722 168.61 10.3786 170.284 10.8341 171.84L12.1381 176.295C12.6343 177.99 12.3584 179.817 11.384 181.291L6.72156 188.339" stroke="#397AF9" />
      <path d="M155.074 188.406L150.14 180.074C149.352 178.743 149.108 177.159 149.46 175.652L150.696 170.36C151.055 168.824 150.794 167.208 149.971 165.863L146.094 159.53C145.141 157.972 144.949 156.065 145.572 154.349L147.485 149.083C148.08 147.445 147.933 145.63 147.083 144.108L142.458 135.828C141.667 134.413 141.482 132.738 141.947 131.184L143.275 126.737C143.781 125.045 143.515 123.216 142.549 121.737L137.926 114.663" stroke="#397AF9" />
      <path d="M159.427 178.837L156.642 173.762C155.949 172.497 155.733 171.025 156.037 169.615L156.544 167.254C156.853 165.816 156.623 164.314 155.897 163.034L154.166 159.98C153.326 158.499 153.155 156.73 153.696 155.115L154.429 152.928C154.945 151.389 154.814 149.706 154.068 148.264L151.804 143.891C151.11 142.55 150.947 140.997 151.348 139.541L151.814 137.848C152.252 136.259 152.016 134.559 151.162 133.149L148.65 128.998" stroke="#397AF9" />
      <path d="M13.7761 128.999L10.9919 134.074C10.2981 135.339 10.0831 136.811 10.3862 138.221L10.8936 140.582C11.2027 142.02 10.9726 143.522 10.247 144.802L8.51572 147.855C7.67575 149.337 7.50496 151.106 8.04594 152.721L8.77853 154.907C9.29425 156.447 9.16407 158.13 8.41772 159.572L6.15411 163.945C5.45988 165.286 5.29692 166.839 5.69777 168.295L6.16375 169.988C6.60134 171.577 6.36563 173.277 5.51198 174.687L2.9999 178.838" stroke="#397AF9" />
      <path d="M113.127 375.397L111.06 365.937C110.729 364.426 110.996 362.845 111.803 361.526L114.639 356.889C115.462 355.543 115.722 353.928 115.363 352.392L113.672 345.162C113.256 343.383 113.673 341.513 114.804 340.079L118.274 335.681C119.354 334.313 119.785 332.543 119.456 330.832L117.665 321.518C117.359 319.926 117.71 318.277 118.639 316.948L121.297 313.144C122.309 311.696 122.631 309.876 122.178 308.169L120.011 300" stroke="#397AF9" />
      <path d="M119.317 366.846L118.261 361.155C117.997 359.737 118.254 358.271 118.983 357.027L120.204 354.943C120.948 353.674 121.199 352.176 120.911 350.733L120.222 347.291C119.888 345.621 120.28 343.888 121.299 342.523L122.679 340.675C123.651 339.375 124.054 337.736 123.796 336.133L123.015 331.271C122.775 329.78 123.107 328.253 123.943 326.996L124.916 325.534C125.829 324.162 126.137 322.474 125.767 320.867L124.681 316.139" stroke="#397AF9" />
      <path d="M47.1506 375.397L49.2178 365.937C49.5481 364.426 49.2818 362.845 48.4745 361.526L45.6384 356.889C44.8153 355.543 44.5552 353.928 44.9143 352.392L46.6051 345.162C47.0209 343.383 46.6043 341.513 45.4732 340.079L42.0032 335.681C40.9238 334.313 40.4926 332.543 40.8216 330.832L42.6124 321.518C42.9185 319.926 42.5672 318.277 41.6386 316.948L38.9803 313.144C37.9687 311.696 37.6463 309.876 38.0993 308.169L40.2663 300" stroke="#397AF9" />
      <path d="M40.9608 366.846L42.0168 361.155C42.2799 359.737 42.0233 358.271 41.2941 357.027L40.0733 354.943C39.3295 353.674 39.078 352.176 39.3666 350.733L40.0551 347.291C40.3892 345.621 39.9978 343.888 38.9786 342.523L37.5983 340.675C36.6267 339.375 36.2236 337.736 36.4812 336.133L37.2624 331.271C37.502 329.78 37.1705 328.253 36.334 326.996L35.3617 325.534C34.4486 324.162 34.1406 322.474 34.5099 320.867L35.5967 316.139" stroke="#397AF9" />
      <path d="M49.8 33L48.3001 34.2757C47.3752 35.0623 47.2973 36.463 48.1291 37.3473V37.3473C49.0121 38.2861 48.8618 39.7887 47.8104 40.5339L47.1934 40.9712C46.1458 41.7137 46.184 43.281 47.2666 43.9716L47.4106 44.0635C48.4945 44.7549 48.453 46.3514 47.3346 46.9855V46.9855C46.2478 47.6018 46.1703 49.1387 47.1896 49.8612L47.972 50.4157C48.92 51.0876 49.0207 52.457 48.1811 53.2603V53.2603C47.3542 54.0515 47.4374 55.3964 48.3555 56.0797L48.4655 56.1615C49.2357 56.7347 49.4789 57.7805 49.0406 58.6347L49.0214 58.6723C48.4346 59.8159 49.0543 61.209 50.2964 61.5392L50.6266 61.6269C51.9098 61.968 52.6349 63.3251 52.2052 64.5813L51.72 66" stroke="#397AF9" />
      <path d="M42.6 37L41.4764 37.9653C40.7799 38.5637 40.721 39.6213 41.3468 40.2934V40.2934C42.0113 41.007 41.8976 42.142 41.1047 42.7097L40.6443 43.0393C39.8555 43.6041 39.8844 44.7863 40.6999 45.3118L40.8088 45.382C41.6253 45.9081 41.5939 47.1123 40.751 47.5951V47.5951C39.9321 48.0641 39.8734 49.2232 40.6407 49.7726L41.227 50.1923C41.9416 50.7039 42.0178 51.738 41.3858 52.3487V52.3487C40.7635 52.9503 40.8264 53.9657 41.5182 54.4858L41.5986 54.5462C42.1778 54.9816 42.3608 55.7699 42.0326 56.416L42.0168 56.4471C41.5753 57.3165 42.0456 58.3718 42.9873 58.6246L43.2189 58.6868C44.1835 58.9457 44.7289 59.9657 44.4085 60.9117L44.04 62" stroke="#397AF9" />
      <path d="M109.2 33L110.7 34.2757C111.625 35.0623 111.703 36.463 110.871 37.3473V37.3473C109.988 38.2861 110.138 39.7887 111.19 40.5339L111.807 40.9712C112.854 41.7137 112.816 43.281 111.733 43.9716L111.589 44.0635C110.505 44.7549 110.547 46.3514 111.665 46.9855V46.9855C112.752 47.6018 112.83 49.1387 111.81 49.8612L111.028 50.4157C110.08 51.0876 109.979 52.457 110.819 53.2603V53.2603C111.646 54.0515 111.563 55.3964 110.644 56.0797L110.535 56.1615C109.764 56.7347 109.521 57.7805 109.959 58.6347L109.979 58.6723C110.565 59.8159 109.946 61.209 108.704 61.5392L108.373 61.6269C107.09 61.968 106.365 63.3251 106.795 64.5813L107.28 66" stroke="#397AF9" />
      <path d="M116.4 37L117.524 37.9653C118.22 38.5637 118.279 39.6213 117.653 40.2934V40.2934C116.989 41.007 117.102 42.142 117.895 42.7097L118.356 43.0393C119.145 43.6041 119.116 44.7863 118.3 45.3118L118.191 45.382C117.375 45.9081 117.406 47.1123 118.249 47.5951V47.5951C119.068 48.0641 119.127 49.2232 118.359 49.7726L117.773 50.1923C117.058 50.7039 116.982 51.738 117.614 52.3487V52.3487C118.237 52.9503 118.174 53.9657 117.482 54.4858L117.401 54.5462C116.822 54.9816 116.639 55.7699 116.967 56.416L116.983 56.4471C117.425 57.3165 116.954 58.3718 116.013 58.6246L115.781 58.6868C114.816 58.9457 114.271 59.9657 114.591 60.9117L114.96 62" stroke="#397AF9" />
    </svg>
  )
}

export default IllnessChillsBack
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { useForm } from "hooks/use-form";

import { ReactComponent as Email } from "images/email.svg";
import { ReactComponent as Warning } from "images/warning.svg";

import { UaeCyclingHeader } from "pages/home/uae-cycling-header/uae-cycling-header";

import styles from "./forgot-password.module.scss";
import { sendOTP } from "api/profile";

export const ForgotPassword = () => {
  const history = useHistory();

  const [error, setError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [form, changeHandler] = useForm({
    email: "",
  });

  const forgotPassword = useCallback(async (email: string) => {
    return sendOTP(email);
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError(false);
    }
    changeHandler(e);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleGoBack = () => {
    history.push("/sign-in");
  };

  const handleForgotPassword = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateEmail(form.email)) {
      setError(true);
      setLoginErrorMsg("Please enter a valid email address.");
      return;
    }
    setIsLoading(true);
    const response = await forgotPassword(form.email);
    setIsLoading(false);
    if (response?.code === 200) {
      setError(false);
      setIsLoading(false);
      setEmailSent(true);
    } else {
      setError(true);
      if (response?.code === 400) {
        setLoginErrorMsg(response.message ?? "");
      } else setLoginErrorMsg("Unknown error occurred. Please try again.");
    }
  };

  const changePasswordContent = () => {
    return (
      <div className={styles.sidebarContent}>
        <h1 className={styles.signInText}>Forgot Password</h1>
        <div className={styles.dontWorryEnter}>
          Don't worry! Enter your email below, and we'll send you a secure link
          to reset your password.
        </div>
        <form
          className={styles.form}
          onSubmit={handleForgotPassword}
          data-testid="forgot-password-form"
        >
          <div className={styles.inputsBlock}>
            <div className={styles.inputBlock}>
              <label htmlFor="email" className={styles.label}>
                Enter your email here
                <div className={styles.inputWrapper}>
                  {error && (
                    <div
                      className={clsx(styles.iconWrapper, styles.warningIcon)}
                    >
                      <Warning />
                    </div>
                  )}
                  <input
                    type="text"
                    name="email"
                    value={form.email}
                    placeholder="Email here"
                    onChange={handleInputChange}
                    className={clsx(
                      styles.forgotPasswordInput,
                      !error && form.email.length > 0 && styles.activeInput,
                      error && styles.errorInput
                    )}
                  />
                </div>
              </label>
            </div>
          </div>
          {error && <div className={styles.errorMessage}>{loginErrorMsg}</div>}
          <div className={styles.buttonBlock}>
            <button
              type="submit"
              className={styles.button}
              tabIndex={0}
              disabled={form.email.length === 0 || isLoading}
            >
              {isLoading ? <div className={styles.loader}></div> : "Send Email"}
            </button>
          </div>
          <div className={styles.backButton} onClick={handleGoBack}>
            Back to Login
          </div>
        </form>
      </div>
    );
  };

  const emailSuccessContent = () => {
    return (
      <div className={styles.emailSuccessContent}>
        <div className={styles.emailIcon}>
          <Email />
        </div>
        <div className={styles.signInText}>Check your Email</div>
        <div className={styles.weveSentAContainer}>
          <p className={styles.weveSentA}>
            {" "}
            We’ve sent a link to reset your password to your email.
          </p>
          <p className={styles.email}>{form.email}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.home}>
      <div className={styles.loginOverlay} />;
      <div className={styles.background}>
        <div className={styles.sidebarWrapper}>
          <UaeCyclingHeader />
          {emailSent ? emailSuccessContent() : changePasswordContent()}
        </div>
      </div>
    </div>
  );
};

const ONE_DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export enum DateRange {
  FourDays = 4 * ONE_DAY_MILLISECONDS,
  Week = 7 * ONE_DAY_MILLISECONDS,
  TwoWeeks = 14 * ONE_DAY_MILLISECONDS,
  EightDays = 8 * ONE_DAY_MILLISECONDS,
  Month = 30 * ONE_DAY_MILLISECONDS,
  TwoMonth = 60 * ONE_DAY_MILLISECONDS,
  ThreeMonth = 90 * ONE_DAY_MILLISECONDS,
  Year = 365 * ONE_DAY_MILLISECONDS,
}

export enum TimeRange {
  TenSeconds = "10Seconds",
  ThirtySeconds = "30Seconds",
  OneMinute = "1Minutes",
  FiveMinutes = "5Minutes",
  TenMinutes = "10Minutes",
  TwentyMinutes = "20Minutes",
  ThirtyMinutes = "30Minutes",
  SixtyMinutes = "60Minutes",
}

export interface WeekReportRange {
  label: string;
  startDate: string;
  endDate: string;
}

export enum MMPType {
  Absolute = "Absolute",
  Relative = "Relative",
}

export enum WorkOptions {
  kJ2000 = 2000,
  kJ3000 = 3000,
  kJ1900 = 1900,
  kJ2800 = 2800,
}

export enum DataTypeSelector {
  ABSOLUTE = "Absolute",
  RELATIVE = "Relative",
}

export enum DateGroupBy {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
}

export enum BarColors {
  Default = "#8C93A6",
  Old = "#EB545A",
  Average = "#FFE37F",
  New = "#008B32",
}

export type TeamPerformanceT = {
  athleteId: number;
  maximumOfMmp10SecondsAbsolute: number;
  maximumOfMmp10SecondsRelative: number;
  maximumOfMmp10SecondsComposite: number;
  maximumOfMmp30SecondsAbsolute: number;
  maximumOfMmp30SecondsRelative: number;
  maximumOfMmp30SecondsComposite: number;
  maximumOfMmp1MinutesAbsolute: number;
  maximumOfMmp1MinutesRelative: number;
  maximumOfMmp1MinutesComposite: number;
  maximumOfMmp5MinutesAbsolute: number;
  maximumOfMmp5MinutesRelative: number;
  maximumOfMmp5MinutesComposite: number;
  maximumOfMmp10MinutesAbsolute: number;
  maximumOfMmp10MinutesRelative: number;
  maximumOfMmp10MinutesComposite: number;
  maximumOfMmp20MinutesAbsolute: number;
  maximumOfMmp20MinutesRelative: number;
  maximumOfMmp20MinutesComposite: number;
  maximumOfMmp30MinutesAbsolute: number;
  maximumOfMmp30MinutesRelative: number;
  maximumOfMmp30MinutesComposite: number;
  maximumOfMmp60MinutesAbsolute: number;
  maximumOfMmp60MinutesRelative: number;
  maximumOfMmp60MinutesComposite: number;
};

export enum TimeOptions {
  thisWeek = 0,
  LastWeek = 1,
  LastThirtyDays = 2,
  LastSixtyDays = 3,
  LastNinetyDays = 4,
  LastThreeHundredSixtyFiveDays = 5,
  Range = 5,
}

export enum ComparisonTimeOptions {
  thisWeek = 0,
  LastWeek = 1,
  LastThirtyDays = 2,
  LastSixtyDays = 3,
  LastNinetyDays = 4,
  LastThreeHundredSixtyFiveDays = 5,
}

export enum DateRangeTimeOptions {
  TwoDateRangesComparison = 0,
}

export enum FlavorType {
  uaeMen = "uaeMen",
  adqWomen = "adqWomen",
}

export enum RoleType {
  coach = "coach",
  cyclist = "cyclist",
}

export enum RadarType {
  absolute = "Absolute MMP",
  relative = "Relative MMP",
  compound = "Compound Score",
}

export enum SourceType {
  coach = "coach",
  athlete = "athlete",
  ai = "ai",
  tp = "tp",
}

export enum ChangePasswordCode {
  SUCCESS = "SUCCESS",
  OLD_AND_NEW_PASS_SAME = "OLD_AND_NEW_PASS_SAME",
  OLD_PASSWORD_IS_WRONG = "OLD_PASSWORD_IS_WRONG",
  PASSWORD_CRITERIA_NOT_MATCHED = "PASSWORD_CRITERIA_NOT_MATCHED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export enum ChangePasswordMessage {
  SUCCESS = "Password Changed Successfully",
  OLD_AND_NEW_PASS_SAME = "The old and new passwords cannot be the same.",
  OLD_PASSWORD_IS_WRONG = "The old password you entered is incorrect. Please try again.",
  PASSWORD_CRITERIA_NOT_MATCHED = "The new password does not meet the required criteria. Password must be at least 6 characters, including upper and lowercase letters, a number, and a special character.",
  UNKNOWN_ERROR = "Failed to change password",
}

export enum ToolTipDataType {
  TIMESTAMP = "TIMESTAMP",
}

export enum UnitsType {
  STRING = "STRING",
  SVG = "SVG",
  TIMESTAMP = "TIMESTAMP",
}

export enum ComparisonType {
  Athlete = 1,
  Group = 2,
}

export enum BodyLocationType{
  HEAD_NECK="HEAD_NECK",
  SHOULDER="SHOULDER",
  ARM_ELBOW="ARM_ELBOW",
  HAND_WRIST="HAND_WRIST",
  BACK_SPINE="BACK_SPINE",
  HIP_PELVIS="HIP_PELVIS",
  LEG_KNEE="LEG_KNEE",
  ANKLE_FOOT="ANKLE_FOOT",
}

export enum AssessmentToolsType{
  RANGE_OF_MOTION="RANGE_OF_MOTION",
  STRENGTH="STRENGTH",
  FUNCTIONAL_MOVEMENT_SCREENING = "FUNCTIONAL_MOVEMENT_SCREENING",
  POSTURAL = "POSTURAL",
  BALANCE_AND_PROPRIOCEPTION = "BALANCE_AND_PROPRIOCEPTION",
  CARDIOVASCULAR_FITNESS = "CARDIOVASCULAR_FITNESS",
  FLEXIBILITY = "FLEXIBILITY",
  GAIT_ANALYSIS = "GAIT_ANALYSIS",
  PAIN = "PAIN",
  EDEMA = "EDEMA",
  SPORT_SPECIFIC_FUNCTIONAL_TESTS = "SPORT_SPECIFIC_FUNCTIONAL_TESTS",
  NEUROLOGICAL = "NEUROLOGICAL",
  PSYCHOLOGICAL = "PSYCHOLOGICAL",
  PERFORMANCE = "PERFORMANCE"
}

export enum ModalityUsedType{
  CRYOTHERAPY = "CRYOTHERAPY",
  THERMOTHERAPY = "THERMOTHERAPY",
  COMPRESSION_THERAPY = "COMPRESSION_THERAPY",
  MANUAL_THERAPY = "MANUAL_THERAPY",
  ELECTRICAL_STIMULATION = "ELECTRICAL_STIMULATION",
  ULTRASOUND_THERAPY = "ULTRASOUND_THERAPY",
  LASER_THERAPY = "LASER_THERAPY",
  KINESIOLOGY_TAPING = "KINESIOLOGY_TAPING",
  EXERCISE_THERAPY = "EXERCISE_THERAPY",
  HYDROTHERAPY = "HYDROTHERAPY",
  SHOCKWAVE_THERAPY = "SHOCKWAVE_THERAPY",
  TRACTION_THERAPY = "TRACTION_THERAPY",
  ACUPUNCTURE_AND_DRY_NEEDLING = "ACUPUNCTURE_AND_DRY_NEEDLING",
  BIOFEEDBACK = "BIOFEEDBACK"
}

export enum Type {
  INJURY_PREVENTION = "INJURY_PREVENTION",
  ACUTE_INJURY_MANAGEMENT = "ACUTE_INJURY_MANAGEMENT",
  POST_SURGICAL_REHABILITATION = "POST_SURGICAL_REHABILITATION",
  CHRONIC_INJURY_MANAGEMENT = "CHRONIC_INJURY_MANAGEMENT",
  PERFORMANCE_ENHANCEMENT = "PERFORMANCE_ENHANCEMENT"
}

export enum RehabilitationType {
  ACUTE="ACUTE",
  SUB_ACUTE="SUB_ACUTE",
  REHABILITATION="REHABILITATION",
  FUNCTIONAL="FUNCTIONAL",
  RETURN_TO_PLAY="RETURN_TO_PLAY"
}



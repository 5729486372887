import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import React, { useEffect, useMemo, useState } from "react";

import { countryCodesMapping } from "country-codes-mapping";

import { getAge, parseDate } from "utils";
import { getCurrentAthlete } from "store/slices/shared";
import { useSelector } from "hooks/app-hooks";

import styles from "./user-info.module.scss";
import { Athlete } from "types";

type UserInfoProps = {
  compareAthlete?: Athlete;
};

function useAthlete(compareAthlete?: Athlete) {
  const currentAthlete = useSelector(getCurrentAthlete);
  const [athlete, setAthlete] = useState<Athlete | undefined>(currentAthlete);

  useEffect(() => {
    if (!compareAthlete) {
      setAthlete(currentAthlete);
    } else {
      setAthlete(compareAthlete);
    }
  }, [compareAthlete, currentAthlete]);
  return athlete;
}

const UserInfo: React.FC<UserInfoProps> = ({ compareAthlete }) => {
  const athlete = useAthlete(compareAthlete);

  const age = useMemo(
    () => (athlete ? getAge(parseDate(athlete.dateOfBirth)) : undefined),
    [athlete]
  );
  const countryAlpha2Code = useMemo(
    () =>
      athlete?.countryCode && countryCodesMapping[athlete.countryCode]
        ? countryCodesMapping[athlete.countryCode].toLowerCase()
        : "ae",
    [athlete]
  );

  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code}.${DEFAULT_CDN_SUFFIX}`;

  if (!athlete) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatar}>
        <img className={styles.image} src={athlete.picture} alt="User avatar" />
      </div>
      <div className={styles.info}>
        <div className={styles.athleteHeader}>
          <div className={styles.name}>
            <span>{athlete.firstName}</span>{" "}
            <span className={styles.strong}>{athlete.lastName}</span>
          </div>
          <img
            src={flagUrl}
            className={styles.country}
            alt={athlete.country}
            title={athlete.country}
          />
        </div>
        <div className={styles.age}>
          <span>{age} Yrs</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserInfo);

const NauseaBack = ({ opacity }: { opacity: number }) => {
    return (
        <svg width="160" height="410" viewBox="0 0 160 410" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={opacity}>
            <g filter="url(#filter0_b_1766_75447)">
                <path opacity="0.9" d="M92.8336 19.4471C103.31 22.2543 109.169 31.5801 108.505 42.1842C108.078 49.0116 105.532 55.3421 102.847 61.5334C95.1618 79.2334 77.9361 76.3831 77.9361 76.3831L77.8948 76.372C77.8948 76.372 61.5848 70.2365 63.7836 51.0665C64.5525 44.3661 65.5142 37.6067 68.5582 31.4805C73.2836 21.969 83.0214 16.8179 93.4982 19.6252" fill="url(#paint0_linear_1766_75447)" fill-opacity="0.3" />
            </g>
            <g filter="url(#filter1_b_1766_75447)">
                <path opacity="0.9" d="M57.4992 23.8677C66.8925 18.4445 77.6296 20.896 84.6579 28.8641C89.1837 33.9937 91.86 40.2702 94.339 46.5469C101.421 64.4968 87.2249 74.6617 87.2249 74.6617L87.1879 74.6831C87.1879 74.6831 71.3164 81.8775 59.316 66.7675C55.1218 61.4859 51.0222 56.0263 48.8428 49.542C45.4585 39.4749 48.7018 28.9469 58.0951 23.5237" fill="url(#paint1_linear_1766_75447)" fill-opacity="0.3" />
            </g>
            <path opacity="0.9" d="M79.3188 18C90.1652 18 98.2381 25.4916 100.341 35.9064C101.695 42.6117 100.875 49.3853 99.8834 56.0607C97.0415 75.1466 79.665 76.8518 79.665 76.8518H79.6223C79.6223 76.8518 62.28 75.1466 59.4423 56.0607C58.4508 49.3896 57.6303 42.6117 58.985 35.9064C61.0877 25.4959 69.1605 18 80.0069 18" fill="#397AF9" fill-opacity="0.86" />
            <path d="M48.2692 48C35.7691 49.5 25.369 58.5 79.769 58.5C134.169 58.5 122.269 49.5 108.269 48" stroke="#81ACFF" stroke-width="0.5" stroke-linecap="round" />
            <path d="M48.273 34.5C30.273 35.5 16.0288 44 79.5951 44C143.161 44 127.773 34.5 108.273 34.5" stroke="#81ACFF" stroke-width="0.5" stroke-linecap="round" />
            <path d="M62.8484 65C55.9645 67 49.8916 71 80.671 71C111.45 71 104.246 67 96.7963 65" stroke="#81ACFF" stroke-width="0.5" stroke-linecap="round" />
            <defs>
                <filter id="filter0_b_1766_75447" x="59.5797" y="14.8613" width="52.976" height="65.6914" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1766_75447" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1766_75447" result="shape" />
                </filter>
                <filter id="filter1_b_1766_75447" x="43.5387" y="16.9619" width="56.7798" height="63.4326" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1766_75447" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1766_75447" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_1766_75447" x1="78.273" y1="78" x2="126.773" y2="20.5" gradientUnits="userSpaceOnUse">
                    <stop offset="0.279356" stop-color="#397AF9" />
                    <stop offset="1" stop-color="white" stop-opacity="0.1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1766_75447" x1="54.7729" y1="25.001" x2="76.2729" y2="72.501" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.1" />
                    <stop offset="0.563123" stop-color="#397AF9" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default NauseaBack
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./graph-ai-body.module.scss";
import ChatHistory from "./chat-history";
import GraphChart from "./graph-chart";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  getAthleteList,
  getCurrentAthlete,
} from "store/slices/shared";
import {
  getAiGraphChatHistory,
  getAiGraphChatQuery,
  getAiGraphComputeData,
  getAiGraphConfiguration,
  updateAiGraphConfig,
  updateMultipleAthleteConfig,
} from "api/ai-graph";
import { countryCodesMapping } from "country-codes-mapping";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import { ReactComponent as DropDown } from "images/dropdown.svg";
import { AthletePopup } from "./athlete-popup/athlete-popup";
import {
  fetchAiGraphId,
  setAiGraphId,
} from "store/slices/aiGraph";
import ChatAiBody from "./graph-ai-chat-body";
import GraphAiChatInput from "./graph-ai-chat-input";
import { AIComputeResponse, AiGraphConfigTurn, AiGraphInScope, Athlete, AthleteType, Axis, ChatBotMessage, Plot, PlotData, YAxisData } from "types";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import {
  COLOR_TOOLTIP,
  COLOR_WHITE,
} from "components/charts/colors";
import HorizontalLoader from "./components/horizontal-loader";
import { computeGraphOption } from "./utils/utils"


const GraphAiBody = () => {
  const dispatch = useDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const athleteList = useSelector(getAthleteList);
  const aiGraphId = useSelector((state: any) => state?.aiGraph?.aiGraphId);

  const [isGraphExpanded, setIsGraphExpanded] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [userMessage, setUserMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [togglePopup, setTogglePopup] = useState(false);
  const [chatBotMessages, setChatBotMessages] = useState<ChatBotMessage[]>([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [graphHistory, setGraphHistory] = useState<[]>([]);
  const [isNewChat, setIsNewChat] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [turnProcess, setTurnProcess] = useState<[]>([]);
  const [activateGraph, setActivateGraph] = useState(false);
  const [graphId, setGraphId] = useState(aiGraphId);
  const [historyId, setHistoryId] = useState("");
  const [computeData, setComputeData] = useState<AIComputeResponse | null>(null);
  const [latestMsgId, setLatestMsgId] = useState("");
  const [onDeleteGraph, setOnDeleteGraph] = useState(false);
  const [chatTitleUpdated, setChatTitleUpdated] = useState(false);
  const [filterAthleteDetails, setFilterAthleteDetails] = useState<AthleteType[] | []>([]);
  const [tempFilterAthleteDetails, setTempFilterAthleteDetails] = useState<AthleteType[] | []>(filterAthleteDetails);
  const [isSubmit, setIsSubmit] = useState(false)
  const [isNewSession,setIsNewSession]=useState(false)

 

  const handleExpandClick = () => {
    setIsGraphExpanded(!isGraphExpanded);
  };

  const handleInputChangeMultiline = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setUserMessage(e.target.value);
  };

  // history api call
  useEffect(() => {
    (async () => {
      const res = await getAiGraphChatHistory();
      const data = res?.reverse();
      setGraphHistory(data);
      if (res?.length == 0) {
        dispatch(setAiGraphId(""));
        setGraphId("");
        setHistoryId("");
        setChatBotMessages([
          {
            message: "Hello, How can I help you ?",
            isUser: false,
            isNewMessage: false,
            id: "",
            isDefaultMsg: true,
            turnSubprocesses: [],
          },
        ]);
      }
      setHistoryLoading(false);
    })();
  }, [onDeleteGraph, dispatch]);

  // to call History api when Clicked on new session
  const processHistory = async () => {
    const res = await getAiGraphChatHistory();
    const data = res?.reverse();
    setGraphHistory(data);
    setHistoryLoading(false);
  };

  // initally set GraphId either from redux or from historyId
  useEffect(() => {
    if (aiGraphId) {
      setGraphId(aiGraphId);
    }
    if (historyId) {
      setComputeData(null);
      setGraphData({});
      setActivateGraph(false);
      setGraphId(historyId);
      dispatch(setAiGraphId(historyId));
    }
  }, [aiGraphId, historyId, dispatch]);

  // default chat-bot message
  useEffect(() => {
    setChatBotMessages([
      {
        message: "Hello, How can I help you?",
        isUser: false,
        isNewMessage: false,
        id: "",
        isDefaultMsg: true,
        turnSubprocesses: [],
      },
    ]);
  }, [historyId]);

  // config api call & compute api call on refreshing the page
  useEffect(() => {
    (async () => {
      if (graphId && graphId === aiGraphId && !isNewSession) {
        try {
          const configResult = await getAiGraphConfiguration(graphId);
          setIsNewSession(false)
          if (
            configResult &&
            configResult?.conversation_turns?.turns?.length > 0
          ) {
            configResult?.conversation_turns?.turns?.map((turn: AiGraphConfigTurn) => {
              const historyMsg = {
                message: turn?.message,
                isUser: turn?.actor === "user",
                isNewMessage: false,
                id: turn?.id,
                isDefaultMsg: false,
                turnSubprocesses: turn?.turn_subprocesses,
              };
              setChatBotMessages((prevMsg) => [...prevMsg, historyMsg]);
            });

            setLatestMsgId(
              configResult?.conversation_turns?.turns?.[
                configResult?.conversation_turns?.turns?.length - 1
              ]?.id
            );
          }
          // to get filtered athlete name from config api
          if (
            configResult?.ai_graph_in_scope?.data_sources
              ?.datasource_group_specific
          ) {
            const groupSpecificMetadata = Object.values(
              configResult?.ai_graph_in_scope?.data_sources
                ?.datasource_group_specific
            ).flatMap((group: any) => group.map((item: any) => item.metadata));

            setFilterAthleteDetails(groupSpecificMetadata);
            setTempFilterAthleteDetails(groupSpecificMetadata);
          }
          setIsLoading(true);
          const computeDataResponse = await getAiGraphComputeData(graphId);

          // set loading = false, if no computed data is available
          if (computeDataResponse?.ai_graph_in_scope?.ai_generated_graphs === undefined)
            setIsLoading(false);
          // check if computed data is available
          const computedData =
            computeDataResponse?.ai_graph_in_scope?.ai_generated_graphs[0]
              ?.computed_data;
          if (
            computedData?.plots_data?.length > 0 &&
            computedData?.x_axes_data?.length > 0 &&
            computedData?.y_axes_data?.length > 0
          ) {
            setActivateGraph(true);
            setComputeData(computeDataResponse);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Failed to fetch data:", error);
        }
      }
    })();
  }, [graphId, aiGraphId,isNewSession]);

  // //ask api call
  useEffect(() => {
    if (graphId && isButtonClicked) {
      setIsButtonClicked(false);
      const handleChunkReceive = (chunk: string) => {
        const lines = chunk.split("\n");

        lines.forEach((line) => {
          if (line.startsWith("data: ")) {
            const jsonData = line.substring(6);

            try {
              const parsedChunk = JSON.parse(jsonData);
              const id = parsedChunk.id;
              setLatestMsgId(id);
              const message = parsedChunk.message || null;
              const turnSubprocesses = parsedChunk.turn_subprocesses;
              const isUser = parsedChunk.actor !== "assistant";
              setIsLoading(!message && true);
              const newMessage = {
                id,
                message,
                isUser,
                turnSubprocesses,
                isNewMessage: true,
                isDefaultMsg: false,
              };
              setTurnProcess(turnSubprocesses);

              setChatBotMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages?.length - 1] = newMessage;
                return updatedMessages;
              });
            } catch (err) {
              setIsLoading(false);
              console.error("Failed to parse JSON:", err);
            }
          }
        });
      };

      (async () => {
        const resp = await getAiGraphChatQuery(
          graphId,
          userMessage,
          handleChunkReceive
        );
        await getAiGraphConfiguration(graphId);
        setIsLoading(true);
        try {
          const res = await getAiGraphComputeData(graphId);
          setActivateGraph(true);
          setComputeData(res);
        }
        catch (error) {
          console.error("compute data api failed:", error);
          setComputeData(null);
          setGraphData({});
        }
        setIsLoading(false);
        // calling history api to re-render the ai_generated message
        processHistory();
        setChatTitleUpdated(true);
        setUserMessage("");
      })();
    }
  }, [graphId, userMessage, isButtonClicked, chatBotMessages]);


  // to assign new graphId when New session is clicked
  useEffect(() => {
    if (isNewChat) {
      if (chatBotMessages?.length > 1) {
        setGraphId("");
        dispatch(setAiGraphId(""));
        setIsNewChat(false);
        setChatBotMessages([
          {
            message: "Hello, How can I help you?",
            isUser: false,
            isNewMessage: false,
            id: "",
            isDefaultMsg: true,
            turnSubprocesses: [],
          },
        ]);
        setFilterAthleteDetails([]);
        setTempFilterAthleteDetails([]);
        setComputeData(null);
        setGraphData({});
        setActivateGraph(false);
      }
    }
  }, [isNewChat, chatBotMessages, dispatch, athleteList]);

  const handleSendClick = async (): Promise<void> => {
    if (!currentAthlete?.id || userMessage.trim() === "") return;
    if (!graphId) {
      const apiPayload = filterAthleteDetails?.map((athlete: AthleteType) => ({
        type: "AthleteDataSourceMetadata",
        athlete_id: athlete?.athlete_id.toString(),
        athlete_image_identifier: athlete?.athlete_image_identifier,
        athlete_flag_identifier: athlete?.athlete_flag_identifier,
        athlete_full_name: athlete?.athlete_full_name,
      }));
      dispatch(fetchAiGraphId(apiPayload));
      setIsNewChat(false);
      setIsNewSession(true)
    }
    setIsButtonClicked(true);
    setIsLoading(true);
    setChatBotMessages((prevMessages) => [
      ...prevMessages,
      {
        message: userMessage,
        isUser: true,
        isNewMessage: true,
        id: "24",
        isDefaultMsg: false,
        turnSubprocesses: [],
      },
      {
        message: "",
        isUser: false,
        isNewMessage: false,
        id: "24",
        isDefaultMsg: false,
        turnSubprocesses: [],
      },
    ]);
  };

  // to create graph Option from the compute Data response
  useEffect(() => {
    if (computeData?.ai_graph_in_scope) {
      (async () => {
        const configOption= computeGraphOption(computeData)
        setGraphData(configOption);
      })();
    }
  }, [computeData, filterAthleteDetails]);

  // to call updateGraphConfig and compute Data when athlete change
  const handlePatchConfig = useCallback(
    async (data) => {
      const athleteConfigRes = await updateMultipleAthleteConfig(graphId, data);
      setIsLoading(true);
      const resp = await getAiGraphComputeData(graphId);
      setActivateGraph(true);
      setComputeData(resp);
      setIsLoading(false);
    },
    [graphId]
  );

  // to filter the selected popup
  const handleFilterPopup = useCallback(async () => {
    setIsSubmit(true);
    if (tempFilterAthleteDetails && tempFilterAthleteDetails.length > 0) {
      setFilterAthleteDetails(tempFilterAthleteDetails);
      if (graphId) {
        handlePatchConfig(tempFilterAthleteDetails);
      }
    }
    setIsSubmit(false);
    setTogglePopup(false);
  }, [handlePatchConfig, graphId, tempFilterAthleteDetails]);

  // to clear the selected athlete
  const handleClearAll = async () => {
    const athleteCountryAlpha2Code =
      athleteList?.[0]?.countryCode &&
        countryCodesMapping[athleteList?.[0].countryCode]
        ? countryCodesMapping[athleteList?.[0]?.countryCode]
        : "AE";
    const athleteFlagUrl = `${DEFAULT_CDN_URL}${athleteCountryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;
    const data = [
      {
        athlete_flag_identifier: athleteFlagUrl,
        athlete_full_name: athleteList?.[0]?.fullName,
        athlete_id: athleteList?.[0]?.id?.toString(),
        athlete_image_identifier: athleteList?.[0]?.picture,
        athlete_profile_pic: null,
        type: "AthleteDataSourceMetadata",
      },
    ];
    setFilterAthleteDetails([]);
    setTempFilterAthleteDetails([]);
    // handlePatchConfig(data)
    // handlePatchConfig(currentAthlete?.id);
    setTogglePopup(false);
  };

  const handleOnClose = () => {
    if (!isSubmit) {
      setTempFilterAthleteDetails(filterAthleteDetails);
    }
    setTogglePopup(false);
  };

  return (
    <div className={styles.container}>
      <div className={clsx(!isGraphExpanded && [styles.leftSection])}>
        {!isGraphExpanded && (
          <ChatHistory
            graphHistory={graphHistory}
            setIsNewChat={setIsNewChat}
            isNewChat={isNewChat}
            loading={historyLoading}
            setHistoryId={setHistoryId}
            historyId={historyId}
            setOnDeleteGraph={setOnDeleteGraph}
            onDeleteGraph={onDeleteGraph}
            chatTitleUpdated={chatTitleUpdated}
            setTitleUpdateCompleted={setChatTitleUpdated}
          />
        )}
      </div>
      <div
        className={clsx(
          !isGraphExpanded ? [styles.graphWrapper] : [styles?.graphExpand]
        )}
      >
        <div
          className={
            !isGraphExpanded ? styles.topSection : styles.topSectionExpanded
          }
        >
          {isGraphExpanded ? (
            <ResizableBox
              width={window.innerWidth * 0.6}
              height={window.innerHeight}
              minConstraints={[500, 500]}
              maxConstraints={[120000, 1800]}
              axis="x"
              handle={<div className={styles.customHandle} />}
              resizeHandles={["e"]} // Allow resizing from all sides
            >
              <div
                className={styles.profile}
                onClick={() => setTogglePopup(true)}
              >
                {filterAthleteDetails?.length <= 0 ? (
                  <div className={styles.profile}>
                    <span className={styles.name}>Select Athletes</span>
                    <DropDown className={styles.dropdown} />
                  </div>
                ) : (
                  <>
                  
                    <img
                      src={filterAthleteDetails?.[0]?.athlete_image_identifier}
                      alt="Profile"
                      className={styles.athleteImage}
                    />
                    
                    <span className={styles.name}>
                      {filterAthleteDetails?.[0]?.athlete_full_name}{" "}
                    </span>

                    <img
                      src={filterAthleteDetails?.[0]?.athlete_flag_identifier}
                      alt={filterAthleteDetails?.[0]?.athlete_flag_identifier}
                      className={styles.athleteFlag}
                    />
                    <DropDown className={styles.dropdown} />
                    <div className={styles?.athleteCounterText}>
                      <span className={styles.athleteCounter}>
                        {" "}
                        {filterAthleteDetails?.length > 1 &&
                          `+${filterAthleteDetails?.length - 1}`}
                      </span>
                      {filterAthleteDetails?.length > 1 && (
                        <p className={styles.athletesText}>Athletes</p>
                      )}
                    </div>
                  </>
                )}
              </div>

              <GraphChart
                className={clsx(
                  isGraphExpanded
                    ? [styles.graphFullHeight, styles.leftSectionExpanded]
                    : styles?.graphContainer
                )}
                onExpand={handleExpandClick}
                isExpanded={isGraphExpanded}
                graphData={graphData}
                filterAthleteDetails={filterAthleteDetails}
                activateGraph={activateGraph}
                isLoading={isLoading}
              />
            </ResizableBox>
          ) : (
            <>
              {!isGraphExpanded && (
                <div
                  className={styles.userInfo}
                  onClick={() => setTogglePopup(true)}
                >
                  {filterAthleteDetails?.length <= 0 ? (
                    <div className={styles.profile}>
                      <span className={styles.name}>Select Athletes</span>
                      <DropDown className={styles.dropdown} />
                    </div>
                  ) : (
                    <>
                      <div className={styles.profile}>
                        <img
                          src={
                            filterAthleteDetails?.[0]?.athlete_image_identifier
                          }
                          alt="Profile"
                          className={styles.athleteImage}
                        />
                        <span className={styles.name}>
                          {filterAthleteDetails?.[0]?.athlete_full_name}{" "}
                        </span>
                      </div>
                      <div className={styles.profileInfo}>
                        <img
                          src={
                            filterAthleteDetails?.[0]?.athlete_flag_identifier
                          }
                          alt={
                            filterAthleteDetails?.[0]?.athlete_flag_identifier
                          }
                          className={styles.athleteFlag}
                        />
                        <DropDown className={styles.dropdown} />
                      </div>
                      <div className={styles?.athleteCounterText}>
                        <span className={styles.athleteCounter}>
                          {" "}
                          {filterAthleteDetails?.length > 1 &&
                            `+${filterAthleteDetails?.length - 1}`}
                        </span>
                        {filterAthleteDetails?.length > 1 && (
                          <p className={styles.athletesText}>Athletes</p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
              <GraphChart
                className={clsx(
                  isGraphExpanded
                    ? [styles.graphFullHeight, styles.leftSectionExpanded]
                    : styles?.graphContainer
                )}
                onExpand={handleExpandClick}
                isExpanded={isGraphExpanded}
                graphData={graphData}
                activateGraph={activateGraph}
                isLoading={isLoading}
                filterAthleteDetails={filterAthleteDetails}

              />
              {(isLoading) && <HorizontalLoader />}
            </>
          )}
        </div>

        <div className={styles.chatWrapper}>
          <ChatAiBody
            isExpanded={isGraphExpanded}
            isLoading={isLoading}
            messages={chatBotMessages}
            latestMsgId={latestMsgId}
          />

          <GraphAiChatInput
            isExpanded={isGraphExpanded}
            isLoading={isLoading}
            name="chatInput"
            onSend={handleSendClick}
            onChangeMultiline={handleInputChangeMultiline}
            disable={filterAthleteDetails?.length <= 0 ? true : false}
          />
        </div>
      </div>
      {/* </div> */}
      {togglePopup && (
        <AthletePopup
          handleClearAll={handleClearAll}
          handleSubmit={handleFilterPopup}
          athleteList={athleteList}
          onClose={handleOnClose}
          setTempFilterAthleteDetails={setTempFilterAthleteDetails}
          tempFilterAthleteDetails={tempFilterAthleteDetails}
          multiSelect={true}
        />
      )}
    </div>
  );
};

export default GraphAiBody;

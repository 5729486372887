import { FC, ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import { ReactComponent as BackNavIcon } from "images/back_nav_icon.svg";

import styles from "./header.module.scss";

import { TopMenu } from "components/top-menu/top-menu";
import { CoachInfo } from "components/coach-info/coach-info";
import { SignOut } from "components/sign-out/sign-out";
import { useSelector } from "hooks/app-hooks";
import { getAthleteList, getCurrentComparisonData } from "store/slices/shared";
import { Athlete, Group } from "types";
import { GroupInfo } from "./group-info/group-info";
import UserInfo from "./user-info/user-info";
import { AddData } from "components/add-data/add-data";
import { useHistory } from "react-router-dom";
import { SettingsBtn } from "components/settings-btn/settings";

type Props = {
  isCyclist?: boolean;
  hideBottom?: boolean;
  showTopMenu?: boolean;
  customHeader?: ReactNode;
};
export const Header: FC<Props> = ({
  isCyclist,
  hideBottom,
  showTopMenu = false,
  customHeader,
}) => {
  const currentComparison = useSelector(getCurrentComparisonData("all"));
  const athleteList = useSelector(getAthleteList);
  const history = useHistory();

  const [comparisonData, setComparisonData] = useState<{
    group: Group | null;
    athlete: Athlete | null;
  }>({ group: null, athlete: null });

  useEffect(() => {
    const fetchComparisonData = async () => {
      let group = null,
        athlete = null;

      if (currentComparison?.type === "group" && currentComparison.group) {
        group = currentComparison.group;
      } else if (
        currentComparison?.type === "individual" &&
        currentComparison.id
      ) {
        athlete =
          athleteList.find((ath) => ath.id === currentComparison.id) || null;
      }

      setComparisonData({ group, athlete });
    };

    fetchComparisonData();
  }, [currentComparison, athleteList]);

  const renderComparisonInfo = () => {
    if (comparisonData.group && !comparisonData.athlete) {
      const memberImages = comparisonData.group.athleteIds.map((id) => {
        return (
          athleteList.find((athlete) => athlete.id === id)?.picture ||
          "https://cycling-bucket.obs.ae-ad-1.g42cloud.com/avatar/men/coach/coach.png"
        );
      });

      return (
        <>
          <span className={styles.versus}>VS</span>
          <GroupInfo
            groupName={comparisonData.group.name}
            memberImages={memberImages}
          />
        </>
      );
    } else if (!comparisonData.group && comparisonData.athlete) {
      return (
        <>
          <span className={styles.versus}>VS</span>
          <UserInfo compareAthlete={comparisonData.athlete} />
        </>
      );
    }

    return null;
  };

  const handleBackNav = async () => {
    history.push("/athlete-selection");
  };


  return (
    <>
      <div className={clsx(styles.header, hideBottom && styles.hideBottom)}>
        {customHeader ?? (
          <div className={styles.athleteGroup}>
            {!isCyclist && (
              <BackNavIcon className={styles.backNav} onClick={handleBackNav} />
            )}
            <UserInfo />
            {!isCyclist && renderComparisonInfo()}
          </div>
        )}
        <div className={styles.topLeft}>
          {!isCyclist ? (
            <CoachInfo className={styles.coachButton} />
          ) : (
            <div className={styles.btnOptions}>
              <SettingsBtn className={styles.signOutButton} />
              <SignOut className={styles.signOutButton} />
            </div>
          )}
        </div>
      </div>
      {showTopMenu && !isCyclist && (
        <div className={styles.topMenu}>
          {/* <TopMenu menuItems={tabOption}/> */}
          <div className={styles.additionalComps}>
            <AddData />
          </div>
        </div>
      )}
      {/* <ChatBot /> */}
    </>
  );
};

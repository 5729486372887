import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { ReactComponent as RadioButton } from "images/radio-button.svg";
import { ReactComponent as SelectedRadioButton } from "images/selected-radio-button.svg";
import styles from "./pinned-chart-popup.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";
import { useSelector } from "hooks/app-hooks";
import { getCurrentAthlete } from "store/slices/shared";
import { getIsCyclist } from "store/slices/auth";
import CountdownTimer from "components/add-data/success-countdown";
import { addAiGraphPinTab, getAiGraphAllPinTabs } from "api/ai-graph";
import { AthleteType } from "types";

type Props = {
  onClose: () => void;
  filterAthleteDetails:AthleteType[]
};

type TabOption = {
  tab_id: string;
  tab_name: string;
};

export const PinChartPopup: FC<Props> = ({ onClose,filterAthleteDetails }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [tabOption, setTabOptions] = useState<TabOption[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Record<string, string>>({});
  const aiGraphId = useSelector((state: any) => state?.aiGraph?.aiGraphId);

  const handleRadioChange = (option: string, tabId: string) => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setSelectedOption({ [tabId]: option });
  };

  const handleSubmitClick = async (): Promise<void> => {
  try {
    const response = await addAiGraphPinTab(
      aiGraphId,
      Object.values(selectedOption)[0] || tabOption?.[0]?.tab_name,
      Object.keys(selectedOption)[0] || tabOption?.[0]?.tab_id,
    );
    if (!response) {
      setErrorMessage("Failed to submit data");
    } else {
      setSuccess(true);
    }
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      setErrorMessage(error.response.data.message);
    } else {
      setErrorMessage("An error occurred while submitting data");
    }}
  };

  // api call to get Tab options
  useEffect(() => {
    if(filterAthleteDetails?.length > 1){
      (async () => {
        const tabsResponse = await getAiGraphAllPinTabs("multiple_athletes");
        setTabOptions(tabsResponse);
      })();
    }
    if(filterAthleteDetails?.length === 1) {
      (async () => {
        const tabsResponse = await getAiGraphAllPinTabs("single_athlete");
        setTabOptions(tabsResponse);
      })();
    }
  }, [filterAthleteDetails]);

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>Pin Chart</h3>
            <Close
              className={styles.closeButton}
              onClick={onClose}
              aria-label="Close"
              title="Close"
            />
          </div>
          {success ? (
            <div className={styles.countDown}>
              <CountdownTimer duration={3} onComplete={onClose} />
            </div>
          ) : (
            <div className={styles.container}>
              <div>
                {(tabOption && tabOption?.length > 0) ?
                  tabOption?.map((option: TabOption, index) => (
                    <label key={index}>
                      <input
                      key={option?.tab_id}
                        type="radio"
                        name={option?.tab_id}
                        value={option?.tab_id}
                        checked={Object.keys(selectedOption).length === 0 && index === 0 ? true:
                          Object?.keys(selectedOption)?.[0] ==
                          option?.tab_id
                        }
                        onChange={() =>
                          handleRadioChange(option?.tab_name, option?.tab_id)
                        }
                      />
                      <div className={styles.options}>
                        {Object.keys(selectedOption).length === 0 && index === 0 || Object?.keys(selectedOption)?.[0] ===
                          option?.tab_id ? (
                          <SelectedRadioButton />
                        ) : (
                          <RadioButton className={styles.radioButtonIcon} />
                        )}
                        {option?.tab_name}
                      </div>
                    </label>))
                    : <div className={styles.noTabOptionsText}>The ability to pin to a group or team? 🛠️ Yeah, still cooking. 🍳</div>}
              </div>
            </div>
          )}
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
          <div className={styles.footer}>
            <PrimaryButton
              disabled={tabOption?.length === 0}
              className={styles.pinButton}
              onClick={handleSubmitClick}
              title={"Pin"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect } from "react";

import { APIBoundedChartResponse } from "api/chart";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { fetchChartData } from "store/slices/chart";
import { GetChartMeta } from "types";

import {
  getCurrentAthlete,
  getCurrentComparison,
  getCurrentDateRange,
  getCurrentGroupBy,
} from "store/slices/shared";

export function useBoundedCharts(getChartMeta: GetChartMeta, name: string) {
  const dispatch = useDispatch();
  const currentDateRange = useSelector(getCurrentDateRange);
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentComparison = useSelector(getCurrentComparison);
  const currentDateUnit = useSelector(getCurrentGroupBy);

  useEffect(() => {
    if (currentAthlete) {
      dispatch(
        fetchChartData<APIBoundedChartResponse>(
          getChartMeta(
            `athlete${name}`,
            currentDateRange,
            [currentAthlete.id],
            currentDateUnit
          )
        )
      );
    }
  }, [
    dispatch,
    currentDateRange,
    currentAthlete,
    getChartMeta,
    name,
    currentDateUnit,
  ]);

  useEffect(() => {
    if (currentComparison) {
      dispatch(
        fetchChartData<APIBoundedChartResponse>(
          getChartMeta(
            `comparison${name}`,
            currentDateRange,
            currentComparison,
            currentDateUnit
          )
        )
      );
    }
  }, [
    dispatch,
    currentDateRange,
    currentComparison,
    getChartMeta,
    name,
    currentDateUnit,
  ]);

  const athletePoints = useSelector(
    (state) => state.chart[`athlete${name}Points`]
  );

  return [athletePoints] as const;
}

// chat-header.tsx

import { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from "./chat-header.module.scss";
import { ReactComponent as CloseIcon } from "images/chat-close.svg";
import { ReactComponent as ChatIcon } from "images/chat.svg";
import { ReactComponent as Expand } from "images/expand.svg";
import { ReactComponent as Collaps } from "images/collaps.svg";
import { ReactComponent as VerticalDots } from "images/vertical_dots.svg";
import { DropdownMenuItemComponent } from "components/dropdown-profile-btns/dropdown-profile";
import { FeedbackPopup } from "components/feedback-popup/feedback-popup";
import { useSelector } from "react-redux";
import { getCurrentAthlete } from "store/slices/shared";
import { clearConversation, getUserChatFeedback } from "api/messages";

type Props = {
  onClose: () => void;
  onMinimize: () => void;
  isMinimized: boolean;
  onExpand: () => void;
  threadId?: string | null;
  setClearHistory?: (value: boolean) => void
};

export const ChatHeader: FC<Props> = ({
  onClose,
  onMinimize,
  isMinimized,
  onExpand,
  threadId,
  setClearHistory
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [userMessage, setUserMessage] = useState("")
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const currentAthlete = useSelector(getCurrentAthlete);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleMinimizeClick = () => {
    onMinimize();
  };

  useEffect(() => {
    (async () => {
      if (threadId && isSubmitClick && userMessage !== "") {
        await getUserChatFeedback(
          currentAthlete?.id,
          threadId,
          "",
          "thumbs_down",
          "",
          userMessage
        );
        setUserMessage("")
        setIsSubmitClick(false);
        setIsSuccess(true);
        // setShowFeedbackPopup(false)
      }
    })();
  }, [isSubmitClick, currentAthlete, threadId, userMessage]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const handleExpandClick = () => {
    setIsExpanded((prev) => !prev);
    onExpand();
  };

  const toggleDropdown = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    setDropdownVisible(!isDropdownVisible);
  };

  const handleResetClick = async () => {
    const res = await clearConversation(currentAthlete?.id)
    setDropdownVisible(false)
    setClearHistory?.(true)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible]);

  return (
    <div
      className={clsx(styles.headerWrapper, isMinimized && styles.minimized)}
    >
      <span className={styles.headerTitle} onClick={onClose}>
        AI Companion Coach
      </span>
      <div>
        {isMinimized ? (
          <ChatIcon className={styles.iconButton} onClick={onMinimize} />
        ) : (
          <>
            <VerticalDots
              className={styles.verticalDots}
              onClick={toggleDropdown}
            />

            {!isExpanded ? (
              <Collaps
                className={styles.ecButton}
                onClick={handleExpandClick}
              />
            ) : (
              <Expand className={styles.ecButton} onClick={handleExpandClick} />
            )}
            <CloseIcon className={styles.iconButton} onClick={onClose} />
          </>
        )}

        {isDropdownVisible && (
          <div className={styles.dropdownWrapper} ref={dropdownRef}>
            <div className={styles.dropdown}>
              <ul className={styles.dropdownMenu}>
                <li>
                  <DropdownMenuItemComponent
                    text="Reset Conversation"
                    className={styles.dropdownMenu}
                    handleClick={async (event) => {
                      event.stopPropagation();
                      handleResetClick()
                    }}
                  />
                  <DropdownMenuItemComponent
                    text="Send Feedback"
                    className={styles.dropdownMenu}
                    handleClick={async (event) => {
                      event.stopPropagation();

                      setShowFeedbackPopup(true);
                      setDropdownVisible(false);
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        )}
        {showFeedbackPopup && (
          <FeedbackPopup
            onClose={() => setShowFeedbackPopup(false)}
            setIsSubmitClick={setIsSubmitClick}
            setUserMessage={setUserMessage}
            isSuccess={isSuccess}
            title={"Provide experience feedback"}
            displayOption={false}
            userMessage={userMessage}
          />
        )}
      </div>
    </div>
  );
};

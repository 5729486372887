import { FC, useMemo } from "react";

import { ChartData } from "types";

import { Loading } from "components/loading/loading";
import {
  BORDER_SCATTER_DOT,
  GRADIENT_AREA_RED,
  TEAM_PERFORMANCE_BAR_COLOR_TWO,
  TEAM_PERFORMANCE_BAR_COLOR_ONE,
} from "components/charts/colors";

import styles from "./measurements.module.scss";
import { BarScatterChart } from "../bar-scatter-chart/bar-scatter-chart";
import { BarScatterModel, ChartValue } from "api/chart";
import { ToolTipDataType, UnitsType } from "enums";

type Props = {
  data?: ChartData;
  formatLabels?: boolean;
  showToolTipTitle?: boolean;
  showGroupBySelector?: boolean;
  addBorder: boolean;
};

export const Measurements: FC<Props> = ({
  data,
  formatLabels = true,
  showToolTipTitle = true,
  showGroupBySelector = true,
  addBorder = false,
}) => {
  const tssIfSeries = useMemo(() => {
    const series: BarScatterModel[] = [];
    const tssValue: ChartValue[] =
      data?.values.tss?.map((e) => {
        return {
          value: Number(e.value.toFixed(0)),
          itemStyle: { decimalPoint: 0 },
        };
      }) || [];
    const intensityFactorValue: ChartValue[] = data?.values.intensityFactor || [];

    if (tssValue && tssValue.length > 0) {
      series.push({
        name: `TSS`,
        color: TEAM_PERFORMANCE_BAR_COLOR_ONE,
        areaColor: GRADIENT_AREA_RED,
        chartType: "bar",
        hideInLegend: false,
        yAxisIndex: 0,
        values: tssValue,
      });
    }

    if (intensityFactorValue && intensityFactorValue.length > 0) {
      series.push({
        name: `IF`,
        color: TEAM_PERFORMANCE_BAR_COLOR_TWO,
        areaColor: GRADIENT_AREA_RED,
        chartType: "scatter",
        hideInLegend: false,
        yAxisIndex: 1,
        values: intensityFactorValue,
        min: 0,
        borderColor: BORDER_SCATTER_DOT,
        showMainCircle: false,
        colorRange: [
          { color: "#F93939", range: [0.8, Infinity], title: "High" },
          { color: "#A1DE54", range: [0.59, 0.8], title: "Medium" },
          { color: "#DEA754", range: [-Infinity, 0.59], title: "Low" },
        ],
      });
    }

    return series;
  }, [data?.values.tss, data?.values.intensityFactor]);

  const durationElevationSeries = useMemo(() => {
    const series: BarScatterModel[] = [];
    const durationValue: ChartValue[] = data?.values.durationInHour || [];
    const elevationValue: ChartValue[] =
      data?.values.climbInMeter?.map((e) => {
        return {
          value: Number(e.value.toFixed(0)),
          itemStyle: { decimalPoint: 0 },
        };
      }) || [];

    if (durationValue && durationValue.length > 0) {
      series.push({
        units: {
          type: UnitsType.TIMESTAMP,
          value: "h:m:s",
        },
        dataType: ToolTipDataType.TIMESTAMP,
        name: `Duration`,
        color: TEAM_PERFORMANCE_BAR_COLOR_ONE,
        areaColor: GRADIENT_AREA_RED,
        chartType: "bar",
        hideInLegend: false,
        yAxisIndex: 0,
        min: 0,
        values: durationValue,
      });
    }

    if (elevationValue && elevationValue.length > 0) {
      series.push({
        units: {
          type: UnitsType.STRING,
          value: "m",
        },
        name: `Elevation`,
        color: TEAM_PERFORMANCE_BAR_COLOR_TWO,
        areaColor: GRADIENT_AREA_RED,
        chartType: "scatter",
        hideInLegend: false,
        yAxisIndex: 1,
        values: elevationValue,
        min: 0,
        borderColor: BORDER_SCATTER_DOT,
      });
    }

    return series;
  }, [data?.values.durationInHour, data?.values.climbInMeter]);

  const distanceDurationSeries = useMemo(() => {
    const series: BarScatterModel[] = [];
    const durationValue: ChartValue[] = data?.values.durationInHour || [];
    const distanceValue: ChartValue[] =
      data?.values.distanceInKm?.map((e) => {
        return {
          value: e.value,
          itemStyle: { decimalPoint: 1 },
        };
      }) || [];

    if (durationValue && durationValue.length > 0) {
      series.push({
        units: {
          type: UnitsType.TIMESTAMP,
          value: "h:m:s",
        },
        name: "Duration",
        dataType: ToolTipDataType.TIMESTAMP,
        color: TEAM_PERFORMANCE_BAR_COLOR_TWO,
        areaColor: GRADIENT_AREA_RED,
        chartType: "bar",
        hideInLegend: false,
        values: durationValue,
        min: 0,

        yAxisIndex: 0,
      });
    }

    if (distanceValue && distanceValue.length > 0) {
      series.push({
        units: {
          type: UnitsType.STRING,
          value: "Km",
        },
        name: "Distance",
        color: TEAM_PERFORMANCE_BAR_COLOR_ONE,
        areaColor: GRADIENT_AREA_RED,
        chartType: "scatter",
        borderColor: BORDER_SCATTER_DOT,
        hideInLegend: false,
        values: distanceValue,
        yAxisIndex: 1,
      });
    }

    return series;
  }, [data?.values.durationInHour, data?.values.distanceInKm]);

  const workIfSeries = useMemo(() => {
    const series: BarScatterModel[] = [];
    const workValue: ChartValue[] =
      data?.values.workInKj?.map((e) => {
        return {
          value: Number(e.value.toFixed(0)),
          itemStyle: { decimalPoint: 0 },
        };
      }) || [];
    const intensityFactorValue: ChartValue[] = data?.values.intensityFactor || [];

    if (workValue && workValue.length > 0) {
      series.push({
        units: {
          type: UnitsType.STRING,
          value: "kJ",
        },
        name: "Work",
        color: TEAM_PERFORMANCE_BAR_COLOR_TWO,
        areaColor: GRADIENT_AREA_RED,
        chartType: "bar",
        hideInLegend: false,
        values: workValue,
        yAxisIndex: 0,
      });
    }

    if (intensityFactorValue && intensityFactorValue.length > 0) {
      series.push({
        name: "IF",
        color: TEAM_PERFORMANCE_BAR_COLOR_ONE,
        areaColor: GRADIENT_AREA_RED,
        min: 0,
        chartType: "scatter",
        borderColor: BORDER_SCATTER_DOT,
        hideInLegend: false,
        values: intensityFactorValue,
        yAxisIndex: 1,
        showMainCircle: false,
        colorRange: [
          { color: "#F93939", range: [0.8, Infinity], title: "High" },
          { color: "#A1DE54", range: [0.59, 0.8], title: "Medium" },
          { color: "#DEA754", range: [-Infinity, 0.59], title: "Low" },
        ],
      });
    }

    return series;
  }, [data?.values.workInKj, data?.values.intensityFactor]);

  if (data?.values === undefined) {
    return (
      <div className={styles.content}>
        <div className={styles.chart}>
          <Loading />
        </div>
        <div className={styles.chart}>
          <Loading />
        </div>
        <div className={styles.chart}>
          <Loading />
        </div>
        <div className={styles.chart}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.mainWrapper}>
      <BarScatterChart
        addBorder={addBorder}
        chartTitle="TSS & IF"
        data={data}
        dataArray={tssIfSeries}
        formatLabels={formatLabels}
        showToolTipTitle={showToolTipTitle}
        showGroupBySelector={showGroupBySelector}
      />
      <BarScatterChart
        addBorder={addBorder}
        chartTitle="Duration & Distance"
        data={data}
        dataArray={distanceDurationSeries}
        formatLabels={formatLabels}
        showToolTipTitle={showToolTipTitle}
        showGroupBySelector={showGroupBySelector}
      />
      <BarScatterChart
        addBorder={addBorder}
        chartTitle="Duration & Elevation"
        data={data}
        dataArray={durationElevationSeries}
        formatLabels={formatLabels}
        showToolTipTitle={showToolTipTitle}
        showGroupBySelector={showGroupBySelector}
      />
      <BarScatterChart
        addBorder={addBorder}
        chartTitle="Work & IF"
        data={data}
        dataArray={workIfSeries}
        formatLabels={formatLabels}
        showToolTipTitle={showToolTipTitle}
        showGroupBySelector={showGroupBySelector}
      />
    </div>
  );
};

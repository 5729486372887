import { configureStore } from "@reduxjs/toolkit";

import { auth } from "store/slices/auth";
import { shared } from "store/slices/shared";
import { wellness } from "store/slices/wellness";
import { performance } from "store/slices/performance";
import { chart } from "store/slices/chart";
import { clientConfig } from "store/slices/clientConfig";
import { health } from "./slices/health";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { aiGraph } from "./slices/aiGraph";

const healthPersistConfig = {
  key: "health",
  storage,
};

const aiGraphPersistConfig = {
  key: "aiGraph",
  storage,
};

const persistedHealthReducer = persistReducer(healthPersistConfig, health);
const persistedAiGraphReducer = persistReducer(aiGraphPersistConfig, aiGraph);

export const store = configureStore({
  reducer: {
    auth,
    shared,
    wellness,
    performance,
    chart,
    clientConfig,
    health: persistedHealthReducer, 
    aiGraph: persistedAiGraphReducer
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

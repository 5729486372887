import { FC, useRef, useState } from "react";
import Calendar from "react-calendar";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { ReactComponent as PerformanceIcon } from "images/performance-form.svg";
import styles from "./performance-popup.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";
import { useSelector } from "hooks/app-hooks";
import { getCurrentAthlete } from "store/slices/shared";
import { CountdownTimer } from "../success-countdown";
import "./date-calendar.overwrite.scss";
import {
  ReusableInputField,
  ReusableInputFieldProps,
} from "../anthropometry-popup/resusable-input-field/resusable-input-field";
import { ErrorsState, FormState } from "../anthropometry-popup/anthropometry-popup";
import { formatDatePower } from "utils";
import { postDateOfNextSFTData, postTargetPowerSFTData } from "./performance-api";
import { trackError } from "appInsights/logInsights";
import { format } from "date-fns";

type Props = {
  onClose: () => void;
};

export const PerformancePopup: FC<Props> = ({ onClose }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const currentAthlete = useSelector(getCurrentAthlete);
  const [success, setSuccess] = useState<boolean>(false);
  const calendarRef = useRef(null);
  const fieldProperties: ReusableInputFieldProps[] = [{ name: "watts", label: "How many watts" }];
  const [form, setForm] = useState<FormState>({ watts: "" });
  const [errors, setErrors] = useState<ErrorsState>({ watts: false });

  const validateInput = (name: string, value: string): boolean => {
    const numericValue = parseFloat(value);
    return numericValue >= 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isValid = validateInput(name, value);

    setForm((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: !isValid }));
  };

  const handleSubmitClick = async (): Promise<void> => {
    if (!dateSelected || !form.watts || errors.watts) {
      setErrorMessage("Please fill in all fields correctly");
      return;
    }

    const dateOfNextSft = formatDatePower(dateSelected);
    const targetPower = parseFloat(form.watts);
    const athleteId = currentAthlete?.id;

    if (!athleteId) {
      setErrorMessage("Athlete ID not found");
      return;
    }

    try {
      await postDateOfNextSFTData(athleteId, dateOfNextSft);
      await postTargetPowerSFTData(athleteId, targetPower);
      setSuccess(true);
    } catch (error) {
      trackError(error as Error, { location: "PerformancePopup" });
      setErrorMessage(`Failed to save data`);
    }
  };

  const handleDateChange = (value: Date): void => {
    setErrorMessage(null);
    setDateSelected(value);
  };

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div className={clsx(styles.inputBox, styles.box)} data-testid="add-comparison-list">
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>Performance</h3>
            <Close
              className={styles.closeButton}
              onClick={onClose}
              aria-label="Close"
              title="Close"
            />
          </div>
          {success ? (
            <div className={styles.countDown}>
              <CountdownTimer duration={3} onComplete={onClose} />
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.calendar}>
                <div
                  ref={calendarRef}
                  className={clsx("current-date-calendar", styles.calendarWrapper)}
                >
                  <div className={styles.subTitle}>Date of next SFT</div>
                  <Calendar
                    onChange={handleDateChange}
                    minDetail="month"
                    prev2Label={null}
                    next2Label={null}
                    formatShortWeekday={(locale, date) => format(date, "E")[0]}
                    formatMonthYear={(locale, date) => format(date, "MMM yyyy")}
                    maxDate={
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 3,
                        new Date().getDate()
                      )
                    }
                    minDate={new Date()}
                    locale="en"
                  />
                </div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.subTitle}>Target Power for SFT</div>
                <div className={styles.inputWrapper}>
                  <PerformanceIcon className={styles.icon} />
                  <form className={styles.form} data-testid="change-password-form">
                    {fieldProperties.map(({ name, label }) => (
                      <ReusableInputField
                        key={name}
                        name={name}
                        label={label}
                        value={form[name as keyof FormState]}
                        onChange={handleInputChange}
                        error={errors[name as keyof ErrorsState]}
                      />
                    ))}
                  </form>
                </div>
              </div>
            </div>
          )}
          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
          <div className={styles.footer}>
            <PrimaryButton
              disabled={
                Object.values(errors).some((error) => error) ||
                !dateSelected ||
                !form.watts ||
                success
              }
              className={styles.addButton}
              onClick={handleSubmitClick}
              title={"Submit"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

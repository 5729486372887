import { trackError } from "appInsights/logInsights";
import { axiosInstance } from "axios-instance/axios-instance";

type AthleteType ={
  type: string,
  athlete_id: string,
  athlete_image_identifier: string,
  athlete_flag_identifier: string,
  athlete_full_name:string,
  athlete_profile_pic?: string|null,
}

// new api
export const getAiGraphId = async (
  dataSources:AthleteType[]
) => {
  try {
    const data = {
      data_sources: dataSources
    };
    const resp = await axiosInstance.post(`ai/v1/ai-graph/new`,data);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getAiGraphId" });
    console.error(
      "Request failed:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const getAiGraphChatQuery = async (graphId: number, query: string,onChunkReceive: (chunk: string) => void) => {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `ai/v1/ai-graph/${graphId}/ask?stream=true`,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Connection: 'keep-alive',
      },
      data:{
        "query": query
      },
      responseType: 'stream',
      timeout: 0,
      onDownloadProgress: (progressEvent) => {
        const reader = progressEvent.currentTarget.responseText; 
        onChunkReceive(reader)
      }
    });
    return response
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getAiGraphConfiguration = async (graphId:number) => {
  try {
    const resp = await axiosInstance.get(`ai/v1/ai-graph/${graphId}/configuration`);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getAiGraphConfiguration" });
    throw error;
  }
};

export const getAiGraphComputeData = async (graphId:number) => {
  try {
    const data={}
    const resp = await axiosInstance.post(`ai/v1/ai-graph/${graphId}/compute_data`,{});
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getAiGraphComputeData" });
    throw error;
  }
};


export const getAiGraphChatHistory = async () => {
  try {
    const resp = await axiosInstance.get(`ai/v1/ai-graph/user_history_panel`);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getAiGraphChatHistory" });
    throw error;
  }
};

// API to delete a specific chat history based on this cURL:
export const deleteAiGraphChatHistory = async (graphId: string) => {
  try {
    const resp = await axiosInstance.delete(`ai/v1/ai-graph/delete_graphs`, {
      data: {
        command: "delete_specific_graph_ids",
        specific_graph_ids: [`${graphId}`]
      }
    });
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "deleteAiGraphChatHistory" });
    throw error;
  }
}

export const updateAiGraphConfig = async (graphId: string,athleteId:number) => {
  try {
    const data={
      "data_sources": [
        {
          "type": "AthleteDataSourceMetadata",
          "athlete_id":  `${athleteId}`
        }
    
      ]
    }
    const resp = await axiosInstance.patch(`ai/v1/ai-graph/${graphId}/configuration`, data);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "updateAiGraphConfig" });
    throw error;
  }
}

export const updateMultipleAthleteConfig = async (graphId: string,dataSources:[]) => {
  try {
    const data={
      "ai_graph_in_scope": {
      "data_sources": dataSources
    }
  }
    const resp = await axiosInstance.patch(`ai/v1/ai-graph/${graphId}/configuration_top_bar_selection`, data);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "updateMultipleAthleteConfig" });
    throw error;
  }
}

export const getAiGraphAllPinTabs = async (type:string) => {
  try {
    const resp = await axiosInstance.get(`ai/v1/ai-graph/user_tab/?filtered_tab_type=${type}`);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getAiGraphAllPinTabs" });
    throw error;
  }
};

export const addAiGraphPinTab = async (graphId: string,value:string,tabId:string) => {
  try {
    const data={
      "tab_graph_relation_metadata":{
          "random_value":value 
      },
      "graph_id":graphId
  }
    const resp = await axiosInstance.post(`ai/v1/ai-graph/user_tab/${tabId}/add_graph`, data);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "addAiGraphPinTab" });
    throw error;
  }
}

export const getSimplifiedComputeData = async (graphId: string,dataSources:any[]) => {
  try {
    const data={
      "data_sources": dataSources
    }
    const resp = await axiosInstance.post(`/ai/v1/ai-graph/${graphId}/compute_data_simplified`, data);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getSimplifiedComputeData" });
    throw error;
  }
}
import React, { FC, useRef, useState } from "react";
import Calendar from "react-calendar";
import clsx from "clsx";

import { formatDate } from "utils";
import { useClickOutside } from "hooks/use-click-outside";

import { ReactComponent as CalendarIcon } from "images/calendar-primary.svg";
import { ReactComponent as ResetIcon } from "images/reset.svg";

import "react-calendar/dist/Calendar.css";
import "./date-calendar.overwrite.scss";

import styles from "./date-calendar.module.scss";

export const LAST_UPDATE_DATE = -1;

type Props = {
  value: number;
  onChange: (newValue: number) => void;
  className?: string;
  direction?: "right" | "bottom";
  maxDate?: Date;
  flex?: boolean;
  resettable?: boolean;
};

export const CurrentDateCalendar: FC<Props> = ({
  value,
  onChange,
  className,
  direction = "bottom",
  maxDate,
  flex = true,
  resettable = true,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const buttonRef = useRef(null);

  const valueDate = new Date(value);

  useClickOutside(calendarRef, (event) => {
    if (buttonRef.current && event.target !== buttonRef.current) {
      setShowCalendar(false);
    }
  });

  const handleToggleClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateChange = (newValue: Date) => {
    setShowCalendar(false);
    onChange(newValue.getTime());
  };

  const handleResetClick = () => {
    setShowCalendar(false);
    onChange(LAST_UPDATE_DATE);
  };

  return (
    <div className={clsx(styles.wrapper, flex && styles.flex)}>
      <button
        type="button"
        className={clsx(styles.toggle, className)}
        onClick={handleToggleClick}
        ref={buttonRef}
      >
        <CalendarIcon className={styles.calendarIcon} />
        {value !== LAST_UPDATE_DATE && formatDate(valueDate)}
        {value === LAST_UPDATE_DATE && "-- / -- / ----"}
      </button>
      {showCalendar && (
        <div
          ref={calendarRef}
          className={clsx(
            "current-date-calendar",
            styles.calendarWrapper,
            direction === "right" && styles.calendarWrapperRight,
            direction === "bottom" && styles.calendarWrapperBottom
          )}
        >
          <Calendar
            value={value === LAST_UPDATE_DATE ? new Date() : valueDate}
            onChange={handleDateChange}
            maxDate={maxDate || undefined}
            locale="en"
          />
          {resettable && (
            <div className={styles.resetWrapper}>
              <button
                type="button"
                className={clsx(styles.resetButton)}
                onClick={handleResetClick}
              >
                <ResetIcon />
                <span className={styles.text}>Reset to Most Recent Dates</span>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

import React, { FC } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import { TimeRange } from "enums";
import { ReactComponent as CalendarPrimary } from "images/calendar-primary.svg";

import styles from "./timeframe-selector.module.scss";
import "./timeframe-selector.overwrite.scss";

interface SelectorOption {
  label: string;
  value: TimeRange;
}

const options: SelectorOption[] = [
  {
    label: "10 Seconds",
    value: TimeRange.TenSeconds,
  },
  {
    label: "30 Seconds",
    value: TimeRange.ThirtySeconds,
  },
  {
    label: "1 Minute",
    value: TimeRange.OneMinute,
  },
  {
    label: "5 Minutes",
    value: TimeRange.FiveMinutes,
  },
  {
    label: "10 Minutes",
    value: TimeRange.TenMinutes,
  },
  {
    label: "20 Minutes",
    value: TimeRange.TwentyMinutes,
  },
  {
    label: "30 Minutes",
    value: TimeRange.ThirtyMinutes,
  },
  {
    label: "60 Minutes",
    value: TimeRange.SixtyMinutes,
  },
];

interface Props {
  small?: boolean;
  header?: boolean;
  selectedTimeFrame: TimeRange;
  onTimeFrameChange: (newTimeFrame: TimeRange) => void;
}

export const TimeframeSelector: FC<Props> = ({
  small,
  header,
  selectedTimeFrame,
  onTimeFrameChange,
}) => {
  const value: SelectorOption | undefined = options.find(
    (option) => option.value === selectedTimeFrame
  );

  const handleChange = (newOption: SelectorOption | null) => {
    if (newOption) {
      onTimeFrameChange(newOption.value);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx(
          "timeframe-selector__select",
          small && "timeframe-selector__select--small",
          header && "timeframe-selector__select--header"
        )}
        classNamePrefix="react-select"
      />
    </div>
  );
};

import { FC, useEffect } from "react";
import {
  MenstrualCycleLegendColor,
  MenstrualCyclePhase,
} from "types/menstrual-cycle";

import styles from "./menstrual-cycle.module.scss";
// import { MenstrualWidget } from "../menstrual-widget/menstrual-widget";
// import { MenstrualChart } from "components/menstrual-chart/menstrual-chart";
// import { MenstrualTrends } from "components/menstrual-trends/menstrual-trends";
import { Heading } from "components/heading/heading";
import { ReactComponent as MenstrualCycleIcon } from "images/menstrual-cycle.svg";
// import { SourceBar } from "components/source-bottom-bar/source-bottom-bar";
import { DateRange } from "enums";
import { ChartMeta } from "types";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentAthlete, getCurrentDateRange } from "store/slices/shared";
import { calculateDateRange, formatDate, formatDateAPI, parseDate } from "utils";
import { fetchChartData } from "store/slices/chart";
import { APIMenstrualChartResponse } from "api/chart";
import { MenstrualForm } from "components/menstrual-form/menstrual-form";
import {
  COLOR_CHART_LINE_ONE,
  COLOR_CHART_LINE_TWO,
  COLOR_CHART_LINE_THREE,
  COLOR_CHART_LINE_FOUR,
  COLOR_CHART_LINE_FIVE,
  COLOR_CHART_LINE_SIX,
  COLOR_CHART_LINE_SEVEN,
  COLOR_CHART_LINE_EIGHT,
  COLOR_CHART_LINE_NINE,
} from "components/charts/colors";

export const MENSTRUAL_PHASE_COLOR_MAP: Record<
  MenstrualCyclePhase,
  MenstrualCycleLegendColor
> = {
  menses: MenstrualCycleLegendColor.Menses,
  follicular: MenstrualCycleLegendColor.Follicular,
  ovulation: MenstrualCycleLegendColor.Ovulation,
  luteal: MenstrualCycleLegendColor.Luteal,
};

type Props = {
  dataTestId?: string;
};

const getChartMeta = (
  name: string,
  dateRange: DateRange,
  athleteId: number
): ChartMeta<APIMenstrualChartResponse> => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    api: {
      url: "/menstrual/:fromDate/:toDate?athleteId=:athleteId",
      params: {
        ":fromDate": formatDateAPI(startDate),
        ":toDate": formatDateAPI(endDate),
        ":athleteId": `${athleteId}`,
      },
    },
    charts: [
      {
        name: `${name}Somatic`,
        getLabels: ({ data }: APIMenstrualChartResponse) =>
          data?.map((item) => formatDate(parseDate(item?.startDate))),
        getValues: ({ data }: APIMenstrualChartResponse) => ({
          cramps: data?.map((item) => ({
            value: item?.somatic.cramps,
            color: COLOR_CHART_LINE_ONE,
          })),
          fatigue: data?.map((item) => ({
            value: item?.somatic.fatigue,
            color: COLOR_CHART_LINE_TWO,
          })),
          noPain: data?.map((item) => ({
            value: item?.somatic.noPain,
            color: COLOR_CHART_LINE_THREE,
          })),
          swollen: data?.map((item) => ({
            value: item?.somatic.swollen,
            color: COLOR_CHART_LINE_FOUR,
          })),
          sleepQualityPerception: data?.map((item) => ({
            value: item?.somatic.sleepQualityPerception,
            color: COLOR_CHART_LINE_FIVE,
          })),
        }),
        getColors: () => ({
          cramps: COLOR_CHART_LINE_ONE,
          fatigue: COLOR_CHART_LINE_TWO,
          noPain: COLOR_CHART_LINE_THREE,
          swollen: COLOR_CHART_LINE_FOUR,
          sleepQualityPerception: COLOR_CHART_LINE_FIVE,
        }),
      },
      {
        name: `${name}Gastrointestinal`,
        getLabels: ({ data }: APIMenstrualChartResponse) =>
          data?.map((item) => formatDate(parseDate(item?.startDate))),
        getValues: ({ data }: APIMenstrualChartResponse) => ({
          bloating: data?.map((item) => ({
            value: item?.gastronintestinal.bloating,
            color: COLOR_CHART_LINE_ONE,
          })),
          nausea: data?.map((item) => ({
            value: item?.gastronintestinal.nausea,
            color: COLOR_CHART_LINE_TWO,
          })),
          diarrhea: data?.map((item) => ({
            value: item?.gastronintestinal.diarrhea,
            color: COLOR_CHART_LINE_THREE,
          })),
          cravings: data?.map((item) => ({
            value: item?.gastronintestinal.cravings,
            color: COLOR_CHART_LINE_FOUR,
          })),
          constipation: data?.map((item) => ({
            value: item?.gastronintestinal.constipation,
            color: COLOR_CHART_LINE_FIVE,
          })),
        }),
        getColors: () => ({
          bloating: COLOR_CHART_LINE_ONE,
          nausea: COLOR_CHART_LINE_TWO,
          diarrhea: COLOR_CHART_LINE_THREE,
          cravings: COLOR_CHART_LINE_FOUR,
          constipation: COLOR_CHART_LINE_FIVE,
        }),
      },
      {
        name: `${name}Mood`,
        getLabels: ({ data }: APIMenstrualChartResponse) =>
          data?.map((item) => formatDate(parseDate(item?.startDate))),
        getValues: ({ data }: APIMenstrualChartResponse) => ({
          moodSwings: data?.map((item) => ({
            value: item?.mood.moodSwings,
            color: COLOR_CHART_LINE_ONE,
          })),
          anxious: data?.map((item) => ({
            value: item?.mood.anxious,
            color: COLOR_CHART_LINE_TWO,
          })),
          insomnia: data?.map((item) => ({
            value: item?.mood.insomnia,
            color: COLOR_CHART_LINE_THREE,
          })),
          depressed: data?.map((item) => ({
            value: item?.mood.depressed,
            color: COLOR_CHART_LINE_FOUR,
          })),
          stress: data?.map((item) => ({
            value: item?.mood.stress,
            color: COLOR_CHART_LINE_FIVE,
          })),
          concentration: data?.map((item) => ({
            value: item?.mood.concentration,
            color: COLOR_CHART_LINE_SIX,
          })),
          obsessiveThoughts: data?.map((item) => ({
            value: item?.mood.obsessiveThoughts,
            color: COLOR_CHART_LINE_SEVEN,
          })),
          happy: data?.map((item) => ({
            value: item?.mood.happy,
            color: COLOR_CHART_LINE_EIGHT,
          })),
          energetic: data?.map((item) => ({
            value: item?.mood.energetic,
            color: COLOR_CHART_LINE_NINE,
          })),
        }),
        getColors: () => ({
          moodSwings: COLOR_CHART_LINE_ONE,
          anxious: COLOR_CHART_LINE_TWO,
          insomnia: COLOR_CHART_LINE_THREE,
          depressed:COLOR_CHART_LINE_FOUR,
          stress: COLOR_CHART_LINE_FIVE,
          concentration: COLOR_CHART_LINE_SIX,
          obsessiveThoughts: COLOR_CHART_LINE_SEVEN,
          happy: COLOR_CHART_LINE_EIGHT,
          energetic:COLOR_CHART_LINE_NINE,
        }),
      },
    ],
  };
};

export const MenstrualCycle: FC<Props> = ({ dataTestId }) => {
  const dispatch = useDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentDateRange = useSelector(getCurrentDateRange);

  useEffect(() => {
    if (currentAthlete) {
      dispatch(
        fetchChartData<APIMenstrualChartResponse>(
          getChartMeta("athlete", currentDateRange, currentAthlete.id)
        )
      );
    }
  }, [dispatch, currentDateRange, currentAthlete]);

  const somaticValues = useSelector((state) => state.chart.athleteSomatic);

  const gastrointestinalValues = useSelector(
    (state) => state.chart.athleteGastrointestinal
  );

  const moodValues = useSelector((state) => state.chart.athleteMood);

  return (
    <section data-testid={dataTestId}>
      <Heading icon={<MenstrualCycleIcon />} className={styles.heading}>
        Somatic
      </Heading>
      <MenstrualForm name="Somatic" data={somaticValues} />
      <Heading icon={<MenstrualCycleIcon />} className={styles.heading}>
        GastroIntestinal
      </Heading>
      <MenstrualForm name="GastroIntestinal" data={gastrointestinalValues} />
      <Heading icon={<MenstrualCycleIcon />} className={styles.heading}>
        Mood
      </Heading>
      <MenstrualForm name="Mood" data={moodValues} />
    </section>
  );
};

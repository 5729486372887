import { FC, useState, useEffect } from "react";
import { Menubar } from "components/menubar/menubar";
import styles from "./group-selection.module.scss";
import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getAthleteList,
  setSelectedGroup,
} from "store/slices/shared";
import { Group } from "types";
import { useHistory } from "react-router-dom";
import { GroupSearch } from "./group-search/group-search";
import { deleteGroups, getAllGroups } from "api/groups";
import { GroupSelectionHeader } from "components/group-selection-header/group-selection-header";
import { GroupCard } from "components/group-card/group-card";
import { GroupSelectionEnum } from "./enum";
import GroupModify from "components/group-modify/group-modify";
import { Loading } from "components/loading/loading";

export const GroupSelection: FC = () => {
  const dispatch = useDispatch();
  const [input, setInput] = useState<string>("");
  const history = useHistory();
  const [shouldFetchAgain, setShouldFetchAgain] = useState<boolean>(true);
  const [groups, setGroups] = useState<Group[] | undefined>();
  const [groupToEdit, setGroupToEdit] = useState<number | null>(null);
  const [filteredGroups, setFilteredGroups] = useState(groups ?? []);
  const athleteList = useSelector(getAthleteList);
  const [groupSelectionType, setGroupSelectionType] = useState(
    GroupSelectionEnum.SELECT_GROUP
  );
  const [loading, setLoading] = useState(false);

  const changeGroupSelectionType = (type: GroupSelectionEnum) => {
    setGroupSelectionType(type);
  };

  useEffect(() => {
    const regex = new RegExp(input, "i");
    const filtered = (groups ?? []).filter((group) => regex.test(group.name));
    setFilteredGroups(filtered);
  }, [input, groups]);

  useEffect(() => {
    const fetchData = async () => {
      if (shouldFetchAgain) {
        const response = await getAllGroups();

        setGroups(response);
        setFilteredGroups(response);
        setShouldFetchAgain(false);
      }
    };
    fetchData().then(() => {});
  }, [shouldFetchAgain, setGroups]);

  const handleDeleteClick = async (groupId: number) => {
    await deleteGroups([groupId]);
    setGroups(groups?.filter((el) => el.id !== groupId));
  };

  const handleEditClick = async (groupId: number) => {
    setGroupToEdit(groupId);
    setGroupSelectionType(GroupSelectionEnum.EDIT_GROUP);
  };

  const handleOnClick = async (group: Group) => {
    setInput("");
    if (group) {
      dispatch(setSelectedGroup(group));
      history.push("/group-profiles");
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const groupSelectionView = () => {
    switch (groupSelectionType) {
      case GroupSelectionEnum.SELECT_GROUP:
        return loading ? (
          <Loading />
        ) : (
          <div className={styles.athleteCardWrapper}>
            {filteredGroups.map((group) => (
              <GroupCard
                key={group.id}
                onDelete={() => handleDeleteClick(group.id)}
                onEdit={() => handleEditClick(group.id)}
                onClick={() => handleOnClick(group)}
                groupName={group.name}
                description={group.description || ""}
                memberImages={group.athleteIds.map((id) => {
                  const athlete = athleteList.find((i) => i.id === id);
                  return (
                    athlete?.picture ||
                    "https://cycling-bucket.obs.ae-ad-1.g42cloud.com/avatar/men/coach/coach.png"
                  );
                })}
              />
            ))}
          </div>
        );

      case GroupSelectionEnum.CREATE_GROUP:
        return (
          <GroupModify
            groupSelectionView={GroupSelectionEnum.CREATE_GROUP}
            setGroupSelectionView={setGroupSelectionType}
            setShouldFetchAgain={setShouldFetchAgain}
          />
        );

      case GroupSelectionEnum.EDIT_GROUP:
        return (
          <GroupModify
            groupSelectionView={GroupSelectionEnum.EDIT_GROUP}
            setGroupSelectionView={setGroupSelectionType}
            setShouldFetchAgain={setShouldFetchAgain}
            group={groups?.find((i) => i.id === groupToEdit)}
          />
        );

      default:
        return <h1>No project match</h1>;
    }
  };

  return (
    <div className={styles.wrapper}>
      <Menubar />
      <div className={styles.page}>
        <GroupSelectionHeader
          hideBottom={false}
          athleteCount={(groups ?? []).length}
        />
        {groupSelectionType === GroupSelectionEnum.SELECT_GROUP && (
          <div className={styles.topWrapper}>
            <div className={styles.searchFilter}>
              <GroupSearch input={input} handleChange={handleChangeInput} />
            </div>
            <div className={styles.buttonBlock}>
              <button
                type="submit"
                className={styles.button}
                data-testid="save-changes-button"
                tabIndex={0}
                onClick={() =>
                  changeGroupSelectionType(GroupSelectionEnum.CREATE_GROUP)
                }
              >
                Add Group
              </button>
            </div>
          </div>
        )}
        {groupSelectionView()}
      </div>
    </div>
  );
};

import styles from './horizontal-loader.module.scss';

const HorizontalLoader = () => {
    return (
        <div className={styles.loadingBarContainer}>
            <div className={styles.loadingBar}></div>
        </div>
    )
}

export default HorizontalLoader
import React, { FC } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import styles from "./power-type-selector.module.scss";
import "./power-type-selector.overwrite.scss";

interface SelectorOption {
  label: string;
  value: string;
}

const options: SelectorOption[] = [
  {
    label: "Absolute",
    value: "Absolute",
  },
  {
    label: "Relative",
    value: "Relative",
  },
  {
    label: "Composite",
    value: "Composite",
  },
];

interface Props {
  small?: boolean;
  header?: boolean;
  selectedMetric: string;
  onMetricChange: (newMetric: string) => void;
  className?: string;
}

export const PowerTypeSelector: FC<Props> = ({
  small,
  header,
  selectedMetric,
  onMetricChange,
  className,
}) => {
  const value: SelectorOption | undefined = options.find(
    (option) => option.value === selectedMetric
  );

  const handleChange = (newOption: SelectorOption | null) => {
    if (newOption) {
      onMetricChange(newOption.value);
    }
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx(
          "power-type-selector__select",
          small && "power-type-selector__select--small",
          header && "power-type-selector__select--header"
        )}
        classNamePrefix="react-select"
      />
    </div>
  );
};

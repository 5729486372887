import { ChangeEvent, FC, useEffect, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";
import styles from "./feedback-popup.module.scss";
import { ReactComponent as Done } from "images/done.svg";
import { useStackState } from "rooks";

type Props = {
  onClose: () => void;
  tag?: string;
  setTag?: (feed: string) => void;
  setUserMessage?: (message: string) => void;
  setIsSubmitClick: (flag: boolean) => void;
  isSuccess?: boolean;
  title: string;
  displayOption: boolean;
  userMessage?: string;
};

const feedbackOptions = [
  "Irrelevant response",
  "Inaccurate information or data",
  "Calculation error",
  "Scientific inaccuracy",
  "Not enough detail",
  "Confusing response",
];

export const FeedbackPopup: FC<Props> = ({
  onClose,
  setTag,
  tag,
  setUserMessage,
  setIsSubmitClick,
  isSuccess,
  title,
  displayOption,
  userMessage,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setErrorMessage("");
    setUserMessage?.(e.target.value);
  };

  // api call to post note
  const handleSubmitClick = async () => {
    if (!displayOption && userMessage === "") {
      setErrorMessage("Please enter some feedback!");
      setIsSubmitClick(false);
    } else if (displayOption && !tag && userMessage === "") {
      setErrorMessage("Please enter some feedback!");
      setIsSubmitClick(false);
    } else {
      setIsSubmitClick(true);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, onClose]);

  return (
    <>
      {isSuccess ? (
         <div className={clsx(styles.popupBox, "comparison-dropdown")}>
           <div
            className={clsx(styles.inputBox, styles.successBox)}
            data-testid="add-comparison-list"
          >
        <div className={styles.feedbackSuccess}>
          <Done className={styles.groupItem} />
          <div className={styles.feedbackSuccessText}>
            Thanks for your feedback!
          </div>
        </div>
        </div>
        </div>
      ) : (
        <div className={clsx(styles.popupBox, "comparison-dropdown")}>
          <div
            className={clsx(styles.inputBox, styles.box)}
            data-testid="add-comparison-list"
          >
            <>
              <div className={styles.header}>
                <span className={styles.title}>{title}</span>

                <Close
                  className={styles.closeButton}
                  onClick={onClose}
                  aria-label="Close"
                  title="Close"
                />
              </div>

              {displayOption && (
                <div className={styles.feedbackWrapper}>
                  {feedbackOptions?.map((feed) => {
                    return (
                      <div
                        className={`${styles.feedbackOption} ${
                          tag == feed && styles.selectedOption
                        }`}
                        onClick={() => {
                          setErrorMessage("");
                          setTag?.(feed);
                        }}
                      >
                        {feed}
                      </div>
                    );
                  })}
                </div>
              )}

              <div className={styles.formContainer}>
                <form>
                  <div className={styles.formGroup}>
                    <textarea
                      placeholder={
                        displayOption
                          ? "Your Feedback (Optional)"
                          : "Your Feedback"
                      }
                      name="note"
                      onChange={handleChange}
                      className={styles.notes}
                    ></textarea>
                  </div>
                </form>
              </div>
              {displayOption && errorMessage && (
                <p className={styles.errorMessage}>{errorMessage}</p>
              )}
              {!displayOption && errorMessage && (
                <p className={styles.errorMessage}>{errorMessage}</p>
              )}

              <div className={styles.footer}>
                <PrimaryButton
                  className={styles.addButton}
                  onClick={handleSubmitClick}
                  title={"Submit"}
                />
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
};

import { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";
import styles from "./athlete-popup.module.scss";
import { AthleteSearch } from "pages/athlete-selection/athlete-search/athlete-search";
import { Athlete, AthleteType } from "types";
import { ReactComponent as Checkbox } from "images/checkbox.svg";
import { ReactComponent as SelectedCheckbox } from "images/checkbox_selected.svg";
import { countryCodesMapping } from "country-codes-mapping";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";

type Props = {
  onClose: () => void;
  athleteList: Athlete[];
  handleSubmit: () => void;
  handleClearAll: () => void;
  multiSelect?: boolean;
  setTempFilterAthleteDetails: (value: AthleteType[]) => void;
  tempFilterAthleteDetails: AthleteType[];
};

export const AthletePopup: FC<Props> = ({
  onClose,
  athleteList,
  handleSubmit,
  handleClearAll,
  multiSelect = true,
  setTempFilterAthleteDetails,
  tempFilterAthleteDetails,
}) => {
  const [input, setInput] = useState<string>("");
  const [filteredAthletes, setFilteredAthletes] = useState(athleteList);

  useEffect(() => {
    const regex = new RegExp(input, "i");
    const uniqueIds = new Set();

    const filtered = athleteList?.filter((athlete) => {
      const matches =
        regex.test(athlete?.firstName) ||
        regex.test(athlete?.lastName) ||
        regex.test(athlete?.countryCode);
      if (matches && !uniqueIds.has(athlete?.id)) {
        uniqueIds.add(athlete?.id);
        return true;
      }
      return false;
    });
    setFilteredAthletes(filtered);
  }, [input, athleteList]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const manageList = useMemo(() => {
    return (athlete: Athlete) => {
      const athleteCountryAlpha2Code =
        athlete?.countryCode && countryCodesMapping[athlete.countryCode]
          ? countryCodesMapping[athlete?.countryCode]
          : "AE";
      const athleteFlagUrl = `${DEFAULT_CDN_URL}${athleteCountryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;
      const athleteDetails = {
        type: "AthleteDataSourceMetadata",
        athlete_id: athlete?.id?.toString(),
        athlete_image_identifier: athlete?.picture,
        athlete_flag_identifier: athleteFlagUrl,
        athlete_full_name: athlete?.fullName,
      };
      if (tempFilterAthleteDetails && multiSelect) {
        if (
          tempFilterAthleteDetails?.find(
            (selectedAthlete: AthleteType) =>
              selectedAthlete?.athlete_id == athlete?.id?.toString()
          )
        ) {
          const arr = tempFilterAthleteDetails?.filter(
            (item: AthleteType) => item?.athlete_id != athlete?.id?.toString()
          );
          return setTempFilterAthleteDetails(arr);
        }
        return setTempFilterAthleteDetails([
          ...tempFilterAthleteDetails,
          athleteDetails,
        ]);
      }
      return setTempFilterAthleteDetails([athleteDetails]);
    };
  }, [tempFilterAthleteDetails, multiSelect, setTempFilterAthleteDetails]);

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Filter</h3>
          <Close
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
            title="Close"
          />
        </div>
        <div className={styles.athleteCardWrapper}>
          <div className={styles.athleteSearch}>
            <span>Athletes</span>
            <AthleteSearch
              input={input}
              handleChange={handleSearchChange}
              className={styles.searchBar}
            />
          </div>
          <div className={styles.listWrapper}>
            <div className={styles.list}>
              {filteredAthletes?.length > 0 ? (
                filteredAthletes?.map((item: Athlete, i: number) => {
                  return (
                    <div
                      className={clsx(
                        styles.item,
                        !(
                          tempFilterAthleteDetails &&
                          tempFilterAthleteDetails?.find(
                            (selectedAthlete: AthleteType) =>
                              selectedAthlete?.athlete_id ==
                              item?.id?.toString()
                          )
                        ) && styles.itemDisabled
                      )}
                      key={i.toString()}
                      onClick={() => manageList(item)}
                    >
                      <button
                        type="button"
                        className={styles.checkbox}
                        onClick={() => manageList(item)}
                      >
                        {tempFilterAthleteDetails &&
                        tempFilterAthleteDetails?.find(
                          (selectedAthlete: AthleteType) =>
                            selectedAthlete?.athlete_id == item?.id?.toString()
                        ) ? (
                          <SelectedCheckbox />
                        ) : (
                          <Checkbox />
                        )}
                      </button>
                      <img
                        className={styles.itemImage}
                        src={item?.picture}
                        alt="Profile"
                      />
                      <div className={styles.itemContent}>
                        <span className={styles.itemName}>
                          {item?.firstName} {item?.lastName}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={styles.noData}>No Athletes Found</div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <PrimaryButton
            className={styles.clearSelection}
            onClick={handleClearAll}
            title={"Clear All"}
          />
          <PrimaryButton
            className={styles.addButton}
            onClick={handleSubmit}
            title={"Show Results"}
            disabled={tempFilterAthleteDetails?.length <= 0}
          />
        </div>
      </div>
    </div>
  );
};

import React, { useCallback, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import ReactEcharts from "echarts-for-react";

import { roundFloat } from "utils";
import { ReactComponent as CircleDashedIcon } from "images/circle-dashed.svg";
import { ChartColor, ChartProps, SeriesItem } from "components/charts/types";
import {
  COLOR_AXIS,
  COLOR_TOOLTIP,
  COLOR_WHITE,
  PERFORMANCE_SEPERATOR,
} from "components/charts/colors";
import {
  ChartSize,
  FONT_SIZE_XSS,
  GradientDirection,
} from "components/charts/constants";
import {
  DEFAULT_CHART_X_AXIS_PHYS,
  DEFAULT_CHART_Y_AXIS_PHYS,
} from "components/charts/defaults";
import {
  getAxisLabelStyle,
  toEChartsColor,
  toEChartsTextStyle,
} from "components/charts/utils";
import { NoData } from "components/charts/no-data/no-data";

import { withHeader } from "hocs/with-header/with-header";

import styles from "./line-chart.module.scss";

export type LineChartSeries = SeriesItem<{
  origin?: string;
  areaColor?: ChartColor;
  shadowColor?: ChartColor;
  shadowBlur?: number;
  shadowOffsetY?: number;
  width?: number;
  xAxisIndex?: number;
  yAxisIndex?: number;
  smooth?: boolean;
  dashed?: boolean;
  seriesName?: string;
}>;

export type LineChartProps = ChartProps<{
  type?: ChartSize;
  units?: string;
  markLineAt?: string;
  xAxis?: {
    name?: string;
    type?: string;
    min?: number;
    position?: string;
    gridIndex?: number;
  }[];
  yAxis?: {
    name?: string;
    min?: number;
    max?: number;
    gridIndex?: number;
    yAxisIndex?: number;
  }[];
  series: (LineChartSeries | undefined)[];
  titlePrefix?: string;
  hideDecimalPoints?: boolean;
  showZeroValues?: boolean;
  name: string;
}>;

interface TooltipValue {
  $vars: string[];
  axisDim: string;
  axisId: string;
  axisIndex: number;
  axisType: string;
  axisValue: string;
  axisValueLabel: string;
  borderColor?: string;
  color: string;
  componentIndex: 0;
  componentSubType: string;
  componentType: string;
  data: string[];
  dataIndex: 1;
  dataType: undefined;
  dimensionNames: string[];
  encode: { x: number[]; y: number[] };
  marker: string;
  name: string;
  seriesId: string;
  seriesIndex: string;
  seriesName: string;
  seriesType: string;
  value: string[];
}

const getGridRightPadding = (type: ChartSize) =>
  type === ChartSize.Big ? 42 : 32;

const findDashedLegend = (name: string, filteredSeries: LineChartSeries[]) => {
  const data = filteredSeries.find((item) => item.name === name);
  if (data) {
    return !!data.dashed;
  }
  return false;
};

export const LineChart = React.forwardRef<ReactEcharts, LineChartProps>(
  (
    {
      type = ChartSize.Small,
      series,
      labels,
      xAxis,
      yAxis,
      markLineAt,
      titlePrefix,
      hideDecimalPoints,
      showZeroValues,
      onChartReady,
      onEvents,
    },
    ref
  ) => {
    const filteredSeries: LineChartSeries[] = series
      .filter((el) => el !== undefined)
      .map((el) => el as LineChartSeries);

    const hasData = useMemo(
      () =>
        filteredSeries.some((seriesItem) => {
          return seriesItem?.values?.filter((item) => item?.value)?.length > 0;
        }),
      [filteredSeries]
    );

    const customTooltipFormatter = (value: TooltipValue[]) => {
      return `
      <div class="${styles.singleTooltip}">
        ${titlePrefix || ""} ${value[0].axisValue}
        ${value
          .map(
            (element: TooltipValue) => `
                <div class="${styles.tooltip}"><div>
                    ${
                      findDashedLegend(element.seriesName, filteredSeries)
                        ? ReactDOMServer.renderToStaticMarkup(
                            <span className={styles.tooltipIcon}>
                              <CircleDashedIcon
                                style={{
                                  stroke: element.color
                                    ? element.color
                                    : "white",
                                }}
                              />
                            </span>
                          )
                        : element.marker
                    }
                    ${element.seriesName}:
                    </div>
                    <div class="${styles.tooltipValue}">
                        ${
                          hideDecimalPoints
                            ? parseFloat(element.data[1]).toFixed(0)
                            : parseFloat(element.data[1]).toFixed(2)
                        }
                    </div>
                  </div>`
          )
          .join("")}</div>`;
    };

    return (
      <div className={styles.wrapper}>
        {!hasData && <NoData chartSize={type} />}
        <ReactEcharts
          ref={ref}
          onChartReady={onChartReady}
          onEvents={onEvents}
          opts={{ renderer: "canvas" }}
          style={{ width: "100%", height: "100%" }}
          notMerge
          option={{
            title: {
              show: false,
            },
            tooltip: {
              trigger: hasData && "axis",
              backgroundColor: COLOR_TOOLTIP,
              borderColor: COLOR_TOOLTIP,
              textStyle: {
                color: COLOR_WHITE,
                fontSize: 12,
                fontFamily: "Montserrat",
              },
              formatter: customTooltipFormatter,
            },
            axisPointer: {
              link: [
                {
                  xAxisIndex: "all",
                },
              ],
            },
            legend: {
              show: false,
            },
            grid: xAxis
              ? [
                  ...xAxis.map((axis, index) => ({
                    left: type === ChartSize.Big ? 50 : 58,
                    right: getGridRightPadding(type),
                    top: index === 1 ? "55%" : 27,
                    bottom: type === ChartSize.Big ? 42 : 25,
                    height: "35%",
                  })),
                ]
              : {
                  top: yAxis ? 27 : 8,
                  left: type === ChartSize.Big ? 50 : 58,
                  bottom: type === ChartSize.Big ? 42 : 25,
                  right: getGridRightPadding(type),
                },
            xAxis: (xAxis || [{}]).map((axis, index) => {
              return {
                ...DEFAULT_CHART_X_AXIS_PHYS,
                boundaryGap: false,
                gridIndex: axis.gridIndex ?? 0,
                axisLabel: {
                  ...toEChartsTextStyle(
                    {},
                    {
                      color: COLOR_AXIS,
                      size: type === ChartSize.Big ? 13 : 8,
                      fontFamily: "Montserrat",
                    }
                  ),
                  margin: 13,
                  formatter(value: string) {
                    return `${value} `;
                  },
                },
                splitLine: {
                  lineStyle: {
                    type: "dashed",
                    color: "rgba(84, 93, 118, 0.8)",
                  },
                  show: hasData,
                },
                data: labels,
              };
            }),
            yAxis: (yAxis || [{}]).map((axis, index) => {
              return {
                ...DEFAULT_CHART_Y_AXIS_PHYS,
                min: axis.min || 0,
                max: axis?.max,
                type: "value",
                gridIndex: axis.gridIndex ?? 0,
                name: axis.name,
                nameTextStyle: {
                  ...toEChartsTextStyle(
                    {},
                    {
                      color: COLOR_AXIS,
                      fontWeight: "normal",
                      size: FONT_SIZE_XSS,
                      fontFamily: "Montserrat",
                    }
                  ),
                  align:
                    axis.yAxisIndex && axis.yAxisIndex > 0 ? "right" : "left",
                  margin: -30,
                },
                axisLabel: {
                  color: COLOR_AXIS,
                  fontWeight: 500,
                  size: 10,
                  fontFamily: "Montserrat",
                  formatter(value: string) {
                    if (axis.name === "Percentage" && parseFloat(value) <= 1) {
                      return `${parseFloat(value) * 100} `;
                    }
                    return `${value} `;
                  },
                },
                splitLine: {
                  ...DEFAULT_CHART_Y_AXIS_PHYS.splitLine,
                  lineStyle: {
                    type: "dashed",
                    color: "rgba(84, 93, 118, 0.3)",
                  },
                  show:
                    hasData && (!axis?.yAxisIndex || axis?.yAxisIndex === 0),
                },
                ...getAxisLabelStyle(type),
              };
            }),
            series: [
              ...filteredSeries.map((seriesItem) => ({
                markLine: markLineAt
                  ? {
                      silent: true,
                      animation: true,
                      label: {
                        show: false,
                      },
                      lineStyle: {
                        color: PERFORMANCE_SEPERATOR,
                        type: "solid",
                      },
                      data: [
                        [
                          {
                            y: "85%",
                            xAxis: markLineAt,
                            symbol:
                              "path://M5.0913 1.5145L3 5L0.908698 1.5145C0.508783 0.847971 0.988896 0 1.76619 0H4.23381C5.0111 0 5.49122 0.847972 5.0913 1.5145Z",
                            symbolKeepAspect: true,
                          },
                          {
                            y: "5%",
                            xAxis: markLineAt,
                            symbol:
                              "path://M5.0913 1.5145L3 5L0.908698 1.5145C0.508783 0.847971 0.988896 0 1.76619 0H4.23381C5.0111 0 5.49122 0.847972 5.0913 1.5145Z",
                            symbolKeepAspect: true,
                          },
                        ],
                      ],
                    }
                  : undefined,
                yAxisIndex: seriesItem.yAxisIndex,
                xAxisIndex: seriesItem.xAxisIndex,
                type: "line",
                symbol: "circle",
                smooth: seriesItem.smooth ?? true,
                showAllSymbol: true,
                itemStyle: {
                  color: seriesItem.color,
                },
                name: seriesItem.name,
                areaStyle: seriesItem.areaColor
                  ? {
                      color: toEChartsColor(
                        seriesItem.areaColor,
                        GradientDirection.TopToBottom
                      ),
                      origin: seriesItem.origin ? seriesItem.origin : "auto",
                    }
                  : null,
                lineStyle: {
                  width: seriesItem.width ? seriesItem.width : 1,
                  type: seriesItem.dashed ? "dashed" : "solid",
                  shadowColor: seriesItem.shadowColor || null,
                  shadowBlur: seriesItem.shadowBlur || null,
                  shadowOffsetY: seriesItem.shadowOffsetY || null,
                },
                data: hasData
                  ? seriesItem?.values?.reduce((acc, item, index) => {
                      const label = labels ? labels[index] : index;
                      const value = roundFloat(item?.value);

                      if (
                        !acc.find(
                          ([existingLabel]) => existingLabel === label
                        ) &&
                        (showZeroValues
                          ? value !== null && value !== undefined
                          : value)
                      ) {
                        acc.push([label, value] as never);
                      }
                      return acc;
                    }, [])
                  : [0],

                // Just adding one data point to force axis rendering
              })),
            ],
          }}
        />
      </div>
    );
  }
);

export const LineChartWithHeader = withHeader(LineChart);

import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { useDispatch, useSelector } from "hooks/app-hooks";
import { useForm } from "hooks/use-form";

import { ReactComponent as SignInUser } from "images/sign-in-user.svg";
import { ReactComponent as SignInLock } from "images/sign-in-lock.svg";
import { ReactComponent as PasswordInputEye } from "images/password-input-eye.svg";
import { ReactComponent as Warning } from "images/warning.svg";
import { ReactComponent as UnSelectedCheckbox } from "images/checkbox_unselected.svg";
import { ReactComponent as SelectedCheckbox } from "images/remember-me-checkbox_selected.svg";

import { login } from "api/auth";
import { AuthParameter } from "types";

import { UaeCyclingHeader } from "pages/home/uae-cycling-header/uae-cycling-header";

import { getAuthState, setAuthState, getAccount, setAccount, setUserName } from "store/slices/auth";
import { setCurrentComparison } from "store/slices/shared";
import styles from "./sign-in.module.scss";
import { FlavorType, RoleType } from "enums";

export const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authorised = useSelector(getAuthState);
  const role = useSelector(getAccount);
  const flavorType = process.env.REACT_APP_FLAVOR;

  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const [remember, setRemember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const VIDEO_SOURCE = "banner.mp4";

  const [form, changeHandler] = useForm({
    login: "",
    password: "",
  });

  const signIn = useCallback(async (data: AuthParameter) => {
    return login(data);
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError(false);
    }
    changeHandler(e);
  };

  const handleSignIn = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await signIn({
      username: form.login,
      password: form.password,
      remember,
    });

    if (response?.data) {
      setError(false);
      if (response?.data?.role === RoleType.cyclist)
        await dispatch(setCurrentComparison(undefined));
      await dispatch(setAuthState(true, response?.data?.accessToken));
      await dispatch(setUserName(form.login));
      dispatch(setAccount(response?.data?.role ?? "coach"));
      setIsLoading(false);
      if (response?.data?.role === RoleType.coach) {
        history.push("/team-dashboard");
      } else {
        history.push("/wellness");
      }
    } else {
      setIsLoading(false);
      setError(true);
      if (response?.code === 403) {
        setLoginErrorMsg(response.message ?? "");
      } else if (response?.code === 401) {
        setLoginErrorMsg("These credentials do not match our records.");
      } else setLoginErrorMsg("These credentials do not match our records.");
    }
  };

  const handlePasswordEyeMouseDown = () => {
    if ("ontouchend" in document) {
      return;
    }
    setShowPassword(true);
  };

  const handlePasswordEyeMouseUp = () => {
    if ("ontouchend" in document) {
      return;
    }
    setShowPassword(false);
  };

  const handlePasswordEyeTouchStart = () => {
    setShowPassword(true);
  };

  const handlePasswordEyeTouchEnd = () => {
    setShowPassword(false);
  };

  const handlePasswordEyeKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === "Space" || e.code === "Enter") {
      setShowPassword(true);
    }
  };

  const handlePasswordEyeKeyUp = (e: React.KeyboardEvent) => {
    if (e.code === "Space" || e.code === "Enter") {
      setShowPassword(false);
    }
  };

  const handleForgotPassword = () => {
    history.push("/forgot-password");
  };

  useEffect(() => {
    if (authorised) {
      history.push(role === RoleType.coach ? "/team-dashboard" : "/wellness");
    }
    if (!role) history.push("/sign-in");
  }, [history, authorised, role]);

  return (
    <div className={styles.home}>
      <video autoPlay={true} loop={true} muted={true} playsInline={true} className={styles.video}>
        <source src={VIDEO_SOURCE} type="video/mp4" />
      </video>
      <div className={styles.loginOverlay}/>
      <div className={styles.background}>
        <div className={styles.sidebarWrapper}>
          <UaeCyclingHeader showMarketingBanner={flavorType === FlavorType.uaeMen}/>
          <div className={styles.sidebarContent}>
            <h1 className={styles.signInText}>Sign In</h1>
            <form className={styles.form} onSubmit={handleSignIn} data-testid="sign-in-form">
              <div className={styles.inputsBlock}>
                <div className={styles.inputBlock}>
                  <label htmlFor="login" className={styles.label}>
                    Username
                    <div className={styles.inputWrapper}>
                      <div
                        className={clsx(
                          styles.iconWrapper,
                          form.login.length > 0 && styles.activeIcon
                        )}
                      >
                        <SignInUser />
                      </div>
                      {error && (
                        <div className={clsx(styles.iconWrapper, styles.warningIcon)}>
                          <Warning />
                        </div>
                      )}
                      <input
                        type="text"
                        name="login"
                        value={form.login}
                        placeholder="Enter your username or email"
                        onChange={handleInputChange}
                        className={clsx(
                          styles.signInInput,
                          !error && form.login.length > 0 && styles.activeInput,
                          error && styles.errorInput
                        )}
                      />
                    </div>
                  </label>
                </div>
                <div className={styles.inputBlock}>
                  <label htmlFor="password" className={styles.label}>
                    Password
                    <div className={styles.inputWrapper}>
                      <div
                        className={clsx(
                          styles.iconWrapper,
                          form.password.length > 0 && styles.activeIcon
                        )}
                      >
                        <SignInLock />
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={form.password}
                        placeholder="Enter your password"
                        onChange={handleInputChange}
                        className={clsx(
                          styles.signInInput,
                          !error && form.password.length > 0 && styles.activeInput,
                          error && styles.errorInput
                        )}
                      />
                      {!error && (
                        <button
                          type="button"
                          className={clsx(
                            styles.iconWrapper,
                            styles.passwordEyeIcon,
                            showPassword && styles.activeIcon
                          )}
                          onMouseDown={handlePasswordEyeMouseDown}
                          onMouseUp={handlePasswordEyeMouseUp}
                          onTouchStart={handlePasswordEyeTouchStart}
                          onTouchEnd={handlePasswordEyeTouchEnd}
                          onKeyDown={handlePasswordEyeKeyDown}
                          onKeyUp={handlePasswordEyeKeyUp}
                        >
                          <PasswordInputEye />
                        </button>
                      )}
                      {error && (
                        <div className={clsx(styles.iconWrapper, styles.warningIcon)}>
                          <Warning />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              {error && <div className={styles.errorMessage}>{loginErrorMsg}</div>}
              <div className={styles.blockFeatures}>
                <div className={styles.rememberMe} onClick={() => setRemember(!remember)}>
                  <div className={styles.checkbox}>
                    {remember ? <SelectedCheckbox /> : <UnSelectedCheckbox />}
                  </div>
                  <span className={styles.rememberMeLabel}>Remember me</span>
                </div>
                <div className={styles.forgotPassword} onClick={handleForgotPassword}>Forgot Password?</div>
              </div>
              <div className={styles.buttonBlock}>
                <button
                  type="submit"
                  className={styles.button}
                  tabIndex={0}
                  disabled={form.login.length === 0 || form.password.length === 0 || isLoading}
                >
                  {isLoading ? <div className={styles.loader}></div> : "Login"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

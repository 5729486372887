import React, { FC, useState, useEffect, useRef } from "react";
import clsx from "clsx";

import { ReactComponent as ExpandDown } from "images/expand-down.svg";
import { ReactComponent as PowerHeld } from "images/power-held.svg";
import { ReactComponent as THR } from "images/thr.svg";
import { ReactComponent as TTE } from "images/tte.svg";

import styles from "./add-data.module.scss";
import { DropdownMenuItemComponent } from "components/dropdown-profile-btns/dropdown-profile";
import { AnthropometryPopup } from "./anthropometry-popup/anthropometry-popup";
import { InputPopup } from "./input-popup/input-popup";
import { FairlyLightPopup } from "./fairly-light-popup/fairly-light-popup";
import { PerformancePopup } from "./performance-popup/performance-popup";
import { useSelector } from "hooks/app-hooks";
import { getIsCyclist } from "store/slices/auth";

type Props = {
  className?: string;
};

type PopupState = {
  anthropometry: boolean;
  fairlyLight: boolean;
  powerHeld: boolean;
  thr: boolean;
  tte: boolean;
  performance: boolean;
};

export const AddData: FC<Props> = ({ className }) => {
  const [popups, setPopups] = useState<PopupState>({
    anthropometry: false,
    fairlyLight: false,
    powerHeld: false,
    thr: false,
    tte: false,
    performance: false,
  });
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const isCyclist: boolean = useSelector(getIsCyclist);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setDropdownVisible(!isDropdownVisible);

  const togglePopup = (popup: keyof PopupState) => {
    setPopups((prev) => ({ ...prev, [popup]: !prev[popup] }));
  };

  const renderPopup = (key: keyof PopupState) => {
    switch (key) {
      case "anthropometry":
        return <AnthropometryPopup onClose={() => togglePopup("anthropometry")} />;
      case "powerHeld":
        return (
          <InputPopup
            submitDate={true}
            dataField="power"
            onClose={() => togglePopup("powerHeld")}
            title="Power Held"
            IconData={PowerHeld}
            endpoint="power-held"
            keyName="watts"
            inputLabel="How many watts"
          />
        );
      case "thr":
        return (
          <InputPopup
            submitDate={true}
            dataField="thr"
            onClose={() => togglePopup("thr")}
            title="Testing Heart Rate"
            IconData={THR}
            endpoint="thr"
            keyName="beats"
            inputLabel="How many beats per minute"
          />
        );
      case "tte":
        return (
          <InputPopup
            dataField="tte"
            onClose={() => togglePopup("tte")}
            title="TTE - Time to Exhaustion"
            IconData={TTE}
            endpoint="tte"
            keyName="mins"
            inputLabel="How many mins"
          />
        );
      case "fairlyLight":
        return <FairlyLightPopup onClose={() => togglePopup("fairlyLight")} />;
      case "performance":
        return <PerformancePopup onClose={() => togglePopup("performance")} />;

      default:
        return null;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownVisible(false);
      }
    };

    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapePress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, []);

  return (
    <>
      <div className={styles.wrapper} ref={dropdownRef}>
        <button
          type="button"
          className={clsx(styles.button, className)}
          onClick={toggleDropdown}
          data-testid="comparison-button"
        >
          Add Data <ExpandDown className={styles.expandDown} />
        </button>
        {isDropdownVisible && (
          <div className={styles.dropdown}>
            <ul className={styles.dropdownMenu}>
              <li>
                {!isCyclist && (
                  <DropdownMenuItemComponent
                    text="Anthropometry"
                    className={styles.dropdownMenu}
                    handleClick={async () => togglePopup("anthropometry")}
                  />
                )}
                {!isCyclist && (
                  <DropdownMenuItemComponent
                    text="Performance"
                    className={styles.dropdownMenu}
                    handleClick={async () => togglePopup("performance")}
                  />
                )}
                <DropdownMenuItemComponent
                  text="RPE"
                  className={styles.dropdownMenu}
                  handleClick={async () => togglePopup("fairlyLight")}
                />
                <DropdownMenuItemComponent
                  text="TTE - Time to Exhaustion"
                  className={styles.dropdownMenu}
                  handleClick={async () => togglePopup("tte")}
                />
                <DropdownMenuItemComponent
                  text="Testing Heart Rate"
                  className={styles.dropdownMenu}
                  handleClick={async () => togglePopup("thr")}
                />
                <DropdownMenuItemComponent
                  text="Power Held"
                  className={styles.dropdownMenu}
                  handleClick={async () => togglePopup("powerHeld")}
                />
              </li>
            </ul>
          </div>
        )}
      </div>
      {Object.keys(popups).map(
        (key) => popups[key as keyof PopupState] && renderPopup(key as keyof PopupState)
      )}
    </>
  );
};

import { forwardRef, ReactNode } from "react";
import clsx from "clsx";

import styles from "./title.module.scss";

export type TitleProps = {
  tag: "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  size?: "l" | "xl" | "xxl";
  weight?: "regular" | "medium" | "bold";
  className?: string;
  dataTestId?: string;
  children?: ReactNode;
};

type TitleRef = HTMLHeadingElement | HTMLDivElement;

export const Title = forwardRef<TitleRef, TitleProps>(
  (
    {
      tag: Component,
      children,
      className,
      dataTestId,
      size = "xxl",
      weight = "bold",
    },
    ref
  ) => (
    <Component
      ref={ref as never}
      className={clsx(className, styles.title, styles[size], styles[weight])}
      data-testid={dataTestId}
    >
      {children}
    </Component>
  )
);

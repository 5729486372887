import { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import Calendar, { CalendarTileProperties } from "react-calendar";
import styles from "./anthropometry-popup.module.scss";
import { isSameDay, getDay, format } from "date-fns";
import { PrimaryButton } from "components/primary-button/primary-button";
import {
  ReusableInputField,
  ReusableInputFieldProps,
} from "./resusable-input-field/resusable-input-field";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { fetchAllAnthro, getAnthroList, getCurrentAthlete } from "store/slices/shared";
import { submitAnthropometry } from "../../../api/anthropometry";
import { trackError } from "appInsights/logInsights";
import "../performance-popup/date-calendar.overwrite.scss";
import { formatDatePower } from "utils";
import { Athlete } from "types";

type Props = {
  onClose: () => void;
  selectedAthlete?: Athlete;
};

export interface FormState {
  [key: string]: string;
}

export interface ErrorsState {
  [key: string]: boolean;
}

const fieldProperties: ReusableInputFieldProps[] = [
  { name: "height", label: "Height", min: 150, max: 200, unit: "cm" },
  { name: "weight", label: "Weight", min: 40, max: 100, unit: "kg" },
  { name: "biceps", label: "Biceps", min: 1, max: 30, unit: "mm" },
  { name: "subscapula", label: "Subscapula", min: 1, max: 30, unit: "mm" },
  { name: "iliacCrest", label: "Iliac Crest", min: 1, max: 30, unit: "mm" },
  { name: "abdominal", label: "Abdominal", min: 1, max: 30, unit: "mm" },
  { name: "triceps", label: "Triceps", min: 1, max: 30, unit: "mm" },
  { name: "thigh", label: "Thigh", min: 1, max: 30, unit: "mm" },
  { name: "supraspinale", label: "Supraspinale", min: 1, max: 30, unit: "mm" },
  { name: "calf", label: "Calf", min: 1, max: 30, unit: "mm" },
];

export const AnthropometryPopup: FC<Props> = ({ onClose, selectedAthlete }) => {
  const dispatch = useDispatch();
  const currentAthleteRedux = useSelector(getCurrentAthlete);
  const currentAthlete = selectedAthlete ?? currentAthleteRedux;
  const anthroList = useSelector(getAnthroList);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const calendarRef = useRef(null);
  const [form, setForm] = useState<FormState>({
    height: currentAthlete?.height.toString() || "",
    weight: "",
    biceps: "",
    subscapula: "",
    iliacCrest: "",
    abdominal: "",
    triceps: "",
    thigh: "",
    supraspinale: "",
    calf: "",
  });

  const [errors, setErrors] = useState<ErrorsState>({
    height: false,
    weight: false,
    biceps: false,
    subscapula: false,
    iliacCrest: false,
    abdominal: false,
    triceps: false,
    thigh: false,
    calf: false,
  });

  useEffect(() => {
    if (currentAthlete) {
      dispatch(fetchAllAnthro(currentAthlete.id));
    }
  }, [currentAthlete, dispatch]);

  useEffect(() => {
    if (anthroList && anthroList?.length > 0) {
      const lastAnthroData = anthroList.find(
        (anthro) => anthro.date === formatDatePower(dateSelected)
      );
      if (lastAnthroData) {
        setIsUpdate(true);
        setForm({
          height: lastAnthroData.height.toString(),
          weight: lastAnthroData.weight.toString(),
          biceps: lastAnthroData.biceps.toString(),
          subscapula: lastAnthroData.subscapula.toString(),
          iliacCrest: lastAnthroData.iliacCrest.toString(),
          abdominal: lastAnthroData.abdominal.toString(),
          triceps: lastAnthroData.triceps.toString(),
          thigh: lastAnthroData.thigh.toString(),
          supraspinale: lastAnthroData.supraspinale.toString(),
          calf: lastAnthroData.calf.toString(),
        });
      } else {
        setIsUpdate(false);
        setForm({
          height: currentAthlete?.height.toString() || "",
          weight: "",
          biceps: "",
          subscapula: "",
          iliacCrest: "",
          abdominal: "",
          triceps: "",
          thigh: "",
          supraspinale: "",
          calf: "",
        });
      }
    }
  }, [anthroList, currentAthlete?.height, dateSelected]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const range = fieldProperties.find((prop) => prop.name === name);
    const numericValue = parseFloat(value);
    const isValid = numericValue >= range!.min! && numericValue <= range!.max!;

    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: !isValid });
  };

  const handleDateChange = (value: Date): void => {
    setDateSelected(value);
  };

  const handleSubmitClick = async (): Promise<void> => {
    const dateOfAnthro = formatDatePower(dateSelected);
    const newErrors: ErrorsState = Object.keys(form).reduce<ErrorsState>((acc, key) => {
      acc[key] = form[key as keyof FormState].trim() === "";
      return acc;
    }, {} as ErrorsState);

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error === true);

    if (!hasErrors && currentAthlete) {
      try {
        const response = await submitAnthropometry(currentAthlete.id, form, dateOfAnthro);
        if (response) {
          onClose();
        }
      } catch (error) {
        trackError(error as Error, { location: "submitAnthropometry" });
        console.error("Error submitting form", error);
      }
    } else {
      console.error("Form submission prevented due to validation errors.");
    }
  };

  const tileContent = ({ date, view }: CalendarTileProperties) => {
    if (view === "month") {
      if (
        !isSameDay(date, dateSelected) &&
        anthroList?.find((anthro) => anthro.date === formatDatePower(date))
      ) {
        return <div className={clsx(styles.anthroData, styles.ellipse)}></div>;
      } else {
        return <div className={clsx(styles.anthroData)}></div>;
      }
    }
    return null;
  };

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div className={clsx(styles.inputBox, styles.box)} data-testid="add-comparison-list">
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>Anthropometry</h3>
            <Close
              className={styles.closeButton}
              onClick={onClose}
              aria-label="Close"
              title="Close"
            />
          </div>
          <div className={styles.athleteCardWrapper}>
            <div className={styles.calendar}>
              <div
                ref={calendarRef}
                className={clsx("current-date-calendar", styles.calendarWrapper)}
              >
                <Calendar
                  onChange={handleDateChange}
                  value={dateSelected}
                  maxDate={new Date()}
                  minDetail="month"
                  prev2Label={null}
                  next2Label={null}
                  formatShortWeekday={(locale, date) => format(date, "E")[0]}
                  formatMonthYear={(locale, date) => format(date, "MMM yyyy")}
                  tileContent={tileContent}
                  minDate={
                    new Date(
                      new Date().getFullYear() - 3,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  }
                  locale="en"
                />
              </div>
            </div>
            <form className={styles.form} data-testid="change-password-form">
              {fieldProperties.map(({ name, label, min, max, unit }) => (
                <ReusableInputField
                  key={name}
                  name={name}
                  label={label}
                  min={min}
                  max={max}
                  unit={unit}
                  value={form[name as keyof FormState]}
                  onChange={handleInputChange}
                  error={errors[name as keyof ErrorsState]}
                />
              ))}
            </form>
          </div>
          <div className={styles.footer}>
            <PrimaryButton
              className={styles.addButton}
              onClick={handleSubmitClick}
              title={isUpdate ? "Update" : "Submit"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { FC } from "react";
import clsx from "clsx";

import { ChartSize } from "components/charts/constants";

import { ReactComponent as NoDataIcon } from "images/no-data.svg";

import styles from "./no-data.module.scss";

type Props = {
  type?: "line" | "bar";
  chartSize?: ChartSize;
  positionStatic?: boolean;
};

export const NoData: FC<Props> = ({
  chartSize,
  type = "line",
  positionStatic = false,
}) => {
  return (
    <div
      className={clsx(
        styles.container,
        chartSize === ChartSize.Small && styles.small,
        type === "bar" && styles.bar,
        positionStatic && styles.static
      )}
    >
      <NoDataIcon />
      <div className={styles.caption}> No data was returned</div>
    </div>
  );
};

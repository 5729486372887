import { toEChartsTextStyle } from "./utils";
import { COLOR_DARK_GRAY, COLOR_AXIS, COLOR_WHITE } from "./colors";
import { FONT_SIZE_XS, FONT_SIZE_XSS, FONT_SIZE_M } from "./constants";

export const DEFAULT_CHART_X_AXIS_PHYS = {
  axisLabel: {
    ...toEChartsTextStyle(
      {},
      {
        color: COLOR_WHITE,
        size: FONT_SIZE_XSS,
        fontFamily: "Montserrat",
      }
    ),
    margin: 13,
  },
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
  axisLine: {
    show: false,
    lineStyle: {
      color: "rgba(84, 93, 118, 0.8)",
    },
  },
  type: "category",
};

export const DEFAULT_CHART_Y_AXIS_PHYS = {
  axisLabel: {
    ...toEChartsTextStyle(
      {},
      {
        color: COLOR_AXIS,
        size: FONT_SIZE_XSS,
        fontFamily: "Montserrat",
      }
    ),
    formatter: "{value}",
  },
  nameTextStyle: {
    fontSize: FONT_SIZE_M,
    textTransform: "upper-case",
  },
  axisTick: {
    show: false,
  },
  axisLine: {
    show: false,
    lineStyle: {
      color: COLOR_WHITE,
    },
  },
  splitLine: {
    lineStyle: {
      color: COLOR_DARK_GRAY,
    },
  },
  type: "value",
};

export const DEFAULT_CHART_TITLE_PHYS = {
  show: true,
  top: 0,
  left: 0,
  textStyle: {
    color: COLOR_WHITE,
    size: FONT_SIZE_M,
    fontFamily: "Montserrat",
    rich: {
      a: {
        fontSize: FONT_SIZE_XS,
        fontWeight: "bold",
        fontFamily: "Montserrat",
      },
      b: {
        fontSize: FONT_SIZE_XS,
        fontWeight: "normal",
        fontFamily: "Montserrat",
      },
    },
  },
};

import { FC, useEffect, useState, useMemo } from "react";
import { format } from "date-fns";
import clsx from "clsx";

import { ReactComponent as HeartMeasurementsIcon } from "images/heart-measurements.svg";
import { ReactComponent as BodyCompositionIcon } from "images/body-composition.svg";
import { ReactComponent as WellnessIcon } from "images/wellness.svg";

import { ScoreCard } from "components/score-card/score-card";
import { ScoreBars, BarItem } from "components/score-bars/score-bars";
import { MemoizedLegendButton } from "components/legend-button/legend-button";
import { ChartSize } from "components/charts/constants";
import { LAST_UPDATE_DATE } from "components/current-date-calendar/current-date-calendar";
import { NoData } from "components/charts/no-data/no-data";

import { fetchWellnessLastUpdates } from "store/slices/wellness";
import {
  getCurrentAthlete,
  getCurrentComparison,
  getCurrentDate,
} from "store/slices/shared";
import { useDispatch, useSelector } from "hooks/app-hooks";

import { BarColors, DateRange } from "enums";
import { roundFloat, multiplier } from "utils";
import { WellnessBarScores } from "types";

import styles from "./wellness-scores.module.scss";
import { Source } from "components/source/source";

const getScoreCardValueColor = (currentDate: number, lastUpdateDate?: number): string => {
  const date = currentDate === LAST_UPDATE_DATE ? lastUpdateDate : currentDate;
  if (!date) {
    return BarColors.Default;
  }
  const dateDistance = new Date().getTime() - date;
  if (dateDistance < DateRange.FourDays) {
    return BarColors.New;
  }
  if (dateDistance <= DateRange.EightDays) {
    return BarColors.Average;
  }
  return BarColors.Old;
};

const checkDataValidity: (data?: WellnessBarScores) => boolean = (data) => {
  return data ? Object.entries(data).some(([, value]) => value) : false;
};

const getScoreBarValue = (
  dateScore: number | undefined,
  lastUpdateScore: number | undefined,
  showRecentDates: boolean,
  isLegendValue: boolean
) => {
  if (!isLegendValue) {
    return 0;
  }
  return showRecentDates ? lastUpdateScore : dateScore;
};

type Props = {
  topHeader: string;
};

export const WellnessScores: FC<Props> = ({ topHeader }) => {
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentDate = useSelector(getCurrentDate);
  const showRecentDates = currentDate === LAST_UPDATE_DATE;
  const currentComparison = useSelector(getCurrentComparison);
  const [athleteLegend, setAthleteLegend] = useState(true);

  const scores = useSelector((state) => state.wellness.overallScore);
  const lastUpdates = useSelector((state) => state.wellness.lastUpdates);
  const comparison = useSelector((state) => state.wellness.overallScoreComparison);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWellnessLastUpdates());
  }, [dispatch, currentAthlete?.id, currentComparison]);

  const getComparisonValue = (dateValue?: number, lastUpdateValue?: number) => {
    if (!currentComparison) {
      return undefined;
    }
    return showRecentDates ? lastUpdateValue : roundFloat(dateValue);
  };

  const barColor = getScoreCardValueColor(currentDate, lastUpdates?.athlete.wellness.date);

  const scoreValues: BarItem[] = useMemo(() => {
    return [
      {
        name: "General",
        athleteValue: getScoreBarValue(
          scores?.wellness.generalScore,
          lastUpdates?.athlete.wellness.data?.generalScore,
          showRecentDates,
          athleteLegend
        )
      },
      {
        name: "Fatigue",
        athleteValue: getScoreBarValue(
          scores?.wellness.fatigueScore,
          lastUpdates?.athlete.wellness.data?.fatigueScore,
          showRecentDates,
          athleteLegend
        )
      },
      {
        name: "Stress",
        athleteValue: getScoreBarValue(
          scores?.wellness.stressScore,
          lastUpdates?.athlete.wellness.data?.stressScore,
          showRecentDates,
          athleteLegend
        ),
      },
      {
        name: "Mood",
        athleteValue: getScoreBarValue(
          scores?.wellness.moodScore,
          lastUpdates?.athlete.wellness.data?.moodScore,
          showRecentDates,
          athleteLegend
        ),
      },
      {
        name: "Sleep",
        athleteValue: getScoreBarValue(
          scores?.wellness.sleepScore,
          lastUpdates?.athlete.wellness.data?.sleepScore,
          showRecentDates,
          athleteLegend
        ),
      },
    ];
  }, [scores, lastUpdates, athleteLegend, showRecentDates]);

  const hasData = showRecentDates
    ? checkDataValidity(lastUpdates?.athlete.wellness.data) ||
      checkDataValidity(lastUpdates?.comparison?.wellness.data)
    : checkDataValidity(scores?.wellness) || checkDataValidity(comparison?.wellness);

  return (
    <div className={styles.wrapper} data-testid="heart-measurements">
      <div className={styles.topHeader}>{topHeader}</div>
      <div className={styles.header}>
        <span className={styles.title}>
          <HeartMeasurementsIcon className={styles.iconColor} />
          Heart Measurements
        </span>
        <div className={styles.colorsLegend}>
          <MemoizedLegendButton name="> 7 days" color={BarColors.Old} type={ChartSize.Big} />
          <MemoizedLegendButton name="4-7 days" color={BarColors.Average} type={ChartSize.Big} />
          <MemoizedLegendButton name="< 4 days" color={BarColors.New} type={ChartSize.Big} />
        </div>
      </div>
      <div className={styles.panels}>
        <div className={styles.leftPanel}>
          <div className={styles.panelContent}>
            <ScoreCard
              color={getScoreCardValueColor(currentDate, lastUpdates?.athlete.hrv.date)}
              source={lastUpdates?.athlete?.hrv?.source}
              lastUpdateDate={lastUpdates?.athlete.hrv.date}
              hideLastUpdate={!showRecentDates}
              showNoData={!showRecentDates && roundFloat(scores?.hrv) === 0}
              title="HRV (ms)"
              athleteValue={
                showRecentDates ? lastUpdates?.athlete.hrv.data : roundFloat(scores?.hrv)
              }
              comparisonValue={getComparisonValue(
                comparison?.hrv,
                lastUpdates?.comparison?.hrv.data
              )}
            />
            <ScoreCard
              color={getScoreCardValueColor(currentDate, lastUpdates?.athlete.rhr.date)}
              source={lastUpdates?.athlete?.rhr?.source}
              lastUpdateDate={lastUpdates?.athlete.rhr.date}
              hideLastUpdate={!showRecentDates}
              showNoData={!showRecentDates && roundFloat(scores?.rhr) === 0}
              title="Resting HR (bpm)"
              athleteValue={
                showRecentDates ? lastUpdates?.athlete.rhr.data : roundFloat(scores?.rhr)
              }
              comparisonValue={getComparisonValue(
                comparison?.rhr,
                lastUpdates?.comparison?.rhr.data
              )}
            />
          </div>
          <div className={clsx(styles.header, styles.marginTop20)}>
            <span className={styles.title}>
              <BodyCompositionIcon className={styles.iconColor} />
              Body Composition
            </span>
          </div>
          <div className={styles.panelContent}>
            <ScoreCard
              color={getScoreCardValueColor(currentDate, lastUpdates?.athlete.height.data)}
              source={lastUpdates?.athlete?.height?.source}
              lastUpdateDate={lastUpdates?.athlete.height.date}
              hideLastUpdate={!showRecentDates}
              showNoData={!showRecentDates && roundFloat(scores?.height) === 0}
              title="Height (cm)"
              athleteValue={
                showRecentDates ? lastUpdates?.athlete.height.data : roundFloat(scores?.height)
              }
              comparisonValue={getComparisonValue(
                comparison?.height,
                lastUpdates?.comparison?.height.data
              )}
            />
            <ScoreCard
              color={getScoreCardValueColor(currentDate, lastUpdates?.athlete.bodyMass.date)}
              source={lastUpdates?.athlete?.bodyMass?.source}
              lastUpdateDate={lastUpdates?.athlete.bodyMass.date}
              hideLastUpdate={!showRecentDates}
              showNoData={!showRecentDates && roundFloat(scores?.bodyMass) === 0}
              title="Body Mass (kg)"
              athleteValue={
                showRecentDates ? lastUpdates?.athlete.bodyMass.data : roundFloat(scores?.bodyMass)
              }
              comparisonValue={getComparisonValue(
                comparison?.bodyMass,
                lastUpdates?.comparison?.bodyMass.data
              )}
            />
            <ScoreCard
              color={getScoreCardValueColor(currentDate, lastUpdates?.athlete.bmi.date)}
              source={lastUpdates?.athlete?.bmi?.source}
              lastUpdateDate={lastUpdates?.athlete.bmi.date}
              hideLastUpdate={!showRecentDates}
              showNoData={!showRecentDates && roundFloat(scores?.bmi) === 0}
              title="Body Mass Index"
              athleteValue={
                showRecentDates ? lastUpdates?.athlete.bmi.data : roundFloat(scores?.bmi)
              }
              comparisonValue={getComparisonValue(
                comparison?.bmi,
                lastUpdates?.comparison?.bmi.data
              )}
            />
          </div>
          <div className={clsx(styles.panelContent, styles.marginTop20)}>
            <ScoreCard
              color={getScoreCardValueColor(currentDate, lastUpdates?.athlete.skinFold.date)}
              source={lastUpdates?.athlete?.skinFold?.source}
              lastUpdateDate={lastUpdates?.athlete.skinFold.date}
              hideLastUpdate={!showRecentDates}
              showNoData={!showRecentDates && roundFloat(scores?.skinFold) === 0}
              title="Sum of Skinfolds (mm)"
              athleteValue={
                showRecentDates ? lastUpdates?.athlete.skinFold.data : roundFloat(scores?.skinFold)
              }
              comparisonValue={getComparisonValue(
                comparison?.skinFold,
                lastUpdates?.comparison?.skinFold.data
              )}
            />
            <ScoreCard
              color={getScoreCardValueColor(currentDate, lastUpdates?.athlete.bodyFatPercent.date)}
              source={lastUpdates?.athlete?.bodyFatPercent?.source}
              lastUpdateDate={lastUpdates?.athlete.bodyFatPercent.date}
              hideLastUpdate={!showRecentDates}
              showNoData={!showRecentDates && roundFloat(scores?.bodyFatPercent) === 0}
              title="Calculated Body Fat %"
              athleteValue={
                showRecentDates ? lastUpdates?.athlete.bodyFatPercent.data : roundFloat(scores?.bodyFatPercent)
              }
              comparisonValue={getComparisonValue(
                comparison?.bodyFatPercent,
                lastUpdates?.comparison?.bodyFatPercent.data
              )}
            />
            <ScoreCard
              color={getScoreCardValueColor(currentDate, lastUpdates?.athlete.fatMass.date)}
              lastUpdateDate={lastUpdates?.athlete.fatMass.date}
              hideLastUpdate={!showRecentDates}
              showNoData={!showRecentDates && roundFloat(scores?.fatMass) === 0}
              title="Fat Mass (kg)"
              source={lastUpdates?.athlete?.fatMass?.source}
              athleteValue={
                showRecentDates ? lastUpdates?.athlete.fatMass.data : roundFloat(scores?.fatMass)
              }
              comparisonValue={getComparisonValue(
                comparison?.fatMass,
                lastUpdates?.comparison?.fatMass.data
              )}
            />
          </div>
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.wellnessPanel}>
            <div className={styles.wellnessContent}>
              <div className={styles.header}>
                <span className={styles.title}>
                  <WellnessIcon className={styles.iconColor} />
                  Wellness
                </span>
              </div>
              <div className={styles.barWrapper}>
                {!hasData && <NoData positionStatic />}
                {hasData && (
                  <ScoreBars color={barColor} multiplier={multiplier} items={scoreValues} />
                )}
                <div className={styles.legendIems}>
                  <span className={styles.lastUpdated}>
                    {showRecentDates && lastUpdates?.athlete?.wellness?.date && (
                      <>
                        <span className={styles.grey}>Last updated:</span>
                        {` ${format(lastUpdates.athlete.wellness.date, "dd/MM/yyyy")}`}
                      </>
                    )}
                    {(!lastUpdates ||
                      lastUpdates.athlete.wellness.date === undefined ||
                      (!showRecentDates && !hasData)) && (
                      <span className={styles.grey}>No data returned</span>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.scoreBarLegend}>
              <Source source="ATHLETE" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";

import { RadarChartIndicator } from "components/charts/radar-chart/athlete-radar-chart";

import styles from "./custom-label.module.scss";

interface Props {
  indicator: RadarChartIndicator;
  decimalPoint: number;
  style: React.CSSProperties;
}

export const MaxLabel: React.FC<Props> = ({ indicator, decimalPoint, style }) => {
  return (
    <div className={styles.athleteLabel} style={style}>
      <div className={styles.athleteMaxValue}>{indicator.max.toFixed(decimalPoint)} {indicator.unit}</div>
      <div
        className={styles.athleteLabelName}
        style={{ color: indicator.indicatorColor }}
      >
        {indicator.name}
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import styles from "./graph-chart.module.scss";
import { ReactComponent as PinTabIcon } from "images/pinTab.svg";
import { ReactComponent as ExpandIcon } from "images/expands-right.svg";
import { ReactComponent as PresentationLine } from "images/presentation-chart-line.svg";
import clsx from "clsx";
import ReactECharts from "echarts-for-react";
import {
  COLOR_TOOLTIP,
  COLOR_LIGHT_GREEN,
  COLOR_LIGHT_YELLOW,
  COLOR_LIGHTER_BLUE,
  COLOR_SALMON,
} from "components/charts/colors";
import HorizontalLoader from "./components/horizontal-loader";
import { PinChartPopup } from "./pinned-tabs/pinned-chart-popup";
import { AthleteType } from "types";
import { validateGraphConfig } from "./utils/utils"

type Props = {
  className: string;
  onExpand: () => void;
  isExpanded: boolean;
  graphData: any;
  activateGraph: boolean;
  isLoading: boolean;
  filterAthleteDetails:AthleteType[]
};

interface TooltipValue {
  $vars: string[];
  axisDim: string;
  axisId: string;
  axisIndex: number;
  axisType: string;
  axisValue: string;
  axisValueLabel: string;
  borderColor?: string;
  color: string;
  componentIndex: 0;
  componentSubType: string;
  componentType: string;
  data: {
    itemStyle: {
      color: string;
    };
    value: number;
  };
  dataIndex: 1;
  dataType: undefined;
  dimensionNames: string[];
  encode: { x: number[]; y: number[] };
  marker: string;
  name: string;
  seriesId: string;
  seriesIndex: string;
  seriesName: string;
  seriesType: string;
  value: string[];
}

const getLabelName = (color: string) => {
  switch (color) {
    case COLOR_SALMON:
      return "Large";
    case COLOR_LIGHT_YELLOW:
      return "Medium";
    case COLOR_LIGHTER_BLUE:
      return "Trivial";
    case COLOR_LIGHT_GREEN:
    default:
      return "Small";
  }
};

const loadingOptions = {
  text: 'Generating graph...',
  color: '#008b32',
  textColor: '#000',
  maskColor: 'rgba(255, 255, 255, 0.7)',
  zlevel: 0,
  fontSize: 14,
  showSpinner: true,
  spinnerRadius: 10,
  lineWidth: 5,
  fontWeight: 500,
  fontFamily: 'Montserrat, sans-serif',
};

const GraphChart = ({
  className,
  onExpand,
  isExpanded,
  graphData,
  activateGraph,
  isLoading,
  filterAthleteDetails
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [togglePopup, setTogglePopup] = useState(false)


 

  return (
    <div className={clsx(styles.graphContainer, className)}>
      {isExpanded && isLoading && <HorizontalLoader />}
      <div
        className={clsx(
          isExpanded ? [styles.graphExpandContainer] : styles.graphHeaderWrapper
        )}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={
            activateGraph ? styles.graphRenderer : styles.graphWelcomePage
          }
          onMouseEnter={() => setIsHovered(true)}
        >
          {validateGraphConfig(graphData) ? (
            <>
              <ReactECharts
                key={JSON.stringify(graphData)}
                opts={{ renderer: "svg" }}
                option={graphData}
                style={{ height: "100%", width: "100%", padding: "1rem" }}
              // showLoading={isLoading}
              // loadingOption={loadingOptions}
              />
              {isHovered && (
                <div
                  className={clsx(
                    isExpanded ? styles.graphIconsExpanded : styles.graphIcons,
                    !isExpanded && styles.graphWidth
                  )}
                >
                  {/* <EditIcon className={clsx(styles.icons, styles.editIcon)} /> */}

                  <PinTabIcon
                    className={styles.icons}
                    style={{ padding: "2px" }}
                    onClick={() => setTogglePopup(true)}
                  />
                  {/* <UploadIcon className={styles.icons} /> */}
                  <ExpandIcon
                    className={clsx(styles.icons, styles.expandIcon)}
                    onClick={onExpand}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className={styles.emptyGraphWrapper}>
                <PresentationLine
                  className={`${styles.graphImage} ${isLoading ? styles.loading : ""}`}
                />
                {!isLoading ? (
                  <span>Your AI-generated graph will render here!</span>
                ) : (
                  <span>Generating chart...</span>
                )}
              </div>
            </>
          )}
          {
            togglePopup && <PinChartPopup onClose={() => setTogglePopup(false)} filterAthleteDetails={filterAthleteDetails}/>
          }
        </div>
      </div>
    </div>
  );
};

export default GraphChart;

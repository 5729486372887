import { FC, useCallback, useEffect, useMemo, useState } from "react";

import {
  calculateDateRange,
  formatDateAPI,
  getWithExpiry,
  setWithExpiry,
  stringifyDateRange,
} from "utils";

import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getCurrentAthlete,
  getCurrentDataType,
  getCurrentDateRange,
  getCurrentWorkOption,
  setCurrentWorkOption,
} from "store/slices/shared";
import { ChartSize } from "components/charts/constants";
import { COLOR_BLUE, COLOR_GRAY, COLOR_GREEN, COLOR_LIGHT_RED } from "components/charts/colors";
import { Loading } from "components/loading/loading";

import styles from "./new-fatigue-resistance-charts.module.scss";
import { LineChartTimeWithHeader } from "components/charts/scatter-chart/line-chart-time";
import { DataTypeSelector, DateRange, WorkOptions } from "enums";
import { WorkSelector } from "components/work-selector/work-selector";
import { FatigureResistanceType } from "api/chart";
import { ChartMeta } from "types";
import { fetchChartData } from "store/slices/chart";
import { debounce } from "lodash";
import { TypeSelector } from "components/type-selector/type-selector";
import clsx from "clsx";

const key = "athleteFatigueResistance-labels";

function getChartValues(dataPoints: number[]) {
  return dataPoints.map((value: number) => ({
    value,
    units: "W",
  }));
}

function getRelativeDataPoints(dataPoints: number[]) {
  return dataPoints.map((value: number) => ({
    value: value,
    units: "W/kg",
    fixedNumber: 2,
  }));
}

function getPercentageValues(mainDataPoints: number[], dataPoints: number[]) {
  return mainDataPoints.map((value: number, index: number) => {
    return {
      value: (1 - value / dataPoints[index]) * 100,
      units: "%",
      fixedNumber: 2,
    };
  });
}

const getChartMeta = (
    name: string,
    dateRange: DateRange,
    athleteId: number
): ChartMeta<FatigureResistanceType> => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    api: {
      url: "/performance/fatigueResistance/:startDate/:endDate?unit=:unit&athleteId=:athleteId",
      params: {
        ":unit": stringifyDateRange(dateRange),
        ":startDate": formatDateAPI(startDate),
        ":endDate": formatDateAPI(endDate),
        ":athleteId": `${athleteId}`
      },
    },
    charts: [
      {
        name: `${name}FatigueResistance`,
        getLabels: (data: FatigureResistanceType) =>
            data?.dataPoints?.map((value: number, index: number) => Math.log2(index).toString()) ||
            [],
        getValues: (data: FatigureResistanceType) => ({
          dataPoints: getChartValues(data?.dataPoints || []),
          dataPointsAfter2kKJ: getChartValues(data?.dataPointsAfter2kKJ || []),
          dataPointsAfter3kKJ: getChartValues(data?.dataPointsAfter3kKJ || []),
          dataPointsAfter1900KJ: getChartValues(data?.dataPointsAfter1900KJ || []),
          dataPointsAfter2800KJ: getChartValues(data?.dataPointsAfter2800KJ || []),
          percentageDecline2kKJ: getPercentageValues(data?.dataPointsAfter2kKJ || [], data?.dataPoints || []),
          percentageDecline3kKJ: getPercentageValues(data?.dataPointsAfter3kKJ || [], data?.dataPoints || []),
          percentageDecline1900KJ: getPercentageValues(data?.dataPointsAfter1900KJ || [], data?.dataPoints || []),
          percentageDecline2800KJ: getPercentageValues(data?.dataPointsAfter2800KJ || [], data?.dataPoints || []),
        }),
      },
    ],
  };
};

const getChartMetaRelative = (
    name: string,
    dateRange: DateRange,
    athleteId: number
): ChartMeta<FatigureResistanceType> => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    api: {
      url: "/performance/fatigueResistanceRelative/:startDate/:endDate?unit=:unit&athleteId=:athleteId",
      params: {
        ":unit": stringifyDateRange(dateRange),
        ":startDate": formatDateAPI(startDate),
        ":endDate": formatDateAPI(endDate),
        ":athleteId": `${athleteId}`
      },
    },
    charts: [
      {
        name: `${name}FatigueResistanceRelative`,
        getLabels: (data: FatigureResistanceType) =>
            data?.dataPoints?.map((value: number, index: number) => Math.log2(index).toString()) ||
            [],
        getValues: (data: FatigureResistanceType) => ({
          dataPoints: getChartValues(data?.dataPoints || []),
          dataPointsAfter2kKJ: getChartValues(data?.dataPointsAfter2kKJ || []),
          dataPointsAfter3kKJ: getChartValues(data?.dataPointsAfter3kKJ || []),
          dataPointsAfter1900KJ: getChartValues(data?.dataPointsAfter1900KJ || []),
          dataPointsAfter2800KJ: getChartValues(data?.dataPointsAfter2800KJ || []),
          percentageDecline2kKJ: getPercentageValues(data?.dataPointsAfter2kKJ || [], data?.dataPoints || []),
          percentageDecline3kKJ: getPercentageValues(data?.dataPointsAfter3kKJ || [], data?.dataPoints || []),
          percentageDecline1900KJ: getPercentageValues(data?.dataPointsAfter1900KJ || [], data?.dataPoints || []),
          percentageDecline2800KJ: getPercentageValues(data?.dataPointsAfter2800KJ || [], data?.dataPoints || []),
        }),
      },
    ],
  };
};

export const NewFatigueResistanceCharts: FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hideSeries, setHideSeries] = useState({});
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentDateRange = useSelector(getCurrentDateRange);
  const currentWorkOption = useSelector(getCurrentWorkOption) || WorkOptions.kJ2000;
  const currentDataType = useSelector(getCurrentDataType);
  const athleteFatigueResistance = useSelector((state) => state.chart.athleteFatigueResistance);
  const athleteFatigueResistanceRelative = useSelector((state) => state.chart.athleteFatigueResistanceRelative);
  const [labels, setLabels] = useState<string[]>(athleteFatigueResistance?.labels ?? []);

  const fatigueResistanceSeries = useMemo(() => {
    let workOptionSeries;
    const data = (currentDataType === DataTypeSelector.ABSOLUTE) ? athleteFatigueResistance?.values : athleteFatigueResistanceRelative?.values;
    const workOptionMapping = {
      [WorkOptions.kJ2000]: {
        name: "2000 kJ",
        values: data?.dataPointsAfter2kKJ || [],
      },
      [WorkOptions.kJ3000]: {
        name: "3000 kJ",
        values: data?.dataPointsAfter3kKJ || [],
      },
      [WorkOptions.kJ1900]: {
        name: "1900 kJ",
        values: data?.dataPointsAfter1900KJ || [],
      },
      [WorkOptions.kJ2800]: {
        name: "2800 kJ",
        values: data?.dataPointsAfter2800KJ || [],
      },
    };

    workOptionSeries = workOptionMapping[currentWorkOption] || {
      name: "Unknown Work Option",
      color: COLOR_GRAY,
      values: [],
    };

    workOptionSeries = {
      ...workOptionSeries,
      color: COLOR_BLUE,
      disableClick: true,
      hideInLegend: false,
      chartType: "line",
      width: 2,
      emphasisDisabled: true,
      dashed: false,
    };

    const percentageDeclineMapping = {
      [WorkOptions.kJ2000]: data?.percentageDecline2kKJ || [],
      [WorkOptions.kJ3000]: data?.percentageDecline3kKJ || [],
      [WorkOptions.kJ1900]: data?.percentageDecline1900KJ || [],
      [WorkOptions.kJ2800]: data?.percentageDecline2800KJ || [],
    };

    return [
      {
        name: "Fresh",
        disableClick: true,
        color: COLOR_LIGHT_RED,
        hideInLegend: false,
        chartType: "line",
        width: 2,
        emphasisDisabled: true,
        dashed: false,
        values: data?.dataPoints || [],
      },
      workOptionSeries,
      {
        name: "% Decline",
        color: COLOR_GREEN,
        disableClick: true,
        hideInLegend: false,
        chartType: "line",
        width: 2,
        yAxisIndex: 1,
        emphasisDisabled: true,
        dashed: false,
        values: percentageDeclineMapping[currentWorkOption] || [],
      },
    ];
  }, [athleteFatigueResistance, currentWorkOption, currentDataType, athleteFatigueResistanceRelative]);

  useEffect(() => {
    setLoading(true);
    if (currentAthlete) {
      dispatch(
        fetchChartData<FatigureResistanceType>(
          getChartMeta("athlete", currentDateRange, currentAthlete.id)
        )
      );

      dispatch(
          fetchChartData<FatigureResistanceType>(
              getChartMetaRelative("athlete", currentDateRange, currentAthlete.id)
          )
      );
    }
    if (currentAthlete?.id) {
      setLoading(false);
    }
  }, [dispatch, currentAthlete, currentDateRange]);

  useEffect(() => {
    if (currentAthlete?.id) {
      const savedLabels = getWithExpiry(`${key}-${currentAthlete?.id}`);
      if (savedLabels === null || savedLabels === undefined || !savedLabels.length) {
        setWithExpiry(
          `${key}-${currentAthlete?.id}`,
          athleteFatigueResistance?.labels,
          15 * 60 * 1000
        );
        setLabels(athleteFatigueResistance?.labels);
      } else {
        setLabels(savedLabels);
      }
    }
  }, [athleteFatigueResistance, currentDataType, currentWorkOption, currentAthlete]);

  useEffect(() => {
    const determineInitialWorkOption = (): WorkOptions => {
      if (athleteFatigueResistance?.values?.dataPointsAfter2kKJ?.length > 0) {
        return WorkOptions.kJ2000;
      } else if (athleteFatigueResistance?.values?.dataPointsAfter1900KJ?.length > 0) {
        return WorkOptions.kJ1900;
      }
      return WorkOptions.kJ2000; // Default option if none of the specific data points exist
    };
    dispatch(setCurrentWorkOption(determineInitialWorkOption()));
  }, [athleteFatigueResistance, dispatch]);

  const saveLabels = useCallback(async () => {
    if (currentAthlete?.id) {
      const savedLabels = getWithExpiry(`${key}-${currentAthlete?.id}`);
      if (savedLabels === null || savedLabels === undefined || !savedLabels.length) {
        setWithExpiry(
          `${key}-${currentAthlete?.id}`,
          athleteFatigueResistance?.labels,
          15 * 60 * 1000
        );
        setLabels(athleteFatigueResistance?.labels);
      } else {
        setLabels(savedLabels);
      }
    }
  }, [athleteFatigueResistance, currentAthlete]);

  useEffect(() => {
    const debouncedRefetchFatigueResistance = debounce(saveLabels, 500);

    window.addEventListener("resize", debouncedRefetchFatigueResistance);

    return () => {
      window.removeEventListener("resize", debouncedRefetchFatigueResistance);
      debouncedRefetchFatigueResistance.cancel();
    };
  }, [saveLabels]);

  if (loading) {
    return (
      <div>
        <div className={styles.content}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={clsx(styles.content)}>
        <LineChartTimeWithHeader
          savedLabels={saveLabels}
          hideSeries={hideSeries}
          setHideSeries={setHideSeries}
          showLegendInSourceBar={true}
          showNoDataComp={false}
          showYAxisName={false}
          type={ChartSize.Big}
          yAxis={fatigueResistanceSeries}
          source="AI"
          name="Durability"
          labels={labels ?? []}
          series={fatigueResistanceSeries}
          titlePrefix={"MMP"}
          headerComponent={
            <div className={styles.headerContainer}>
              <div className={styles.bigTitle}>
                <span className={styles.bold}>Durability</span>
              </div>
              <div className={styles.selectors}>
                <WorkSelector />
                <TypeSelector />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
